import * as R from 'ramda';
import React from 'react';
import { shape, string, number, arrayOf } from 'prop-types';
import { NOTHING_UI_STRING, ProjectType } from '@poly/constants';
import { formatCurrencyOr, formatTotal } from '@poly/utils';
import { pathOrNothingUI } from '@poly/client-utils';
import { SidebarRow } from '@poly/admin-ui';

import {
  SectionWrapper,
  BlockWithLabel,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import {
  FeeType,
  ServiceType,
  ProjectAmount,
  NotRMComponent,
  ProjectSpendType,
  ProjectDetailsRow,
  SubPropertiesLinks,
} from '../components/commonSidebarDetailsList.js';
import { SupplierLink } from '../../components/Links.js';
import { ClientReferenceComp } from '../components/ClientReferenceComp.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import {
  ProjectSubCategoryInfoRow,
  isSubCategoryEnabled,
  getNotRMValue,
} from '../ProjectSidebar/ProjectSidebarDetails.js';

// formatHourlyRate :: Number -> String
const formatHourlyRate = R.compose(R.concat(R.__, ' / hr'), formatTotal);

// getAmountByProp :: [String] -> Project -> String
const getAmountByPath = (path) =>
  R.compose(formatCurrencyOr(NOTHING_UI_STRING), R.path(path));

// getHousekeepingMRProjectDetails :: Project -> Object
const getHousekeepingMRProjectDetails = R.compose(
  R.ifElse(
    R.isNil,
    R.always({}),
    R.evolve({
      clientMonthlyFee: formatTotal,
      clientHourlyRate: formatHourlyRate,
      supplierMonthlyFee: formatTotal,
      supplierHourlyRate: formatHourlyRate,
    }),
  ),
  R.path(['payload', ProjectType.HOUSEKEEPING]),
);

// hasSupplier :: Project -> Boolean
const hasSupplier = R.compose(R.complement(R.isEmpty), R.prop('suppliers'));

const NothingUIComponent = getSectionText(NOTHING_UI_STRING);

function SupplierComponent({ project }) {
  if (!hasSupplier(project)) {
    return <NothingUIComponent />;
  }

  return project.suppliers.map((supplier) => (
    <SupplierLink
      key={supplier._id}
      _id={supplier._id}
      name={supplier.company.name}
    />
  ));
}

SupplierComponent.propTypes = {
  project: shape({
    suppliers: arrayOf(
      shape({
        _id: string.isRequired,
        nte: number,
        company: shape({ name: string.isRequired }),
      }),
    ),
  }),
};

function SupplierNteComponent({ project }) {
  if (!hasSupplier(project)) {
    return <NothingUIComponent />;
  }

  return project.suppliers.map((supplier) => {
    const Component = getSectionText(formatTotal(supplier.nte));
    return <Component key={supplier._id} />;
  });
}

SupplierNteComponent.propTypes = {
  project: shape({
    suppliers: arrayOf(
      shape({
        _id: string.isRequired,
        nte: number,
        company: shape({ name: string.isRequired }),
      }),
    ),
  }),
};

export function MasterRecurringProjectSidebarDetails({ project }) {
  const {
    clientMonthlyFee,
    clientHourlyRate,
    weeklyPorterHours,
    supplierMonthlyFee,
    supplierHourlyRate,
  } = getHousekeepingMRProjectDetails(project);

  const isBid = project.childType === ProjectType.BID;
  const isFee = project.childType === ProjectType.FEE;
  const isHousekeeping = project.childType === ProjectType.HOUSEKEEPING;

  return (
    <SectionWrapper>
      <ProjectDetailsRow project={project} />
      <SubPropertiesLinks project={project} />
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          {...threeBlocksProps}
          id="property-location"
          label="Property Location"
          Component={getSectionText(
            R.pathOr('Other', ['propertyLocation', 'path'])(project),
          )}
        />
        {isFee && (
          <FeeType
            project={project}
            propPath={['payload', ProjectType.FEE, 'feeType']}
          />
        )}
        {isFee && (
          <BlockWithLabel
            {...threeBlocksProps}
            id="fee-amount"
            label="Fee Amount"
            Component={getSectionText(
              getAmountByPath(['payload', ProjectType.FEE, 'feeAmount'])(
                project,
              ),
            )}
          />
        )}
        {!isFee && <ServiceType project={project} />}
        <ProjectSpendType project={project} />
        <ProjectSubCategoryInfoRow project={project} />
        {!isHousekeeping && !isFee && (
          <ProjectAmount
            isRecurring
            isBid={isBid}
            project={project}
            typeProp="childType"
            bidPath={['payload', ProjectType.BID, 'bidAmount']}
          />
        )}
        {!isHousekeeping && (
          <ClientReferenceComp id="client-reference-number" project={project} />
        )}
        {isSubCategoryEnabled(project) && (
          <NotRMComponent id="not-rm" text={getNotRMValue(project)} />
        )}
        <BlockWithLabel
          id="problem-code"
          label="Problem Code"
          {...threeBlocksProps}
          Component={getSectionText(
            pathOrNothingUI(['problemCode', 'name'])(project),
          )}
        />
        {isHousekeeping && (
          <>
            <BlockWithLabel
              {...threeBlocksProps}
              id="monthly-client"
              label="Monthly Housekeeping Client"
              Component={getSectionText(clientMonthlyFee)}
            />
            <BlockWithLabel
              {...threeBlocksProps}
              id="monthly-supplier"
              label="Monthly Housekeeping Supplier"
              Component={getSectionText(supplierMonthlyFee)}
            />
            <BlockWithLabel
              {...threeBlocksProps}
              id="client-rate"
              label="Client Hourly Rate"
              Component={getSectionText(clientHourlyRate)}
            />
            <BlockWithLabel
              {...threeBlocksProps}
              id="supplier-rate"
              label="Supplier Hourly Rate"
              Component={getSectionText(supplierHourlyRate)}
            />
            <BlockWithLabel
              {...threeBlocksProps}
              id="porter-hours"
              label="Porter Hours"
              Component={getSectionText(weeklyPorterHours)}
            />
          </>
        )}
      </SidebarRow>
    </SectionWrapper>
  );
}

MasterRecurringProjectSidebarDetails.propTypes = {
  project: shape({ _id: string.isRequired }),
};
