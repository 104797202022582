import React from 'react';
import * as R from 'ramda';
import Slider from 'react-slick';
import styled from 'styled-components';
import { arrayOf, bool, shape, string, number } from 'prop-types';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const SliderS = styled(Slider)`
  .slick-arrow {
    z-index: 1;
    width: 50px;
    height: 50px;

    :before {
      font-size: 40px;
    }
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }

  .slick-slide {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  img {
    max-height: 94vh;
    max-width: 100%;
    width: auto !important;
    padding: 30px;
  }
`;

export function GallerySlider({ settings, images }) {
  const isSingleImage = images.length <= 1;

  const sliderSettings = {
    ...settings,
    ...(isSingleImage && { infinite: false }),
  };

  return (
    <SliderS {...sliderSettings}>
      {R.map(
        ({ fileName, url }) => (
          <img
            src={url}
            alt={fileName}
            key={R.pipe(R.split('/'), R.last)(url)}
          />
        ),
        images,
      )}
    </SliderS>
  );
}

GallerySlider.propTypes = {
  settings: shape({
    speed: number,
    infinite: bool,
    slidesToShow: number,
    slidesToScroll: number,
  }),
  images: arrayOf(shape({ fileName: string, url: string })),
};

GallerySlider.defaultProps = {
  images: [],
  settings: {},
};
