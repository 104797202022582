import * as R from 'ramda';
import React from 'react';
import { object } from 'prop-types';
import { LinkButtonWithLoader } from '@poly/admin-book';
import { formatDate, formatTotal } from '@poly/utils';
import {
  performExcelExport,
  EXPORT_XLS_CAPTION,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';

import { transactionTypeUILabels } from '../../modules/core/constants/journals.js';
import { useSearchTransactionsLazyQuery } from './useSearchTransactionsQuery.js';

// getSuppliersRowConfig :: TransactionSearchResult -> [ExcelExportDataCell]
const getTransactionAmountSearchRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 5),
    ExcelExportCellType.defaultCurrency,
  ]),
  R.juxt([
    R.compose(formatDate, R.prop('date')),
    R.pathOr('', ['createdBy', 'profile', 'fullName']),
    R.either(
      R.path(['supplier', 'company', 'name']),
      R.path(['client', 'name']),
    ),
    R.path(['account', 'name']),
    R.compose(R.prop(R.__, transactionTypeUILabels), R.prop('type')),
    R.compose(formatTotal, R.prop('amount')),
  ]),
);

export const getSearchTransactionsXlsExportConfig = (transactions) => ({
  exportFileName: 'transaction_amount_search_export.xlsx',
  columnWidths: [15, 20, 40, 40, 20, 20],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: 'Transaction Amount Search',
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 5),
        ExcelExportCellType.tableCurrencyHeader,
      ],
      [
        'Transaction Date',
        'Created By',
        'Account Name',
        'Chart Of Accounts',
        'Transaction Type',
        'Transaction Amount',
      ],
    ),

    ...R.map(getTransactionAmountSearchRowConfig, transactions),
  ],
});

export function SearchTransactionsXlsExportButton({ sort, filters, ...props }) {
  const queryHandler = useSearchTransactionsLazyQuery();

  const onClick = async () => {
    const { transactions } = await queryHandler({ sort, filters });

    const excelConfig = getSearchTransactionsXlsExportConfig(transactions);

    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader {...props} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}

SearchTransactionsXlsExportButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sort: object,
  // eslint-disable-next-line react/forbid-prop-types
  filters: object,
};
