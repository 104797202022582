import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { ClientBidRulesTypes } from '@poly/constants';
import { MoneyInput } from '@poly/admin-ui';
import { isNilOrEmpty } from '@poly/utils';
import {
  InputWarningMsg,
  FieldLayout,
  TextButton,
  Checkbox,
  Select,
  Input,
} from '@poly/admin-book';

import {
  Line,
  LinesWrapper,
} from '../../../../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/styled.js';
import { DeleteLineIcon } from '../../../../components/DeleteLineIcon.js';
import { ClientBidRulesAssetsWarning } from '../../constants.js';

const WrapperS = styled.div`
  width: 45%;
  display: flex;
  position: relative;
  justify-content: flex-start;

  > div:last-child {
    right: 22px;
  }
`;

const LineS = styled(Line)`
  padding-right: 0;
`;

const WarningLineS = styled(Line)`
  padding: 0 0 8px 0;

  > div {
    padding-top: 0;
  }
`;

const DeleteLineIconS = styled(DeleteLineIcon)`
  top: 8px;
`;

const LastLineS = styled(LineS)`
  justify-content: flex-end;
`;

const CheckboxS = styled(Checkbox)`
  margin-top: 10px;
`;

const SelectS = styled(Select)`
  .select__control,
  .select__control:hover,
  .select__control:focus,
  .select__control--is-focused {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &::before {
      display: block;
      position: absolute;
      content: '';
      top: 8px;
      left: 0;
      width: 1px;
      height: 14px;
      background-color: #bcbcbc;
      z-index: 1;
    }
  }

  .select__dropdown-indicator {
    padding: 0 5px 0 3px;
  }

  .select__clear-indicator {
    display: none;
  }

  .select__menu-portal,
  .select__menu {
    width: 100%;
    min-width: 30px;
  }
`;

function PercentInput(props) {
  return (
    <Input
      {...props}
      floatValue
      suffix="%"
      decimalScale={2}
      type="numberMask"
      skipFixedDecimalScale
    />
  );
}

function RuleTypeSelect(props) {
  const options = [
    { label: '%', value: ClientBidRulesTypes.jobCostPercentage },
    { label: '$', value: ClientBidRulesTypes.flatFee },
  ];

  return (
    <SelectS
      {...props}
      name="rule-type"
      options={options}
      isClearable={false}
      isSearchable={false}
    />
  );
}

RuleTypeSelect.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
};

// getPlaceholderByType :: String -> String
const getPlaceholderByType = R.ifElse(
  R.equals(ClientBidRulesTypes.jobCostPercentage),
  R.always('Enter percentage'),
  R.always('Enter amount'),
);

// getInputComponentByType :: String -> ReactComponent
const getInputComponentByType = R.ifElse(
  R.equals(ClientBidRulesTypes.jobCostPercentage),
  R.always(PercentInput),
  R.always(MoneyInput),
);

// getLineWarningIfNeed :: BidRule -> String
const getLineWarningIfNeed = R.ifElse(
  R.allPass([
    R.propEq(true, 'isRuleActive'),
    R.propEq(true, 'showAssetsWarning'),
    R.propSatisfies(R.both(R.complement(isNilOrEmpty), R.lt(0)), 'amount'),
  ]),
  R.always(ClientBidRulesAssetsWarning),
  R.always(null),
);

export function ClientBidRulesLineComponent({
  name,
  index,
  fields: { value, remove, push },
}) {
  const lineIsDefault = index < 2;
  const lineType = value[index].type;
  const isLastLine = index === value.length - 1;
  const isLineSelected = value[index].isRuleActive;

  const placeholder = getPlaceholderByType(lineType);
  const InputComponent = getInputComponentByType(lineType);

  const warningMessage = getLineWarningIfNeed(value[index]);

  const onAddNewLine = () =>
    push({ type: ClientBidRulesTypes.flatFee, isRuleActive: false });

  return (
    <LinesWrapper>
      <LineS>
        <FieldLayout
          layout={{ width: '5%' }}
          field={{
            Component: CheckboxS,
            name: `${name}.isRuleActive`,
          }}
        />
        <FieldLayout
          required
          layout={{ width: '45%' }}
          disabled={lineIsDefault}
          field={{
            Component: Input,
            name: `${name}.label`,
          }}
          validators={[[R.identity, 'Rule Label is required']]}
        />
        <WrapperS>
          <FieldLayout
            required={isLineSelected}
            layout={{ width: lineIsDefault ? '100%' : '72%' }}
            field={{
              name: `${name}.amount`,
              Component: InputComponent,
              additionalProps: { placeholder },
            }}
            validators={[
              ...(isLineSelected
                ? [[R.complement(R.isNil), 'Rule Amount is required']]
                : []),
            ]}
          />
          {!lineIsDefault && (
            <>
              <DeleteLineIconS
                size={14}
                name="delete"
                onClick={() => remove(index)}
              />
              <FieldLayout
                layout={{ width: '20%', position: 'absolute', right: 0 }}
                field={{
                  name: `${name}.type`,
                  Component: RuleTypeSelect,
                }}
              />
            </>
          )}
        </WrapperS>
      </LineS>
      {!!warningMessage && (
        <WarningLineS>
          <InputWarningMsg>{warningMessage}</InputWarningMsg>
        </WarningLineS>
      )}
      {isLastLine && (
        <LastLineS>
          <TextButton onClick={onAddNewLine}>Add New Rule</TextButton>
        </LastLineS>
      )}
    </LinesWrapper>
  );
}

ClientBidRulesLineComponent.displayName = 'ClientBidRulesLineComponent';

ClientBidRulesLineComponent.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    push: func.isRequired,
    remove: func.isRequired,
    value: arrayOf(shape({ type: string.isRequired })).isRequired,
  }),
};
