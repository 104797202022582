import React from 'react';
import * as R from 'ramda';
import { bool, shape, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { MoneyInputAsCents } from '@poly/admin-ui/src/modules/forms/fields/MoneyInput.js';
import { useNotificationState } from '@poly/admin-ui/src/hooks/useNotificationState.js';
import { useModalContext } from '@poly/admin-ui/src/components/Modal/ModalProvider.js';
import { usePristineSubscribe } from '@poly/client-routing/src/hooks.js';
import { FormCreator } from '@poly/admin-book/src/Form/FormCreator.js';
import { Input } from '@poly/admin-book/src/Input/index.js';

import {
  addAssetManufacturerFormId,
  editAssetManufacturerFormId,
} from './constants.js';
import { commonModalLayout } from '../../modules/forms/common.js';

const editSuccessMessage = 'Manufacturer was edited successfully';
const createSuccessMessage = 'Manufacturer was created successfully';

const assetManufacturersFormSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Name',
        order: 1,
        layout: { row: 1 },
        required: true,
        validators: [[R.identity, 'Manufacturer name is required']],
        field: {
          name: 'name',
          Component: Input,
        },
      },
      {
        label: 'Asset Replacement Cost',
        order: 2,
        layout: { row: 2 },
        field: {
          name: 'replacementCost',
          Component: MoneyInputAsCents,
        },
      },
    ],
  },
];

const CREATE_ASSET_MANUFACTURER_MUTATION = gql`
  mutation CREATE_ASSET_MANUFACTURER_MUTATION(
    $input: CreateAssetManufacturerInput!
  ) {
    createAssetManufacturer(input: $input) {
      _id
    }
  }
`;

const UPDATE_ASSET_MANUFACTURER_MUTATION = gql`
  mutation UPDATE_ASSET_MANUFACTURER_MUTATION(
    $id: ID!
    $input: UpdateAssetManufacturerInput!
  ) {
    updateAssetManufacturer(id: $id, input: $input) {
      _id
    }
  }
`;

// prepareMutationVariables :: (Boolean, AssetManufacturer) -> FormData -> { variables: { input:  } }
// Result = {
//    variables: {
//      input: CreateAssetManufacturerInput | UpdateAssetManufacturerInput
//    }
// }
const prepareMutationVariables = (isNewManufacturer, document) =>
  R.compose(
    R.objOf('variables'),
    R.ifElse(
      () => isNewManufacturer,
      R.compose(R.objOf('input'), R.pick(['name', 'replacementCost'])),
      R.compose(
        R.assoc('id', document._id),
        R.objOf('input'),
        R.applySpec({
          name: R.prop('name'),
          replacementCost: R.propOr(null, 'replacementCost'),
        }),
      ),
    ),
  );

export function AssetManufacturersForm({
  document,
  isNewManufacturer,
  ...props
}) {
  const { closeModal } = useModalContext();
  const pristineSubscribeProps = usePristineSubscribe();
  const { showSuccessNotification } = useNotificationState();

  const submitMutation = isNewManufacturer
    ? CREATE_ASSET_MANUFACTURER_MUTATION
    : UPDATE_ASSET_MANUFACTURER_MUTATION;

  const [assetManufacturerMutation] = useMutation(submitMutation);

  const formId = isNewManufacturer
    ? addAssetManufacturerFormId
    : editAssetManufacturerFormId;

  const successMessage = isNewManufacturer
    ? createSuccessMessage
    : editSuccessMessage;

  const onSubmit = async (input) => {
    await assetManufacturerMutation(
      prepareMutationVariables(isNewManufacturer, document)(input),
    );

    showSuccessNotification(successMessage);
    closeModal(formId);
  };

  return (
    <FormCreator
      {...props}
      id={formId}
      formId={formId}
      onSubmit={onSubmit}
      onCancel={closeModal}
      layout={commonModalLayout}
      successMessage={successMessage}
      sections={assetManufacturersFormSections}
      initialValues={R.pick(['name', 'replacementCost'], document)}
      {...pristineSubscribeProps}
    />
  );
}

AssetManufacturersForm.defaultProps = { document: {} };

AssetManufacturersForm.propTypes = {
  isNewManufacturer: bool.isRequired,
  document: shape({ _id: string, name: string }),
};
