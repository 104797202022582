import * as R from 'ramda';
import { addHours } from 'date-fns';
import {
  ClientProjectPriorityUnit,
  DEFAULT_CLIENT_PROJECT_PRIORITIES,
} from '@poly/constants';

import { isNilOrEmpty } from '../general.js';
import { ensureIsDate } from '../dates.js';

// getSupplierArrivalTimeByClientConfigs :: Input -> DateTime
// Input = {
//    client: Client
//    priority: String
//    startDate: DateTime
//    endDate: DateTime
// }
export const getSupplierArrivalTimeByClientConfigs = ({
  client,
  priorityId,
  startDate,
  endDate,
}) =>
  R.compose(
    R.ifElse(
      R.compose(isNilOrEmpty, R.always(startDate)),
      R.always(endDate),
      (hoursToAdd) => addHours(ensureIsDate(startDate), hoursToAdd),
    ),
    R.ifElse(
      R.propEq(ClientProjectPriorityUnit.DAYS, 'supplierArrivalUnit'),
      R.compose(R.multiply(24), R.propOr(0, 'supplierArrivalAmount')),
      R.propOr(0, 'supplierArrivalAmount'),
    ),
    R.find(R.propEq(priorityId, 'id')),
    R.pathOr(DEFAULT_CLIENT_PROJECT_PRIORITIES, [
      'configs',
      'projectPriorities',
    ]),
  )(client);
