import * as R from 'ramda';
import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useTableInfiniteScrollQuery } from '@poly/client-utils/src/hooks/useTableInfiniteScrollQuery.js';
import { useTableSorting } from '@poly/admin-ui/src/hooks/useTableSorting.js';

import { salesTaxReportTableConfigByState } from './SalesTaxReportTable.js';
import { generateSalesTaxReportQueryByState } from './salesTaxReportUtils.js';

// eslint-disable-next-line import/no-unused-modules
export const salesTaxReportQuery = gql`
  query salesTaxReportQuery($input: SalesTaxReportInput!) {
    salesTaxReport(input: $input) {
      hits {
        _id
        state
        number
        invoiceDate
        amount
        markupAmount
        taxAmount
        lines {
          total
        }
        project {
          _id
          projectId
          type
          property {
            address {
              address_parts {
                postal_code
                postal_code_suffix
              }
            }
          }
        }
        client {
          _id
          nickName
        }
      }
      total
    }
  }
`;

// prepareReport :: { salesTaxReport: SalesTaxReportResult } -> [ClientInvoice]
const prepareReport = R.compose(
  R.pathOr([], ['salesTaxReport', 'hits']),
  R.defaultTo({}),
);

export const useSalesTaxReport = ({ filters, state }) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: salesTaxReportTableConfigByState(filters?.state || state),
    column: 2,
  });

  const query = useMemo(
    () => ({ ...generateSalesTaxReportQueryByState(filters), sort }),
    [filters, sort],
  );

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    salesTaxReportQuery,
    query,
    {
      endpointName: 'salesTaxReport',
      pageSize: 50,
      skip: !query?.state,
    },
  );

  const report = useMemo(() => prepareReport(data), [data]);

  return {
    query,
    report,
    loading,
    tableProps: {
      ...tableProps,
      ...tableSortingProps,
    },
  };
};
