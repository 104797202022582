import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { MAX_ITEMS } from '@poly/admin-ui/src/constants/general.js';

import { ASSET_TYPES_QUERY } from './useAssetTypesQuery.js';

export const useAssetTypesLazyQuery = (sort) => {
  const searchText = useSelector(R.prop('searchText'));

  const [loading, setLoading] = useState(false);

  const input = useMemo(
    () => ({
      searchText,
      size: MAX_ITEMS,
      sort,
    }),
    [searchText, sort],
  );

  const [runAssetTypesQuery] = useLazyQuery(ASSET_TYPES_QUERY);

  return {
    loading,
    runAssetTypesQuery: async () => {
      setLoading(true);
      const { data } = await runAssetTypesQuery({
        variables: { input },
      });
      setLoading(false);
      return R.pathOr([], ['assetTypes', 'hits'], data);
    },
  };
};
