import React from 'react';
import * as R from 'ramda';
import { ProjectType } from '@poly/constants';
import { SidebarTabs } from '@poly/admin-ui';

import { MasterRecurringProjectSidebarBidRulesTab } from './tabs/MasterRecurringProjectSidebarBidRulesTab.js';
import { MasterRecurringProjectSidebarFinancialTab } from './tabs/MasterRecurringProjectSidebarFinancialTab.js';

const recurringProjectFinancialTabs = {
  recurringProjectBidRules: 'recurring-project-bid-rules',
  recurringProjectFinancial: 'recurring-project-financial',
};

// checkIfRecurringProjectChildBidType :: { project: RecurringProject } -> Boolean
const checkIfRecurringProjectChildBidType = R.pathEq(ProjectType.BID, [
  'project',
  'childType',
]);

export function MasterRecurringProjectSidebarFinancial(props) {
  const showBidRulesTab = checkIfRecurringProjectChildBidType(props);

  return showBidRulesTab ? (
    <SidebarTabs
      omitSidebarByTabs={['sidebarTab']}
      customTabQueryKey="recurringProjectFinancialTab"
      defaultValue={recurringProjectFinancialTabs.recurringProjectFinancial}
      tabs={[
        [
          'Financial',
          recurringProjectFinancialTabs.recurringProjectFinancial,
          <MasterRecurringProjectSidebarFinancialTab {...props} withoutLabel />,
        ],
        [
          'Bid Rules',
          recurringProjectFinancialTabs.recurringProjectBidRules,
          <MasterRecurringProjectSidebarBidRulesTab {...props} />,
        ],
      ]}
    />
  ) : (
    <MasterRecurringProjectSidebarFinancialTab {...props} />
  );
}
