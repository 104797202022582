import React from 'react';
import { shape } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';

import { getAssetTypesExcelConfig } from './getAssetTypesExcelConfig.js';
import { useAssetTypesLazyQuery } from './useAssetTypesLazyQuery.js';
import { AssetTypesPrintTable } from './AssetTypesPageTable.js';
import { PrintExportButtons } from '../../components/ExportButtons.js';

export function AssetTypesExportButtons({ sort }) {
  const { runAssetTypesQuery, loading } = useAssetTypesLazyQuery(sort);

  const handlePrintPDF = async () => {
    const assetTypes = await runAssetTypesQuery();

    await openPrintWindowWithData({
      fileName: 'asset_types_export.pdf',
      Layout: CommonPrintLayout,
      Table: AssetTypesPrintTable,
      metaData: {
        title: 'Asset Types',
      },
      assetTypes,
    });
  };

  const handleExportXLS = async () => {
    const assetTypes = await runAssetTypesQuery();

    const config = getAssetTypesExcelConfig(assetTypes);
    return performExcelExport(config);
  };

  return (
    <PrintExportButtons
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
      loading={loading}
    />
  );
}

AssetTypesExportButtons.propTypes = {
  sort: shape({}),
};
