export const addClientFormId = 'add_client_form_id';
export const editClientFormId = 'edit_client_form_id';
export const clientTabsSidebarId = 'client_tabs_sidebar_id';
export const editClientTMRulesFormId = 'edit_client_tm_rules_form_id';
export const editClientBidRulesFormId = 'edit_client_bid_rules_form_id';
export const editClientStaffRatesFormId = 'edit_client_staff_rates_form_id';

export const clientFinancialTabs = {
  clientFinancial: 'client-financial',
  clientTMRules: 'client-tm-rules',
  clientBidRules: 'client-bid-rules',
  clientRates: 'client-rates',
  clientReports: 'client-reports',
  clientHousekeepingCalendar: 'client-housekeeping-calendar',
  clientDistricts: 'client-districts',
  clientNotification: 'client-notification',
  clientRequesterSurvey: 'client-requester-survey',
  clientTags: 'client-tags',
};

export const ClientBidRulePerAssetFeeLabel = 'Per Asset Fee';
export const ClientBidRuleManagementFeeLabel = 'Management Fee';

export const ClientBidRulesAssetsWarning =
  'There are no assets attached to this WO. This rule will be ignored when calculating the Bid Amount. To include the per-asset fee, please attach the required assets.';
