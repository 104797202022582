import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';

const projectEstimateByTemporalAccessQuery = gql`
  query projectEstimateByTemporalAccessQuery {
    projectEstimateByTemporalAccess {
      projectNumber
      location
      projectManagerName
      estimateDate
      description
      woCharge
      subTotal
      tax
      markupTotal
      afterHoursCall
      total
      isTransparent
      isBreakdown
      manualEntries {
        description
        rate
        quantity
        total
      }
      markupEntries {
        total
        amount
        markup
        supplierName
      }
      timeEntries {
        type
        description
        totalMinutes
        rate
        total
      }
      attachments {
        url
        fileName
      }
    }
  }
`;

export const useReviewProjectEstimate = (isAuthorized) => {
  const { data, loading } = useQuery(projectEstimateByTemporalAccessQuery, {
    fetchPolicy: 'network-only',
    skip: !isAuthorized,
  });

  const projectEstimate = R.propOr({}, 'projectEstimateByTemporalAccess', data);

  return {
    projectEstimate,
    loading,
  };
};
