import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { func, string, number, oneOfType } from 'prop-types';
import React, { useEffect, useMemo, useCallback } from 'react';
import { formatDate, centsToDollarsWithFormat } from '@poly/utils';
import { useTableSorting, ALL } from '@poly/admin-ui';
import { InputOutForm } from '@poly/admin-book';
import {
  useTableInfiniteScrollQuery,
  parseFieldToNumberIfExists,
  mongoSortQuery,
} from '@poly/client-utils';
import {
  ClientInvoiceStatuses,
  NOTHING_UI_STRING,
  DESC_SORT_ORDER,
} from '@poly/constants';

import {
  SelectCheckbox,
  SelectInvoicesCheckBox,
} from '../../components/PaymentsFromComponents/SelectInvoiceCheckBox.js';
import { DiscountSelect } from './DiscountSelect.js';
import { PaymentInput } from '../../components/PaymentsFromComponents/PaymentInput.js';
import { BalanceInput } from '../../components/PaymentsFromComponents/BalanceInput.js';
import { DeductionsInput } from '../../components/PaymentsFromComponents/DeductionsInput.js';
import { transformClientInvoicesForPayment } from './clientPaymentsHelpers.js';
import { setGlobalTrigger } from '../../redux/globalTriger.js';
import { setInvoices } from '../../redux/invoices.js';

const clientInvoicesQuery = gql`
  query CLIENT_INVOICES($input: ClientInvoicesInput!) {
    clientInvoices(input: $input) {
      total
      hits {
        _id
        number
        createdAt
        invoiceDate
        amount
        balance
        project {
          _id
          projectId
        }
        client {
          _id
          financial {
            terms
          }
        }
        consolidatedClientInvoice {
          _id
          invoiceNumber
        }
      }
    }
  }
`;

function DeductionsAccountSelect({
  currentBalance,
  deductionsAmount,
  discountAccountId,
  dropdownOrientation,
  changeDiscountAccountId,
}) {
  return (
    <InputOutForm
      isOutForm
      size="small"
      width="100%"
      inputHeight="30px"
      value={discountAccountId}
      Component={DiscountSelect}
      placeholder="Select GL Code"
      disabled={currentBalance < 0}
      required={deductionsAmount > 0}
      direction={dropdownOrientation}
      onChange={changeDiscountAccountId}
    />
  );
}

DeductionsAccountSelect.propTypes = {
  discountAccountId: string,
  dropdownOrientation: string,
  currentBalance: number.isRequired,
  changeDiscountAccountId: func.isRequired,
  deductionsAmount: oneOfType([number, string]),
};

export const paySuppliersTableConfig = (onSelectAll, isAllSelected) => [
  ['Inv Number', R.prop('invoiceNumber'), mongoSortQuery('number')],
  [
    'Invoice Date',
    R.compose(formatDate, R.prop('invoiceDate')),
    mongoSortQuery('invoiceDate'),
  ],
  [
    'Due Date',
    R.compose(formatDate, R.prop('dueDate')),
    mongoSortQuery('dueDate'),
  ],
  ['Project #', R.prop('projectId'), mongoSortQuery('projectNumber')],
  [
    'Consolidated Invoice #',
    R.propOr(NOTHING_UI_STRING, 'consolidatedClientInvoiceNumber'),
    mongoSortQuery('consolidatedInvoiceNumber'),
  ],
  [
    'Invoice Amount',
    R.compose(centsToDollarsWithFormat, R.prop('total')),
    mongoSortQuery('amount'),
  ],
  ['Deductions', (props) => <DeductionsInput asCents {...props} />],
  ['Chart Of Accounts', DeductionsAccountSelect],
  [
    <SelectCheckbox
      onChange={onSelectAll}
      value={!!isAllSelected}
      key="select-all-invoices-checkbox"
      name="select-all-invoices-checkbox"
      dataTestId="select-all-invoices-checkbox"
    />,
    SelectInvoicesCheckBox,
  ],
  ['Received Amount', (props) => <PaymentInput asCents {...props} />],
  ['Due Amount', (props) => <BalanceInput asCents {...props} />],
];

// filterQueryInput :: Object -> ClientInvoicesInput
const filterQueryInput = R.compose(
  R.reject(R.anyPass([R.isEmpty, R.isNil, R.equals(ALL)])),
  parseFieldToNumberIfExists('invoiceNumber'),
  parseFieldToNumberIfExists('invoiceNumberFrom'),
  parseFieldToNumberIfExists('invoiceNumberTo'),
  R.mergeRight({
    status: [
      ClientInvoiceStatuses.PENDING,
      ClientInvoiceStatuses.PARTIALLY_PAID,
    ],
  }),
  R.omit(['discount']),
);

export const useFetchClientInvoices = (filters) => {
  const dispatch = useDispatch();

  const { sort, ...tableSortingProps } = useTableSorting({
    defaultSort: mongoSortQuery('number')(DESC_SORT_ORDER),
    tableConfig: paySuppliersTableConfig(() => null, false),
  });

  const input = useMemo(
    () => filters && filterQueryInput({ ...filters, sort }),
    [filters, sort],
  );

  const pageSize = 500;

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    clientInvoicesQuery,
    input,
    {
      endpointName: 'clientInvoices',
      skip: !filters,
      pageSize,
    },
  );

  const invoices = useMemo(
    () =>
      R.compose(
        transformClientInvoicesForPayment,
        R.pathOr([], ['clientInvoices', 'hits']),
      )(data),
    [data],
  );

  const setGlobalTriggerDebounce = useCallback(
    (isGlobalTrigger) => dispatch(setGlobalTrigger(isGlobalTrigger)),
    [],
  );

  useEffect(() => {
    dispatch(setInvoices(invoices));
    setGlobalTriggerDebounce(false);
  }, [invoices]);

  return {
    loading,
    pageSize,
    invoices,
    tableProps: { ...tableSortingProps, ...tableProps },
  };
};
