import * as R from 'ramda';
import { useReactiveQuery } from '@poly/client-utils';
import { useMemo, useState } from 'react';
import { BulkEditObjectType } from '@poly/constants';
import {
  CLIENTS_BY_SEARCH_SUB,
  CLIENTS_SEARCH_FOR_SELECT,
} from '@poly/admin-ui';

import { defaultFieldValueComponentConfig } from './default-field-value-component-config.js';
import { getProjectRuleValueComponentConfig } from './project-rule-value-component-config.js';

// getRuleValueComponentConfig :: { objectType: String } -> FieldConfig
// FieldConfig = Object
const getRuleValueComponentConfig = R.ifElse(
  R.propEq(BulkEditObjectType.PROJECTS, 'objectType'),
  getProjectRuleValueComponentConfig,
  R.always(defaultFieldValueComponentConfig),
);

export const useRuleValueComponent = ({
  ruleField,
  objectType,
  ruleFieldOperation,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const {
    Component,
    leftMove,
    getOptions,
    placeholder,
    gqlSearchQuery,
    getGqlQueryVariables,
    gqlSearchChangedQuery,
  } = getRuleValueComponentConfig({
    ruleField,
    objectType,
    ruleFieldOperation,
  });

  const queryOptions = useMemo(
    () => ({
      variables: !getGqlQueryVariables ? {} : getGqlQueryVariables(searchTerm),
      skip: !gqlSearchQuery,
    }),
    [searchTerm, gqlSearchQuery, getGqlQueryVariables],
  );

  const { data } = useReactiveQuery(
    gqlSearchQuery || CLIENTS_SEARCH_FOR_SELECT,
    gqlSearchChangedQuery || CLIENTS_BY_SEARCH_SUB,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  return {
    Component,
    placeholder,
    ...(!leftMove ? {} : { leftMove }),
    ...(!getOptions ? {} : { options: getOptions(data) }),
    ...(!getOptions ? {} : { onInputChange: setSearchTerm }),
  };
};
