import * as R from 'ramda';
import React, { useEffect } from 'react';
import { MoneyInputAsCents } from '@poly/admin-ui/src/modules/forms/fields/MoneyInput.js';
import { NEW_YORK_STATE_SHORTNAME } from '@poly/constants';

import { costPropTypes } from './common.js';
import { isEditInvoiceFormDisabled } from '../helpers.js';

// checkIfNYStateAndWithTax :: { formData: FormData, value: Float } -> Boolean
const checkIfNYStateAndWithTax = R.allPass([
  R.either(
    R.pathEq(NEW_YORK_STATE_SHORTNAME, [
      'formData',
      'project',
      'property',
      'address',
      'address_parts',
      'administrative_area_level_1',
    ]),
    R.pathEq(NEW_YORK_STATE_SHORTNAME, ['formData', 'propertyLocationState']),
  ),
  R.compose(R.both(R.is(Number), R.gt(R.__, 0)), R.prop('value')),
]);

// getWarningMessage :: { value: Float, formData: FormData } -> String
const getWarningMessage = R.cond([
  [checkIfNYStateAndWithTax, R.always('This state should not have Tax')],
  [
    R.pathOr(false, ['formData', 'exemptSalesTax']),
    R.always('The project is exempt from sales tax'),
  ],
  [R.T, R.always(undefined)],
]);

export function InvoiceTaxField({ onChange, formData, value, ...props }) {
  const warning = getWarningMessage({ formData, value });

  const onFocus = (e) => {
    if (e.target.value === '$0.00') {
      onChange('');
    }
  };

  const onBlur = (e) => {
    if (!e.target.value) {
      onChange(0);
    }
  };

  useEffect(() => {
    const isCostChanged = formData.labourCost > 0 || formData.materialCost > 0;
    if (!value && isCostChanged) {
      onChange(0);
    }
  }, [formData.labourCost, formData.materialCost]);

  const inputProps = {
    ...props,
    value,
    onBlur,
    onFocus,
    warning,
    onChange,
    disabled: isEditInvoiceFormDisabled({ formData }),
  };

  return <MoneyInputAsCents {...inputProps} />;
}

InvoiceTaxField.propTypes = costPropTypes;
