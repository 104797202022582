import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string, bool } from 'prop-types';
import { EntityStatus, LinkButton, Text } from '@poly/admin-book';
import { FlexContainer, SidebarRow } from '@poly/admin-ui';
import { UPDATE_CLIENT_PERMISSION } from '@poly/security';
import {
  useHasUserAccessWithPermission,
  pathOrNothingUI,
} from '@poly/client-utils';

import {
  SidebarLabel,
  BlockWithLabel,
  SectionWrapper,
  headerTextProps,
} from '../components/commonSidebarComponents.js';
import { SidebarIDs } from '../constants.js';
import { clientTabsSidebarId } from './constants.js';
import { clientStatusesColors } from '../../modules/core/constants/client.js';
import { OpenFullInfoSidebarButton } from '../components/OpenFullInfoSidebarButton.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import { useOpenClientTabsSidebar } from './tabs/useOpenClientTabsSidebar.js';
import { useEditClientSidebar } from './forms/edit/useEditClientSidebar.js';
import { getEntityAddressPreview } from '../utils/address.js';
import { SubmitProjectWebFormUrl } from '../PropertySidebar/SubmitProjectWebFormUrl.js';
import { isRequestsAppEnabled } from './forms/form/sections/configsSection.js';

const ClientPrioritiesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  gap: 10px 24px;
`;

const TopRow = styled(SidebarRow)`
  align-items: baseline;
  justify-content: space-between;

  > div:first-child {
    align-items: baseline;
  }
`;

// prepareClientPriorities :: { client: Client } -> [String]
const prepareClientPriorities = R.compose(
  R.map(({ name, amount, unit }) => `${name} - ${amount} ${unit}`),
  R.pathOr([], ['client', 'configs', 'projectPriorities']),
);

function ClientPrioritiesRow(props) {
  const preparedClientPriorities = prepareClientPriorities(props);

  return (
    <ClientPrioritiesWrapper {...props}>
      {preparedClientPriorities.map((priority) => (
        <Text {...headerTextProps} key={priority}>
          {priority}
        </Text>
      ))}
    </ClientPrioritiesWrapper>
  );
}

// getClientType :: Client -> String
const getClientType = R.cond([
  [R.propSatisfies(R.is(Object), 'parentClient'), R.always('Client Child')],
  [
    R.propSatisfies(R.complement(R.isEmpty), 'childClients'),
    R.always('Client Master'),
  ],
  [R.T, R.always('Client')],
]);

export function ClientSidebarHeader({ client, isCard = false }) {
  const { name, status } = client;
  const openEditClientSidebar = useEditClientSidebar(isCard);
  const openFullClient = useOpenClientTabsSidebar();

  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_CLIENT_PERMISSION,
  );

  return (
    <SectionWrapper>
      <TopRow>
        <FlexContainer>
          <SidebarLabel margin={10}>{name}</SidebarLabel>
          <EntityStatus
            title={getClientType(client)}
            status={{
              text: R.toLower(status),
              color: clientStatusesColors[status],
            }}
          />
        </FlexContainer>
        <OpenFullInfoSidebarButton
          isCard={isCard}
          sidebarId={SidebarIDs.client}
          fullSidebarId={clientTabsSidebarId}
          openSidebarHandler={() => openFullClient(client)}
        />
      </TopRow>
      <SidebarRow justify>
        <FlexContainer>
          {getSectionText(
            pathOrNothingUI(['cardNumber'])(client),
            headerTextProps,
          )()}
        </FlexContainer>
        {ifHasPermission && (
          <LinkButton onClick={() => openEditClientSidebar({ client })}>
            Edit
          </LinkButton>
        )}
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          id="address"
          label="Address"
          Component={getSectionText(
            getEntityAddressPreview(client),
            headerTextProps,
          )}
        />
      </SidebarRow>
      {isRequestsAppEnabled(client) && (
        <SidebarRow>
          <BlockWithLabel
            id="request-app-link"
            label="Web Form"
            client={client}
            Component={SubmitProjectWebFormUrl}
          />
        </SidebarRow>
      )}
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          width="100%"
          id="priorities"
          label="Priorities"
          Component={ClientPrioritiesRow}
          client={client}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

ClientSidebarHeader.propTypes = {
  isCard: bool,
  client: shape({
    name: string.isRequired,
    status: string.isRequired,
  }),
};
