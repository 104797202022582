import * as R from 'ramda';
import { NOTHING_UI_STRING, ProjectSpendTypesUI } from '@poly/constants';
import {
  boolToYesNo,
  commonSortQuery,
  keywordSortQuery,
  keywordMultiFieldSortQuery,
} from '@poly/client-utils';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { getClientRefNumberUI } from '../../components/ProjectPrintToPDF/parts/PrintProjectProjectDetails.js';

export const getMondelezProjectRMStatusReportTableConfig = (isPrintPDF) => [
  [
    'WO #',
    R.compose(ProjectLink, R.mergeRight({ isPrintPDF })),
    keywordSortQuery(['projectId']),
  ],
  [
    'WO Type',
    R.compose(
      R.propOr(NOTHING_UI_STRING, R.__, ProjectSpendTypesUI),
      R.prop('spendType'),
    ),
    keywordSortQuery(['spendType']),
  ],
  [
    'Client Ref # / PO',
    getClientRefNumberUI,
    keywordMultiFieldSortQuery([
      ['adminPurchaseOrder', 'poNumber'],
      ['clientReferenceNumber'],
    ]),
  ],
  [
    'Not R&M',
    R.compose(boolToYesNo, R.propOr(false, 'notRM')),
    commonSortQuery(['notRM']),
  ],
];
