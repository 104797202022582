import * as R from 'ramda';

// getSupplierServiceTypesNamesStr :: Supplier -> String
// eslint-disable-next-line import/no-unused-modules
export const getSupplierServiceTypesNamesStr = R.compose(
  R.join(', '),
  R.pluck('name'),
  R.sortBy(R.compose(R.toLower, R.prop('name'))),
  R.defaultTo([]),
  R.path(['company', 'services']),
);
