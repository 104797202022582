import * as R from 'ramda';
import { MAX_ITEMS } from '@poly/admin-ui';
import { gql } from '@apollo/client';
import { AccountingPeriodStatuses } from '@poly/constants';
import { useReactiveQuery } from '@poly/client-utils/src/hooks/useReactiveQuery.js';

const accountingPeriodsQuery = gql`
  query accountingPeriods($input: AccountingPeriodsInput) {
    accountingPeriods(input: $input) {
      hits {
        _id
        startDate
        endDate
        status
      }
    }
  }
`;

const accountingPeriodsSubs = gql`
  subscription accountingPeriodsSubs($input: CollectionSearchParams) {
    searchAccountingPeriodChanged(input: $input) {
      id
      type
    }
  }
`;

export const useAccountingPeriodsByTypeAndStatus = ({
  type,
  status,
  skip = false,
}) => {
  const { data, loading } = useReactiveQuery(
    accountingPeriodsQuery,
    accountingPeriodsSubs,
    {
      queryOptions: {
        variables: {
          input: { type, status, size: MAX_ITEMS },
        },
        fetchPolicy: 'network-only',
        skip,
      },
      subscriptionOptions: {
        variables: {
          query: { must: [{ term: { type } }, { term: { status } }] },
        },
        skip,
      },
    },
  );

  return [R.pathOr([], ['accountingPeriods', 'hits'], data), loading];
};

export const useAccountingPeriods = (type) => {
  const [closedAccountingPeriods, closedAccountingPeriodsLoading] =
    useAccountingPeriodsByTypeAndStatus({
      type,
      status: AccountingPeriodStatuses.CLOSED,
    });

  const [openedAccountingPeriods, openedAccountingPeriodsLoading] =
    useAccountingPeriodsByTypeAndStatus({
      type,
      status: AccountingPeriodStatuses.OPENED,
    });

  return {
    closedAccountingPeriods,
    openedAccountingPeriods,
    loading: closedAccountingPeriodsLoading || openedAccountingPeriodsLoading,
  };
};
