import * as R from 'ramda';
import React from 'react';
import { shape } from 'prop-types';
import { differenceInDays } from 'date-fns';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';
import { NOTHING_UI_STRING, ProjectTypeToNameMap } from '@poly/constants';
import {
  getProjectDateCompletedUI,
  getProjectStartEndDatesUI,
} from '@poly/utils/src/projects/common.js';

import {
  PrintProjectFlexRow,
  PrintProjectFlexColumn,
  PrintProjectBlockWithLabel,
} from '../components.js';

// getProjectTypeUI :: Project -> String
const getProjectTypeUI = R.compose(
  R.propOr(NOTHING_UI_STRING, R.__, ProjectTypeToNameMap),
  R.prop('type'),
);

// getProjectStatusUI :: Project -> String
const getProjectStatusUI = R.compose(
  R.join(''),
  R.juxt([
    R.compose(R.toUpper, R.head),
    R.compose(R.join(''), R.map(R.toLower), R.tail),
  ]),
  R.split(''),
  R.propOr('', 'status'),
);

// getProjectPriorityUI :: Project -> String
const getProjectPriorityUI = R.pathOr(NOTHING_UI_STRING, ['priority', 'name']);

// getProjectOpenUI :: Project -> String
const getProjectOpenUI = R.compose(
  R.concat(R.__, ' Days'),
  R.toString,
  R.ifElse(
    R.identity,
    R.converge(differenceInDays, [alwaysNewDate, ensureIsDate]),
    R.always(NOTHING_UI_STRING),
  ),
  R.prop('startDate'),
);

// getProjectDescriptionUI :: Project -> String
const getProjectDescriptionUI = R.propOr(NOTHING_UI_STRING, 'description');

export function PrintProjectGeneralDetails({ project }) {
  return (
    <PrintProjectFlexColumn withBottomBorder>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Project Type"
          value={getProjectTypeUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Project Status"
          value={getProjectStatusUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Priority"
          value={getProjectPriorityUI(project)}
        />
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Start - End Date"
          value={getProjectStartEndDatesUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Open"
          value={getProjectOpenUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Date completed"
          value={getProjectDateCompletedUI(project)}
        />
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Description"
          value={getProjectDescriptionUI(project)}
        />
      </PrintProjectFlexRow>
    </PrintProjectFlexColumn>
  );
}

PrintProjectGeneralDetails.propTypes = {
  project: shape({}),
};
