import * as R from 'ramda';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { convertCentsToDollars } from '@poly/utils/src/converters.js';

// getAssetManufacturersRowExcelConfig :: AssetManufacture -> [ExcelExportDataCell]
const getAssetManufacturersRowExcelConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ExcelExportCellType.default,
    ExcelExportCellType.defaultCurrency,
  ]),
  R.juxt([
    R.propOr('', 'name'),
    R.compose(convertCentsToDollars, R.propOr(0, 'replacementCost')),
  ]),
);

// getAssetManufacturersExcelConfig :: [AssetManufacture] -> ExcelConfig
export const getAssetManufacturersExcelConfig = (assetManufacturers) => ({
  exportFileName: 'asset_manufacturers_export.xls',
  columnWidths: [30, 25],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [
        ExcelExportCellType.tableHeader,
        ExcelExportCellType.tableCurrencyHeader,
      ],
      ['Name', 'Asset Replacement Cost'],
    ),
    ...R.map(getAssetManufacturersRowExcelConfig, assetManufacturers),
  ],
});
