import styled from 'styled-components';
import { getThemeColor } from '@poly/admin-book';
import { SidebarWrapper } from '@poly/admin-ui';

export const BulkEditingSidebarWrapperS = styled(SidebarWrapper)`
  overflow-x: hidden;
  height: 100%;
`;

export const BulkEditingSidebarHeaderS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
  border-bottom: 1px solid #ebebeb;
`;

export const BulkEditingPageTitleS = styled.h3`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${getThemeColor(['primary'])};
  margin: 0;
`;
