import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { parseQuery, stringifyQuery } from './utils.js';

// getPreviousStateByLocation :: Location -> { state: { previous: Location } }
const getPreviousStateByLocation = R.compose(
  R.objOf('state'),
  R.pick(['previous']),
  R.propOr({}, 'state'),
);

// getCommonRouterProps :: { location: Location, params: RouterParams } -> CommonRouterProps
// CommonRouterProps = {
//   params: RouterParams
//   pathname: String
//   previous: Location
//   query: SearchParamsObject
//   hash: [String]
// }
export const getCommonRouterProps = R.applySpec({
  params: R.prop('params'),
  pathname: R.path(['location', 'pathname']),
  previous: R.path(['location', 'state', 'previous']),
  query: R.pipe(R.path(['location', 'search']), parseQuery),
  hash: R.pipe(
    R.path(['location', 'hash']),
    R.when(R.complement(R.isEmpty), R.pipe(R.split('_'), R.last)),
  ),
});

export const useSetQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (newParams) => {
    navigate(
      `${location.pathname}?${stringifyQuery(newParams)}${location.hash || ''}`,
      getPreviousStateByLocation(location),
    );
  };
};

export const useUpdateQueryParams = () => {
  const setQueryParams = useSetQueryParams();
  const location = useLocation();

  return (newParams) => {
    const oldParams = parseQuery(location.search);
    const params = R.mergeDeepLeft(newParams, oldParams);
    setQueryParams(params);
  };
};

export const useUpdateSidebarLinkByParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const oldParams = parseQuery(location.search);

  return ({ query = {}, hash = '' }) => {
    const mergedParams = R.mergeDeepLeft(query, oldParams);
    const link = `${location.pathname}?${stringifyQuery(mergedParams)}${hash}`;

    navigate(link, getPreviousStateByLocation(location));
  };
};

export const useClearQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return () => navigate(location.pathname);
};

export const useUpdateHash = (hashPrefix) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (hashParam) => {
    const hashString = hashParam ? `#${hashPrefix}_${hashParam}` : '';

    navigate(`${location.pathname}${location.search}${hashString}`);
  };
};

// formatNavigateUrl :: (Location, QueryParams) -> URL
const formatNavigateUrl = (location, search) =>
  `${location.pathname}?${stringifyQuery(search)}${location.hash || ''}`;

export const usePristineSubscribe = (form, params = {}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pristineState, setPristineState] = useState(true);

  const oldParams = parseQuery(location.search);
  const queryParams = R.mergeDeepLeft(
    {
      sidebarTab: undefined,
      sidebarSubTab: undefined,
      ...params,
    },
    oldParams,
  );
  const previousState = getPreviousStateByLocation(location);
  const formState = form
    ? { state: { form, pristine: pristineState } }
    : { state: { pristine: pristineState } };
  const stateWithForm = R.mergeDeepLeft(formState, previousState);

  useEffect(() => {
    navigate(formatNavigateUrl(location, queryParams), {
      ...stateWithForm,
      replace: true,
    });
  }, [pristineState]);

  return {
    formSubscription: {
      subscriber: ({ pristine }) => setPristineState(pristine),
      subscription: { pristine: true },
    },
  };
};

export const useRouterQuery = (queryFields) => {
  const { search } = useLocation();
  const query = parseQuery(search);

  return R.isEmpty(queryFields) ? query : R.pick(queryFields, query);
};

export const useRouterParams = (paramsFields) => {
  const params = useParams();
  return R.pick(paramsFields, params);
};
