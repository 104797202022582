import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import React, { useState } from 'react';
import { arrayOf, shape, func, string, bool } from 'prop-types';
import { Link } from '@poly/client-routing';
import {
  entityToOptionByLabelPath,
  capitalizeFirstLetter,
  useReactiveQuery,
} from '@poly/client-utils';
import {
  singleButtonColumnStyles,
  moneyColumnStyles,
  TextButton,
  Select,
  Input,
  Table,
} from '@poly/admin-book';
import {
  AccountTypeStatus,
  AccountStatuses,
  DivisionStatus,
} from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

const statusesOptions = [
  {
    value: AccountStatuses.ACTIVE,
    label: capitalizeFirstLetter(AccountStatuses.ACTIVE),
  },
  {
    value: AccountStatuses.INACTIVE,
    label: capitalizeFirstLetter(AccountStatuses.INACTIVE),
  },
];

export function StatusDropDown(props) {
  return <Select {...props} options={statusesOptions} />;
}

export const DisabledStatusDropDown = styled(StatusDropDown).attrs(() => ({
  disabled: true,
}))``;

const GET_ACCOUNT_TYPES_QUERY = gql`
  query GET_ACCOUNT_TYPES_QUERY($input: AccountTypesInput) {
    accountTypes(input: $input) {
      hits {
        _id
        name
        status
      }
    }
  }
`;

const ACCOUNT_TYPES_CHANGED_SUBSCRIPTION = gql`
  subscription ACCOUNT_TYPES_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAccountTypeChanged(input: $input) {
      id
      type
    }
  }
`;

// filterByStatusAndId :: (String, ID) -> [Entity] -> [Entity]
// Entity = {status: String _id: ID}
const filterByStatusAndId = (status, id) =>
  R.filter(R.either(propEqLegacy('status', status), propEqLegacy('_id', id)));

// getAccountTypeOptions :: {accountTypes: [AccountType]} ->  [Option]
const getAccountTypeOptions = (data, value) =>
  R.compose(
    R.map(entityToOptionByLabelPath(['name'])),
    filterByStatusAndId(AccountTypeStatus.ACTIVE, value),
    R.pathOr([], ['accountTypes', 'hits']),
  )(data);

export function TypeDropDown({ value, ...props }) {
  const queryOptions = {
    fetchPolicy: 'network-only',
  };
  const { data, loading } = useReactiveQuery(
    GET_ACCOUNT_TYPES_QUERY,
    ACCOUNT_TYPES_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: {} },
  );

  const dropdownOptions = getAccountTypeOptions(data, value);

  const selectProps = {
    ...props,
    value,
    loading,
    options: dropdownOptions,
    allowModalOverflow: true,
  };

  return <Select {...selectProps} />;
}

TypeDropDown.propTypes = {
  value: string,
};

const GET_DIVISIONS_QUERY = gql`
  query GET_DIVISIONS_QUERY($input: DivisionsInput) {
    divisions(input: $input) {
      hits {
        _id
        name
        status
      }
      total
    }
  }
`;

const DIVISIONS_CHANGED_SUBSCRIPTION = gql`
  subscription DIVISIONS_CHANGED_SUBSCRIPTION($input: CollectionSearchParams) {
    searchDivisionChanged(input: $input) {
      id
      type
    }
  }
`;

// getDivisionOptions :: ({divisions: [Division]}, String) -> [Option]
const getDivisionOptions = (data, value) =>
  R.compose(
    R.map(entityToOptionByLabelPath(['name'])),
    filterByStatusAndId(DivisionStatus.ACTIVE, value),
    R.pathOr([], ['divisions', 'hits']),
  )(data);

export function DivisionDropDown({ value, ...props }) {
  const queryOptions = {
    fetchPolicy: 'network-only',
  };
  const { data, loading } = useReactiveQuery(
    GET_DIVISIONS_QUERY,
    DIVISIONS_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: {} },
  );

  const dropdownOptions = getDivisionOptions(data, value);

  const selectProps = {
    ...props,
    value,
    loading,
    options: dropdownOptions,
  };

  return <Select {...selectProps} />;
}

DivisionDropDown.propTypes = {
  value: string,
};

export const AccountGLCodeInputS = styled(Input).attrs(() => ({
  type: 'numberMask',
  format: '#####',
  mask: '_',
}))``;

export function DisabledInputS(props) {
  return <Input {...props} disabled />;
}

// checkIfTaxAccountById :: ID -> [Account] -> Boolean
const checkIfTaxAccountById = (id) =>
  R.compose(
    R.propOr(false, 'is_parent_tax_account'),
    R.find(propEqLegacy('_id', id)),
  );

export function ParentAccountDropDown({
  accountId,
  parentAccounts,
  onChange,
  changeFieldValue,
  ...props
}) {
  const dropDownOptions = R.compose(
    R.map(({ _id, code, name }) => ({
      value: _id,
      label: `${code} - ${name}`,
    })),
    R.reject(propEqLegacy('_id', accountId)),
  )(parentAccounts);

  const [searchText, setSearchText] = useState('');

  const onParentChange = (value) => {
    changeFieldValue(
      'isTaxAccount',
      checkIfTaxAccountById(value)(parentAccounts),
    );
    onChange(value);
  };

  const selectProps = {
    ...props,
    searchText,
    onInputChange: setSearchText,
    onChange: onParentChange,
    options: dropDownOptions,
  };

  return <Select {...selectProps} />;
}

ParentAccountDropDown.propTypes = {
  accountId: string,
  parentAccounts: arrayOf(
    shape({
      _id: string.isRequired,
      code: string,
      name: string,
      is_parent_tax_account: bool,
    }),
  ),
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};

export const TableS = styled(Table)`
  td {
    padding: 0 5px;
    height: unset;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 200px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 200px;
  }

  ${singleButtonColumnStyles(6)};

  ${moneyColumnStyles(5)};

  th:nth-child(5),
  td:nth-child(5) {
    width: 200px;
    padding-right: 90px;
  }

  /*
  Note: padding-bottom needs to fix cropped information
        on linux + firefox
   */
  @media print {
    padding-bottom: 20px;

    tbody {
      padding-bottom: 20px;
    }
  }
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;

export const SpanWithPadding = styled.span`
  padding-left: 20px;
`;

export const BoldLinkButtonS = styled(Link)`
  font-weight: bold;
`;

export const LinkButtonWithPaddingS = styled(Link)`
  margin-left: 20px;
`;

export const TextButtonS = styled(TextButton)`
  font-size: 14px;
`;

export const SpanS = styled.span``;
