export const safeLocalStorage = {
  setItem(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      return false;
    }
    return true;
  },

  getItem(key) {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  },

  removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      return false;
    }
    return true;
  },

  clear() {
    try {
      localStorage.clear();
    } catch (e) {
      return false;
    }
    return true;
  },
};
