import * as R from 'ramda';
import { formatRawNumber } from '@poly/utils';
import { NOTHING_UI_STRING } from '@poly/constants';
import {
  ExcelExportCellType,
  applyPathOrNothingUI,
  createExcelExportCell,
} from '@poly/client-utils';

// getClientPortalUsersXlsExportRowConfig :: User -> [ExcelExportDataCell]
const getClientPortalUsersXlsExportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 7),
  ]),
  R.juxt([
    R.prop('fullName'),
    R.compose(
      R.join(', '),
      R.map(R.pathOr('', ['userGroup', 'name'])),
      R.propOr([], ['userGroups']),
    ),
    applyPathOrNothingUI(['profile', 'workPhoneNumber'], formatRawNumber),
    R.pathOr(NOTHING_UI_STRING, ['profile', 'workPhoneNumberExt']),
    applyPathOrNothingUI(['profile', 'cellPhoneNumber'], formatRawNumber),
    R.pathOr(NOTHING_UI_STRING, ['profile', 'loginCellPhoneNumber']),
    R.propOr(NOTHING_UI_STRING, 'email'),
  ]),
);

export const getClientPortalUsersXlsExportConfig = (
  currentTab,
  foundUsers,
) => ({
  exportFileName: `client_portal_users_${currentTab}_export.xlsx`,
  columnWidths: [20, 25, 15, 15, 15, 20, 30],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 7)],
      [
        'Name',
        'User Group',
        'Phone',
        'Ext',
        'Mobile',
        'Login Cell Phone Number',
        'Email',
      ],
    ),
    ...R.map(getClientPortalUsersXlsExportRowConfig, foundUsers),
  ],
});
