import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import {
  BodyRow,
  defaultTheme,
  Icon,
  LinkButton,
  Table,
} from '@poly/admin-book';
import {
  highlightedTextPropType,
  useMapConfigToTablePropsWithSorting,
} from '@poly/admin-ui';
import {
  assocBy,
  isNilOrEmpty,
  pathEqLegacy,
  propEqLegacy,
  ofArrayLegacy,
} from '@poly/utils';

const {
  colors: { primaryLight },
} = defaultTheme;

const SelectPropertiesTableBase = styled(Table)`
  td:nth-child(1),
  th:nth-child(1) {
    width: 30px;
  }
`;

const PropertyNameComponentS = styled(LinkButton)`
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    margin-right: 5px;
  }
`;

const SubPropertyTableRowS = styled(BodyRow)`
  padding-left: 20px;
`;

// prepareMasterPropertiesWithSub :: [Property] -> [Property]
const prepareMasterPropertiesWithSub = (list) =>
  R.compose(
    R.filter(R.propSatisfies(isNilOrEmpty, 'masterProperty')),
    R.map(
      R.when(
        R.prop('isMaster'),
        assocBy(
          'subProperties',
          R.compose(
            (id) => R.filter(pathEqLegacy(['masterProperty', '_id'], id))(list),
            R.prop('_id'),
          ),
        ),
      ),
    ),
  )(list);

// getSelectedPropertyWithSubs :: ID -> [Property] -> [ID]
const getSelectedPropertyWithSubs = (rowId) =>
  R.converge(R.concat, [
    R.compose(ofArrayLegacy, R.always(rowId)),
    R.compose(
      R.map(R.prop('_id')),
      R.defaultTo([]),
      R.prop('subProperties'),
      R.find(propEqLegacy('_id', rowId)),
    ),
  ]);

function PropertyNameComponent({
  isMaster,
  isExpanded,
  toggleExpanded,
  highlightedName,
}) {
  return isMaster ? (
    <PropertyNameComponentS onClick={() => toggleExpanded(!isExpanded)}>
      <Icon
        size={10}
        color={primaryLight}
        name={isExpanded ? 'arrow-up' : 'arrow-down'}
      />
      {highlightedName}
    </PropertyNameComponentS>
  ) : (
    highlightedName
  );
}

PropertyNameComponent.propTypes = {
  isExpanded: bool,
  toggleExpanded: func,
  isMaster: bool.isRequired,
  highlightedName: highlightedTextPropType.isRequired,
};

// getSubPropertiesForMaster :: { row: Property } -> [Property]
const getSubPropertiesForMaster = R.pathOr([], ['row', 'subProperties']);

function MasterPropertyTableRow(props) {
  const { row } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const subProperties = getSubPropertiesForMaster(props);

  return (
    <>
      <BodyRow
        {...props}
        row={{ ...row, isExpanded, toggleExpanded: setIsExpanded }}
      />
      {isExpanded &&
        subProperties.map((subProperty) => (
          <SubPropertyTableRowS
            {...props}
            row={subProperty}
            key={subProperty._id}
          />
        ))}
    </>
  );
}

MasterPropertyTableRow.propTypes = { row: shape({ _id: string.isRequired }) };

const tableConfig = [
  ['Property Name', PropertyNameComponent, R.prop('name')],
  [
    'Address',
    R.prop(['highlightedAddress']),
    R.path(['address', 'formatted_address']),
  ],
];

// checkIsMasterPropertyRow :: { row: Property } -> Boolean
const checkIsMasterPropertyRow = pathEqLegacy(['row', 'isMaster'], true);

// checkAllRowsSelected :: { selectedRows: [ID], rows: [Property] } -> Boolean
const checkAllRowsSelected = R.compose(
  R.isEmpty,
  R.converge(R.without, [
    R.prop('selectedRows'),
    R.compose(R.map(R.prop('_id')), R.prop('rows')),
  ]),
);

const checkRowsArePartiallySelected = R.both(
  R.propSatisfies(R.complement(R.isEmpty), 'selectedRows'),
  R.compose(R.not, checkAllRowsSelected),
);

function PropertyTableRow(props) {
  const isMasterProperty = checkIsMasterPropertyRow(props);

  const RowComponent = isMasterProperty ? MasterPropertyTableRow : BodyRow;

  return <RowComponent {...props} />;
}

export function SelectPropertiesTable({ onChange, properties, value }) {
  const [selectedProperties, setSelectedProperties] = useState(value);

  const preparedProperties = useMemo(
    () => prepareMasterPropertiesWithSub(properties),
    [properties],
  );

  const toggleRow = (rowId) => {
    const selectedWithSubs =
      getSelectedPropertyWithSubs(rowId)(preparedProperties);
    if (selectedProperties.includes(rowId)) {
      const currentPropertyIds = R.without(
        selectedWithSubs,
        selectedProperties,
      );

      onChange(currentPropertyIds);
      setSelectedProperties(currentPropertyIds);
    } else {
      const currentPropertyIds = R.concat(selectedProperties, selectedWithSubs);

      onChange(currentPropertyIds);
      setSelectedProperties(currentPropertyIds);
    }
  };

  const toggleSelectAll = () => {
    if (selectedProperties.length === properties.length) {
      onChange([]);
      setSelectedProperties([]);
    } else {
      const currentPropertyIds = properties.map((x) => x._id);

      onChange(currentPropertyIds);
      setSelectedProperties(currentPropertyIds);
    }
  };

  const tableProps = useMapConfigToTablePropsWithSorting({
    tableConfig,
    items: preparedProperties,
    initialSorting: { columnKey: 0, dir: 1 },
  });

  return (
    <SelectPropertiesTableBase
      isSortable
      showAllSelector
      toggleRow={toggleRow}
      rows={tableProps.rows}
      columns={tableProps.columns}
      headers={tableProps.headers}
      sorting={tableProps.sorting}
      RowComponent={PropertyTableRow}
      selectedRows={selectedProperties}
      toggleSelectAll={toggleSelectAll}
      sortQueries={tableProps.sortQueries}
      checkAllRowsSelected={checkAllRowsSelected}
      onHeaderCellClick={tableProps.onHeaderCellClick}
      checkRowsArePartiallySelected={checkRowsArePartiallySelected}
    />
  );
}

SelectPropertiesTable.propTypes = {
  value: arrayOf(string),
  onChange: func,
  properties: arrayOf(shape({ _id: string.isRequired })),
};
