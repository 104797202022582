import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { TableCardWithPaginationContainer } from '@poly/admin-ui';
import { StaffReportMetricType } from '@poly/constants';

import { formatDate } from '@poly/utils';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  MainHeader,
  Loader,
  Table,
  TableWithToolBarWrapper,
} from '@poly/admin-book';

import { staffReportMetricTitles } from '../StaffReport/metricTitle.js';
import { useStaffReportDetail } from './useStaffReportDetail.js';

const firstDateColumnTableCSS = css`
  th:nth-child(1),
  td:nth-child(1) {
    width: 300px;
  }
`;

const completedProjectTasksTableCSS = css`
  th:nth-child(1),
  td:nth-child(1) {
    width: 300px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 150px;
  }
`;

const metricTypeToTableCSSMap = {
  [StaffReportMetricType.CREATED_SUPPLIERS]: firstDateColumnTableCSS,
  [StaffReportMetricType.POSTED_UPDATES]: firstDateColumnTableCSS,
  [StaffReportMetricType.COMPLETED_PROJECT_TASKS]:
    completedProjectTasksTableCSS,
  [StaffReportMetricType.CREATED_PROJECTS]: firstDateColumnTableCSS,
  [StaffReportMetricType.COMPLETED_REACTIVE_PROJECTS]: firstDateColumnTableCSS,
  [StaffReportMetricType.COMPLETED_RECURRING_PROJECTS]: firstDateColumnTableCSS,
  [StaffReportMetricType.COMPLETED_PREVENTATIVE_PROJECTS]:
    firstDateColumnTableCSS,
  [StaffReportMetricType.COMPLETED_CAP_EX_PROJECTS]: firstDateColumnTableCSS,
  [StaffReportMetricType.CLOSED_PROJECTS]: firstDateColumnTableCSS,
  [StaffReportMetricType.ACTIVE_RECURRING_PROJECTS]: firstDateColumnTableCSS,
  [StaffReportMetricType.ACTIVE_CHILDREN_PROJECTS]: firstDateColumnTableCSS,
  [StaffReportMetricType.ACTIVE_PROJECTS]: firstDateColumnTableCSS,
  [StaffReportMetricType.CREATED_MASTER_PROJECTS]: firstDateColumnTableCSS,
};

// getTableStylesByMetricType :: String -> CSS
const getTableStylesByMetricType = R.propOr('', R.__, metricTypeToTableCSSMap);

const StaffActivityTableS = styled(Table)`
  ${({ metricType }) => getTableStylesByMetricType(metricType)};
`;

const EmptyActivityList = styled.div.attrs(() => ({
  children: 'List is empty',
}))`
  text-align: center;
  width: 100%;
`;

// getUserFullNameByData :: Object -> String
const getFullNameByData = R.path(['user', 'profile', 'fullName']);

// getMetricTitleByType :: String -> String
const getMetricTitleByType = (type) =>
  R.compose(
    R.last,
    R.find(R.compose(R.equals(type), R.head)),
  )(staffReportMetricTitles);

const getSafeHeaderInfo = ({ data, startDate, endDate }) =>
  `${getFullNameByData(data)}, ${formatDate(startDate)} - ${formatDate(
    endDate,
  )}`;

// formatDetailsHeaderInfo :: Input -> String
// Input = {
//    data: { user: User }
//    startDate: DateTime
//    endDate: DateTime
// }
const formatDetailsHeaderInfo = R.ifElse(
  R.either(
    R.propSatisfies(R.isNil, 'startDate'),
    R.propSatisfies(R.isNil, 'endDate'),
  ),
  R.always(''),
  getSafeHeaderInfo,
);

const ReportSubHeader = styled.div`
  font-size: 14px;
`;

export function StaffReportDetailsPage() {
  const {
    rows,
    data,
    headers,
    columns,
    sortQueries,
    type,
    startDate,
    endDate,
    loading,
    activityRecords,
  } = useStaffReportDetail();

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageHeaderContainer>
        <div>
          <MainHeader>{getMetricTitleByType(type)}</MainHeader>
          <ReportSubHeader>
            {formatDetailsHeaderInfo({ data, startDate, endDate })}
          </ReportSubHeader>
        </div>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCardWithPaginationContainer>
          <TableWithToolBarWrapper>
            {activityRecords.length > 0 ? (
              <StaffActivityTableS
                metricType={type}
                headers={headers}
                rows={rows}
                columns={columns}
                sortQueries={sortQueries}
              />
            ) : (
              <EmptyActivityList />
            )}
          </TableWithToolBarWrapper>
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </>
  );
}
