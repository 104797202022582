import * as R from 'ramda';
import { useMemo } from 'react';
import { assocBy, isNilOrEmpty } from '@poly/utils';
import {
  useInfiniteScrollQueryWithSubscription,
  highlightMatchesInObjectCurried,
} from '@poly/client-utils';
import {
  entities,
  prepareSortingWithSearchText,
  useMapConfigToTableProps,
  useTableSorting,
} from '@poly/admin-ui';

import { DESC_SORT_ORDER } from '@poly/constants/src/misc.js';
import { projectsQueries } from '../../../modules/tabs/common.js';
import { PROJECTS_BY_SEARCH } from '../../../modules/core/hooks/projects/queries.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../../modules/core/hooks/projects/subscriptions.js';
import { commonProjectTableConfig } from '../../../modules/tables/projectsTable/ProjectsTable.js';
import { getProjectsQueryByEntityQuery } from './getProjectsQueryByEntityQuery.js';

export const searchProjectTextPaths = [
  ['projectId'],
  ['description'],
  ['property', 'name'],
  ['client', 'name'],
  ['manager', 'fullName'],
];

// prepareProjectsForTable :: String -> SearchProjectsResult -> [Project]
const prepareProjectsForTable = (searchTerm) =>
  R.compose(
    R.map(highlightMatchesInObjectCurried(searchProjectTextPaths, searchTerm)),
    R.map(assocBy('projectLinkId', R.prop('projectId'))),
    R.pathOr([], ['searchProjects', 'hits']),
  );

// getSubPropertiesElasticQuery :: (String, String) -> [ElasticQuery]
export const getSubPropertiesElasticQuery = (entityName, entityId) =>
  entityName === entities.PROPERTY
    ? [{ term: { subPropertiesIds: entityId } }]
    : [];

// getEntityQueryObj :: Entity -> ElasticQuery
// Entity = { id: ID, name: String, queryField: String, mustQueryItems: [ElasticQuery] }
const getEntityQueryObj = ({ _id, name, queryField, mustQueryItems = [] }) => {
  const keyProp = queryField || `${name}Id`;

  return [
    {
      bool: {
        should: [
          { match: { [keyProp]: _id } },
          ...getSubPropertiesElasticQuery(name, _id),
        ],
        ...(isNilOrEmpty(mustQueryItems) ? {} : { must: mustQueryItems }),
      },
    },
  ];
};

export const useSearchSubTabsProjectsQuery = ({
  status,
  entity,
  searchTerm = '',
}) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: commonProjectTableConfig,
    column: 8,
    order: DESC_SORT_ORDER,
  });

  const statusQuery = projectsQueries[status];

  const entityQuery = getEntityQueryObj(entity);

  const searchInput = useMemo(
    () => ({
      searchTerm,
      sort: prepareSortingWithSearchText(searchTerm)(sort),
      query: entity
        ? getProjectsQueryByEntityQuery(entityQuery)(statusQuery)
        : statusQuery,
    }),
    [sort, status, entity, searchTerm],
  );

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    PROJECTS_BY_SEARCH,
    searchInput,
    {
      endpointName: 'searchProjects',
      inputName: 'searchInput',
    },
    PROJECTS_BY_SEARCH_SUB,
    { searchInput },
  );

  const dataTableProps = useMapConfigToTableProps(
    prepareProjectsForTable(searchTerm),
    commonProjectTableConfig,
    data,
  );

  const count = R.pathOr(0, ['searchProjects', 'total'], data);
  const projects = R.pathOr([], ['searchProjects', 'hits'], data);

  return {
    count,
    loading,
    projects,
    tableProps: {
      ...tableProps,
      ...dataTableProps,
      ...tableSortingProps,
    },
  };
};
