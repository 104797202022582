import React from 'react';
import { Select } from '@poly/admin-book';

import { PriorityUnits } from './constants.js';

const UNIT_SELECT_OPTIONS = [
  { label: 'days', value: PriorityUnits.DAYS },
  { label: 'hours', value: PriorityUnits.HOURS },
];

export function PriorityUnitSelect(props) {
  const selectOptions = {
    ...props,
    required: true,
    options: UNIT_SELECT_OPTIONS,
  };

  return <Select {...selectOptions} />;
}
