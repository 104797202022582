import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { FormField } from '@poly/admin-book';
import { arrayOf, func, number, object, shape, string } from 'prop-types';

import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';

import { FieldSelect } from './components/FieldSelect.js';
import { DeleteLineIcon } from './components/DeleteLineIcon.js';
import { ObjectTypeSelect } from './components/ObjectTypeSelect.js';
import { RulesAndOrCondition } from './components/RulesAndOrCondition.js';
import { FieldValueComponent } from './components/FieldValueComponent/FieldValueComponent.js';
import { FieldOperationSelect } from './components/FieldOperationSelect.js';
import { getAlreadySelectedFields } from './helpers/already-selected-fields.js';

const RuleLineWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  border-bottom: 1px solid #ebebeb;
`;

const RowGroupS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: ${R.propOr('auto', 'width')};
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 18px;
  border-right: 1px solid #bcbcbc;
`;

const FormFieldWrapper = styled.div`
  width: 30%;
`;

const DeleteLineIconS = styled(DeleteLineIcon)`
  margin-left: 12px;
`;

// getCurrentRuleGroupObjectType :: [{ objectType: String }] -> String
const getCurrentRuleGroupObjectType = R.path(['0', 'objectType']);

// getCurrentRuleField :: Int -> [{ field: String }] -> String
const getCurrentRuleField = (index) => R.path([index, 'field']);

// getCurrentRuleOperationField :: Int -> [{ fieldOperation: String }] -> String
const getCurrentRuleOperationField = (index) =>
  R.path([index, 'fieldOperation']);

export function RuleLine({
  name,
  index,
  setObjectType,
  changeFieldValue,
  fields: { push, value, remove },
}) {
  const isFirstLine = index === 0;
  const alreadySelectedFields = getAlreadySelectedFields(value);

  const onAddRule = (e) => {
    e.preventDefault();
    push({});
  };

  const objectType = getCurrentRuleGroupObjectType(value);
  const ruleField = getCurrentRuleField(index)(value);
  const ruleFieldOperation = getCurrentRuleOperationField(index)(value);

  return (
    <RuleLineWrapperS>
      {isFirstLine && (
        <>
          <FormField
            name={`${name}.objectType`}
            Component={ObjectTypeSelect}
            additionalProps={{ setObjectType }}
          />
          <RowGroupS>
            <FormField
              name={`${name}.rulesAndOrCondition`}
              Component={RulesAndOrCondition}
            />
            <VerticalDivider />
            <LinkButton onClick={onAddRule}>Add Rule</LinkButton>
          </RowGroupS>
        </>
      )}
      {!isFirstLine && (
        <RowGroupS width="100%">
          <FormFieldWrapper>
            <FormField
              name={`${name}.field`}
              Component={FieldSelect}
              additionalProps={{
                index,
                objectType,
                changeFieldValue,
                alreadySelectedFields,
              }}
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormField
              name={`${name}.fieldOperation`}
              Component={FieldOperationSelect}
              additionalProps={{ index, ruleField, changeFieldValue }}
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormField
              name={`${name}.fieldValue`}
              Component={FieldValueComponent}
              additionalProps={{ ruleField, objectType, ruleFieldOperation }}
            />
          </FormFieldWrapper>
          <DeleteLineIconS
            name="delete"
            size={18}
            onClick={() => remove(index)}
          />
        </RowGroupS>
      )}
    </RuleLineWrapperS>
  );
}

RuleLine.propTypes = {
  name: string,
  index: number,
  setObjectType: func,
  changeFieldValue: func,
  fields: shape({
    push: func,
    remove: func,
    // eslint-disable-next-line react/forbid-prop-types
    value: arrayOf(object),
  }),
};
