import React, { useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@poly/admin-book';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonPrintLayout } from '@poly/admin-ui';
import { openPrintWindowWithData } from '@poly/client-utils';
import { arrayOf, object, oneOfType, string } from 'prop-types';

import { ExportPDFBtn } from '../WorkInProgressReport/WorkInProgressExportPDFBtn.js';
import { UnassignedProjectsTable } from './UnassignedProjectsTable.js';
import { useUnassignedProjectsLazyQuery } from './useUnassignedProjectsQuery.js';
import { getUnassignedProjectsByQueryData } from './helpers.js';

const LoaderWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  min-width: 40px;
`;

export function UnassignedProjectsPDFBtn({ clientId, sort }) {
  const [printing, setPrinting] = useState(false);
  const queryHandler = useUnassignedProjectsLazyQuery();

  const onClick = async () => {
    setPrinting(true);
    const { data } = await queryHandler({ clientId, sort });

    const projects = getUnassignedProjectsByQueryData(data);

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: UnassignedProjectsTable,
      fileName: 'unassigned_projects_export',
      metaData: { title: 'Unassigned' },
      projects,
    });

    setPrinting(false);
  };

  if (printing) {
    return (
      <LoaderWrapperS>
        <Loader size={16} />
      </LoaderWrapperS>
    );
  }

  return <ExportPDFBtn onClick={onClick}>{PRINT_PDF_CAPTION}</ExportPDFBtn>;
}

UnassignedProjectsPDFBtn.propTypes = {
  clientId: string,
  sort: arrayOf(oneOfType([string, object])),
};
