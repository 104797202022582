import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';
import { CancelBtn, SubmitBtn } from '@poly/admin-ui';
import { useOutSidebarContext } from '@poly/client-utils';

import { BulkEditingSidebar } from './BulkEditingSidebar.js';
import { BulkEditingSidebarId } from './constants.js';
import {
  bulkEditQuerySortPropType,
  bulkEditQueryInputPropType,
} from './prop-types.js';

const BulkEditFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 0 65px;
  height: ${R.prop('height')};
`;

export function BulkEditFooter({
  height,
  disabled,
  querySort,
  objectType,
  queryInput,
  setDocuments,
  setQueryInput,
  setDocumentsCount,
}) {
  const { openOutSidebar } = useOutSidebarContext();

  const onSubmit = () =>
    openOutSidebar({
      width: 610,
      alwaysFirst: true,
      content: (
        <BulkEditingSidebar
          querySort={querySort}
          queryInput={queryInput}
          objectType={objectType}
          setDocuments={setDocuments}
          setQueryInput={setQueryInput}
          setDocumentsCount={setDocumentsCount}
        />
      ),
      id: BulkEditingSidebarId,
    });

  const onCancel = () => null;

  return (
    <BulkEditFooterWrapper height={height}>
      <CancelBtn disabled={disabled} onClick={onCancel} />
      <SubmitBtn disabled={disabled} onClick={onSubmit}>
        Edit
      </SubmitBtn>
    </BulkEditFooterWrapper>
  );
}

BulkEditFooter.propTypes = {
  height: string,
  disabled: bool,
  querySort: bulkEditQuerySortPropType,
  objectType: string,
  queryInput: bulkEditQueryInputPropType,
  setDocuments: func,
  setQueryInput: func,
  setDocumentsCount: func,
};
