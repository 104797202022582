import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import {
  arrayOf,
  bool,
  func,
  shape,
  number,
  string,
  oneOfType,
  instanceOf,
} from 'prop-types';
import { Table } from '@poly/admin-book/src/Table/Table.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { useModalContext } from '@poly/admin-ui/src/components/Modal/ModalProvider.js';
import { useNotificationState } from '@poly/admin-ui/src/hooks/useNotificationState.js';
import { confirmCancelFormPayload } from '@poly/admin-ui/src/components/common.js';
import { extractTablePropsFromConfig } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { mongoSortQuery } from '@poly/client-utils/src/sorting.js';

// eslint-disable-next-line import/no-unused-modules
export const MARK_SUPPLIER_INVOICE_BATCH_AS_PRINTED_MUTATION = gql`
  mutation MARK_SUPPLIER_INVOICE_BATCH_AS_PRINTED_MUTATION($batchId: ID!) {
    markSupplierInvoiceBatchAsPrinted(batchId: $batchId) {
      _id
    }
  }
`;

const TableS = styled(Table)`
  td:nth-child(1),
  th:nth-child(1) {
    width: 60%;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 20px;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 10px;
  }

  td:nth-child(4),
  th:nth-child(4) {
    text-align: center;
    width: 13px;
  }

  td:nth-child(5),
  th:nth-child(5) {
    text-align: center;
    width: 10px;
  }

  td:nth-child(6),
  th:nth-child(6) {
    text-align: center;
    width: 16px;
  }
`;

// getPrintBatchFileLinkUrl :: { supplierBatchFile: Attachment } -> String
const getPrintBatchFileLinkUrl = R.path(['supplierBatchFile', 'url']);

function ViewBatchFileLink(props) {
  const url = getPrintBatchFileLinkUrl(props);

  return (
    <LinkButton
      type="button"
      onClick={() => (url ? window.open(url, '_blank') : null)}
    >
      View PDF
    </LinkButton>
  );
}

function MarkAsPrintedButton({ refetch, _id, isPrinted, ...props }) {
  const { openConfirmModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [markAsPrintedMutation] = useMutation(
    MARK_SUPPLIER_INVOICE_BATCH_AS_PRINTED_MUTATION,
  );

  const url = getPrintBatchFileLinkUrl(props);

  const onClick = () =>
    url
      ? openConfirmModal({
          ...confirmCancelFormPayload,
          content: 'Are you sure you want to Mark as Printed this batch?',
          onConfirm: (closeConfirmModal) => async () => {
            await markAsPrintedMutation({ variables: { batchId: _id } });

            closeConfirmModal();
            showSuccessNotification(
              'Batch was successfully Marked as Printed.',
            );

            refetch();
          },
        })
      : null;

  return (
    <LinkButton type="button" onClick={onClick}>
      {isPrinted ? 'Printed' : 'Mark as Printed'}
    </LinkButton>
  );
}

MarkAsPrintedButton.propTypes = {
  _id: string,
  isPrinted: bool,
  refetch: func,
};

export const getDailySupplierInvoicesBatchTableConfig = (refetch, isPrint) => [
  ['Batch Date', R.prop('dateToHighlight'), mongoSortQuery('date')],
  [
    'Printed By',
    R.prop('printedByToHighlight'),
    mongoSortQuery('printedByName'),
  ],
  ['Printed At', R.prop('printedAtToHighlight'), mongoSortQuery('printedAt')],
  ['Status', R.prop('statusToHighlight'), mongoSortQuery('isPrinted')],
  ...(isPrint
    ? []
    : [
        ['', ViewBatchFileLink],
        ['', (props) => <MarkAsPrintedButton {...props} refetch={refetch} />],
      ]),
];

export function DailySupplierInvoicesBatchTable({
  printedSupplierBatches,
  refetch,
  isPrint,
  ...tableSortingProps
}) {
  const tableProps = extractTablePropsFromConfig(
    getDailySupplierInvoicesBatchTableConfig(refetch, isPrint),
  );

  return (
    <TableS
      {...tableProps}
      {...tableSortingProps}
      rows={printedSupplierBatches}
    />
  );
}

DailySupplierInvoicesBatchTable.propTypes = {
  refetch: func,
  isPrint: bool,
  windowedTableProps: shape({
    loadMoreItems: func,
    itemCount: number,
    loadedCount: number,
  }),
  printedSupplierBatches: arrayOf(
    shape({
      _id: string,
      isPrinted: bool,
      date: oneOfType([string, instanceOf(Date)]),
      printedAt: oneOfType([string, instanceOf(Date)]),
      printedBy: shape({
        profile: shape({
          fullName: string,
        }),
      }),
      supplierBatchFile: shape({
        fileName: string,
        url: string,
      }),
    }),
  ),
};
