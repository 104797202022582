import {
  isDate,
  endOfDay,
  setHours,
  addMonths,
  isSameDay,
  startOfDay,
  setMinutes,
} from 'date-fns';
import * as R from 'ramda';
import { addToRange } from 'react-day-picker';
import { useState, useEffect, useContext } from 'react';
import { instanceOf, oneOfType, shape, string } from 'prop-types';
import {
  alwaysNewDate,
  ensureIsDate,
  isNilOrEmpty,
  renameProp,
} from '@poly/utils';

import { ModalContext } from '../Modal/Modal.js';
import { DateRangeToButton } from './constants.js';
import { modifyStringToDate } from './utils.js';

export const valuePropTypes = oneOfType([
  instanceOf(Date),
  string,
  shape({
    startDate: oneOfType([instanceOf(Date), string]),
    endDate: oneOfType([instanceOf(Date), string]),
  }),
]);

export const useDatePickerLogic = ({
  value,
  onBlur,
  onChange,
  isMultiple,
  formatDate,
  withTimePicker,
  disableOverflowChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateValue, setDateValue] = useState('');

  const { overflow, setModalOverflow } = useContext(ModalContext);

  useEffect(() => {
    setDateValue(value ? formatDate(value) : '');
  }, [value]);

  const toggleIsOpen = (newValue) => {
    setIsOpen(newValue);
    if (
      typeof setModalOverflow === 'function' &&
      !disableOverflowChange &&
      overflow !== 'unset'
    ) {
      setModalOverflow(newValue ? 'initial' : 'auto');
    }
  };

  const onDayClick = (v, { disabled }, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      if (!isMultiple && !withTimePicker) toggleIsOpen(false);

      let finalValue = v;

      if (!isNilOrEmpty(value) && withTimePicker) {
        const minutes = ensureIsDate(value).getMinutes();
        const hours = ensureIsDate(value).getHours();

        finalValue = setHours(finalValue, hours);
        finalValue = setMinutes(finalValue, minutes);
      }

      onChange(finalValue);
      onBlur(e);
    }
  };

  const onChangeTimePicker = onChange;

  const onReset = (e) => {
    e.stopPropagation();
    const resetValue = isMultiple ? { startDate: '', endDate: '' } : '';
    setDateValue(resetValue);
    onChange(resetValue);
  };

  return {
    isOpen,
    onReset,
    overflow,
    dateValue,
    onDayClick,
    toggleIsOpen,
    setDateValue,
    onChangeTimePicker,
    modifiedValue: modifyStringToDate(value),
  };
};

// checkMonthIsValid :: Date -> Boolean
export const checkMonthIsValid = (monthDate) =>
  monthDate instanceof Date && Number.isFinite(monthDate.getTime());

export const useMultipleDayPickerLogic = ({
  value,
  onDayClick,
  getCustomButtons,
  ...props
}) => {
  const [range, setRange] = useState({
    from: R.prop('startDate', value),
    to: R.prop('endDate', value),
  });

  const [month, setMonth] = useState(
    R.pipe(
      R.path(['from']),
      R.when(R.anyPass([R.isEmpty, R.isNil]), () => alwaysNewDate()),
    )(range),
  );

  const onDayClickHandler = (day, disabled, e) => {
    if (
      isSameDay(ensureIsDate(range.from), ensureIsDate(range.to)) &&
      isSameDay(ensureIsDate(day), ensureIsDate(range.from))
    ) {
      return;
    }

    const { from, to } = isDate(day) ? addToRange(day, range) : day;

    const newRange = {
      from: isDate(from) ? startOfDay(from) : from,
      to: isDate(to) ? endOfDay(to) : to,
    };

    setRange(newRange);

    if (newRange.from && newRange.to) {
      const payloadDate = R.pipe(
        renameProp('from', 'startDate'),
        renameProp('to', 'endDate'),
      )(newRange);

      onDayClick(payloadDate, disabled, e);
    } else {
      onDayClick({ startDate: '', endDate: '' }, disabled, e);
    }
  };

  const changeMonth = (num) => () =>
    setMonth(addMonths(ensureIsDate(month), num));

  const onFooterBtnClick = (e, btnName) => {
    const FooterButtons = getCustomButtons
      ? getCustomButtons(props)
      : DateRangeToButton;
    onDayClick(FooterButtons[btnName], true, e);
    setMonth(FooterButtons[btnName].to);
  };

  return {
    changeMonth,
    onFooterBtnClick,
    onDayClick: onDayClickHandler,
    month: checkMonthIsValid(month) ? month : alwaysNewDate(),
    selected: [range.from, { from: range.from, to: range.to }],
  };
};
