import { gql } from '@apollo/client';

import { commonUpdateFields } from '../updates/fragments.js';
import { basicTaskFields, commonTaskDocumentFields } from './fragments.js';

export const TASK_UPDATES = gql`
  query TASK_UPDATES($id: ID!, $documentUpdatesInput: DocumentUpdatesInput!) {
    task(id: $id) {
      ...basicTaskFields
      action
      document {
        ...commonTaskDocumentFields
      }
      manager {
        _id
      }
      nextTask {
        _id
        description
        dueDate
      }
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
      }
    }
  }

  ${commonUpdateFields}
  ${basicTaskFields}
  ${commonTaskDocumentFields}
`;

export const ENTITY_TASKS = gql`
  query ENTITY_TASKS($input: TasksInput!) {
    tasks(input: $input) {
      hits {
        _id
        dueDate
        description
        complete
        scheduleDate
        supplierArrivedAt
        supplier {
          _id
          company {
            name
          }
        }
        multiInvoiceNote
        action
        createdAt
        completedAt
        manager {
          _id
          fullName
        }
        supplierInvoice {
          _id
          status
          isOwnSupplierInvoice
        }
        isMainSupplierTask
        documentId
        projectStatus
        nextTask {
          _id
          description
          complete
          dueDate
          manager {
            _id
          }
        }
      }
      total
    }
  }
`;

export const MY_TASKS_QUERY = gql`
  query MY_TASKS($input: TasksInput!) {
    me {
      _id
      tasks(input: $input) {
        total
        hits {
          ...basicTaskFields
          action
          collection
          documentId
          supplierInvoice {
            _id
            status
          }
          document {
            ...commonTaskDocumentFields
          }
        }
      }
    }
  }

  ${basicTaskFields}
  ${commonTaskDocumentFields}
`;

export const PROJECT_PO_QUERY = gql`
  query PROJECT_PO_QUERY($projectId: ID!) {
    project(id: $projectId) {
      _id
      type
      adminPurchaseOrder {
        _id
      }
      parent {
        adminPurchaseOrder {
          _id
        }
      }
    }
  }
`;

export const PROJECT_SUB = gql`
  subscription PROJECT_DETAILS_SUB($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
    }
  }
`;
