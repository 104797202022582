import * as R from 'ramda';
import React from 'react';
import { startOfYear, endOfYear, isEqual } from 'date-fns';
import { bool, func, shape, string } from 'prop-types';
import { DatePicker, ToolBarBtnDivider } from '@poly/admin-book';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';
import { USAStates } from '@poly/constants';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { SalesTaxReportPrintBtn } from './SalesTaxReportPrintPDFBtn.js';
import { SalesTaxReportExportXLBtn } from './SalesTaxReportExportXLBtn.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import {
  getFiscalYearSubscribersByDate,
  FiscalYearSelect,
  THIS_YEAR_VALUE,
} from '../../components/FiscalYearSelect.js';
import { taxReportProps } from './prop-types.js';
import { SalesTaxReportStateSelect } from './salesTaxReportComponents.js';

const SALES_REPORT_BY_STATE_TITLE = 'Sales Report by State';

const filterProps = { width: '160px', size: 'small' };

const getSalesTaxReportHeaderConfig = (state) => [
  {
    name: 'startDate',
    defaultValue: startOfYear(alwaysNewDate()),
  },
  {
    name: 'endDate',
    defaultValue: endOfYear(alwaysNewDate()),
  },
  {
    name: 'year',
    defaultValue: THIS_YEAR_VALUE,
    subscribers: getFiscalYearSubscribersByDate(alwaysNewDate()),
  },
  {
    name: 'state',
    defaultValue: state,
  },
];

// getTitleByState :: String -> String
const getTitleByState = R.ifElse(
  R.isNil,
  R.always(SALES_REPORT_BY_STATE_TITLE),
  R.compose(R.concat(R.__, ' Sales Tax Report'), R.prop(R.__, USAStates)),
);

// isSameFilterByProp :: { filters: PageFilters, searchFilters: PageFilters } -> Boolean
const isSameFilterByProp = (prop) =>
  R.converge(R.equals, [
    R.path(['filters', prop]),
    R.path(['searchFilters', prop]),
  ]);

// isDatesEqual :: DateTime -> DateTime -> Boolean
const isDatesEqual = R.curry((date1, date2) => isEqual(date1, date2));

// isSameDateFilterByProp :: { filters: PageFilters, searchFilters: PageFilters } -> Boolean
const isSameDateFilterByProp = (prop) =>
  R.converge(isDatesEqual, [
    R.compose(ensureIsDate, R.path(['filters', prop])),
    R.compose(ensureIsDate, R.path(['values', prop])),
  ]);

// isFiltersChanged :: { filters: PageFilters, values: PageFilters } -> Boolean
// PageFilters = {
//    startDate: DateTime
//    endDate: DateTime
//    year: String
//    state: String
// }
const isFiltersChanged = R.compose(
  R.any(R.equals(false)),
  R.juxt([
    isSameDateFilterByProp('startDate'),
    isSameDateFilterByProp('endDate'),
    isSameFilterByProp('year'),
    isSameFilterByProp('state'),
  ]),
);

export function SalesTaxReportHeader({
  state,
  filters,
  loading,
  setFilters,
  exportProps,
}) {
  const filtersConfig = getSalesTaxReportHeaderConfig(state);

  const onSearchHandler = (values) => {
    if (isFiltersChanged({ filters, values })) {
      setFilters(values);
    }
  };

  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    filtersConfig,
    onSearchHandler,
  );

  const onResetSearch = () => {
    onReset();
    setFilters(null);
  };

  const title = getTitleByState(searchFilters.state);

  return (
    <SearchPageHeaderContainer title={title}>
      <FiltersContainer>
        <FlexSpaceBetween>
          <FlexCenterAlign>
            <SearchHeaderColumn
              title="From Date"
              titleWidth="90px"
              filterWidth="120px"
              labelForId="startDate"
            >
              <DatePicker
                {...filterProps}
                showResetBtn
                id="startDate"
                width="120px"
                value={searchFilters.startDate}
                onChange={handlers.startDate}
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn
              title="To Date"
              titleWidth="60px"
              filterWidth="120px"
              labelForId="endDate"
            >
              <DatePicker
                {...filterProps}
                showResetBtn
                id="endDate"
                width="120px"
                value={searchFilters.endDate}
                onChange={handlers.endDate}
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn filterWidth="160px" labelForId="endDate">
              <FiscalYearSelect
                id="year"
                name="year"
                {...filterProps}
                value={searchFilters.year}
                onChange={handlers.year}
                required
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn
              title="State"
              titleWidth="45px"
              filterWidth="160px"
              labelForId="state"
            >
              <SalesTaxReportStateSelect
                {...filterProps}
                id="state"
                value={searchFilters.state}
                onChange={handlers.state}
                required
              />
            </SearchHeaderColumn>
          </FlexCenterAlign>
          <SearchHeaderColumn
            titleWidth="0px"
            filterWidth="375px"
            position="flex-end"
          >
            <FlexCenterAlign>
              <SalesTaxReportPrintBtn {...{ title, ...exportProps }} />
              <ToolBarBtnDivider />
              <SalesTaxReportExportXLBtn
                {...{ title, state: searchFilters.state, ...exportProps }}
              />
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

SalesTaxReportHeader.propTypes = {
  setFilters: func.isRequired,
  state: string,
  filters: shape({}),
  exportProps: shape({
    report: taxReportProps,
    query: shape({ state: string }),
  }),
  loading: bool,
};
