import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { SystemAccountTypes } from '@poly/constants';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { useMemo, useCallback, useEffect } from 'react';
import { convertCentsToDollars, pathEqLegacy } from '@poly/utils';

import { setGlobalTrigger } from '../../../redux/globalTriger.js';
import { setEditablePayments } from '../../../redux/editablePayments.js';

const getEditableClientPaymentsQuery = gql`
  query GET_EDITABLE_CLIENT_PAYMENTS_QUERY(
    $input: EditableClientPaymentsInput!
  ) {
    getEditableClientPayments(input: $input) {
      total
      hits {
        _id
        transaction_number
        ref_number
        date
        type
        lines {
          accrual_amount
          cash_amount
          account {
            _id
            code
            name
            is_client_payment_deduction
            accountType {
              _id
              system_type
            }
          }
        }
        client {
          _id
          name
        }
        clientInvoice {
          _id
          number
          amount
          invoiceDate
          project {
            _id
            projectId
          }
        }
      }
    }
  }
`;

// prepareQueryInput :: EditableClientPaymentsSortInput -> PageFilters -> EditableClientPaymentsInput
// PageFilters = {
//    clientId: ID
//    projectId: ID
//    clientInvoiceId: ID
//    paymentEndDate: String
//    paymentStartDate: String
// }
const prepareQueryInput = (sort) =>
  R.compose(
    R.reject(R.isNil),
    R.applySpec({
      sort: R.always(sort),
      clientId: R.prop('clientId'),
      projectId: R.prop('projectId'),
      clientInvoiceId: R.prop('clientInvoiceId'),
      paymentEndDate: R.compose(
        R.unless(R.isNil, (dateString) => new Date(dateString)),
        R.prop('paymentEndDate'),
      ),
      paymentStartDate: R.compose(
        R.unless(R.isNil, (dateString) => new Date(dateString)),
        R.prop('paymentStartDate'),
      ),
    }),
    R.defaultTo({}),
  );

// getClientInvoiceAmount :: JournalPostgre -> Float
const getClientInvoiceAmount = R.compose(
  convertCentsToDollars,
  R.path(['clientInvoice', 'amount']),
);

// getJournalLines :: JournalPostgre -> [JournalLinePostgre]
const getJournalLines = R.propOr([], 'lines');

// getBankJournalLine :: JournalPostgre -> JournalLinePostgre
const getBankJournalLine = R.compose(
  R.find(
    pathEqLegacy(
      ['account', 'accountType', 'system_type'],
      SystemAccountTypes.BANK_ACCOUNT,
    ),
  ),
  getJournalLines,
);

// getDeductionJournalLine :: JournalPostgre -> JournalLinePostgre
const getDeductionJournalLine = R.compose(
  R.find(R.path(['account', 'is_client_payment_deduction'])),
  getJournalLines,
);

// getReceivedAmount :: JournalPostgre -> Float
const getReceivedAmount = R.compose(R.prop('cash_amount'), getBankJournalLine);

// getDeductionAmount :: JournalPostgre -> Float
const getDeductionAmount = R.compose(
  R.prop('cash_amount'),
  getDeductionJournalLine,
);

// getDeductionGL :: JournalPostgre -> String
const getDeductionGL = R.compose(
  R.path(['account', '_id']),
  getDeductionJournalLine,
);

// getDepositAccount :: JournalPostgre -> String
const getDepositAccount = R.compose(
  R.path(['account', '_id']),
  getBankJournalLine,
);

// getEditablePayments :: { getEditableClientPayments: EditableClientPaymentsResult } -> [EditablePaymentRows]
// EditablePaymentRows = {
//    _id: ID
//    clientName: String
//    clientInvoiceDate: DateTime
//    projectNumber: String
//    clientInvoiceNumber: Int
//    clientInvoiceAmount: Int
//    deductionAmount: Float
//    deductionGL: ID
//    receivedAmount: Float
//    refNumber: String
//    depositAccount: ID
//    receivedDate: DateTime
//    transactionNumber: Int
// }
const getEditablePayments = R.compose(
  R.map(
    R.applySpec({
      _id: R.prop('_id'),
      clientName: R.path(['client', 'name']),
      clientInvoiceDate: R.path(['clientInvoice', 'invoiceDate']),
      projectNumber: R.path(['clientInvoice', 'project', 'projectId']),
      clientInvoiceNumber: R.path(['clientInvoice', 'number']),
      clientInvoiceAmount: getClientInvoiceAmount,
      deductionAmount: getDeductionAmount,
      deductionGL: getDeductionGL,
      receivedAmount: getReceivedAmount,
      refNumber: R.propOr('', 'ref_number'),
      depositAccount: getDepositAccount,
      receivedDate: R.prop('date'),
      transactionNumber: R.prop('transaction_number'),
      isSelected: R.F,
    }),
  ),
  R.pathOr([], ['getEditableClientPayments', 'hits']),
);

export const useFetchEditableClientPayments = ({ sort, filters }) => {
  const dispatch = useDispatch();

  const input = useMemo(
    () => filters && prepareQueryInput(sort)(filters),
    [sort, filters],
  );

  const pageSize = 500;

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    getEditableClientPaymentsQuery,
    input,
    {
      endpointName: 'getEditableClientPayments',
      pageSize,
      skip: !filters,
    },
  );

  const editablePayments = useMemo(() => getEditablePayments(data), [data]);

  const setGlobalTriggerDebounce = useCallback(
    (isGlobalTrigger) => dispatch(setGlobalTrigger(isGlobalTrigger)),
    [],
  );

  useEffect(() => {
    dispatch(setEditablePayments(editablePayments));
    setGlobalTriggerDebounce(false);
  }, [editablePayments]);

  return {
    editablePayments,
    loading,
    tableProps,
    pageSize,
  };
};
