import React from 'react';
import { func, string } from 'prop-types';

import { useFieldToEditValueComponentProps } from './useFieldToEditValueComponentProps.js';

export function FieldToEditValueComponent({
  onChange,
  objectType,
  selectedField,
  ...props
}) {
  const { Component, ...componentProps } = useFieldToEditValueComponentProps({
    objectType,
    selectedField,
  });

  return (
    <Component
      {...props}
      {...componentProps}
      required
      onChange={onChange}
      handleChange={onChange}
    />
  );
}

FieldToEditValueComponent.propTypes = {
  onChange: func,
  objectType: string,
  selectedField: string,
};
