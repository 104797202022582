import React, { useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import { TableCard } from '@poly/admin-book';
import { TablePagination } from '@poly/admin-ui/src/components/Pagination.js';
import { TableWithPaginationContainer } from '@poly/admin-book/src/Card.js';

import { SuppliersTable } from '../../tables/suppliersTable/SuppliersTable.js';

export function SuppliersTab({
  query,
  currentTab,
  setExportSort,
  setCurrentTab,
  showDateColumn,
}) {
  useEffect(() => {
    if (setCurrentTab) {
      setCurrentTab(currentTab);
    }
  }, []);

  return (
    <TableCard>
      <TableWithPaginationContainer {...{ paginationVisible: true }}>
        <SuppliersTable
          {...{ query }}
          setExportSort={setExportSort}
          showDateColumn={showDateColumn}
        />
      </TableWithPaginationContainer>
      <TablePagination />
    </TableCard>
  );
}

SuppliersTab.displayName = 'SuppliersTab';

SuppliersTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object.isRequired,
  currentTab: string,
  setExportSort: func,
  setCurrentTab: func,
  showDateColumn: bool,
};
