import React, { useEffect } from 'react';
import * as R from 'ramda';
import { shape, instanceOf, string, func } from 'prop-types';
import { DEFAULT_CLIENT_PROJECT_PRIORITIES } from '@poly/constants';
import { getEndDateByProjectWithClient } from '@poly/utils';
import { Select } from '@poly/admin-book';

import { formTypeConstants } from '../utils/constants.js';

// prepareSelectOptions :: Client -> [SelectOption]
const prepareSelectOptions = R.compose(
  R.map(R.applySpec({ value: R.prop('id'), label: R.prop('name') })),
  R.when(R.isEmpty, R.always(DEFAULT_CLIENT_PROJECT_PRIORITIES)),
  R.pathOr([], ['configs', 'projectPriorities']),
);

export function WorkPrioritySelectField({
  changeFieldValue,
  onChange,
  formData: {
    formType,
    startDate,
    endDate,
    client,
    priorityId: formDataPriorityId,
  },
  ...restProps
}) {
  const onChangeHandler = (priorityId) => {
    onChange(priorityId);
    if (changeFieldValue) {
      const newEndDate = getEndDateByProjectWithClient({
        priorityId,
        startDate,
        client,
      });

      changeFieldValue('endDate', newEndDate);
    }
  };

  useEffect(() => {
    if (client && changeFieldValue && formType === formTypeConstants.CREATE) {
      const newEndDate = getEndDateByProjectWithClient({
        priorityId: formDataPriorityId,
        startDate,
        client,
      });

      if (!R.equals(newEndDate, endDate)) {
        changeFieldValue('endDate', newEndDate);
      }
    }
  }, [client]);

  const selectProps = {
    ...restProps,
    name: 'WorkPrioritySelectField',
    options: prepareSelectOptions(client),
    onChange: onChangeHandler,
  };

  return <Select {...selectProps} />;
}

WorkPrioritySelectField.displayNme = 'WorkPrioritySelectField';

WorkPrioritySelectField.propTypes = {
  formData: shape({
    formType: string,
    endDate: instanceOf(Date),
    startDate: instanceOf(Date),
  }),
  onChange: func,
  changeFieldValue: func,
};
