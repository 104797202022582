import * as R from 'ramda';
import { NOTHING_UI_STRING } from '@poly/constants';
import {
  PROPERTY_ID_AIT,
  EQUALS_AIT_OPERATOR,
  FLIPPED_CONTAINS_AIT_OPERATOR,
  NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
} from '@poly/security';
import { mapIndexed } from '@poly/utils';

// prepareUserGroupName :: [UserGroup] -> { userGroupId: ID } -> String
const prepareUserGroupName = (userGroups) =>
  R.compose(
    R.propOr(NOTHING_UI_STRING, 'name'),
    R.find(R.__, userGroups),
    R.propEq(R.__, '_id'),
    R.prop('userGroupId'),
  );

// prepareVariableDisplayValue :: (Client, [Property]) -> AccessItemTypesObjectInput -> String
const prepareVariableDisplayValue = (client, properties) =>
  R.cond([
    [
      R.pathSatisfies(R.is(String), [PROPERTY_ID_AIT, EQUALS_AIT_OPERATOR]),
      R.compose(
        R.concat('Property|EQ|'),
        R.prop('name'),
        R.find(R.__, properties),
        R.propEq(R.__, '_id'),
        R.path([PROPERTY_ID_AIT, EQUALS_AIT_OPERATOR]),
      ),
    ],
    [
      R.pathSatisfies(R.is(String), [
        PROPERTY_ID_AIT,
        FLIPPED_CONTAINS_AIT_OPERATOR,
        '0',
      ]),
      R.compose(
        R.concat('Property|IN|'),
        R.join(', '),
        R.map(R.prop('name')),
        R.map(R.find(R.__, properties)),
        R.map(R.propEq(R.__, '_id')),
        R.path([PROPERTY_ID_AIT, FLIPPED_CONTAINS_AIT_OPERATOR]),
      ),
    ],
    [
      R.pathSatisfies(R.is(String), [
        PROPERTY_ID_AIT,
        NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
        '0',
      ]),
      R.compose(
        R.concat('Property|NIN|'),
        R.join(', '),
        R.map(R.prop('name')),
        R.map(R.find(R.__, properties)),
        R.map(R.propEq(R.__, '_id')),
        R.path([PROPERTY_ID_AIT, NOT_FLIPPED_CONTAINS_AIT_OPERATOR]),
      ),
    ],
    [R.T, R.always(`Client|EQ|${client?.name}`)],
  ]);

// prepareTableItem :: (Int, Client, [Property], [UserGroup]) -> UserUserGroupInput -> Object
const prepareTableItem = (index, client, properties, userGroups) =>
  R.applySpec({
    _id: R.compose(R.concat(`${index}_`), R.prop('userGroupId')),
    index: R.always(index),
    userGroupName: prepareUserGroupName(userGroups),
    originalPayload: R.identity,
    variables: R.compose(
      R.when(R.isEmpty, R.always([NOTHING_UI_STRING])),
      R.map(prepareVariableDisplayValue(client, properties)),
      R.map(R.prop('value')),
      R.propOr([], 'variables'),
    ),
  });

// prepareTableItems :: (Client, [Property], [UserGroup]) -> [UserUserGroupInput]
// -> [Object]
export const prepareTableItems = (client, properties, userGroups) =>
  R.compose(
    mapIndexed((value, index) =>
      prepareTableItem(index, client, properties, userGroups)(value),
    ),
    R.defaultTo([]),
  );
