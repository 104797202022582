import { gql, useMutation } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';

import { hasSupplierProjectEstimate } from './useRemoveProjectSupplier.js';

const UNASSIGN_PROJECT_SUPPLIER = gql`
  mutation UNASSIGN_PROJECT_SUPPLIER($input: UnassignSupplierAtProjectInput!) {
    unassignSupplierAtProject(input: $input) {
      project {
        _id
      }
    }
  }
`;

export const useUnassignProjectSupplier = ({ supplierId, project }) => {
  const { openConfirmModal } = useModalContext();
  const [unassignProjectSupplier] = useMutation(UNASSIGN_PROJECT_SUPPLIER);
  const { showSuccessNotification } = useNotificationState();

  const handleUnassignSupplier = async () => {
    await unassignProjectSupplier({
      variables: { input: { projectId: project?._id, supplierId } },
    });
    showSuccessNotification('Supplier was successfully unassigned');
  };

  return async () => {
    const isHasProjectEstimate = hasSupplierProjectEstimate(
      supplierId,
      project,
    );

    if (isHasProjectEstimate) {
      openConfirmModal({
        cancelBtnCaption: 'Cancel',
        btnCaption: 'Yes',
        id: 'confirmUnassignSupplierModalId',
        content:
          'If you unassign this supplier, the associated client estimate will also be removed. Do you want to continue?',
        onConfirm: (closeConfirmModal) => async () => {
          await handleUnassignSupplier();
          closeConfirmModal();
        },
      });

      return;
    }

    await handleUnassignSupplier();
  };
};
