import React from 'react';
import { AccountTypeStatus } from '@poly/constants';
import { Select } from '@poly/admin-book';
import { capitalizeFirstLetter } from '@poly/client-utils';

const statusOptions = [
  {
    value: AccountTypeStatus.ACTIVE,
    label: capitalizeFirstLetter(AccountTypeStatus.ACTIVE),
  },
  {
    value: AccountTypeStatus.INACTIVE,
    label: capitalizeFirstLetter(AccountTypeStatus.INACTIVE),
  },
];

export function AccountTypeStatusDropDown(props) {
  return <Select {...props} options={statusOptions} />;
}
