import * as R from 'ramda';
import { bool } from 'prop-types';
import { useQuery } from '@apollo/client';
import React, { useEffect, memo } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { useRouterQuery } from '@poly/client-routing';
import {
  moneyColumnStyles,
  WindowedTable,
  getThemeColor,
  Table,
} from '@poly/admin-book';

import { GET_ACCOUNTS_QUERY } from '../../components/AccountsSelect.js';
import { filterSelectedInvoices } from '../PaySuppliersPage/paySuppliersUtils/payInvoicesUtils.js';
import { useInvoicesHandlers } from '../PaySuppliersPage/usePaymentsHandlers.js';
import { paySuppliersTableConfig } from './useFetchClientInvoices.js';
import { setAllInvoices } from '../../redux/invoices.js';
import {
  calculateSelectedInvoicesDeductionsTotal,
  distributeInvoicesDiscount,
  adjustDiscount,
} from './clientPaymentsHelpers.js';

const payClientInvoicesTableStyles = css`
  th:nth-child(5),
  td:nth-child(5) {
    width: 150px;
  }

  th:nth-child(8),
  td:nth-child(8) {
    width: 170px;
  }
  th:nth-child(9),
  td:nth-child(9) {
    width: 1.9%;
    text-align: center;
  }
  th:nth-child(7),
  td:nth-child(7),
  th:nth-child(10),
  td:nth-child(10) {
    width: 110px;
  }

  th:nth-child(11),
  td:nth-child(11) {
    width: 130px;
  }

  ${moneyColumnStyles(6)};

  th:nth-child(6),
  td:nth-child(6) {
    width: 150px;
  }
`;

export const PayInvoicesWindowedTableS = styled(WindowedTable)`
  th {
    font-size: 10px;
  }

  tbody {
    vertical-align: middle;
  }

  thead {
    white-space: nowrap;
  }

  tr {
    margin: 0;
    width: 100%;
  }

  td {
    padding: 5px;

    & > div {
      & > input {
        height: 26px;
      }
      & > div {
        padding: 0;
        line-height: 14px;
      }
      & > svg {
        top: 5px;
      }
    }
  }

  tfoot {
    position: unset;
  }
  thead {
    background: ${getThemeColor(['lightest'])};
  }
  ${R.prop('tableStyles')}
`;

const PayInvoicesTableS = styled(Table)`
  ${R.prop('tableStyles')};
`;

const useClientDiscountLogic = () => {
  const { discount } = useRouterQuery(['discount']);
  const invoices = useSelector(R.prop('invoices'));
  const dispatch = useDispatch();

  const { data } = useQuery(GET_ACCOUNTS_QUERY, {
    variables: {
      filters: {
        from: 0,
        size: 1,
        subAccount: true,
        isClientPaymentDeduction: true,
        searchText: 'Customer Discounts Given',
      },
    },
  });
  const discountAccountId = R.path(['getAccounts', 'hits', 0, '_id'], data);

  useEffect(() => {
    const selectedInvoices = filterSelectedInvoices(invoices);
    if (selectedInvoices.length > 0 && discount && discount > 0) {
      let invoicesWithDiscount = distributeInvoicesDiscount(
        discount,
        invoices,
        discountAccountId,
      );
      const distributedDiscountTotal =
        calculateSelectedInvoicesDeductionsTotal(invoicesWithDiscount);
      const unassignedDiscountFraction = discount - distributedDiscountTotal;
      if (unassignedDiscountFraction !== 0) {
        invoicesWithDiscount = adjustDiscount(
          unassignedDiscountFraction,
          invoicesWithDiscount,
        );
      }
      dispatch(setAllInvoices(invoicesWithDiscount));
    }
  }, [discount]);
};

export const ReceivePaymentsTable = memo(({ virtualized, ...props }) => {
  const { onSelectAll, isAllSelected, rows } = useInvoicesHandlers();

  useClientDiscountLogic();

  const tableConfig = paySuppliersTableConfig(onSelectAll, isAllSelected);

  const tableProps = useMapConfigToTableProps(R.identity, tableConfig, rows);

  const TableC = virtualized ? PayInvoicesWindowedTableS : PayInvoicesTableS;

  return (
    <TableC
      {...tableProps}
      {...props}
      itemSize={60}
      showScrollBar
      moreScrollPosition="0px"
      tableStyles={payClientInvoicesTableStyles}
    />
  );
});

ReceivePaymentsTable.propTypes = { virtualized: bool };
