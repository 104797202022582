import {
  func,
  number,
  object,
  string,
  arrayOf,
  element,
  oneOfType,
} from 'prop-types';
import React from 'react';
import { Text } from '@poly/admin-book';

import { FlexRow } from '../appBar/HistoryPopUp/historyComponents.js';
import { BlockWithLabel } from '../../sidebars/components/commonSidebarComponents.js';
import { defaultTextProps } from '../../sidebars/components/commonSidebarSectionFormatters.js';

const { default: styled } = require('styled-components');

export const ProjectPrintToPDFTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 40px;

  @page {
    margin: 0;
  }
`;

export const PrintProjectFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ withBottomBorder }) =>
    withBottomBorder && 'border-bottom: 1px solid #dedede;'}
`;

export const PrintProjectFlexRow = styled(FlexRow)`
  justify-content: space-between;
  width: ${({ width }) => width || '1070px'};
  margin: 15px 0;
  ${({ withBottomBorder }) =>
    withBottomBorder && 'border-bottom: 1px dashed #dedede;'}
`;

const AssetImageS = styled.img`
  width: 300px;
  height: auto;
  margin: 5px 20px 5px 5px;
`;

const NoAssetImageText = styled(Text)`
  width: 300px;
  align-self: center;
`;

const BlockWithLabelS = styled(BlockWithLabel)`
  label {
    color: #e0e0e0;
  }
`;

function PrintProjectText({ value }) {
  return <Text {...defaultTextProps}>{value}</Text>;
}

PrintProjectText.propTypes = {
  value: oneOfType([string, number]),
};

export function PrintProjectArrayText({
  arrayValue,
  arrayKeyPart,
  uniqKeyParts,
}) {
  return (
    <PrintProjectFlexColumn>
      {arrayValue.map((value, index) => (
        <Text
          key={`${value}${uniqKeyParts ? uniqKeyParts[index] : ''}-${
            arrayKeyPart || ''
          }-key`}
          {...defaultTextProps}
        >
          {value}
        </Text>
      ))}
    </PrintProjectFlexColumn>
  );
}

PrintProjectArrayText.propTypes = {
  arrayKeyPart: string,
  arrayValue: arrayOf(oneOfType([string, number])),
  uniqKeyParts: arrayOf(oneOfType([string, number])),
};

export function PrintProjectBlockWithLabel({ label, Component, ...props }) {
  const CurrentComponent = Component || PrintProjectText;

  return (
    <BlockWithLabelS
      width="100%"
      margin="0 7px"
      label={label}
      id={`${label}_id`}
      Component={CurrentComponent}
      {...props}
    />
  );
}

PrintProjectBlockWithLabel.propTypes = {
  label: string.isRequired,
  Component: oneOfType([element, func, object]),
};

export function PrintProjectAssetImage({ url }) {
  if (!url) {
    return (
      <PrintProjectFlexColumn width="300px" margin="5px 20px 5px 5px">
        <NoAssetImageText {...defaultTextProps}>No Image</NoAssetImageText>
      </PrintProjectFlexColumn>
    );
  }

  return <AssetImageS src={url} alt="asset-photo" />;
}

PrintProjectAssetImage.propTypes = {
  url: string,
};
