import React from 'react';
import * as R from 'ramda';
import { Loader } from '@poly/admin-book';
import { removePropDeep } from '@poly/utils';
import { string, func, bool } from 'prop-types';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
  CLIENT_ID_AIT,
  PROPERTY_ID_AIT,
  EQUALS_AIT_OPERATOR,
  FLIPPED_CONTAINS_AIT_OPERATOR,
} from '@poly/security';

import { formatUserInput } from './form/formatUserInput.js';
import { ClientUserFormBase } from './ClientUserFormBase.js';
import { editClientUserFormId } from './constants.js';
import { useUserGroupListQuery } from '../../../pages/UserGroupManagement/useUserGroupListQuery.js';

const editUserDetailsQuery = gql`
  query editUserDetailsQuery($userId: ID!) {
    user(id: $userId) {
      _id
      status
      isClientPortalUser
      userGroups {
        userGroup {
          _id
        }
        variables {
          variableId
          value {
            clientId {
              equals
              flippedContains
              notFlippedContains
            }
            propertyId {
              equals
              flippedContains
              notFlippedContains
            }
          }
        }
      }
      profile {
        firstName
        lastName
        workPhoneNumber
        workPhoneNumberExt
        cellPhoneNumber
        cellPhoneNumber
        loginCellPhoneNumber
      }
      email
      accessItems {
        permission
        types {
          propertyId {
            equals
            flippedContains
          }
        }
      }
    }
  }
`;

// checkIsUserWithMoreThenOneUserGroup :: ID -> User -> Boolean
const checkIsUserWithMoreThenOneUserGroup = (clientId) =>
  R.compose(
    R.not,
    R.all(
      R.allPass([
        R.compose(R.equals(1), R.length),
        R.compose(R.equals(clientId), R.head),
      ]),
    ),
    R.map(R.uniq),
    R.reject(R.isEmpty),
    R.map(R.reject(R.isNil)),
    R.map(R.flatten),
    R.map(
      R.map(
        R.juxt([
          R.path(['variables', 'value', CLIENT_ID_AIT, EQUALS_AIT_OPERATOR]),
          R.path([
            'variables',
            'value',
            CLIENT_ID_AIT,
            FLIPPED_CONTAINS_AIT_OPERATOR,
          ]),
        ]),
      ),
    ),
    R.map(R.unwind('variables')),
    R.propOr([], 'userGroups'),
  );

// getInitialValues :: ID -> Object -> FormValues
const getInitialValues = (clientId) =>
  R.compose(
    R.applySpec({
      status: R.prop('status'),
      firstName: R.path(['profile', 'firstName']),
      lastName: R.path(['profile', 'lastName']),
      email: R.prop('email'),
      workPhone: R.path(['profile', 'workPhoneNumber']),
      ext: R.path(['profile', 'workPhoneNumberExt']),
      mobilePhone: R.path(['profile', 'cellPhoneNumber']),
      loginCellPhoneNumber: R.path(['profile', 'loginCellPhoneNumber']),
      isClientPortalUser: R.prop('isClientPortalUser'),
      isUserWithMoreThenOneUserGroup:
        checkIsUserWithMoreThenOneUserGroup(clientId),
      userGroups: R.compose(
        R.map(R.objOf('userGroupId')),
        R.map(R.path(['userGroup', '_id'])),
        R.propOr([], 'userGroups'),
      ),
      selectedPropertyIds: R.compose(
        R.uniq,
        R.filter(R.is(String)),
        R.flatten,
        R.map(
          R.juxt([
            R.path(['value', PROPERTY_ID_AIT, EQUALS_AIT_OPERATOR]),
            R.path(['value', PROPERTY_ID_AIT, FLIPPED_CONTAINS_AIT_OPERATOR]),
          ]),
        ),
        R.flatten,
        R.map(R.propOr([], 'variables')),
        R.propOr([], 'userGroups'),
      ),
    }),
    removePropDeep('__typename'),
    R.prop('user'),
  );

const UPDATE_USER_WITH_USER_GROUPS_MUTATION = gql`
  mutation UPDATE_USER_WITH_USER_GROUPS_MUTATION(
    $input: UpdateUserWithUserGroupsInput!
  ) {
    updateUserWithUserGroups(update: $input) {
      _id
    }
  }
`;

export function EditClientUserForm({
  userId,
  clientId,
  onCancel,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const [editUser] = useMutation(UPDATE_USER_WITH_USER_GROUPS_MUTATION);

  const { userGroupList, loading: userGroupLoading } = useUserGroupListQuery();

  const { data, loading } = useQuery(editUserDetailsQuery, {
    variables: { userId },
    fetchPolicy: 'network-only',
  });

  if (loading || userGroupLoading) {
    return <Loader />;
  }

  const mutate = async (values) => {
    await editUser({
      variables: {
        input: { userId, ...formatUserInput(userGroupList)(values) },
      },
    });
    onCancel();
  };

  const initialValues = {
    clientId,
    ...getInitialValues(clientId)(data),
  };

  return (
    <ClientUserFormBase
      mutate={mutate}
      title="Edit User"
      clientId={clientId}
      onCancel={onCancel}
      withResetPasswordBtn
      formId={editClientUserFormId}
      successMessage="User successfully edited"
      initialValues={initialValues}
      userGroupList={userGroupList}
      isAssetScannerEnabled={isAssetScannerEnabled}
      isClientPortalEnabled={isClientPortalEnabled}
    />
  );
}

EditClientUserForm.propTypes = {
  onCancel: func.isRequired,
  userId: string.isRequired,
  clientId: string.isRequired,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};
