import React from 'react';
import { Loader } from '@poly/admin-book';
import { bool, func, number, object, shape, string } from 'prop-types';

import { LoadMoreInvoicesBtn } from '../../components/PaymentsFromComponents/LoadMoreBtn.js';
import { EditClientPaymentsTable } from './EditClientPaymentsTable.js';
import { EditClientPaymentsFooter } from './EditClientPaymentsFooter.js';
import { useWindowedTablePropsForCustomLoadButton } from './useWindowedTablePropsForCustomLoadButton.js';

export function EditClientPaymentsForm({
  loading,
  pageSize,
  tableProps,
  submitting,
  setSubmitting,
  onScroll,
  tableSortProps,
  ...props
}) {
  const { windowedTableProps, buttonProps } =
    useWindowedTablePropsForCustomLoadButton(tableProps);

  return loading ? (
    <Loader />
  ) : (
    <>
      <EditClientPaymentsTable
        {...{
          ...props,
          ...windowedTableProps,
          ...tableSortProps,
        }}
        submitting={submitting}
      />
      <LoadMoreInvoicesBtn offset={0} {...buttonProps} pageSize={pageSize} />
      <EditClientPaymentsFooter
        submitting={submitting}
        setSubmitting={setSubmitting}
      />
    </>
  );
}

EditClientPaymentsForm.propTypes = {
  loading: bool,
  pageSize: number,
  // eslint-disable-next-line react/forbid-prop-types
  tableProps: object,
  submitting: bool,
  setSubmitting: func,
  onScroll: func,
  tableSortProps: shape({
    isServerSorting: bool,
    isSortable: bool,
    sorting: shape({
      dir: number,
      columnKey: number,
      query: shape({
        field: string,
        order: string,
        type: string,
      }),
    }),
  }),
};
