import { useMemo, useState } from 'react';
import * as R from 'ramda';
import { useLazyQuery } from '@apollo/client';
import { useTableSearch } from '@poly/admin-ui/src/redux/search-text.js';
import { MAX_ITEMS } from '@poly/admin-ui/src/constants/general.js';
import { adminUserQuery } from '@poly/admin-ui';

import { STAFF_USERS_BY_SEARCH_TABLE } from '../../modules/core/hooks/users/queries.js';

export const useStaffUsersLazyQuery = (sort) => {
  const [loading, setLoading] = useState(false);

  const [queryHandler] = useLazyQuery(STAFF_USERS_BY_SEARCH_TABLE);

  const { searchText } = useTableSearch();

  const searchInput = useMemo(
    () => ({
      sort,
      searchTerm: searchText,
      size: MAX_ITEMS,
      query: { bool: { filter: adminUserQuery } },
    }),
    [searchText, sort],
  );

  const runStaffUsersQuery = async () => {
    setLoading(true);
    const { data } = await queryHandler({
      variables: { searchInput },
    });
    setLoading(false);
    return R.pathOr([], ['searchUsers', 'hits'], data);
  };

  return { runStaffUsersQuery, loading };
};
