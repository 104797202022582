import * as R from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { performExcelExport } from '@poly/client-utils';
import { LinkButtonWithLoader } from '@poly/admin-book';
import { arrayOf, object, string } from 'prop-types';

import { getSidebarContactsTabUsersXlsExportConfig } from './getSidebarContactsTabUsersXlsExportConfig.js';

const LinkButtonWithLoaderS = styled(LinkButtonWithLoader)`
  width: 20px;
  min-width: unset;
  position: relative;
`;

export function SidebarContactsTabXlsExportButton({
  foundContacts,
  currentTab,
}) {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    const xlsConfig = getSidebarContactsTabUsersXlsExportConfig(
      currentTab,
      foundContacts,
    );

    performExcelExport(xlsConfig);
    setLoading(false);
  };

  return (
    <LinkButtonWithLoaderS
      disabled={R.isEmpty(foundContacts)}
      loader={loading}
      onClick={onClick}
    >
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoaderS>
  );
}

SidebarContactsTabXlsExportButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  foundContacts: arrayOf(object),
  currentTab: string,
};
