import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { performExcelExport } from '@poly/client-utils';
import { useRouterQuery } from '@poly/client-routing';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { CommonOuterButton } from '@poly/admin-ui';

import { ApprovePostSupplierInvoicesTabsMap } from './constants.js';
import { getApproveSupplierInvoicesXLSConfig } from './getApproveSupplierInvoicesXLSConfig.js';
import { useApproveInvoicesLazyQuery } from './useApproveInvoices.js';

const { TO_REVIEW } = ApprovePostSupplierInvoicesTabsMap;

const CommonOuterButtonS = styled(CommonOuterButton)`
  margin-right: 10px;
`;

// getFileNameByTab :: String -> String
export const getFileNameByTab = R.compose(
  R.join('_'),
  R.append('export'),
  R.concat(['supplier', 'invoices']),
  R.split(' '),
  R.toLower,
  R.defaultTo(TO_REVIEW),
);

export function ApproveSupplierInvoicesExportXLSBtn({ userId }) {
  const { tab = TO_REVIEW } = useRouterQuery(['tab']);

  const { queryHandler, loading } = useApproveInvoicesLazyQuery({
    userId,
    tab,
  });

  const onClick = async () => {
    const { invoices } = await queryHandler();

    if (invoices.length > 0) {
      const exportConfig = getApproveSupplierInvoicesXLSConfig({
        invoices,
        exportFileName: getFileNameByTab(tab),
      });

      performExcelExport(exportConfig);
    }
  };

  return (
    <CommonOuterButtonS onClick={onClick} loading={loading} disabled={loading}>
      {EXPORT_XLS_CAPTION}
    </CommonOuterButtonS>
  );
}

ApproveSupplierInvoicesExportXLSBtn.propTypes = { userId: string };
