import * as R from 'ramda';
import { NOTHING_UI_STRING } from '@poly/constants/src/misc.js';
import { formatTaxId } from '@poly/utils/src/formatters.js';

// formatTaxIdOrAbsentString :: String -> String
export const formatTaxIdOrAbsentString = R.ifElse(
  R.prop('taxId'),
  R.compose(formatTaxId, R.prop('taxId')),
  R.ifElse(
    R.prop('absentTaxId'),
    R.always('Currently Absent'),
    R.always(NOTHING_UI_STRING),
  ),
);
