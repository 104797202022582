import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { shape, number, string, arrayOf, oneOfType, bool } from 'prop-types';
import { Table, Head, HeaderRow, HeaderCell } from '@poly/admin-book';
import { assocBy, insertParamsIntoURL } from '@poly/utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { Link } from '@poly/client-routing';

import {
  staffReportPropTypes,
  staffReportQueryPropTypes,
} from './prop-types.js';
import { routesNames } from '../../routes/constants.js';
import { staffReportMetricTitles } from './metricTitle.js';

const staffReportNumberColumnStyles = (columnNumber, columnWidth) => css`
  th:nth-child(${columnNumber}),
  td:nth-child(${columnNumber}) {
    width: ${columnWidth}px;
    text-align: right;
    padding: 10px 15px 10px 5px;
  }
`;

const printStyles = css`
  th,
  td {
    padding: 5px !important;
  }

  thead {
    height: 100px !important;
    margin-bottom: -12px !important;
  }
`;

const StaffReportTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 115px;
  }

  ${staffReportNumberColumnStyles(2, 65)};
  ${staffReportNumberColumnStyles(3, 68)};
  ${staffReportNumberColumnStyles(4, 66)};
  ${staffReportNumberColumnStyles(5, 80)};
  ${staffReportNumberColumnStyles(6, 80)};
  ${staffReportNumberColumnStyles(7, 80)};
  ${staffReportNumberColumnStyles(8, 106)};
  ${staffReportNumberColumnStyles(9, 65)};
  ${staffReportNumberColumnStyles(10, 65)};
  ${staffReportNumberColumnStyles(11, 82)};
  ${staffReportNumberColumnStyles(12, 75)};
  ${staffReportNumberColumnStyles(13, 71)};
  ${staffReportNumberColumnStyles(14, 71)};

  thead {
    height: 120px;
    margin-bottom: -2px;

    & > tr:nth-child(1) > th {
      overflow: visible;
      white-space: nowrap;
    }

    & > tr > th:nth-child(4) {
      border-right: 1px solid rgba(197, 198, 201, 0.35);
    }
  }

  td:nth-child(4) {
    border-right: 1px solid rgba(197, 198, 201, 0.35);
  }

  ${({ isPrint }) => !!isPrint && printStyles}
`;

function StaffReportTableHeader({ headers, className }) {
  return (
    <Head className={className}>
      <HeaderRow>
        <HeaderCell />
        <HeaderCell>Current Assignments</HeaderCell>
        <HeaderCell />
        <HeaderCell />
        <HeaderCell />
        <HeaderCell />
        <HeaderCell />
        <HeaderCell />
        <HeaderCell>Activity</HeaderCell>
        <HeaderCell />
        <HeaderCell />
        <HeaderCell />
        <HeaderCell />
        <HeaderCell />
      </HeaderRow>
      <HeaderRow>
        {headers.map(({ title }) => (
          <HeaderCell key={title}>{title}</HeaderCell>
        ))}
      </HeaderRow>
    </Head>
  );
}

StaffReportTableHeader.propTypes = {
  className: string,
  headers: arrayOf(shape({ title: string.isRequired })),
};

function StaffReportMetricCount({ metricType, query, user, ...metrics }) {
  return (
    <Link
      target="_blank"
      href={insertParamsIntoURL(
        {
          userId: user._id,
          type: metricType,
          endDate: query.endDate.toISOString(),
          startDate: query.startDate.toISOString(),
        },
        routesNames.STAFF_ACTIVITY_REPORT_DETAILS,
      )}
    >
      {R.propOr(0, metricType, metrics)}
    </Link>
  );
}

StaffReportMetricCount.propTypes = {
  metricType: string,
  metrics: oneOfType([string, number]),
  user: shape({ _id: string.isRequired }),
  query: shape(staffReportQueryPropTypes),
};

// createStaffReportMetricCount :: (String, ElasticQuery) -> StaffReportEntry -> ReactComponent
const createStaffReportMetricCount = (metricType, query) =>
  function (metrics) {
    return (
      <StaffReportMetricCount
        metricType={metricType}
        query={query}
        {...metrics}
      />
    );
  };

// getStaffReportTableConfig :: ElasticQuery -> TableConfig
const getStaffReportTableConfig = (query) => [
  ['Assigned CSR', R.path(['user', 'profile', 'fullName'])],
  ...staffReportMetricTitles.map(([type, title]) => [
    title,
    createStaffReportMetricCount(type, query),
  ]),
];

// assocUserIdToRoot :: [StaffReportEntry] -> [StaffReportEntry]
const assocUserIdToRoot = R.map(assocBy('_id', R.path(['user', '_id'])));

export function StaffReportTable({ query, staffReport, isPrint }) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    assocUserIdToRoot,
    getStaffReportTableConfig(query),
    staffReport,
  );

  return (
    <StaffReportTableS
      rows={rows}
      isPrint={isPrint}
      headers={headers}
      columns={columns}
      sortQueries={sortQueries}
      HeadComponent={StaffReportTableHeader}
    />
  );
}

StaffReportTable.propTypes = {
  isPrint: bool,
  query: shape(staffReportQueryPropTypes),
  staffReport: arrayOf(shape(staffReportPropTypes)).isRequired,
};
