import React from 'react';
import { bool } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonOuterButton } from '@poly/admin-ui';

import { paymentsDataPropTypes } from './propTypes.js';
import { VoidSupplierPaymentsTableComponent } from './VoidSupplierPaymentsTable.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';
import { getBatchPaymentsByData } from './helpers.js';

export function VoidSupplierPaymentsPrintPDFBtn({ paymentsData, loading }) {
  const Layout = usePrintLayout();

  const onClick = () => {
    const batchPayments = getBatchPaymentsByData(paymentsData);

    return openPrintWindowWithData({
      Layout,
      batchPayments,
      isPrint: true,
      fileName: 'void_supplier_payments_export',
      Table: VoidSupplierPaymentsTableComponent,
    });
  };

  return (
    <CommonOuterButton
      onClick={onClick}
      loading={loading}
      disabled={!paymentsData}
    >
      {PRINT_PDF_CAPTION}
    </CommonOuterButton>
  );
}

VoidSupplierPaymentsPrintPDFBtn.propTypes = {
  loading: bool.isRequired,
  paymentsData: paymentsDataPropTypes,
};
