import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import { EXPORT_XLS_CAPTION, PRINT_PDF_CAPTION } from '@poly/constants';
import { LinkButton, Table } from '@poly/admin-book';
import { Link } from '@poly/client-routing';
import {
  openPrintWindowWithData,
  performExcelExport,
} from '@poly/client-utils';

import { routesNames } from '../../../routes/index.js';
import { searchSuppliersTableStyles } from './supplierSearchTableUtils.js';
import { usePrintLayout } from '../../../hooks/usePrintLayout.js';
import { getSupplierSearchXLS } from './exportToXLSUtils.js';

const suppliersPropTypes = arrayOf(
  shape({
    _id: string.isRequired,
    company: shape({
      name: string,
      address: shape({
        formatted_address: string,
      }),
    }),
  }),
);

const ShowInMapBtn = styled(Link)`
  font-size: 12px;
  line-height: 18px;
`;

// getSuppliersInfo :: Array -> String
const getSuppliersInfo = R.pipe(
  R.map(R.pick(['_id', 'company'])),
  (data) => JSON.stringify(data),
  (json) => localStorage.setItem('suppliers', json),
);

export function SupplierSearchShowInMapBtn({ suppliers }) {
  const onClick = () => getSuppliersInfo(suppliers);

  const additionalProps = {
    onClick,
    target: '_blank',
    children: 'Show In Map',
    href: routesNames.SUPPLIERS_MAP,
  };

  return <ShowInMapBtn {...additionalProps} />;
}

SupplierSearchShowInMapBtn.displayName = 'SupplierSearchShowInMapBtn';

SupplierSearchShowInMapBtn.propTypes = { suppliers: suppliersPropTypes };

export function SupplierSearchExportToXLSBtn({ suppliers }) {
  const disabled = R.isEmpty(suppliers);

  const onClick = () => {
    const config = getSupplierSearchXLS(suppliers);
    performExcelExport(config);
  };

  return (
    <LinkButton {...{ disabled, onClick }}>{EXPORT_XLS_CAPTION}</LinkButton>
  );
}

SupplierSearchExportToXLSBtn.propTypes = { suppliers: suppliersPropTypes };

const PrintTableS = styled(Table)`
  margin-top: 25px;

  ${searchSuppliersTableStyles}
`;

export function SearchSuppliersPrintPDFBtn({ suppliers, ...props }) {
  const disabled = R.isEmpty(suppliers);

  const Layout = usePrintLayout();

  const onClick = () =>
    openPrintWindowWithData({
      ...props,
      Layout,
      suppliers,
      isSortable: false,
      Table: PrintTableS,
      fileName: 'supplier_search_export',
      metaData: { title: 'Search Suppliers' },
    });

  return (
    <LinkButton {...{ disabled, onClick }}>{PRINT_PDF_CAPTION}</LinkButton>
  );
}

SearchSuppliersPrintPDFBtn.propTypes = { suppliers: suppliersPropTypes };
