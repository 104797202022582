import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, bool, string } from 'prop-types';
import { ESFMFullLogo } from '@poly/admin-ui/src/components/Logo.js';
import {
  SupplierDocumentRequestOnboardingMessage,
  SupplierDocumentRequestInsuranceMessage,
  SupplierDocumentRequestDefaultMessage,
  supplierDocumentRequestTypes,
  ESFM_INFO,
} from '@poly/constants';

import { Text } from './components.js';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
  padding-bottom: 18px;
`;

export const Title = styled.h3`
  padding: 0;
  margin: 12px 0 8px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #1a1514;
`;

const SubTitle = styled(Text)`
  font-family: 'Verdana';
`;

const BoldText = styled(Title)`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
`;

// isSupplierOnboarding :: [String] -> Boolean
const isSupplierOnboarding = R.includes(supplierDocumentRequestTypes.W9);

export function DocumentsRequestHeader({
  documentTypes,
  isMWBEOrVeteran,
  withInsuranceLink,
}) {
  const isOnboarding = isSupplierOnboarding(documentTypes);

  const [address1, address2] = R.split(',', ESFM_INFO.addressLine1);

  const defaultDocumentsSubtitle = withInsuranceLink
    ? SupplierDocumentRequestInsuranceMessage
    : SupplierDocumentRequestDefaultMessage;

  const documentsSubtitle = isOnboarding
    ? SupplierDocumentRequestOnboardingMessage
    : defaultDocumentsSubtitle;

  return (
    <HeaderWrapper>
      <ESFMFullLogo />
      <Title>
        {isOnboarding ? 'Supplier Onboarding' : 'Documents Request'}
      </Title>
      <SubTitle margin="0 0 5px 0">
        {isMWBEOrVeteran
          ? 'We need to collect a few documents from you. If this is incorrect please call and let us know.'
          : documentsSubtitle}
      </SubTitle>
      {withInsuranceLink && (
        <>
          <BoldText>Certificate Holder Must Read:</BoldText>
          <SubTitle>{ESFM_INFO.name}</SubTitle>
          <SubTitle>{address1}</SubTitle>
          <SubTitle>{address2}</SubTitle>
          <SubTitle>{ESFM_INFO.addressLine2}</SubTitle>
        </>
      )}
    </HeaderWrapper>
  );
}

DocumentsRequestHeader.propTypes = {
  isMWBEOrVeteran: bool,
  withInsuranceLink: bool,
  documentTypes: arrayOf(string),
};
