import React from 'react';
import { arrayOf, oneOfType, shape, string } from 'prop-types';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';

import { useStaffUsersLazyQuery } from './useStaffUsersLazyQuery.js';
import { ReassignUserPrintTable } from './ReassignUserTable.js';
import { getXlsStaffUsersConfig } from './getXlsStaffUsersConfig.js';
import { PrintExportButtons } from '../../components/ExportButtons.js';

export function ReassignUserExportButtons({ sort }) {
  const { runStaffUsersQuery, loading } = useStaffUsersLazyQuery(sort);

  const handlePrintPDF = async () => {
    const users = await runStaffUsersQuery();

    await openPrintWindowWithData({
      fileName: 'reassign_user_export.pdf',
      Layout: CommonPrintLayout,
      Table: ReassignUserPrintTable,
      metaData: {
        title: 'Reassign User',
      },
      users,
    });
  };

  const handleExportXLS = async () => {
    const users = await runStaffUsersQuery();

    const exportConfig = getXlsStaffUsersConfig(users);

    return performExcelExport(exportConfig);
  };

  return (
    <PrintExportButtons
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
      loading={loading}
    />
  );
}

ReassignUserExportButtons.propTypes = {
  sort: arrayOf(oneOfType([string, shape({})])),
};
