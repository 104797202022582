import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { gql, useLazyQuery } from '@apollo/client';
import { startOfDay, endOfDay } from 'date-fns';
import { roundTo, ensureIsDate, assocBy } from '@poly/utils';
import { usePaginationParams, useSetItemsCount } from '@poly/admin-ui';
import { paginationToQueryParams, useReactiveQuery } from '@poly/client-utils';

import { JOURNAL_CHANGED_SUBSCRIPTION } from '../../sidebars/TransactionDetailsSidebar/TransactionDetailsSidebar.js';

const searchTransactionsQuery = gql`
  query searchTransactions($input: SearchTransactionsInput!) {
    searchTransactions(input: $input) {
      hits {
        transactionNumber
        amount
        date
        type
        account {
          _id
          name
        }
        supplier {
          _id
          company {
            name
          }
        }
        client {
          _id
          name
        }
        createdBy {
          _id
          profile {
            fullName
          }
        }
      }
      total
    }
  }
`;

const formatTransactionsSearchInput = R.compose(
  R.evolve({
    amount: R.compose(roundTo(2), R.constructN(1, Number)),
    startDate: R.compose(startOfDay, ensureIsDate),
    endDate: R.compose(endOfDay, ensureIsDate),
  }),
  R.omit(['dateRange']),
);

const getQueryVariables = ({ sort, filters, paginationParams }) => ({
  input: {
    sort,
    ...paginationParams,
    ...formatTransactionsSearchInput(filters),
  },
});

// getTransactionsByQueryData :: { searchTransactions: { hits: [TransactionSearchResult] } } -> [TransactionSearchResult]
const getTransactionsByQueryData = R.compose(
  R.map(assocBy('_id', R.prop('transactionNumber'))),
  R.pathOr([], ['searchTransactions', 'hits']),
);

export const useSearchTransactionsQuery = ({ sort, filters }) => {
  const paginationParams = usePaginationParams();

  const queryOptions = useMemo(
    () => ({
      variables: getQueryVariables({ sort, filters, paginationParams }),
      skip: !filters,
      fetchPolicy: 'network-only',
    }),
    [sort, filters, paginationParams],
  );

  const subscriptionOptions = useMemo(() => {
    if (!filters) {
      return { skip: true };
    }

    return {
      variables: {
        input: {
          query: {
            bool: {
              must: {
                range: {
                  date: {
                    gte: filters.startDate,
                    lte: filters.endDate,
                  },
                },
              },
            },
          },
        },
      },
    };
  }, [filters]);

  const { data, loading } = useReactiveQuery(
    searchTransactionsQuery,
    JOURNAL_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions },
  );

  useSetItemsCount(R.pathOr(0, ['searchTransactions', 'total']), data);

  return { loading, transactions: getTransactionsByQueryData(data) };
};

export const useSearchTransactionsLazyQuery = () => {
  const [queryHandler] = useLazyQuery(searchTransactionsQuery);
  const pagination = useSelector(R.prop('pagination'));
  const paginationParams = paginationToQueryParams(pagination);

  const handler = async ({ sort, filters }) => {
    const { data } = await queryHandler({
      fetchPolicy: 'network-only',
      variables: getQueryVariables({ sort, filters, paginationParams }),
    });

    return { transactions: getTransactionsByQueryData(data) };
  };

  return handler;
};
