import React from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { TableCardWithPaginationContainer } from '@poly/admin-ui/src/components/Table.js';
import { useTableSorting } from '@poly/admin-ui';

import { DivisionsPageHeader } from './DivisionsPageHeader.js';
import { DivisionsTable } from './DivisionsTable.js';
import { useDivisionsQuery } from './useDivisionsQuery.js';
import { divisionsTableConfig } from './divisionsTableConfig.js';

export function DivisionsPage() {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: divisionsTableConfig,
    column: 1,
  });

  const { divisions, loading } = useDivisionsQuery(sort);

  return (
    <>
      <DivisionsPageHeader divisions={divisions} loading={loading} />
      <DefaultBodyWrapper height="calc(100% - 85px)">
        <TableCardWithPaginationContainer>
          {loading ? (
            <Loader />
          ) : (
            <DivisionsTable
              divisions={divisions}
              loading={loading}
              {...tableSortingProps}
            />
          )}
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </>
  );
}
