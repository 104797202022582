import * as R from 'ramda';
import React from 'react';
import { applyPathOrNothingUI, keywordSortQuery } from '@poly/client-utils';
import { NOTHING_UI_STRING, SupplierPhoneTypes } from '@poly/constants';
import { Dot } from '@poly/admin-book';
import {
  getUserCellPhoneObject,
  getUserWorkPhoneObject,
  formatPhoneNumber,
  formatRawNumber,
  assocBy,
  applyOr,
  propEqLegacy,
  isNilOrEmpty,
} from '@poly/utils';
import { getSupplierServiceTypesNamesStr } from '@poly/utils/src/getSupplierServiceTypesNamesStr.js';

import { supplierStatusesColors } from '../../constants/suppliers.js';

export const getSupplierCityStateAddress = R.compose(
  applyOr(R.join(','))(NOTHING_UI_STRING),
  R.filter(R.complement(R.isNil)),
  R.values,
  R.pick(['locality', 'administrative_area_level_1']),
  R.pathOr({}, ['company', 'address', 'address_parts']),
);

const assocDotProps = assocBy(
  'color',
  ({ status }) => supplierStatusesColors[R.toLower(status)],
);

export function SuppliersStatus(props) {
  const dotProps = assocDotProps(props);

  return <Dot {...dotProps} />;
}

// getServiceTypes :: Supplier -> String
export const getServiceTypes = R.compose(
  R.when(isNilOrEmpty, R.always(NOTHING_UI_STRING)),
  getSupplierServiceTypesNamesStr,
);

export const getSupplierPhone = R.compose(
  formatPhoneNumber('-'),
  assocBy('number', R.prop('phone')),
  R.find(propEqLegacy('type', SupplierPhoneTypes.GENERAL)),
  R.pathOr([], ['company', 'phones']),
);

const EXT_DIVIDER = 'Ext';

// getUserPhoneNumberFormatted :: User -> String
export const getUserPhoneNumberFormatted = R.compose(
  applyPathOrNothingUI(['number'], formatRawNumber),
  getUserWorkPhoneObject,
);

/**
 * getWorkPhoneFormatted :: User -> String
 */
export const getWorkPhoneFormatted = R.compose(
  formatPhoneNumber(EXT_DIVIDER),
  getUserWorkPhoneObject,
);

/**
 * getCellPhoneFormatted :: User -> String
 */
export const getCellPhoneFormatted = R.compose(
  formatPhoneNumber(EXT_DIVIDER),
  getUserCellPhoneObject,
);

export const supplierAddressColumn = [
  'Address',
  R.path(['company', 'address', 'formatted_address']),
  keywordSortQuery(['company', 'address', 'formatted_address']),
];
