import React from 'react';
import * as R from 'ramda';
import { arrayOf, number, shape, string, bool } from 'prop-types';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_CLIENT_PERMISSION } from '@poly/security';
import { collectionNames } from '@poly/constants';

import { BidRulesTab } from './BidRulesTab.js';

export function ClientBidRulesTab({ client }) {
  const hasAccess = useHasUserAccessWithPermission(UPDATE_CLIENT_PERMISSION);

  const tabProps = {
    hasAccess,
    id: client._id,
    collection: collectionNames.clients,
    bidRules: R.pathOr([], ['bidRules'], client),
  };

  return <BidRulesTab {...tabProps} />;
}

ClientBidRulesTab.propTypes = {
  client: shape({
    _id: string.isRequired,
    bidRules: arrayOf(
      shape({
        type: string.isRequired,
        label: string.isRequired,
        amount: number.isRequired,
        isRuleActive: bool.isRequired,
      }),
    ),
  }),
};
