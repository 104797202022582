import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import { SidebarRow } from '@poly/admin-ui';
import { propEqLegacy } from '@poly/utils';
import {
  taskConfigsForAllProjectTypes,
  NOTHING_UI_STRING,
  RWOTaskListTypeUI,
  RWOTaskListType,
  taskActionTypes,
} from '@poly/constants';

import {
  SectionLabel,
  SectionWrapper,
  BlockWithLabel,
} from '../components/commonSidebarComponents.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import { getBooleanTextWithDot } from '../ProjectSidebar/components/projectSidebarDetailsRowList.js';

// getTaskListTypeByMasterProject :: RecurringProject -> String
const getTaskListTypeByMasterProject = R.propOr(
  RWOTaskListType.LONG_LIST,
  'taskListType',
);

// getMRProjectSidebarTasksInfo :: Project -> Object
export const getMRProjectSidebarTasksInfo = (project) =>
  R.applySpec({
    enableCollectDocumentsTask: R.propOr(false, 'enableCollectDocumentsTask'),
    taskType: R.compose(
      R.prop(R.__, RWOTaskListTypeUI),
      getTaskListTypeByMasterProject,
    ),
    taskDescription: R.compose(
      R.ifElse(
        R.isEmpty,
        R.always(NOTHING_UI_STRING),
        R.compose(R.join(' / '), R.map(R.prop('description'))),
      ),
      R.unless(
        R.always(project?.enableCollectDocumentsTask),
        R.reject(R.propEq(taskActionTypes.COLLECT_DOCUMENTS, 'action')),
      ),
      R.converge(R.pathOr([]), [
        R.compose(
          R.append(R.__, ['taskLists']),
          getTaskListTypeByMasterProject,
        ),
        R.compose(
          R.find(R.__, taskConfigsForAllProjectTypes),
          propEqLegacy('type'),
          R.prop('childType'),
        ),
      ]),
    ),
  })(project);

export function MasterRecurringProjectSidebarTasks({ project }) {
  const { taskType, taskDescription, enableCollectDocumentsTask } =
    getMRProjectSidebarTasksInfo(project);

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Project Task Types</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          id={taskType}
          label={taskType}
          Component={getSectionText(taskDescription)}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          id="enable-collect-documents-task"
          label="Enable Collect Documents Task"
          Component={getBooleanTextWithDot(enableCollectDocumentsTask)}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

MasterRecurringProjectSidebarTasks.propTypes = {
  project: shape({ _id: string.isRequired }),
};
