import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { ALL } from '@poly/admin-ui';
import { isNilOrEmpty } from '@poly/utils';
import { ProjectSpendTypes } from '@poly/constants';
import { DatePicker, Select, ToolBarBtnDivider } from '@poly/admin-book';
import {
  bool,
  func,
  shape,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';

import { Text } from '@poly/admin-book/src/Text/index.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { ButtonsWrapper } from '../UnassignedProjectsPage/UnassignedProjectsComponents.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { ButtonsContainerS } from '../WorkInProgressReport/WorkInProgressHeader.js';
import { ProjectSpendTypeSelect } from '../SpendReport/SpendDetailsPageHeader/ProjectSpendTypeSelect.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { MondelezProjectRMStatusPDFBtn } from './MondelezProjectRMStatusPDFBtn.js';
import { MondelezProjectRMStatusXLSBtn } from './MondelezProjectRMStatusXLSBtn.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import {
  SelectsWrapper,
  SearchHeaderWrapper,
} from '../WorkInProgressQCReport/WorkInProgressQCHeader.js';

const SearchHeaderWrapperS = styled(SearchHeaderWrapper)`
  margin-top: 0;

  @media (max-width: 1470px) {
    flex-direction: row;
  }
`;

const SearchHeaderColumnS = styled(SearchHeaderColumn)`
  @media (max-width: 1430px) {
    margin-right: 5px;
    width: 100px;
  }
`;

const SelectsWrapperS = styled(SelectsWrapper)`
  width: auto;
`;

const ButtonsWrapperS = styled(ButtonsWrapper)`
  width: 320px;
`;

const mondelezProjectRMStatusFiltersConfig = [
  { name: 'notRM', defaultValue: ALL },
  { name: 'dateTo' },
  { name: 'dateFrom' },
  { name: 'spendType' },
  { name: 'projectId' },
];

const projectSpendTypeSelectOptions = [
  { value: ProjectSpendTypes.REACTIVE_REPAIR, label: 'Reactive Repair' },
  { value: ProjectSpendTypes.PREVENTIVE_REPAIR, label: 'Preventive Repair' },
  { value: ProjectSpendTypes.RECURRING_SERVICE, label: 'Recurring Service' },
  { value: ProjectSpendTypes.CAP_EX, label: 'CapEx.' },
];

// checkIsDisabledExport :: { searchProjects: { hits: [Project] } } -> Boolean
const checkIsDisabledExport = R.pathSatisfies(isNilOrEmpty, [
  'searchProjects',
  'hits',
]);

function HeaderTitle() {
  return <Text>Mondelez Project R&M status</Text>;
}

function NotRMSelect(props) {
  const selectProps = {
    ...props,
    required: true,
    options: [
      { value: ALL, label: 'All' },
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  };

  return <Select {...selectProps} />;
}

export function MondelezProjectRMStatusPageHeader({
  data,
  sort,
  loading,
  setQueryFilters,
  isUserWithPermission,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    mondelezProjectRMStatusFiltersConfig,
  );

  const onSearch = () => {
    setQueryFilters(searchFilters);
  };

  const handleReset = () => {
    setQueryFilters({});
    onReset();
  };

  const isDisabledExport = checkIsDisabledExport(data);

  return (
    <SearchPageHeaderContainer TitleComp={HeaderTitle}>
      <SearchHeaderWrapperS>
        <SelectsWrapperS>
          <SearchHeaderColumn
            title="WO Number"
            titleWidth="120px"
            filterWidth="240px"
          >
            <ProjectSelect
              value={searchFilters.projectId}
              onChange={handlers.projectId}
              width="100%"
            />
          </SearchHeaderColumn>
        </SelectsWrapperS>
        <SelectsWrapperS>
          <SearchHeaderColumn
            title="Date From"
            titleWidth="80px"
            filterWidth="240px"
          >
            <DatePicker
              value={searchFilters.dateFrom}
              onChange={handlers.dateFrom}
              width="100%"
              disabledDays={{ after: new Date(searchFilters.dateTo) }}
            />
          </SearchHeaderColumn>
        </SelectsWrapperS>
        <SelectsWrapperS>
          <SearchHeaderColumn
            title="Date To"
            titleWidth="80px"
            filterWidth="240px"
          >
            <DatePicker
              value={searchFilters.dateTo}
              onChange={handlers.dateTo}
              width="100%"
              disabledDays={{ before: new Date(searchFilters.dateFrom) }}
            />
          </SearchHeaderColumn>
        </SelectsWrapperS>
      </SearchHeaderWrapperS>
      <SearchHeaderWrapperS>
        <SelectsWrapperS>
          <SearchHeaderColumn
            title="WO Type"
            titleWidth="120px"
            filterWidth="240px"
          >
            <ProjectSpendTypeSelect
              value={searchFilters.spendType}
              onChange={handlers.spendType}
              width="100%"
              options={projectSpendTypeSelectOptions}
            />
          </SearchHeaderColumn>
        </SelectsWrapperS>
        <SelectsWrapperS>
          <SearchHeaderColumn
            title="Not R&M"
            titleWidth="80px"
            filterWidth="240px"
          >
            <NotRMSelect
              value={searchFilters.notRM}
              onChange={handlers.notRM}
              name="notRM"
              width="100%"
            />
          </SearchHeaderColumn>
        </SelectsWrapperS>
        <ButtonsWrapperS>
          <SearchHeaderColumnS filterWidth="120px">
            <ButtonsContainerS>
              <MondelezProjectRMStatusPDFBtn
                disabled={isDisabledExport}
                loader={loading}
                sort={sort}
                filter={searchFilters}
              />
              <ToolBarBtnDivider />
              <MondelezProjectRMStatusXLSBtn
                disabled={isDisabledExport}
                filter={searchFilters}
                loader={loading}
                sort={sort}
              />
            </ButtonsContainerS>
          </SearchHeaderColumnS>
          <SearchHeaderColumn filterWidth="190px">
            <SearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={handleReset}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              onClick={onSearch}
              loader={loading}
              disabled={!isUserWithPermission}
            >
              Search
            </SearchHeaderButton>
          </SearchHeaderColumn>
        </ButtonsWrapperS>
      </SearchHeaderWrapperS>
    </SearchPageHeaderContainer>
  );
}

MondelezProjectRMStatusPageHeader.propTypes = {
  data: shape({}),
  sort: arrayOf(oneOfType([string, object])),
  loading: bool,
  setQueryFilters: func.isRequired,
  isUserWithPermission: bool,
};
