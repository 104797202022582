import React, { useState } from 'react';
import { func, string, bool } from 'prop-types';
import {
  isErrorEqualTwoFAError,
  fetchUserToken,
  getLoginError,
} from '@poly/client-utils';

import LoginForm from './LoginForm.js';
import { TwoFAConfirmForm } from '../TwoFAConfirmForm.js';

export function LoginPageBase({
  // "phone" used at asset-scanner for staff users login
  phone,
  title,
  subTitle,
  hideLogo,
  className,
  onSubmitSuccess,
  goToForgotPassword,
  forgotPasswordMessage,
}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({});
  const [is2FAError, setIs2FAError] = useState(false);

  const handleSubmit = async (newCredentials) => {
    setLoading(true);
    setCredentials(newCredentials);

    try {
      await fetchUserToken(newCredentials);

      setLoading(false);

      await onSubmitSuccess(newCredentials);
    } catch (err) {
      setLoading(false);

      if (isErrorEqualTwoFAError(err)) {
        setIs2FAError(true);
      }

      const errorMsg = getLoginError(err);
      setError(errorMsg);
    }
  };

  const backToLogin = () => {
    setIs2FAError(false);
    setError(null);
    setCredentials({});
  };

  return is2FAError ? (
    <TwoFAConfirmForm
      title={title}
      errorMsg={error}
      loading={loading}
      hideLogo={hideLogo}
      goBack={backToLogin}
      className={className}
      setErrorMsg={setError}
      submitBtnText="Log In"
      credentials={credentials}
      handleSubmit={handleSubmit}
    />
  ) : (
    <LoginForm
      phone={phone}
      title={title}
      loading={loading}
      subTitle={subTitle}
      hideLogo={hideLogo}
      signInError={error}
      className={className}
      handleSubmit={handleSubmit}
      goToForgotPassword={goToForgotPassword}
      forgotPasswordMessage={forgotPasswordMessage}
    />
  );
}

LoginPageBase.propTypes = {
  title: string,
  phone: string,
  hideLogo: bool,
  subTitle: string,
  className: string,
  goToForgotPassword: func,
  forgotPasswordMessage: string,
  onSubmitSuccess: func.isRequired,
};
