import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { startOfMonth, endOfMonth, isSameMonth, addDays } from 'date-fns';
import { useMutation, gql } from '@apollo/client';
import { arrayOf, shape, string } from 'prop-types';
import { WhiteCard } from '@poly/admin-book/src/Card.js';
import { Text } from '@poly/admin-book/src/Text/index.js';
import { Button } from '@poly/admin-book/src/Button/index.js';
import { DatePicker } from '@poly/admin-book/src/DatePicker/index.js';
import { ensureIsDate } from '@poly/utils/src/dates.js';
import { useNotificationState } from '@poly/admin-ui/src/hooks/useNotificationState.js';
import {
  AccountingPeriodStatuses,
  AccountingPeriodTypes,
} from '@poly/constants';
import { isNilOrEmpty } from '@poly/utils';

import { useAccountingPeriodsByTypeAndStatus } from '../ReopenAccountingPeriod/useAccountingPeriods.js';

const Wrapper = styled(WhiteCard)`
  padding: 15px;
  display: flex;
  height: 80px;
`;

const TextS = styled(Text)`
  font-size: 19px;
  color: #000;
  margin-right: 25px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const lockPreviousMonthAccountingPeriodMutation = gql`
  mutation lockPreviousMonthAccountingPeriod(
    $input: LockPreviousMonthAccountingPeriodInput!
  ) {
    lockPreviousMonthAccountingPeriod(input: $input) {
      _id
    }
  }
`;

// getEndDate :: AccountingPeriod -> Date
const getEndDate = R.compose(ensureIsDate, R.prop('endDate'));

// getLastMonth :: AccountingPeriod -> Date
const getLastMonth = R.compose(
  R.ifElse(R.identity, (d) => addDays(d, 1), R.always(null)),
  getEndDate,
  R.last,
  R.sortBy(getEndDate),
);

export function LockPreviousAccountingMonth({ monthlyAccountingPeriods }) {
  const isNoMonthPeriod = isNilOrEmpty(monthlyAccountingPeriods);

  const [isLocking, setIsLocking] = useState(false);

  const { showSuccessNotification } = useNotificationState();

  const [lockPreviousMonth] = useMutation(
    lockPreviousMonthAccountingPeriodMutation,
  );

  const [closedAnnualAccountingPeriods, loading] =
    useAccountingPeriodsByTypeAndStatus({
      type: AccountingPeriodTypes.YEAR,
      status: AccountingPeriodStatuses.CLOSED,
      skip: !isNoMonthPeriod,
    });

  if (loading) {
    return null;
  }

  const lastMonth = getLastMonth(
    isNoMonthPeriod ? closedAnnualAccountingPeriods : monthlyAccountingPeriods,
  );

  const isDisabled = !lastMonth || isSameMonth(lastMonth, new Date());

  const handleClick = async () => {
    try {
      setIsLocking(true);
      await lockPreviousMonth({
        variables: { input: { date: lastMonth } },
      });

      showSuccessNotification('Accounting periods was successfully locked');
    } finally {
      setIsLocking(false);
    }
  };

  return (
    <Wrapper>
      <FlexContainer>
        <TextS>Lock Previous Month</TextS>
        <DatePicker value={startOfMonth(lastMonth)} disabled />
        <DatePicker value={endOfMonth(lastMonth)} disabled />
      </FlexContainer>
      <Button
        onClick={handleClick}
        disabled={isDisabled}
        size="small"
        loader={isLocking}
      >
        Lock the Month
      </Button>
    </Wrapper>
  );
}

LockPreviousAccountingMonth.propTypes = {
  monthlyAccountingPeriods: arrayOf(
    shape({
      startDate: string.isRequired,
    }),
  ),
};
