import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func, number, string } from 'prop-types';
import {
  defaultTheme,
  Status,
  Loader,
  Icon,
  LinkButton,
} from '@poly/admin-book';
import { UserStatuses } from '@poly/constants';
import { formatCount, formatRawNumber, forceTitleCase } from '@poly/utils';
import {
  useOutSidebarContext,
  applyPathOrNothingUI,
  pathOrNothingUI,
  useHasUserAccessWithPermission,
  keywordSortQuery,
  commonSortQuery,
} from '@poly/client-utils';
import {
  useMapConfigToTableProps,
  SidebarTabTableWrapper,
  useSearchTabLogic,
  SidebarTabToolbar,
  SidebarTabHeader,
  SearchInputComp,
  SidebarSubTabs,
  EmailLink,
  entities,
  useTableSorting,
} from '@poly/admin-ui';
import { CREATE_USER_PERMISSION, UPDATE_USER_PERMISSION } from '@poly/security';

import {
  SectionLabel,
  DoubleTabsWrapper,
  CommonSidebarSubEntityTable,
} from '../../components/commonSidebarComponents.js';
import { addClientUserFormId } from '../forms/constants.js';
import { AddClientUsersForm } from '../forms/AddClientUserForm.js';
import { useOnCloseFormSidebar } from '../../useOnCloseFormSidebar.js';
import { useClientPortalUsers } from './useClientPortalUsersQuery.js';
import {
  commonClientUserSidebarProps,
  useOpenUpdateClientUser,
} from './useOpenUpdateClientUser.js';
import { ClientPortalUsersXlsExportButton } from './ClientPortalUsersXlsExportButton/ClientPortalUsersXlsExportButton.js';

const ClientPortalUsersButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

const PortalUserStatusColor = {
  [UserStatuses.ACTIVE]: active,
  [UserStatuses.INACTIVE]: inactive,
};

function AddClientPortalUserButton({
  clientId,
  isCard,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar(isCard);

  const closeForm = () => closeOutSidebar(addClientUserFormId);

  const onCancel = () => {
    closeForm();
    onClose(entities.CLIENT, clientId);
  };

  const onClick = () =>
    openOutSidebar({
      ...commonClientUserSidebarProps,
      id: addClientUserFormId,
      content: (
        <AddClientUsersForm
          clientId={clientId}
          onCancel={onCancel}
          closeForm={closeForm}
          isAssetScannerEnabled={isAssetScannerEnabled}
          isClientPortalEnabled={isClientPortalEnabled}
        />
      ),
    });

  return <LinkButton onClick={onClick}>Add New</LinkButton>;
}

AddClientPortalUserButton.propTypes = {
  isCard: bool,
  clientId: string.isRequired,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};

function ClientPortalUsersTabTitle({ status, clientId, searchTerm }) {
  const { count } = useClientPortalUsers(clientId, status, searchTerm);

  const title = `${forceTitleCase(status)} (${formatCount(count)})`;

  return <Status color={PortalUserStatusColor[status]} text={title} />;
}

ClientPortalUsersTabTitle.propTypes = {
  searchTerm: string,
  status: string.isRequired,
  clientId: string.isRequired,
};

function EditClientUserBtn({
  _id,
  clientId,
  isCard,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const onClick = useOpenUpdateClientUser(
    clientId,
    isCard,
    isAssetScannerEnabled,
    isClientPortalEnabled,
  );

  return <Icon name="edit" size={11} onClick={() => onClick(_id, false)} />;
}

EditClientUserBtn.propTypes = {
  _id: string.isRequired,
  clientId: string.isRequired,
  isCard: bool,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};

const portalUsersTableConfig = (hasUpdateUserPermission) => [
  ['Name', R.prop('fullName'), keywordSortQuery(['profile', 'fullName'])],
  [
    'User Group',
    R.compose(
      R.join(', '),
      R.map(R.pathOr('', ['userGroup', 'name'])),
      R.propOr([], ['userGroups']),
    ),
    commonSortQuery(['userGroupsNamesStr']),
  ],
  [
    'Phone',
    applyPathOrNothingUI(['profile', 'workPhoneNumber'], formatRawNumber),
    commonSortQuery(['profile', 'workPhoneNumber']),
  ],
  [
    'Ext',
    pathOrNothingUI(['profile', 'workPhoneNumberExt']),
    commonSortQuery(['profile', 'workPhoneNumberExt']),
  ],
  [
    'Mobile',
    applyPathOrNothingUI(['profile', 'cellPhoneNumber'], formatRawNumber),
    commonSortQuery(['phoneNumbersStr']),
  ],
  [
    'Login Cell Phone Number',
    pathOrNothingUI(['profile', 'loginCellPhoneNumber']),
    commonSortQuery(['profile', 'loginCellPhoneNumber']),
  ],
  ['Email', EmailLink, commonSortQuery(['emailsStr'])],
  ...(hasUpdateUserPermission ? [['', EditClientUserBtn]] : []),
];

function ClientPortalUsersTab({
  status,
  clientId,
  searchTerm,
  isCard,
  setFoundUsers,
  setCurrentTab,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const hasUpdateUserPermission = useHasUserAccessWithPermission(
    UPDATE_USER_PERMISSION,
  );

  const tableConfig = portalUsersTableConfig(hasUpdateUserPermission);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    column: 1,
  });

  const { loading, users, count } = useClientPortalUsers(
    clientId,
    status,
    searchTerm,
    sort,
  );

  const tableProps = useMapConfigToTableProps(
    R.identity,
    tableConfig,
    R.map(
      R.mergeRight({
        clientId,
        isCard,
        isAssetScannerEnabled,
        isClientPortalEnabled,
      }),
      users,
    ),
  );

  useEffect(() => {
    if (setCurrentTab) {
      setCurrentTab(status);
    }
  }, [status]);

  useEffect(() => {
    if (setFoundUsers) {
      setFoundUsers(users || []);
    }
  }, [count]);

  if (loading) return <Loader />;

  return (
    <CommonSidebarSubEntityTable
      {...tableProps}
      {...tableSortingProps}
      showEditBtn={hasUpdateUserPermission}
    />
  );
}

ClientPortalUsersTab.propTypes = {
  status: string.isRequired,
  clientId: string.isRequired,
  searchTerm: string.isRequired,
  isCard: bool.isRequired,
  setFoundUsers: func,
  setCurrentTab: func,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};

// getClientPortalUsersTab :: (String, ID, String, Boolean, Boolean, Boolean) -> [ReactComponent, String, ReactComponent]
const getClientPortalUsersTab = ({
  status,
  clientId,
  searchTerm,
  ...otherProps
}) => [
  <ClientPortalUsersTabTitle {...{ status, clientId, searchTerm }} />,
  status,
  <ClientPortalUsersTab
    {...{
      status,
      clientId,
      searchTerm,
      ...otherProps,
    }}
  />,
];

export function ClientSidebarPortalUsersTab({
  isCard,
  clientId,
  doubleTabWidth,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const [currentTab, setCurrentTab] = useState(UserStatuses.ACTIVE);
  const [foundUsers, setFoundUsers] = useState([]);

  const { searchTerm, ...inputProps } = useSearchTabLogic('users');
  const hasCreateUserPermission = useHasUserAccessWithPermission(
    CREATE_USER_PERMISSION,
  );

  return (
    <DoubleTabsWrapper width={doubleTabWidth} isCard={isCard}>
      <SidebarTabHeader justify>
        <SectionLabel>Client Portal Users</SectionLabel>
        {hasCreateUserPermission && (
          <ClientPortalUsersButtonsWrapper>
            <ClientPortalUsersXlsExportButton
              currentTab={currentTab}
              foundUsers={foundUsers}
            />
            <AddClientPortalUserButton
              clientId={clientId}
              isCard={isCard}
              isAssetScannerEnabled={isAssetScannerEnabled}
              isClientPortalEnabled={isClientPortalEnabled}
            />
          </ClientPortalUsersButtonsWrapper>
        )}
      </SidebarTabHeader>
      <SidebarTabToolbar>
        <SearchInputComp {...inputProps} />
      </SidebarTabToolbar>
      <SidebarTabTableWrapper offset={48}>
        <SidebarSubTabs
          tabs={[
            getClientPortalUsersTab({
              status: UserStatuses.ACTIVE,
              clientId,
              searchTerm,
              isCard,
              setFoundUsers,
              setCurrentTab,
              isAssetScannerEnabled,
              isClientPortalEnabled,
            }),
            getClientPortalUsersTab({
              status: UserStatuses.INACTIVE,
              clientId,
              searchTerm,
              isCard,
              setFoundUsers,
              setCurrentTab,
              isAssetScannerEnabled,
              isClientPortalEnabled,
            }),
          ]}
          defaultValue={UserStatuses.ACTIVE}
        />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapper>
  );
}

ClientSidebarPortalUsersTab.propTypes = {
  isCard: bool.isRequired,
  clientId: string.isRequired,
  doubleTabWidth: number.isRequired,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};
