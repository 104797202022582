export const FORGOT_PASSWORD_ROUTE = '/forgot-password';

export const SUPPLIERS_INSURANCE_ROUTE = '/assets/docs/insurance.pdf';

export const DOCUMENTS_REQUEST_ROUTE = '/documents-request/:randomId';

export const ADD_SERVICE_TICKET_ROUTE = '/add-service-ticket';

export const VENDOR_INFO_PAGE_ROUTE = '/vendor-info';

export const SUPPLIER_ATTACH_INVOICE_ROUTE = '/attach-supplier-invoice-file';

export const COMPLETE_CONFIRM_SATISFACTION_ROUTE =
  '/complete-confirm-satisfaction';

export const SUBCONTRACTOR_AGREEMENT_ROUTE = '/public/subcontractor-agreement';

export const ADMIN_SETUP_2FA_PAGE_PATH = '/setup-2fa';

export const ADMIN_PO_PATH = '/purchase-orders';

export const ADMIN_PO_SIDEBAR_ID = 'purchaseOrderSidebarId';

export const REQUESTOR_SUBMIT_SURVEY_ROUTE = '/requestor-survey';

export const WOC_ASSETS_DOWNLOAD_PATH = '/woc-assets-download';

export const REVIEW_PROJECT_ESTIMATE_ROUTE =
  '/review-project-estimate/:randomId';
