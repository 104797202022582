import React from 'react';
import { shape } from 'prop-types';
import { useSelector } from 'react-redux';
import { openPrintWindowWithData } from '@poly/client-utils';

import { PrintExportButtons } from '../../components/ExportButtons.js';
import { useLazyProjectQuery } from './useLazyProjectQuery.js';
import { generateProjectsXlsReport } from '../../modules/tables/projectsTable/exportToXlsUtils.js';
import { ProjectsPageCommonTable } from '../../modules/tables/projectsTable/ProjectsTable.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';

export function EmergencyProjectsExportButtons({ query }) {
  const sort = useSelector((state) => state.searchEntityParams?.sort);
  const Layout = usePrintLayout();

  const { runProjectsQuery, loading } = useLazyProjectQuery({ query, sort });

  const handlePrintPDF = async () => {
    const projects = await runProjectsQuery();

    await openPrintWindowWithData({
      projects,
      Layout,
      fileName: 'emergency_projects_export.pdf',
      Table: ProjectsPageCommonTable,
      metaData: {
        title: 'Emergency Projects',
      },
      isPrint: true,
    });
  };

  const handleExportXLS = async () => {
    const projects = await runProjectsQuery();

    return generateProjectsXlsReport('emergency_projects_export')(projects);
  };

  return (
    <PrintExportButtons
      loading={loading}
      handleExportXLS={handleExportXLS}
      handlePrintPDF={handlePrintPDF}
    />
  );
}

EmergencyProjectsExportButtons.propTypes = {
  query: shape({}).isRequired,
};
