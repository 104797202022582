import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, shape } from 'prop-types';
import { SidebarTabs } from '@poly/admin-ui';

import { RatesTab } from './RatesTab.js';
import { ReportsTab } from './ReportsTab.js';
import { FinancialTab } from './FinancialTab.js';
import { clientFinancialTabs } from '../constants.js';
import { ClientTMRulesTab } from './ClientTMRulesTab.js';
import { ClientNotificationSubTab } from './ClientNotificationSubtab.js';
import { ClientTagsSubTab } from './ClientTagsSubTab/ClientTagsSubTab.js';
import { ClientBidRulesTab } from './ClientBidRulesTab/ClientBidRulesTab.js';
import { DistrictsSidebarTab } from './ClientDistricsTab/DistrictsSidebarTab.js';
import { ClientRequesterSurveySubTab } from './RequesterSurveySubTab/ClientRequesterSurveySubTab.js';
import { HousekeepingCalendarSidebarTab } from './HousekeepingCalendarTab/HousekeepingCalendarSidebarTab.js';

const SidebarTabsS = styled(SidebarTabs)`
  > div:first-child {
    overflow-x: auto;
    overflow-y: hidden;

    & > div > div {
      padding: 10px 8px;
    }
  }
`;

// getClientConfigs :: { client: Client } -> Result
// Result = {
//    housekeepingEnabled: Boolean
//    districtsEnabled: Boolean
//    requesterSurveyEnabled: Boolean
// }
const getClientConfigs = R.compose(
  R.applySpec({
    housekeepingEnabled: R.propOr(false, 'enableHousekeepingProjectType'),
    districtsEnabled: R.propOr(false, 'enableDistricts'),
    requesterSurveyEnabled: R.pathOr(false, ['requesterSurvey', 'enabled']),
  }),
  R.path(['client', 'configs']),
);

export function ClientSidebarFinancialTabs(props) {
  const { housekeepingEnabled, districtsEnabled, requesterSurveyEnabled } =
    getClientConfigs(props);

  return (
    <SidebarTabsS
      omitSidebarByTabs={['sidebarTab']}
      customTabQueryKey="clientFinancialTab"
      defaultValue={clientFinancialTabs.clientFinancial}
      tabs={[
        [
          'Financial',
          clientFinancialTabs.clientFinancial,
          <FinancialTab {...props} />,
        ],
        [
          'T&M Rules',
          clientFinancialTabs.clientTMRules,
          <ClientTMRulesTab {...props} />,
        ],
        [
          'Bid Rules',
          clientFinancialTabs.clientBidRules,
          <ClientBidRulesTab {...props} />,
        ],
        ['Rates', clientFinancialTabs.clientRates, <RatesTab {...props} />],
        ...(housekeepingEnabled
          ? [
              [
                'Reports',
                clientFinancialTabs.clientReports,
                <ReportsTab key={clientFinancialTabs.clientReports} />,
              ],
              [
                'HSK Calendar',
                clientFinancialTabs.clientHousekeepingCalendar,
                <HousekeepingCalendarSidebarTab {...props} />,
              ],
            ]
          : []),
        ...(districtsEnabled
          ? [
              [
                'Districts',
                clientFinancialTabs.clientDistricts,
                <DistrictsSidebarTab {...props} />,
              ],
            ]
          : []),
        [
          'Notification',
          clientFinancialTabs.clientNotification,
          <ClientNotificationSubTab {...props} />,
        ],
        ...(requesterSurveyEnabled
          ? [
              [
                'Requester Survey',
                clientFinancialTabs.clientRequesterSurvey,
                <ClientRequesterSurveySubTab {...props} />,
              ],
            ]
          : []),
        [
          'Tags',
          clientFinancialTabs.clientTags,
          <ClientTagsSubTab {...props} />,
        ],
      ]}
    />
  );
}

ClientSidebarFinancialTabs.propTypes = {
  client: shape({
    configs: shape({
      enableDistricts: bool,
      enableHousekeepingProjectType: bool,
    }),
  }),
};
