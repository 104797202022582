import * as R from 'ramda';
import React, { useMemo } from 'react';
import { arrayOf, number, shape, string, bool } from 'prop-types';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_PROPERTY_PERMISSION } from '@poly/security';
import { collectionNames } from '@poly/constants';

import { BidRulesTab } from '../ClientSidebar/financialTabs/ClientBidRulesTab/BidRulesTab.js';

// getBidRulesByProperty :: { property: Property } -> [ClientBidRule]
const getBidRulesByProperty = R.pathOr([], ['property', 'bidRules']);

// getBidRulesByClient :: { property: Property } -> [ClientBidRule]
const getBidRulesByClient = R.pathOr([], ['property', 'client', 'bidRules']);

// getPropertyId :: { property: Property } -> ID
const getPropertyId = R.path(['property', '_id']);

// checkBidRulesInheritedFromClient :: { property: Property } -> Boolean
const checkBidRulesInheritedFromClient = R.compose(
  R.isEmpty,
  R.reject(R.propEq(false, 'isRuleActive')),
  getBidRulesByProperty,
);

export function PropertyBidRulesTab(props) {
  const hasAccess = useHasUserAccessWithPermission(UPDATE_PROPERTY_PERMISSION);

  const propertyId = useMemo(() => getPropertyId(props), [props]);

  const isBidRulesInheritedFromClient = useMemo(
    () => checkBidRulesInheritedFromClient(props),
    [props],
  );

  const tabProps = {
    hasAccess,
    id: propertyId,
    collection: collectionNames.properties,
    bidRules: isBidRulesInheritedFromClient
      ? getBidRulesByClient(props)
      : getBidRulesByProperty(props),
    rulesInheritedWarning: isBidRulesInheritedFromClient
      ? 'Bid Rules Inherited from Client'
      : null,
  };

  return <BidRulesTab {...tabProps} />;
}

const bidRulePropTypes = {
  type: string.isRequired,
  label: string.isRequired,
  amount: number.isRequired,
  isRuleActive: bool.isRequired,
};

PropertyBidRulesTab.propTypes = {
  property: shape({
    _id: string.isRequired,
    bidRules: arrayOf(shape(bidRulePropTypes)),
    client: shape({
      _id: string.isRequired,
      bidRules: arrayOf(shape(bidRulePropTypes)),
    }),
  }),
};
