import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, shape } from 'prop-types';
import { useHighlightMatchesBySearch } from '@poly/client-utils/src/hooks/useHighlightMatchesBySearch.js';
import { useSortableTable } from '@poly/client-utils/src/hooks/useSortableTable.js';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { Table } from '@poly/admin-book/src/Table/Table.js';

import { getAccountTypesTableConfig } from './accountTypesTableConfig.js';
import { useAccountTypesQuery } from './useAccountTypesQuery.js';
import { prepareAccountTypesTableData } from './prepareAccountTypesTableData.js';

const AccountTypesTableS = styled(Table)`
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: 300px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 100px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 40px;
  }
`;

export function AccountTypesTablePrintTable({ data }) {
  const tableProps = useMapConfigToTableProps(
    prepareAccountTypesTableData,
    getAccountTypesTableConfig(true),
    data,
  );

  return <AccountTypesTableS {...tableProps} />;
}

AccountTypesTablePrintTable.propTypes = {
  data: shape({ accountTypes: shape({ hits: arrayOf(shape({})) }) }),
};

export function AccountTypesTable({ setSort }) {
  const tableConfig = getAccountTypesTableConfig();

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig,
  });

  useEffect(() => {
    if (setSort) {
      setSort(sort);
    }
  }, [sort, setSort]);

  const { data, loading } = useAccountTypesQuery(sort);

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    prepareAccountTypesTableData,
    tableConfig,
    data,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['name'], ['categoryUI'], ['normalBalanceUI']],
    rows,
  );

  return loading ? (
    <Loader />
  ) : (
    <AccountTypesTableS
      {...tableSortProps}
      isSortable
      headers={headers}
      rows={highlightedRows}
      columns={columns}
      sortQueries={sortQueries}
      isLoading={loading}
    />
  );
}

AccountTypesTable.propTypes = {
  setSort: func.isRequired,
};
