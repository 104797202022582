import {
  func,
  shape,
  string,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { useMutation, gql, useQuery, useApolloClient } from '@apollo/client';
import { ALL, ClientSelect } from '@poly/admin-ui';
import { isNilOrEmpty } from '@poly/utils';
import {
  CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION,
  showBase64PDF,
} from '@poly/client-utils';
import {
  DEFAULT_PROPERTY_HIERARCHY_OPTION,
  DEFAULT_PROPERTY_HIERARCHY_NAME,
  SpendReportFilters,
  PRINT_PDF_CAPTION,
} from '@poly/constants';
import {
  PageHeaderContainer,
  ToolBarBtnDivider,
  DateRangePicker,
  LinkButton,
  MainHeader,
  Loader,
  Button,
  Icon,
  Text,
} from '@poly/admin-book';

import {
  FlexColumn,
  FlexCenterAlign,
} from '../../../components/FlexContainer.js';
import { ProjectSpendTypeSelect } from './ProjectSpendTypeSelect.js';
import { SpendReportsExportXlsBtn } from './SpendReportsExportXlsBtn.js';
import { spendReportHierarchyBranchPropType } from './spendReportsPropTypes.js';
import { FlexSpaceBetween } from '../../../modules/forms/assignSupplierForm/styles.js';
import { ClientPropertiesSelect } from '../../../modules/selects/PropertySelect/PropertySelect.js';
import { ClientHierarchiesSelector } from '../../PropertyHierarchyManagement/ClientHierarchiesSelector.js';
import { useHierarchyOptions } from '../../PropertyHierarchyManagement/useHierarchyOptions.js';
import { prepareSpendReportQueryInput } from '../helpers.js';
import { SpendReportAllClientsExportXls } from './SpendReportAllClientsExportXls.js';
import { SpendCostFilters } from './SpendCostFilters.js';
import {
  isDefaultProperty,
  prepareSpendReportFilters,
} from './prepareSpendreportFilters.js';

export const FilterContainerS = styled(FlexSpaceBetween)`
  margin-top: 10px;
  width: 100%;
`;

export const FlexColumnFullWidth = styled(FlexColumn)`
  width: 100%;
`;

const GeneratePDFBtnContainer = styled.div`
  position: relative;
  width: 25px;
`;

export const FlexCenterAlignS = styled(FlexCenterAlign)`
  & > div {
    margin-left: 15px;
  }
  & > div:first-child {
    margin-left: 0;
  }
  & > button {
    margin-left: 15px;
  }
`;

export const SelectContainerS = styled.div`
  width: 185px;
`;

const headerStyles = css`
  height: 140px;
  z-index: 3;
`;

export const ButtonContainerS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`;

const ClientHierarchiesSelectorS = styled(ClientHierarchiesSelector)`
  margin-left: 0;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ClientExemptSalesTaxWrapperS = styled(FlexContainer)`
  width: 245px;

  > div {
    color: #f79009;
  }
`;

function ClientExemptSalesTax() {
  const message = 'The client is exempt from sales tax';
  return (
    <ClientExemptSalesTaxWrapperS>
      <Icon name="warning-info" size={11} color="#F79009" />
      <Text size="12px">{message}</Text>
    </ClientExemptSalesTaxWrapperS>
  );
}

export const predefinedPropertyOptions = [
  [SpendReportFilters.all, 'All Properties'],
  [SpendReportFilters.mainAccount, 'Main Account'],
  [ALL, 'All Properties + Main Account'],
];

// skipPropertySelectQuery :: String -> Boolean
const skipPropertySelectQuery = (value) =>
  R.includes(value, [SpendReportFilters.mainAccount, SpendReportFilters.all]);

// prependHierarchyDefaultOption :: [Options] -> [Options]
export const prependHierarchyDefaultOption = R.prepend({
  value: DEFAULT_PROPERTY_HIERARCHY_OPTION,
  label: DEFAULT_PROPERTY_HIERARCHY_NAME,
});

// getPDFFileString :: { data: { createClientSpendReportWithHierarchy: { fileString: String } } } -> String
const getPDFFileString = R.path([
  'data',
  'createClientSpendReportWithHierarchy',
  'fileString',
]);

// prepareFilterUpdates :: String -> { filterType: String, propertyId: String }
const prepareFilterUpdates = R.cond([
  [
    R.includes(R.__, [SpendReportFilters.all, SpendReportFilters.mainAccount]),
    R.applySpec({ filterType: R.identity, propertyId: R.identity }),
  ],
  [isNilOrEmpty, R.always({ filterType: null, propertyId: null })],
  [R.T, R.applySpec({ filterType: R.always(null), propertyId: R.identity })],
]);

const MASTER_PROPERTY_CHILDREN_QUERY = gql`
  query MASTER_PROPERTY_CHILDREN_QUERY($id: ID!) {
    property(id: $id) {
      _id
      childProperties {
        _id
      }
    }
  }
`;

const CLIENT_SALES_TAX_EXEMPT_QUERY = gql`
  query CLIENT_SALES_TAX_EXEMPT_QUERY($clientId: ID!) {
    client(id: $clientId) {
      _id
      financial {
        exemptSalesTax
      }
    }
  }
`;

// getChildPropertyIds :: { property: Property } -> [ID]
const getChildPropertyIds = R.compose(
  R.map(R.prop('_id')),
  R.pathOr([], ['property', 'childProperties']),
);

// shouldSkipMasterPropertyQuery :: Input -> Boolean
// Input = ID|String|[ID]
const shouldSkipMasterPropertyQuery = R.anyPass([
  R.isNil,
  R.is(Array),
  isDefaultProperty,
]);

export function SpendReportsPageHeaderC({
  reports,
  queryInput,
  setQueryInput,
}) {
  const [childPropertyIds, setChildPropertyIds] = useState([]);
  const [filters, setFilters] = useState({
    endDate: endOfDay(new Date()),
    startDate: startOfDay(subMonths(new Date(), 1)),
    hierarchyId: DEFAULT_PROPERTY_HIERARCHY_OPTION,
    spendCostConfig: {
      includeClientInvoice: false,
      includeSupplierInvoice: false,
    },
  });
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState('');
  const [salesTaxExempt, setSalesTaxExempt] = useState(false);
  const [createClientSpendReportWithHierarchyMutation] = useMutation(
    CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION,
  );

  const {
    endDate,
    clientId,
    spendType,
    startDate,
    propertyId,
    hierarchyId,
    spendCostConfig,
  } = filters;

  const { data } = useQuery(MASTER_PROPERTY_CHILDREN_QUERY, {
    variables: { id: propertyId },
    skip: shouldSkipMasterPropertyQuery(propertyId),
  });

  const { options, loading: optionsLoading } = useHierarchyOptions(clientId);
  const allOptions = prependHierarchyDefaultOption(options);

  const isPreviewDisabled =
    !clientId || (clientId && options.length > 0 && !hierarchyId);

  const generatePDF = async () => {
    setLoading(true);
    const result = await createClientSpendReportWithHierarchyMutation({
      variables: { input: prepareSpendReportQueryInput(queryInput) },
    });

    const fileString = getPDFFileString(result);
    await showBase64PDF(fileString);
    setLoading(false);
  };

  const onClientPropertyChange = (propertyValue) => {
    const filterUpdates = prepareFilterUpdates(propertyValue);
    setFilters({ ...filters, ...filterUpdates });
  };

  const onProjectSpendTypeChange = (value) => {
    setFilters({ ...filters, spendType: value });
  };

  const onProjectSpendCostChange = (field) => (value) => {
    setFilters({
      ...filters,
      spendCostConfig: { ...filters.spendCostConfig, [field]: value },
    });
  };

  useEffect(() => {
    setChildPropertyIds(getChildPropertyIds(data));
  }, [data?.property?._id]);

  const onPreview = async () => {
    setQueryInput(prepareSpendReportFilters(childPropertyIds)(filters));

    const result = await apolloClient.query({
      query: CLIENT_SALES_TAX_EXEMPT_QUERY,
      variables: { clientId },
      skip: !clientId,
    });

    const clientSalesTaxExempt = R.pathOr(
      false,
      ['data', 'client', 'financial', 'exemptSalesTax'],
      result,
    );

    if (clientSalesTaxExempt !== salesTaxExempt) {
      setSalesTaxExempt(clientSalesTaxExempt);
    }
  };

  return (
    <PageHeaderContainer {...{ headerStyles }}>
      <FlexColumnFullWidth>
        <FlexContainer>
          <MainHeader>Spend Reports</MainHeader>
          <FlexContainer>
            <SpendReportAllClientsExportXls />
            {reports.length > 0 && (
              <ButtonContainerS>
                <ToolBarBtnDivider />
                <GeneratePDFBtnContainer>
                  {!loading && (
                    <LinkButton onClick={generatePDF}>
                      {PRINT_PDF_CAPTION}
                    </LinkButton>
                  )}
                  {loading && <Loader size={16} />}
                </GeneratePDFBtnContainer>
                <ToolBarBtnDivider />
                <SpendReportsExportXlsBtn
                  reports={reports}
                  clientName={clientName}
                  startDate={startDate}
                  endDate={endDate}
                />
              </ButtonContainerS>
            )}
          </FlexContainer>
        </FlexContainer>

        <FilterContainerS>
          <FlexCenterAlignS>
            <SelectContainerS>
              <ClientSelect
                withoutSkip
                selectedOptionAtOnChange
                includeAllOption={false}
                value={clientId}
                onChange={(value, selectedOption) => {
                  if (selectedOption) {
                    setClientName(selectedOption.label);
                  }

                  setFilters({
                    ...filters,
                    clientId: value,
                    propertyId: null,
                    filterType: null,
                    hierarchyId: DEFAULT_PROPERTY_HIERARCHY_OPTION,
                  });
                }}
              />
            </SelectContainerS>
            <SelectContainerS>
              <ClientPropertiesSelect
                withoutSkip
                disabled={!clientId}
                clientId={clientId}
                value={propertyId}
                onChange={onClientPropertyChange}
                includeAllOption
                predefinedOptions={predefinedPropertyOptions}
                skipSelectedOptionQuery={skipPropertySelectQuery(propertyId)}
              />
            </SelectContainerS>
            {clientId && options.length > 0 && (
              <SelectContainerS>
                <ClientHierarchiesSelectorS
                  required
                  value={hierarchyId}
                  placeholder="Start typing hierarchy"
                  options={allOptions}
                  loading={optionsLoading}
                  clientId={clientId}
                  onChange={(value) =>
                    setFilters({ ...filters, hierarchyId: value })
                  }
                  changeClientId={() => null}
                />
              </SelectContainerS>
            )}
            <SelectContainerS>
              <ProjectSpendTypeSelect
                value={spendType}
                onChange={onProjectSpendTypeChange}
              />
            </SelectContainerS>
            <DateRangePicker
              width="115px"
              value={{ startDate, endDate }}
              onChange={(dates) => setFilters({ ...filters, ...dates })}
            />
            <Button
              size="small"
              onClick={onPreview}
              disabled={isPreviewDisabled}
            >
              Preview
            </Button>
          </FlexCenterAlignS>
        </FilterContainerS>
        <FlexSpaceBetween>
          <SpendCostFilters
            spendCostConfig={spendCostConfig}
            onProjectSpendCostChange={onProjectSpendCostChange}
          />
          {salesTaxExempt && <ClientExemptSalesTax />}
        </FlexSpaceBetween>
      </FlexColumnFullWidth>
    </PageHeaderContainer>
  );
}

SpendReportsPageHeaderC.propTypes = {
  setQueryInput: func.isRequired,
  reports: arrayOf(spendReportHierarchyBranchPropType),
  queryInput: shape({
    clientId: string,
    propertyId: oneOfType([string, arrayOf(string)]),
    hierarchyId: string,
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
  }),
};
