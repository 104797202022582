import React, { useState } from 'react';
import * as R from 'ramda';
import { alwaysNewDate, ensureIsDate, propEqLegacy } from '@poly/utils';
import { startOfYear, addMonths } from 'date-fns';
import styled from 'styled-components';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import {
  DatePicker,
  Select,
  Loader,
  NumberInputHTML5,
  ToolBarBtnDivider,
} from '@poly/admin-book';
import { TableCardWithPaginationContainer } from '@poly/admin-ui';
import { useSortableTable } from '@poly/client-utils';

import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
  SearchPageHeaderContainer,
} from '../../components/PageWithSearchHeader.js';
import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';
import {
  SearchTransactionsTable,
  transactionsSearchTableConfig,
} from './SearchTransactionsTable.js';
import { SearchTransactionsXlsExportButton } from './SearchTransactionsXlsExportButton.js';
import { SearchTransactionsPdfExportButton } from './SearchTransactionsPdfExportButton.js';
import { useSearchTransactionsQuery } from './useSearchTransactionsQuery.js';

const SearchTransactionsExportButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  div {
    margin: 0;
  }
`;

const ONE_YEAR_RANGE_VALUE = 'oneYear';
const TWO_YEARS_RANGE_VALUE = 'twoYears';
const THIS_YEAR_RANGE_VALUE = 'thisYear';
const CUSTOM_RANGE_VALUE = 'customRange';

const dateRangeOptions = [
  { value: THIS_YEAR_RANGE_VALUE, label: 'This Year' },
  { value: ONE_YEAR_RANGE_VALUE, label: '12 Months' },
  { value: TWO_YEARS_RANGE_VALUE, label: '24 Months' },
  { value: CUSTOM_RANGE_VALUE, label: 'Custom' },
];

const monthSubtractionsMap = {
  [ONE_YEAR_RANGE_VALUE]: 12,
  [TWO_YEARS_RANGE_VALUE]: 24,
};

const PageWrapper = styled.div`
  height: calc(100vh - 60px);
  overflow-y: scroll;
`;

// transformStartDateByRangeSelect :: Date -> (String, Date) -> Date
const transformStartDateByRangeSelect =
  (currentDate) => (dateRange, currentValue) => {
    if (dateRange === THIS_YEAR_RANGE_VALUE) {
      return startOfYear(ensureIsDate(currentDate));
    }
    if (dateRange === CUSTOM_RANGE_VALUE) {
      return currentValue;
    }
    return addMonths(
      ensureIsDate(currentDate),
      -monthSubtractionsMap[dateRange],
    );
  };

function SearchTransactionsExportButtons({ sort, filters }) {
  const totalCount = useSelector(R.path(['pagination', 'allItemsCount']));

  return (
    <SearchTransactionsExportButtonsWrapper>
      <SearchTransactionsXlsExportButton
        sort={sort}
        filters={filters}
        disabled={totalCount === 0}
      />
      <ToolBarBtnDivider />
      <SearchTransactionsPdfExportButton
        sort={sort}
        filters={filters}
        disabled={totalCount === 0}
      />
    </SearchTransactionsExportButtonsWrapper>
  );
}

SearchTransactionsExportButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sort: object,
  // eslint-disable-next-line react/forbid-prop-types
  filters: object,
};

const searchTransactionsHeaderConfig = (sort, filters, currentDate) => [
  {
    key: 'amountRow',
    columns: [
      {
        name: 'amount',
        title: 'Transaction Amount',
        filterProps: { width: '100%', changeOnBlur: true },
        column: 1,
        Component: NumberInputHTML5,
      },
      {
        name: 'export-buttons',
        column: 4,
        Component: (props) => (
          <SearchTransactionsExportButtons
            {...props}
            sort={sort}
            filters={filters}
          />
        ),
      },
    ],
  },
  {
    key: 'dateRow',
    rowLayout: { justifyContent: 'flex-start' },
    columns: [
      {
        name: 'startDate',
        defaultValue: addMonths(ensureIsDate(currentDate), -12),
        title: 'From',
        filterProps: { width: '100%' },
        disabledIf: R.complement(propEqLegacy('dateRange', CUSTOM_RANGE_VALUE)),
        column: 1,
        Component: DatePicker,
      },
      {
        name: 'endDate',
        defaultValue: currentDate,
        title: 'To',
        columnLayout: { titleWidth: '50px' },
        filterProps: { width: '100%' },
        disabledIf: R.complement(propEqLegacy('dateRange', CUSTOM_RANGE_VALUE)),
        column: 2,
        Component: DatePicker,
      },
      {
        name: 'dateRange',
        defaultValue: ONE_YEAR_RANGE_VALUE,
        filterProps: {
          width: '100%',
          options: dateRangeOptions,
          required: true,
        },
        subscribers: [
          ['startDate', transformStartDateByRangeSelect(currentDate)],
        ],
        column: 3,
        Component: Select,
      },
    ],
  },
];

export function SearchTransactionsPage() {
  const [filters, setFilters] = useState(null);

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: transactionsSearchTableConfig,
  });

  const { loading, transactions } = useSearchTransactionsQuery({
    sort,
    filters,
  });

  return (
    <PageWrapper>
      <PageWithSearchHeader headerHeight="144px">
        <SearchPageHeaderContainer title="Transaction Amount Search">
          <SearchHeaderCreator
            config={searchTransactionsHeaderConfig(
              sort,
              filters,
              alwaysNewDate(),
            )}
            loading={loading}
            onSearch={setFilters}
          />
        </SearchPageHeaderContainer>
        <SearchPageContentWrapper>
          <TableCardWithPaginationContainer>
            {loading ? (
              <Loader />
            ) : (
              <SearchTransactionsTable
                transactions={transactions}
                {...tableSortProps}
              />
            )}
          </TableCardWithPaginationContainer>
        </SearchPageContentWrapper>
      </PageWithSearchHeader>
    </PageWrapper>
  );
}
