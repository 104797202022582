import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import {
  commonAddressFields,
  useMapConfigToTablePropsWithSorting,
} from '@poly/admin-ui';
import {
  filterItemsBySearchText,
  useHighlightMatchesBySearch,
} from '@poly/client-utils';
import { useSelector } from 'react-redux';

import {
  prepareSupplier1099ReportData,
  supplier1099ReportTableConfig,
} from './supplier1099ReportUtils.js';

const supplier1099ReportQuery = gql`
  query supplier1099ReportQuery($input: SuppliersAAC1099ReportInput!) {
    suppliersAAC1099Report(input: $input) {
      _id
      paidByCC
      paidByOthers
      supplier {
        _id
        company {
          dba
          name
          addressTwo
          address {
            ...commonAddressFields
          }
        }
        tax {
          is1099
          id
          socialSecurityNumber
          name
          address {
            ...commonAddressFields
          }
          addressTwo
        }
        mwbe {
          minority
          isWBE
        }
        foodBuy {
          vendorType
          vaPercentage
          isEnabled
        }
        remit {
          address {
            ...commonAddressFields
          }
          addressTwo
        }
      }
      masterSupplier {
        _id
        company {
          dba
          name
          addressTwo
          address {
            ...commonAddressFields
          }
          addressTwo
        }
        tax {
          is1099
          id
          socialSecurityNumber
          name
          address {
            ...commonAddressFields
          }
          addressTwo
        }
        mwbe {
          minority
          isWBE
        }
        foodBuy {
          vendorType
          vaPercentage
          isEnabled
        }
        remit {
          address {
            ...commonAddressFields
          }
          addressTwo
        }
      }
    }
  }
  ${commonAddressFields}
`;

const highlightPaths = [
  ['taxName'],
  ['name'],
  ['address'],
  ['addressTwo'],
  ['city'],
  ['state'],
  ['zip'],
];

export const useSupplier1099Report = (query) => {
  const { data, loading } = useQuery(supplier1099ReportQuery, {
    variables: { input: query },
    fetchPolicy: 'no-cache',
    skip: !query,
  });

  const searchText = useSelector((state) => state.searchText);

  const { rows, ...tableProps } = useMapConfigToTablePropsWithSorting({
    tableConfig: supplier1099ReportTableConfig,
    items: prepareSupplier1099ReportData(data),
    initialSorting: {
      columnKey: 0,
      dir: 1,
    },
  });

  const filteredRows = filterItemsBySearchText(
    searchText,
    highlightPaths,
    rows,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    highlightPaths,
    filteredRows,
  );

  return {
    loading,
    filteredRows,
    tableProps: { rows: highlightedRows, ...tableProps },
  };
};
