import * as R from 'ramda';
import React from 'react';
import { LinkButton } from '@poly/admin-book';
import { func, object } from 'prop-types';

// checkIsUserGroupSelected :: { formData: { userGroupId: ID } } -> Boolean
const checkIsUserGroupSelected = R.propSatisfies(R.is(String), 'userGroupId');

export function AddUserGroupLinkButton({ formData, changeFieldValue }) {
  const isUserGroupSelected = checkIsUserGroupSelected(formData);

  const onClick = (e) => {
    e.preventDefault();

    const preparedUserGroup = R.pick(['userGroupId'], formData);

    changeFieldValue('userGroupId', null);
    changeFieldValue('userGroups', [
      ...(formData?.userGroups || []),
      preparedUserGroup,
    ]);
  };

  return (
    <LinkButton onClick={onClick} disabled={!isUserGroupSelected}>
      Add User Group
    </LinkButton>
  );
}

AddUserGroupLinkButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formData: object,
  changeFieldValue: func,
};
