import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { isNilOrEmpty } from '@poly/utils';

const REMOVE_PROJECT_SUPPLIER = gql`
  mutation REMOVE_PROJECT_SUPPLIER($input: RemoveSupplierFromProjectInput!) {
    removeSupplierFromProject(input: $input) {
      project {
        _id
      }
    }
  }
`;

const REMOVE_RECURRING_PROJECT_SUPPLIER = gql`
  mutation REMOVE_RECURRING_PROJECT_SUPPLIER(
    $input: RemoveSupplierFromRecurringProjectInput!
  ) {
    removeSupplierFromRecurringProject(input: $input) {
      _id
    }
  }
`;

// hasSupplierProjectEstimate :: (String, Project) -> Boolean
export const hasSupplierProjectEstimate = (supplierId, project) =>
  R.compose(
    R.complement(isNilOrEmpty),
    R.find(R.pathEq(supplierId, ['supplier', '_id'])),
    R.unnest,
    R.reject(isNilOrEmpty),
    R.pluck('markupEntries'),
    R.pathOr([], ['estimates']),
  )(project);

export const useRemoveProjectSupplier = ({
  project,
  supplierId,
  isRecurringProject,
}) => {
  const { openConfirmModal } = useModalContext();
  const [removeProjectSupplier] = useMutation(REMOVE_PROJECT_SUPPLIER);
  const [removeRecurringProjectSupplier] = useMutation(
    REMOVE_RECURRING_PROJECT_SUPPLIER,
  );
  const { showSuccessNotification } = useNotificationState();

  const removeSupplierMutation = isRecurringProject
    ? removeRecurringProjectSupplier
    : removeProjectSupplier;

  const handleRemoveSupplier = async () => {
    await removeSupplierMutation({
      variables: {
        input: { projectId: project?._id, supplierId },
      },
    });
    showSuccessNotification('Supplier was successfully deleted');
  };

  return async () => {
    const isHasProjectEstimate = hasSupplierProjectEstimate(
      supplierId,
      project,
    );

    if (isHasProjectEstimate) {
      openConfirmModal({
        cancelBtnCaption: 'Cancel',
        btnCaption: 'Yes',
        id: 'confirmDeleteSupplierModalId',
        content:
          'If you delete this supplier, the associated client estimate will also be removed. Do you want to continue?',
        onConfirm: (closeConfirmModal) => async () => {
          await handleRemoveSupplier();
          closeConfirmModal();
        },
      });

      return;
    }

    await handleRemoveSupplier();
  };
};
