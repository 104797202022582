import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import {
  WorkOrderPriority,
  ProjectPrioritiesUIMap,
  ClientProjectPriorityUnit,
  DEFAULT_CLIENT_PROJECT_PRIORITIES,
} from '@poly/constants';
import { FieldLayout, Icon, TextButton } from '@poly/admin-book';
import { arrayOf, func, number, shape, string } from 'prop-types';

import { PriorityNameInput } from './PriorityNameInput.js';
import { PriorityUnitSelect } from './PriorityUnitSelect.js';
import { PriorityAmountInput } from './PriorityAmountInput.js';

const LinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  > div:nth-child(1) {
    width: 35%;
  }

  > div:nth-child(2) {
    width: 15%;
  }

  > div:nth-child(3) {
    width: 10%;
  }

  > div:nth-child(4) {
    width: 20%;
  }

  > div:nth-child(5) {
    width: 10%;
  }
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #999;
  font-weight: 400;
  padding: 0 0 8px 0;
`;

const DeleteIcon = styled(Icon)`
  cursor: pointer;
  padding-top: 10px;
`;

const TextButtonS = styled(TextButton)`
  width: 70px;
`;

const DEFAULT_PRIORITIES = R.map(
  R.prop('id'),
  DEFAULT_CLIENT_PROJECT_PRIORITIES,
);

// checkIsDefaultPriority :: String -> Boolean
const checkIsDefaultPriority = R.includes(R.__, DEFAULT_PRIORITIES);

// shouldDisplaySupplierArrivalTime :: String -> Boolean
const shouldDisplaySupplierArrivalTime = R.includes(R.__, [
  ProjectPrioritiesUIMap[WorkOrderPriority.URGENT],
  ProjectPrioritiesUIMap[WorkOrderPriority.EMERGENCY],
]);

export function ClientProjectPrioritiesTable({
  name,
  index,
  fields: { value, remove, push },
}) {
  const isFirstLine = index === 0;
  const isLastLine = index === value.length - 1;

  const {
    id,
    amount,
    unit,
    name: priorityName,
    supplierArrivalAmount,
    supplierArrivalUnit,
  } = value[index] || {};

  const isDefaultPriority = checkIsDefaultPriority(id);
  const withSupplierArrivalTime =
    shouldDisplaySupplierArrivalTime(priorityName);

  return (
    <LinesWrapper>
      {isFirstLine && (
        <Line>
          <Label>Priority</Label>
          <Label>Amount</Label>
          <Label>Unit</Label>
          <Label>Supplier Expected Arrival Time</Label>
        </Line>
      )}
      <Line>
        <FieldLayout
          layout={{ width: '30%' }}
          field={{
            name: `${name}.name`,
            Component: PriorityNameInput,
            additionalProps: { value: priorityName, isDefaultPriority },
          }}
          validators={[[R.identity, 'Priority Name is required']]}
        />
        <FieldLayout
          layout={{ width: '30%' }}
          field={{
            name: `${name}.amount`,
            Component: PriorityAmountInput,
            additionalProps: { value: amount },
          }}
          validators={[[R.identity, 'Priority Amount is required']]}
        />
        <FieldLayout
          layout={{ width: '30%' }}
          field={{
            name: `${name}.unit`,
            Component: PriorityUnitSelect,
            additionalProps: { value: unit },
          }}
          validators={[[R.identity, 'Priority Unit is required']]}
        />
        {withSupplierArrivalTime && (
          <>
            <FieldLayout
              layout={{ width: '30%' }}
              field={{
                name: `${name}.supplierArrivalAmount`,
                Component: PriorityAmountInput,
                additionalProps: { value: supplierArrivalAmount },
              }}
              validators={[[R.identity, 'Supplier Arrival Amount is required']]}
            />
            <FieldLayout
              layout={{ width: '30%' }}
              field={{
                name: `${name}.supplierArrivalUnit`,
                Component: PriorityUnitSelect,
                additionalProps: { value: supplierArrivalUnit },
              }}
              validators={[[R.identity, 'Supplier Arrival Unit is required']]}
            />
          </>
        )}
        {!isDefaultPriority && (
          <DeleteIcon
            name="delete"
            color="#FF6363"
            size={13}
            onClick={() => remove(index)}
          />
        )}
      </Line>
      {isLastLine && (
        <TextButtonS
          onClick={() => push({ unit: ClientProjectPriorityUnit.HOURS })}
        >
          Add Priority
        </TextButtonS>
      )}
    </LinesWrapper>
  );
}

ClientProjectPrioritiesTable.propTypes = {
  name: string,
  index: number,
  fields: shape({
    push: func,
    remove: func,
    value: arrayOf(shape({ priority: string })),
  }),
};
