import React from 'react';
import { string } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils';
import { useRouterQuery } from '@poly/client-routing';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonOuterButton } from '@poly/admin-ui';

import { ApprovePostSupplierInvoicesTabsMap } from './constants.js';
import { getFileNameByTab } from './ApproveSupplierInvoicesExportXLSBtn.js';
import { ApproveInvoicesTableComponent } from './ApproveSupplierInvoicesTable.js';
import { useApproveInvoicesLazyQuery } from './useApproveInvoices.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';

const { TO_REVIEW } = ApprovePostSupplierInvoicesTabsMap;

export function ApproveSupplierInvoicesPrintPDFBtn({ userId }) {
  const Layout = usePrintLayout();
  const { tab = TO_REVIEW } = useRouterQuery(['tab']);

  const { queryHandler, loading } = useApproveInvoicesLazyQuery({
    tab,
    userId,
  });

  const onClick = async () => {
    const { invoices } = await queryHandler();

    return openPrintWindowWithData({
      Layout,
      invoices,
      isPrint: true,
      fileName: getFileNameByTab(tab),
      Table: ApproveInvoicesTableComponent,
    });
  };

  return (
    <CommonOuterButton onClick={onClick} loading={loading} disabled={loading}>
      {PRINT_PDF_CAPTION}
    </CommonOuterButton>
  );
}
ApproveSupplierInvoicesPrintPDFBtn.propTypes = { userId: string };
