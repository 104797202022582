import React from 'react';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';

import { useAccountTypesLazyQuery } from './table/useAccountTypesQuery.js';
import { PrintExportButtons } from '../../components/ExportButtons.js';
import { AccountTypesTablePrintTable } from './table/AccountTypesTable.js';
import { getAccountTypesExcelConfig } from './getAccountTypesExcelConfig.js';

export function AccountTypesExportButtons(props) {
  const { runAccountTypesQuery, loading } = useAccountTypesLazyQuery(props);

  const handlePrintPDF = async () => {
    const data = await runAccountTypesQuery();

    await openPrintWindowWithData({
      data,
      Layout: CommonPrintLayout,
      Table: AccountTypesTablePrintTable,
      fileName: 'account_types_export.pdf',
      metaData: { title: 'Account Types' },
    });
  };

  const handleExportXLS = async () => {
    const data = await runAccountTypesQuery();

    const config = getAccountTypesExcelConfig(data);

    return performExcelExport(config);
  };

  return (
    <PrintExportButtons
      loading={loading}
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
    />
  );
}
