import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { usePaginationParams } from '@poly/admin-ui/src/hooks/usePaginationParams.js';
import { useSetItemsCount } from '@poly/admin-ui/src/hooks/useSetItemsCount.js';
import { assocBy, formatDate } from '@poly/utils';
import { applyPathOrNothingUI } from '@poly/client-utils';
import { NOTHING_UI_STRING } from '@poly/constants';
import { getDailySupplierInvoicesBatchStatusUI } from './helpers.js';

// eslint-disable-next-line import/no-unused-modules
export const printedSupplierBatchesQuery = gql`
  query printedSupplierBatchesQuery($input: PrintedSupplierBatchesQueryInput!) {
    printedSupplierBatches(input: $input) {
      hits {
        _id
        isPrinted
        date
        printedAt
        printedBy {
          profile {
            fullName
          }
        }
        supplierBatchFile {
          fileName
          url
        }
      }
      total
    }
  }
`;
// getPrintedSupplierBatches :: PrintedSupplierBatchesQueryResult -> [PrintedSupplierBatch]
const getPrintedSupplierBatches = R.compose(
  R.map(
    R.compose(
      assocBy('statusToHighlight', getDailySupplierInvoicesBatchStatusUI),
      assocBy(
        'printedAtToHighlight',
        applyPathOrNothingUI(['printedAt'], formatDate),
      ),
      assocBy(
        'printedByToHighlight',
        R.pathOr(NOTHING_UI_STRING, ['printedBy', 'profile', 'fullName']),
      ),
      assocBy('dateToHighlight', applyPathOrNothingUI(['date'], formatDate)),
    ),
  ),
  R.pathOr([], ['printedSupplierBatches', 'hits']),
);

export const usePrintedSupplierBatches = ({ sort, searchText }) => {
  const paginationParams = usePaginationParams();

  const queryOptions = useMemo(
    () => ({
      variables: { input: { searchText, sort, ...paginationParams } },
      fetchPolicy: 'network-only',
    }),
    [sort, searchText, paginationParams],
  );

  const { data, loading, refetch } = useQuery(
    printedSupplierBatchesQuery,
    queryOptions,
  );

  useSetItemsCount(R.pathOr(0, ['printedSupplierBatches', 'total']), data);

  return {
    loading,
    refetch,
    printedSupplierBatches: getPrintedSupplierBatches(data),
  };
};

export const usePrintedSupplierBatchesLazyQuery = ({ sort, searchText }) => {
  const [loading, setLoading] = useState(false);

  const input = useMemo(() => ({ searchText, sort }), [searchText, sort]);

  const [runQuery] = useLazyQuery(printedSupplierBatchesQuery);

  const runPrintedSupplierBatchesQuery = async () => {
    setLoading(true);

    const { data } = await runQuery({ variables: { input } });

    setLoading(false);

    return getPrintedSupplierBatches(data);
  };

  return {
    loading,
    runPrintedSupplierBatchesQuery,
  };
};
