import * as R from 'ramda';
import React, { useMemo } from 'react';
import { arrayOf, number, shape, string, bool } from 'prop-types';
import { UPDATE_RECURRING_PROJECT_PERMISSION } from '@poly/security';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { collectionNames } from '@poly/constants';

import { BidRulesTab } from '../../ClientSidebar/financialTabs/ClientBidRulesTab/BidRulesTab.js';

// checkIfRecurringProjectWithoutAssets :: RecurringProject -> Boolean
const checkIfRecurringProjectWithoutAssets = R.compose(
  R.equals(0),
  R.pathOr(0, ['searchAssets', 'total']),
);

// getInheritedRulesWarning :: RecurringProject -> String
const getInheritedRulesWarning = R.ifElse(
  R.converge(R.equals, [
    R.propOr([], 'bidRules'),
    R.pathOr([], ['property', 'bidRules']),
  ]),
  R.always('Bid Rules Inherited from Property'),
  R.always(null),
);

export function MasterRecurringProjectSidebarBidRulesTab({ project }) {
  const hasAccess = useHasUserAccessWithPermission(
    UPDATE_RECURRING_PROJECT_PERMISSION,
  );

  const showAssetsWarning = useMemo(
    () => checkIfRecurringProjectWithoutAssets(project),
    [project],
  );

  const rulesInheritedWarning = useMemo(
    () => getInheritedRulesWarning(project),
    [project],
  );

  const tabProps = {
    hasAccess,
    id: project._id,
    showAssetsWarning,
    rulesInheritedWarning,
    collection: collectionNames.recurringProjects,
    bidRules: R.pathOr([], ['bidRules'], project),
  };

  return <BidRulesTab {...tabProps} />;
}

MasterRecurringProjectSidebarBidRulesTab.propTypes = {
  project: shape({
    _id: string.isRequired,
    bidRules: arrayOf(
      shape({
        type: string.isRequired,
        label: string.isRequired,
        amount: number.isRequired,
        isRuleActive: bool.isRequired,
      }),
    ),
  }),
};
