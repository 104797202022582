import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string } from 'prop-types';

const RowInfoContainer = styled.div`
  display: flex;
  justify-content: ${R.propOr('flex-start', 'justifyContent')};
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
`;

const RowInfoValue = styled.p`
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-family: Verdana;
`;

const RowInfoLabel = styled(RowInfoValue)`
  color: #999999;
`;

export function ProjectEstimateRowInfo({ label, value, ...props }) {
  return (
    <RowInfoContainer {...props}>
      <RowInfoLabel>{label}:</RowInfoLabel>
      <RowInfoValue>{value}</RowInfoValue>
    </RowInfoContainer>
  );
}

ProjectEstimateRowInfo.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
};

export const SubTitleTitle = styled.h3`
  padding: 0;
  margin: 12px 0 8px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #1a1514;
`;
