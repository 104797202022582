import * as R from 'ramda';
import { ClientProjectPriorityUnit } from '@poly/constants';

// isHoursProjectPriorityUnit :: ProjectWithClient -> Boolean
export const isHoursProjectPriorityUnit = ({ priorityId, client }) =>
  R.compose(
    R.propEq(ClientProjectPriorityUnit.HOURS, 'unit'),
    R.find(R.propEq(priorityId, 'id')),
    R.defaultTo([]),
    R.path(['configs', 'projectPriorities']),
  )(client);
