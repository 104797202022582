import * as R from 'ramda';
import styled from 'styled-components';
import React from 'react';
import { bool, func, number, object, shape, string } from 'prop-types';
import { Select, getThemeColor, RadioButton } from '@poly/admin-book';
import { ScheduleRepeats, ProjectType } from '@poly/constants';
import { formTypeConstants } from '@poly/admin-ui';

import { forceTitleCase, propEqLegacy } from '@poly/utils';
import {
  repeatsByArray,
  scheduleRepeatsBy,
  scheduleDaysArray,
  scheduleRepeatsTextUI,
} from '../../modules/core/constants/schedule.js';
import { CheckBoxS } from '../../modules/forms/formsStyles.js';
import { getRecurringSummary } from './recurringSchedulerUtils.js';
import { isPMProjectWithDisableChildrenGeneration } from '../../sidebars/ProjectSidebar/forms/add/formDataToMutationData.js';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const FirstRow = styled(Row)`
  flex-direction: row;
  justify-content: space-between;
`;

const RowPart = styled(Row)`
  width: calc(33% - 15px);
`;

const WideRowPart = styled(Row)`
  width: calc(67% - 15px);
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100px;
  margin-bottom: 10px;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-start;
  color: ${getThemeColor(['primaryLighter2'])};
`;

const TextBlock = styled(Label)`
  padding-top: 35px;
  width: calc(34% - 10px);
  color: ${getThemeColor(['primary'])};
`;

const CheckBoxWrapper = styled(Row)`
  height: 25px;
  flex-direction: row;
  margin: 0;
  justify-content: flex-start;
`;

const RadioButtonsWrapper = styled(Row)`
  height: 38px;
  flex-direction: row;
  margin: 0;
  align-items: center;
  justify-content: flex-start;

  label:not(:last-child) {
    margin-right: 20px;
  }
`;

const CheckBoxItem = styled(CheckBoxS)`
  margin-right: 20px;
`;

const SummaryBlock = styled(Label)`
  width: 100%;
  margin: 0;
  color: ${getThemeColor(['primary'])};
`;

// getRepeatsOptions :: ScheduleRepeats -> [Pair String, String]
const getRepeatsOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.identity,
      label: forceTitleCase,
    }),
  ),
  R.values,
);

// getRepeatsEveryOptions :: ScheduleRepeats -> [Pair String, String]
const getRepeatsEveryOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.identity,
      label: R.identity,
    }),
  ),
  R.range(1),
);

function RecurringSchedulerComponent({
  value,
  summary,
  disableRepeats,
  repeatsEveryText,
  onRepeatsEveryChange,
  onRepeatsByChange,
  onRepeatsChange,
  onDaysChange,
  onKeyDown,
  selectorDirection,
}) {
  const commonSelectProps = {
    onKeyDown,
    labelColor: 'midDark',
    width: '100%',
    required: true,
    disabled: disableRepeats,
    direction: selectorDirection,
  };

  const repeatsSelectProps = {
    ...commonSelectProps,
    name: 'repeats',
    label: 'Repeats',
    onChange: onRepeatsChange,
    options: getRepeatsOptions(ScheduleRepeats),
    value: value.repeats,
  };

  const repeatsEverySelectProps = {
    ...commonSelectProps,
    name: 'every',
    label: 'Repeats Every',
    onChange: onRepeatsEveryChange,
    options: getRepeatsEveryOptions(30),
    value: disableRepeats ? 1 : value.every,
  };

  return (
    <Wrapper>
      <FirstRow>
        <RowPart>
          <Select {...repeatsSelectProps} />
        </RowPart>
        {R.any(R.equals(value.repeats))(repeatsByArray) ? (
          <WideRowPart>
            <Label>Repeats By</Label>
            <RadioButtonsWrapper>
              <RadioButton
                name={scheduleRepeatsBy.month}
                value={!value.weekDay}
                onChange={onRepeatsByChange}
                label={scheduleRepeatsBy.month}
              />
              <RadioButton
                name={scheduleRepeatsBy.week}
                value={value.weekDay}
                onChange={onRepeatsByChange}
                label={scheduleRepeatsBy.week}
              />
            </RadioButtonsWrapper>
          </WideRowPart>
        ) : (
          <>
            <RowPart>
              <Select {...repeatsEverySelectProps} />
            </RowPart>
            <TextBlock>{repeatsEveryText}</TextBlock>
          </>
        )}
      </FirstRow>
      {!!propEqLegacy('repeats', ScheduleRepeats.WEEKLY, value) && (
        <Row>
          <Label>Repeats on</Label>
          <CheckBoxWrapper>
            {R.map(
              ({ day, name, fullName }) => (
                <CheckBoxItem
                  key={day}
                  label={name}
                  name={fullName}
                  value={R.includes(day, R.propOr([], 'days', value))}
                  onChange={() => onDaysChange(day)}
                  disabled={disableRepeats}
                />
              ),
              scheduleDaysArray,
            )}
          </CheckBoxWrapper>
        </Row>
      )}
      <Row>
        <Label>Summary</Label>
        <SummaryBlock>{summary}</SummaryBlock>
      </Row>
    </Wrapper>
  );
}

RecurringSchedulerComponent.propTypes = {
  value: shape({
    repeats: string,
    every: number,
  }).isRequired,
  summary: string.isRequired,
  disableRepeats: bool.isRequired,
  repeatsEveryText: string.isRequired,
  onRepeatsChange: func.isRequired,
  onRepeatsEveryChange: func.isRequired,
  onDaysChange: func.isRequired,
  onRepeatsByChange: func.isRequired,
  onKeyDown: func,
  selectorDirection: string,
};

RecurringSchedulerComponent.defaultProps = {
  selectorDirection: 'down',
};

// isDisabledRecurringScheduler :: FormData -> Boolean
const isDisabledRecurringScheduler = R.ifElse(
  R.propEq(formTypeConstants.EDIT, 'formType'),
  R.complement(isPMProjectWithDisableChildrenGeneration),
  R.F,
);

export function RecurringScheduler({ formData, value, onChange, ...props }) {
  const isDisableRepeats = isDisabledRecurringScheduler(formData);

  const onRepeatsChange = (repeats) =>
    onChange({
      ...value,
      repeats,
      days: [],
      weekDay: false,
      every: 1,
    });

  const onRepeatsEveryChange = (every) =>
    onChange({
      ...value,
      every,
    });

  const onRepeatsByChange = (period) =>
    onChange({
      ...value,
      weekDay: period === scheduleRepeatsBy.week,
    });

  const onDaysChange = (day) =>
    onChange({
      ...value,
      days: R.ifElse(
        R.includes(day),
        R.without([day]),
        R.append(day),
      )(R.propOr([], 'days', value)),
    });

  return (
    <RecurringSchedulerComponent
      {...props}
      summary={R.concat('Every ', getRecurringSummary(formData))}
      value={value}
      disableRepeats={
        isDisableRepeats || formData.type === ProjectType.HOUSEKEEPING
      }
      repeatsEveryText={scheduleRepeatsTextUI[value.repeats]}
      onRepeatsChange={onRepeatsChange}
      onRepeatsEveryChange={onRepeatsEveryChange}
      onRepeatsByChange={onRepeatsByChange}
      onDaysChange={onDaysChange}
    />
  );
}

RecurringScheduler.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formData: object,
  onChange: func.isRequired,
  value: shape({
    repeats: string,
    every: number,
  }).isRequired,
};
