import * as R from 'ramda';
import { Select } from '@poly/admin-book';
import { entityToOptionByLabelPath } from '@poly/client-utils';
import {
  ProjectSpendTypes,
  ProjectSpendTypesUI,
  UserEmployeeInfoStatus,
} from '@poly/constants';
import {
  ALL,
  MAX_ITEMS,
  MoneyInput,
  USERS_BY_SEARCH,
  USER_SEARCH_CHANGED,
  SERVICE_TYPES_BY_SEARCH,
  SERVICE_TYPES_BY_SEARCH_SUB,
  getAdminUsersByEmploymentStatusQuery,
} from '@poly/admin-ui';

import { YesNoRadioButtons } from './YesNoRadioButtons.js';
import { ProjectStatusOptions } from '../FieldValueComponent/constants/project-status-options.js';
import { DescriptionFieldToEditEditor } from './DescriptionFieldToEditEditor.js';
import { BulkEditProjectFieldToEditValues } from '../../constants.js';
import { defaultFieldValueComponentConfig } from '../FieldValueComponent/default-field-value-component-config.js';
import {
  PROBLEM_CODES_BY_SEARCH,
  PROBLEM_CODES_BY_SEARCH_SUB,
} from '../../../../components/ProblemCodeSelect.js';

const {
  STATUS,
  SPEND_TYPE,
  SERVICE_TYPE,
  PROBLEM_CODE,
  ASSIGNED_CSR,
  AFTER_HOURS_CALL,
  INVOICE_DESCRIPTION,
  CLIENT_NOT_TO_EXCEED,
  EXEMPT_FROM_SALES_TAX,
} = BulkEditProjectFieldToEditValues;

export const getProjectFieldToEditValueComponentConfig = R.cond([
  [
    R.propEq(PROBLEM_CODE, 'selectedField'),
    R.always({
      placeholder: 'Select problem code',
      gqlSearchQuery: PROBLEM_CODES_BY_SEARCH,
      gqlSearchChangedQuery: PROBLEM_CODES_BY_SEARCH_SUB,
      getGqlQueryVariables: R.assocPath(['searchInput', 'searchTerm'], R.__, {
        searchInput: { size: MAX_ITEMS },
      }),
      getOptions: R.compose(
        R.map(entityToOptionByLabelPath(['name'])),
        R.pathOr([], ['searchProblemCodes', 'hits']),
      ),
      Component: Select,
    }),
  ],
  [
    R.propEq(SPEND_TYPE, 'selectedField'),
    R.always({
      getOptions: R.always([
        {
          value: ProjectSpendTypes.REACTIVE_REPAIR,
          label: ProjectSpendTypesUI[ProjectSpendTypes.REACTIVE_REPAIR],
        },
        {
          value: ProjectSpendTypes.PREVENTIVE_REPAIR,
          label: ProjectSpendTypesUI[ProjectSpendTypes.PREVENTIVE_REPAIR],
        },
        {
          value: ProjectSpendTypes.RECURRING_SERVICE,
          label: ProjectSpendTypesUI[ProjectSpendTypes.RECURRING_SERVICE],
        },
        {
          value: ProjectSpendTypes.CAP_EX,
          label: ProjectSpendTypesUI[ProjectSpendTypes.CAP_EX],
        },
      ]),
      placeholder: 'Select spend type',
      gqlSearchQuery: null,
      getGqlQueryVariables: null,
      gqlSearchChangedQuery: null,
      Component: Select,
    }),
  ],
  [
    R.propEq(STATUS, 'selectedField'),
    R.always({
      getOptions: R.compose(
        R.reject(R.propEq(ALL, 'value')),
        R.always(ProjectStatusOptions),
      ),
      placeholder: 'Select status',
      gqlSearchQuery: null,
      getGqlQueryVariables: null,
      gqlSearchChangedQuery: null,
      Component: Select,
    }),
  ],
  [
    R.propEq(SERVICE_TYPE, 'selectedField'),
    R.always({
      placeholder: 'Enter value',
      gqlSearchQuery: SERVICE_TYPES_BY_SEARCH,
      gqlSearchChangedQuery: SERVICE_TYPES_BY_SEARCH_SUB,
      getGqlQueryVariables: R.assocPath(['searchInput', 'searchTerm'], R.__, {
        searchInput: { size: MAX_ITEMS },
      }),
      getOptions: R.compose(
        R.map(entityToOptionByLabelPath(['name'])),
        R.pathOr([], ['searchServiceTypes', 'hits']),
      ),
      Component: Select,
    }),
  ],
  [
    R.propEq(ASSIGNED_CSR, 'selectedField'),
    R.always({
      placeholder: 'Enter value',
      gqlSearchQuery: USERS_BY_SEARCH,
      gqlSearchChangedQuery: USER_SEARCH_CHANGED,
      getGqlQueryVariables: R.assocPath(['searchInput', 'searchTerm'], R.__, {
        searchInput: {
          query: getAdminUsersByEmploymentStatusQuery(
            UserEmployeeInfoStatus.ACTIVE,
          ),
          size: MAX_ITEMS,
        },
      }),
      getOptions: R.compose(
        R.map(entityToOptionByLabelPath(['fullName'])),
        R.pathOr([], ['searchUsers', 'hits']),
      ),
      Component: Select,
    }),
  ],
  [
    R.propSatisfies(
      R.includes(R.__, [EXEMPT_FROM_SALES_TAX, AFTER_HOURS_CALL]),
      'selectedField',
    ),
    R.always({
      gqlSearchQuery: null,
      getGqlQueryVariables: null,
      gqlSearchChangedQuery: null,
      Component: YesNoRadioButtons,
    }),
  ],
  [
    R.propEq(CLIENT_NOT_TO_EXCEED, 'selectedField'),
    R.always({
      getOptions: null,
      placeholder: 'Enter value',
      gqlSearchQuery: null,
      getGqlQueryVariables: null,
      gqlSearchChangedQuery: null,
      Component: MoneyInput,
    }),
  ],
  [
    R.propEq(INVOICE_DESCRIPTION, 'selectedField'),
    R.always({
      getOptions: null,
      placeholder: 'Enter value',
      gqlSearchQuery: null,
      getGqlQueryVariables: null,
      gqlSearchChangedQuery: null,
      Component: DescriptionFieldToEditEditor,
    }),
  ],
  // for "DESCRIPTION" will be used default config
  [R.T, R.always(defaultFieldValueComponentConfig)],
]);
