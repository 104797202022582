import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { paginationToQueryParams } from '@poly/client-utils';

import { ASSET_MANUFACTURERS_QUERY } from './useAssetManufacturersQuery.js';

export const useAssetManufacturersLazyQuery = (sort) => {
  const [loading, setLoading] = useState(false);
  const searchTerm = useSelector(R.prop('searchText'));
  const pagination = useSelector(R.prop('pagination'));

  const input = useMemo(
    () => ({
      ...paginationToQueryParams(pagination),
      searchTerm,
      sort,
    }),
    [searchTerm, sort, pagination],
  );

  const [runQuery] = useLazyQuery(ASSET_MANUFACTURERS_QUERY);

  const runAssetManufacturersQuery = async () => {
    setLoading(true);

    const { data } = await runQuery({ variables: { input } });

    setLoading(false);

    return R.pathOr([], ['searchAssetManufactures', 'hits'], data);
  };

  return {
    loading,
    runAssetManufacturersQuery,
  };
};
