import * as R from 'ramda';
import { usePagination } from '@poly/admin-ui';
import { useRouterParams } from '@poly/client-routing';
import { DESC_SORT_ORDER, ELASTIC_SCORE_FIELD } from '@poly/constants';
import { useEntitiesByReactiveReduxSearch } from '@poly/client-utils';

import {
  MY_PROJECTS,
  PROJECTS_BY_SEARCH,
  MY_RECURRING_PROJECTS,
  RECURRING_PROJECTS_BY_SEARCH,
  SUPPLIER_RECURRING_PROJECTS_BY_SEARCH,
} from './queries.js';
import {
  PROJECTS_BY_SEARCH_SUB,
  RECURRING_PROJECTS_BY_SEARCH_SUB,
} from './subscriptions.js';

// adjustSortByStartDate :: [Sorting] -> [Sorting]
const adjustSortByStartDate = R.append({ createdAt: DESC_SORT_ORDER });

export const useProjectsBySearch = ({
  query = null,
  skipQuery = false,
  sort = [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
}) => {
  const { pagination } = usePagination();

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    query,
    skipQuery,
    pagination,
    alias: 'useProjectsBySearch',
    sort: adjustSortByStartDate(sort),
    gqlSearchQuery: PROJECTS_BY_SEARCH,
    gqlSearchChangedQuery: PROJECTS_BY_SEARCH_SUB,
  });

  return {
    loading,
    refetch,
    result,
  };
};

export const useMyProjectsBySearch = ({
  query = null,
  sort = [{ priorityId: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
}) => {
  const { pagination } = usePagination();

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    query,
    pagination,
    gqlSearchQuery: MY_PROJECTS,
    sort: adjustSortByStartDate(sort),
    gqlSearchChangedQuery: PROJECTS_BY_SEARCH_SUB,
  });

  return { loading, refetch, result };
};

export const useMyRecurringProjects = ({
  sort = [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
  query = null,
}) => {
  const { pagination } = usePagination();

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: MY_RECURRING_PROJECTS,
    gqlSearchChangedQuery: RECURRING_PROJECTS_BY_SEARCH_SUB,
    sort,
    pagination,
    query,
  });

  return {
    loading,
    refetch,
    result,
  };
};

export const useRecurringProjectsBySearch = ({
  sort = [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
  query = null,
}) => {
  const { pagination } = usePagination();

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: RECURRING_PROJECTS_BY_SEARCH,
    gqlSearchChangedQuery: RECURRING_PROJECTS_BY_SEARCH_SUB,
    sort,
    pagination,
    query,
  });

  return {
    loading,
    refetch,
    result,
  };
};

export const useSupplierRecurringProjectsBySearch = ({ sort, query }) => {
  const { supplierId } = useRouterParams(['supplierId']);

  const { pagination } = usePagination();

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: SUPPLIER_RECURRING_PROJECTS_BY_SEARCH,
    gqlSearchChangedQuery: RECURRING_PROJECTS_BY_SEARCH_SUB,
    sort,
    pagination,
    query,
    additionalVars: { documentId: supplierId },
  });

  return {
    loading,
    refetch,
    result,
  };
};
