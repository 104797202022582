import React, { useState } from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { TableCardWithPaginationContainer } from '@poly/admin-ui/src/components/Table.js';

import { AssetTypesPageTable } from './AssetTypesPageTable.js';
import { AssetTypesPageHeader } from './AssetTypesPageHeader.js';

export function AssetTypesPage() {
  const [sort, setSort] = useState(null);

  return (
    <>
      <AssetTypesPageHeader sort={sort} />
      <DefaultBodyWrapper height="calc(100% - 100px)">
        <TableCardWithPaginationContainer>
          <AssetTypesPageTable setSort={setSort} />
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </>
  );
}
