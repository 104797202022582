import * as R from 'ramda';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { arrayOf, func, string, bool } from 'prop-types';
import { WindowedTable, moneyColumnStyles, Table } from '@poly/admin-book';
import { JournalPaymentStatus } from '@poly/constants';
import {
  useSelectableTableRowsProps,
  useMapConfigToTableProps,
} from '@poly/admin-ui';

import { preparedPaymentsPropTypes } from './propTypes.js';
import { batchPaymentsTableConfig } from './useVoidSupplierPaymentsQuery.js';

const batchPaymentsTableStyles = css`
  tbody {
    vertical-align: middle;
  }

  thead {
    height: 45px;
  }

  tr {
    width: 100%;
    margin: 0;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 250px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 150px;
  }

  ${moneyColumnStyles(6)};

  th:nth-child(7),
  td:nth-child(7) {
    width: 45px;
  }
`;

const BatchPaymentsTableS = styled(WindowedTable)`
  ${batchPaymentsTableStyles};
`;

const BatchPaymentsPrintTableS = styled(Table)`
  margin-top: 50px;

  ${batchPaymentsTableStyles};
`;

// isPaymentReconciled :: MoneyTransaction -> Boolean
const isPaymentReconciled = R.propEq(
  JournalPaymentStatus.PENDING,
  'payment_status',
);

export function VoidSupplierPaymentsTableComponent({
  isPrint,
  batchPayments,
  ...restProps
}) {
  const [tableSort, setTableSort] = useState({ columnKey: 0, dir: 1 });

  const onHeaderCellClick = (columnKey) => {
    if (columnKey === tableSort.columnKey) {
      setTableSort({ columnKey: tableSort.columnKey, dir: tableSort.dir * -1 });
    }
  };

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    batchPaymentsTableConfig,
    tableSort.dir > 0 ? batchPayments : R.reverse(batchPayments),
  );

  const TableComponent = isPrint
    ? BatchPaymentsPrintTableS
    : BatchPaymentsTableS;

  return (
    <TableComponent
      rows={rows}
      showScrollBar
      headers={headers}
      columns={columns}
      selectedRowsInEnd
      sorting={tableSort}
      isSortable={!isPrint}
      isPartiallySelectable
      moreScrollPosition="0px"
      sortQueries={sortQueries}
      isRowSelectable={isPaymentReconciled}
      onHeaderCellClick={onHeaderCellClick}
      {...restProps}
    />
  );
}

VoidSupplierPaymentsTableComponent.propTypes = {
  isPrint: bool,
  batchPayments: preparedPaymentsPropTypes,
};

export function VoidSupplierPaymentsTable({
  isRefetching,
  batchPayments,
  selectedPayments,
  setSelectedPayments,
  ...restProps
}) {
  const { toggleRow } = useSelectableTableRowsProps(
    selectedPayments,
    setSelectedPayments,
    batchPayments,
  );

  return (
    <VoidSupplierPaymentsTableComponent
      {...restProps}
      batchPayments={batchPayments}
      selectedRows={selectedPayments}
      toggleRow={isRefetching ? () => null : toggleRow}
    />
  );
}

VoidSupplierPaymentsTable.propTypes = {
  isRefetching: bool.isRequired,
  setSelectedPayments: func.isRequired,
  batchPayments: preparedPaymentsPropTypes,
  selectedPayments: arrayOf(string).isRequired,
};
