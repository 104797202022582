import * as R from 'ramda';
import React from 'react';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { LinkButtonWithLoader } from '@poly/admin-book';
import { arrayOf, object, oneOfType, string } from 'prop-types';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';

import { getExpiredDateInWords } from '../../modules/core/utils/general.js';
import { useUnassignedProjectsLazyQuery } from './useUnassignedProjectsQuery.js';
import { getUnassignedProjectsByQueryData } from './helpers.js';

// getUnassignedProjectsRowConfig :: Project -> [ExcelExportDataCell]
const getUnassignedProjectsRowConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 6)),
  R.juxt([
    R.pathOr('', ['priority', 'name']),
    R.compose(getExpiredDateInWords, R.prop('expiresAt')),
    R.propOr('', 'projectId'),
    R.propOr('', 'description'),
    R.pathOr('', ['client', 'name']),
    R.pathOr('', ['property', 'name']),
  ]),
);

export const getUnassignedProjectsXlsExportConfig = (projects) => ({
  exportFileName: 'unassigned_projects_export.xlsx',
  columnWidths: [10, 10, 10, 30, 20, 30],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: 'Unassigned',
      },
    ],
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 6),
      [
        'Priority',
        'Expires',
        'Project ID',
        'Description',
        'Client',
        'Property',
      ],
    ),
    ...R.map(getUnassignedProjectsRowConfig, projects),
  ],
});

export function UnassignedProjectsXLSBtn({ clientId, sort }) {
  const queryHandler = useUnassignedProjectsLazyQuery();

  const onClick = async () => {
    const { data } = await queryHandler({ clientId, sort });

    const projects = getUnassignedProjectsByQueryData(data);
    const excelConfig = getUnassignedProjectsXlsExportConfig(projects);

    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}

UnassignedProjectsXLSBtn.propTypes = {
  clientId: string,
  sort: arrayOf(oneOfType([string, object])),
};
