import styled from 'styled-components';
import React, { useState } from 'react';
import { useSortableTable } from '@poly/client-utils';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { EditClientPaymentsForm } from './EditClientPaymentsForm.js';
import { EditClientPaymentsPageHeader } from './EditClientPaymentsPageHeader.js';
import { useFetchEditableClientPayments } from './hooks/useFetchEditableClientPayments.js';
import { getEditClientPaymentsTableConfig } from './EditClientPaymentsTable.js';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

export function EditClientPaymentsPage() {
  const [filters, setFilters] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: getEditClientPaymentsTableConfig(),
  });

  const { loading, tableProps, pageSize } = useFetchEditableClientPayments({
    sort,
    filters,
  });

  return (
    <PageWithSearchHeader headerHeight="140px">
      <EditClientPaymentsPageHeader
        loading={loading}
        submitting={submitting}
        setFilters={setFilters}
      />
      <TableContainer>
        <EditClientPaymentsForm
          virtualized
          loading={loading}
          pageSize={pageSize}
          tableProps={tableProps}
          submitting={submitting}
          setSubmitting={setSubmitting}
          tableSortProps={tableSortProps}
        />
      </TableContainer>
    </PageWithSearchHeader>
  );
}
