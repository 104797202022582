import React from 'react';
import styled from 'styled-components';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';
import { TableSearchInput } from '@poly/admin-ui';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  MainHeader,
  TextButton,
} from '@poly/admin-book';

import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { ApprovePostSupplierInvoicesTabsMap } from './constants.js';
import { ApproveInvoicesTable } from './ApproveSupplierInvoicesTable.js';
import { ToolBarBtnDivider } from '../../modules/core/tableToolbar/tableToolBarStyles.js';
import { ApproveSupplierInvoicesPrintPDFBtn } from './ApproveSupplierInvoicesPrintPDFBtn.js';
import { ApproveSupplierInvoicesExportXLSBtn } from './ApproveSupplierInvoicesExportXLSBtn.js';
import { ApproveSupplierInvoicesPageTabTitle } from './ApproveSupplierInvoicesPageTabTitle.js';
import { useAddSupplierInvoicesSidebar } from './AddSupplierInvoicesForm/useAddSupplierInvoicesSidebar.js';

const { TO_REVIEW, SUBMITTED_BY_ME, ALL } = ApprovePostSupplierInvoicesTabsMap;

const HeaderLinksWrapperS = styled(HeaderLinksWrapper)`
  margin: 0;

  > div {
    margin: 0 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export function ApproveSupplierInvoicesPage() {
  const { user } = useCurrentUserByStoreOrQuery();
  const openAddSupplierInvoicesSidebar = useAddSupplierInvoicesSidebar();

  const userId = user?._id;

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Approve/Post Supplier Invoices</MainHeader>
        <TextButton onClick={openAddSupplierInvoicesSidebar}>
          Add Supplier Invoice
        </TextButton>
      </PageHeaderContainer>
      <PageTabs
        toolBar={
          <HeaderLinksWrapperS>
            <ApproveSupplierInvoicesPrintPDFBtn userId={userId} />
            <ToolBarBtnDivider />
            <ApproveSupplierInvoicesExportXLSBtn userId={userId} />
            <TableSearchInput />
          </HeaderLinksWrapperS>
        }
        tabs={[
          [
            <ApproveSupplierInvoicesPageTabTitle
              excludeUserId={userId}
              tab={TO_REVIEW}
              key="to-review-tab"
            />,
            TO_REVIEW,
            <ApproveInvoicesTable
              excludeUserId={userId}
              tab={TO_REVIEW}
              key="to-review-tab"
            />,
          ],
          [
            <ApproveSupplierInvoicesPageTabTitle
              includeUserId={userId}
              tab={SUBMITTED_BY_ME}
              key="submitted-by-me-tab"
            />,
            SUBMITTED_BY_ME,
            <ApproveInvoicesTable
              includeUserId={userId}
              tab={SUBMITTED_BY_ME}
              key="submitted-by-me-tab"
            />,
          ],
          [
            <ApproveSupplierInvoicesPageTabTitle
              tab={ALL}
              userId={userId}
              key="all-tab"
            />,
            ALL,
            <ApproveInvoicesTable tab={ALL} userId={userId} key="all-tab" />,
          ],
        ]}
        defaultValue={TO_REVIEW}
      />
    </>
  );
}
