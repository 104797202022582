import React from 'react';
import {
  FlexColumn,
  SidebarWrapper,
  SidebarWrapperForTableForm,
} from '@poly/admin-ui';
import { Loader } from '@poly/admin-book';
import { bool, shape, string } from 'prop-types';

import { ProjectSidebarWeeklyTickets } from '../../../sidebars/ProjectSidebar/ProjectSidebarWeeklyTickets.js';
import { isFeeProjectType } from '../../../sidebars/ProjectSidebar/forms/form/utils/sections.js';
import { ProjectSidebarFinancialTab } from '../../../sidebars/ProjectSidebar/tabs/ProjectSidebarFinancialTab.js';
import { ProjectSidebarSuppliers } from '../../../sidebars/ProjectSidebar/ProjectSidebarSuppliers.js';
import { ProjectSidebarDetails } from '../../../sidebars/ProjectSidebar/ProjectSidebarDetails.js';
import { ProjectSidebarContactInfo } from '../../../sidebars/ProjectSidebar/ProjectSidebarContactInfo.js';

export function ProjectInfoTab({ project, loading }) {
  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarWrapper>
      <SidebarWrapperForTableForm>
        <FlexColumn>
          <ProjectSidebarDetails project={project} />
          <ProjectSidebarContactInfo project={project} />
          {!isFeeProjectType(project) && (
            <ProjectSidebarSuppliers
              project={project}
              showActionButtons={false}
            />
          )}
          <ProjectSidebarWeeklyTickets project={project} />
          <ProjectSidebarFinancialTab project={project} disableTabs />
        </FlexColumn>
      </SidebarWrapperForTableForm>
    </SidebarWrapper>
  );
}

ProjectInfoTab.propTypes = {
  project: shape({
    _id: string.isRequired,
  }),
  loading: bool.isRequired,
};
