import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { TablePagination } from '@poly/admin-ui/src/index.js';
import {
  TableWithPaginationContainer,
  TableCard,
} from '@poly/admin-book/src/Card.js';

import { SubCategoryReportTable } from './SubCategoryReportTable.js';
import { SubCategoryReportHeader } from './SubCategoryReportHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { useSubCategoryReport } from './useSubCategoryReport.js';

const SubCategoryReportWrapperS = styled(DefaultBodyWrapper)`
  height: calc(100% - 160px);
  padding: 0 20px;
`;

const TableCardS = styled(TableCard)`
  width: 100%;
  max-width: 100%;
`;

export function SubCategoryReportPage() {
  const [filter, setFilter] = useState({});
  const pagination = useSelector(R.prop('pagination'));

  const { reportItems, loading } = useSubCategoryReport(filter);

  return (
    <PageWithSearchHeader headerHeight="165px">
      <SubCategoryReportHeader
        filter={filter}
        setFilter={setFilter}
        loading={loading}
      />
      <SubCategoryReportWrapperS>
        <TableCardS>
          <TableWithPaginationContainer paginationVisible>
            <SubCategoryReportTable
              loading={loading}
              pagination={pagination}
              reportItems={reportItems}
            />
          </TableWithPaginationContainer>
          <TablePagination />
        </TableCardS>
      </SubCategoryReportWrapperS>
    </PageWithSearchHeader>
  );
}
