import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import { Button } from '@poly/admin-book';
import { useSelector } from 'react-redux';

import { SubmitButton } from '../../../modules/forms/supplierInvoiceForm/sections.js';
import { BulkEditingFormId } from '../constants.js';

const FilterActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

// isSubmitDisabledByProps :: { formData: { fieldsToEdit: [Object] } } -> Boolean
const isSubmitDisabledByProps = R.compose(
  R.lt(R.__, 2),
  R.length,
  R.pathOr([], ['formData', 'fieldsToEdit']),
);

export function BulkEditActionButtons({ changeFieldValue, ...props }) {
  const submitting = useSelector((state) => state.processes[BulkEditingFormId]);

  const onReset = () => {
    changeFieldValue('fieldsToEdit', [{}, {}]);
  };

  const disabled = submitting || isSubmitDisabledByProps(props);

  return (
    <FilterActionButtonsWrapper>
      <SubmitButton disabled={disabled}>Edit</SubmitButton>
      <Button
        size="small"
        type="button"
        styleType="basicSecondary"
        onClick={onReset}
      >
        Reset
      </Button>
    </FilterActionButtonsWrapper>
  );
}

BulkEditActionButtons.propTypes = {
  changeFieldValue: func,
};
