import * as R from 'ramda';
import { string, number } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { ProjectType, UserStatuses } from '@poly/constants';
import { isNilOrEmpty, debounce, propEqLegacy } from '@poly/utils';
import { Loader } from '@poly/admin-book';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
  MAX_ITEMS,
} from '@poly/admin-ui';
import {
  usePersistentFormValue,
  useOutSidebarContext,
  useReactiveQuery,
} from '@poly/client-utils';

import {
  ProjectEstimateForm,
  projectEstimateFormId,
} from './ProjectEstimateForm/ProjectEstimateForm.js';
import { ClientConfigsContext } from './ClientConfigsContext.js';
import { SidebarFormLayout } from '../../components/SidebarFormLayout.js';
import { useClearPristineState } from '../../useClearPristineOnSidebarMount.js';
import {
  formatProjectEstimateMutationInput,
  takeOnlyAssignedSuppliers,
  getEstimateInitialValues,
  getClientConfigs,
} from './helpers.js';

const { WORK_ORDER, BID } = ProjectType;

const addProjectEstimateMutation = gql`
  mutation addProjectEstimateMutation($input: ProjectEstimateInput!) {
    createProjectEstimate(input: $input) {
      _id
    }
  }
`;

const updateProjectEstimateMutation = gql`
  mutation updateProjectEstimateMutation(
    $id: ID!
    $update: ProjectEstimateInput!
  ) {
    updateProjectEstimate(id: $id, update: $update) {
      _id
    }
  }
`;

const projectEstimateQuery = gql`
  query projectEstimateQuery($projectId: ID!, $input: CollectionSearchParams) {
    project(id: $projectId) {
      _id
      projectId
      type
      invoiceDescription
      exemptSalesTax
      suppliers {
        _id
        statusInProject
        company {
          name
        }
      }
      client {
        _id
        woCharge
        tmMarkupRules {
          upTo
          percent
        }
        staffRates {
          afterHoursCall
          customRates {
            description
            rate
            overtime
            doubleTime
          }
        }
        clientGroupEmail
        taxRate
        searchContacts(input: $input) {
          hits {
            _id
            fullName
            email
          }
        }
      }
      clientManager {
        _id
        email
      }
      siteContact {
        _id
        email
      }
      manager {
        _id
        email
      }
      property {
        _id
        taxRate
        woCharge
        tmMarkupRules {
          upTo
          percent
        }
        branchManager {
          email
        }
      }
      estimates {
        _id
        total
        emailTo
        description
        afterHoursCall
        isBreakdown
        manualEntries {
          description
          rate
          quantity
          total
        }
        markupEntries {
          total
          amount
          markup
          supplier {
            _id
            company {
              name
            }
          }
        }
        timeEntries {
          type
          description
          totalMinutes
          rate
          total
        }
        attachments {
          url
          fileName
          fileSize
          fileType
        }
      }
    }
  }
`;

const PROJECT_DETAILS_SUB = gql`
  subscription PROJECT_DETAILS_SUB($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
    }
  }
`;

// getEstimateById :: Project -> ProjectEstimate
const getEstimateById = (id) =>
  R.compose(
    R.find(propEqLegacy('_id', id)),
    R.defaultTo([]),
    R.prop('estimates'),
  );

// checkIsCalculateMode :: Project -> Boolean
const checkIsCalculateMode = R.allPass([
  R.propSatisfies(R.includes(R.__, [WORK_ORDER, BID]), 'type'),
  R.compose(
    R.complement(R.isEmpty),
    takeOnlyAssignedSuppliers,
    R.propOr([], 'suppliers'),
  ),
  R.either(
    R.pathSatisfies(R.complement(isNilOrEmpty), ['client', 'tmMarkupRules']),
    R.pathSatisfies(R.complement(isNilOrEmpty), ['property', 'tmMarkupRules']),
  ),
]);

export function ProjectEstimateSidebarForm({
  projectId,
  estimateId,
  estimateNumber,
}) {
  const clearPristine = useClearPristineState();
  const { closeOutSidebar } = useOutSidebarContext();
  const [isManualMode, setIsManualMode] = useState(true);
  const { showSuccessNotification } = useNotificationState();
  const [addProjectEstimate] = useMutation(addProjectEstimateMutation);
  const [updateProjectEstimate] = useMutation(updateProjectEstimateMutation);

  const {
    cleanupRetainedValue,
    onChangePersistentValue,
    retainedValue: persistScopeOfWork,
  } = usePersistentFormValue(`projectEstimate.details.${projectId}`);

  const pristineClearDebounced = useCallback(
    debounce(100)(() => clearPristine()),
    [],
  );

  const onClose = () => {
    closeOutSidebar(projectEstimateFormId);
    cleanupRetainedValue();
    pristineClearDebounced();
  };

  const { data, loading } = useReactiveQuery(
    projectEstimateQuery,
    PROJECT_DETAILS_SUB,
    {
      queryOptions: {
        fetchPolicy: 'network-only',
        variables: {
          projectId,
          input: {
            size: MAX_ITEMS,
            query: {
              bool: {
                must: [{ match: { status: UserStatuses.ACTIVE } }],
              },
            },
          },
        },
      },
      subscriptionOptions: { variables: { input: { id: projectId } } },
    },
  );

  const project = useMemo(() => data?.project, [data]);

  const estimate = useMemo(
    () => getEstimateById(estimateId)(project),
    [project, estimateId],
  );

  const onSubmit = async (values) => {
    const formattedValues =
      formatProjectEstimateMutationInput(isManualMode)(values);

    const input = { ...formattedValues, estimateNumber };

    const mutate = estimate ? updateProjectEstimate : addProjectEstimate;

    const variables = estimate ? { id: estimateId, update: input } : { input };

    await mutate({ variables });

    const successMessage = `Estimate was ${
      estimate ? 'updated' : 'created'
    } successfully`;

    showSuccessNotification(successMessage);
    onClose();
  };

  const { onSubmit: onSubmitHandler } = useOnSubmitSetStopSubmitting(
    projectEstimateFormId,
    onSubmit,
  );

  useEffect(() => {
    if (!estimate && checkIsCalculateMode(project)) {
      setIsManualMode(false);
    }
  }, [project, estimate]);

  const clintConfigs = useMemo(() => getClientConfigs(project), [project]);

  if (loading) {
    return <Loader />;
  }

  const initialValues = getEstimateInitialValues({
    ...project,
    estimate,
    persistScopeOfWork,
  });

  const formTitle = estimate ? 'Edit Estimate' : 'Create Estimate';

  return (
    <SidebarFormLayout
      title={formTitle}
      onClose={onClose}
      submitButtonCaption="Save"
      formId={projectEstimateFormId}
    >
      <ClientConfigsContext.Provider value={clintConfigs}>
        <ProjectEstimateForm
          isEditMode={!!estimate}
          onSubmit={onSubmitHandler}
          isManualMode={isManualMode}
          initialValues={initialValues}
          setIsManualMode={setIsManualMode}
          onChangePersistentValue={onChangePersistentValue}
        />
      </ClientConfigsContext.Provider>
    </SidebarFormLayout>
  );
}

ProjectEstimateSidebarForm.propTypes = {
  estimateId: string,
  estimateNumber: number,
  projectId: string.isRequired,
};
