import React from 'react';
import { ServiceTypeStatuses } from '@poly/constants';
import { openPrintWindowWithData } from '@poly/client-utils';
import { CommonPrintLayout } from '@poly/admin-ui';
import { ServiceTypesPrintTable } from '@poly/admin-ui/src/modules/tables/ServiceTypesTable/ServiceTypesTable.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { useRouterQuery } from '@poly/client-routing';

import { useServiceTypesLazyQuery } from './useServiceTypesLazyQuery.js';
import {
  getServiceTypesExcelConfig,
  getServiceTypesExportFileName,
} from './getServiceTypesExcelConfig.js';
import { PrintExportButtons } from '../../components/ExportButtons.js';

export function ServiceTypesExportButtons() {
  const { tab } = useRouterQuery(['tab']);

  const status = tab || ServiceTypeStatuses.active;

  const { runServiceTypesQuery, loading } = useServiceTypesLazyQuery(status);

  const handlePrintPDF = async () => {
    const serviceTypes = await runServiceTypesQuery();

    await openPrintWindowWithData({
      fileName: getServiceTypesExportFileName(status, 'pdf'),
      Layout: CommonPrintLayout,
      Table: ServiceTypesPrintTable,
      metaData: {
        title: 'Service Types',
      },
      serviceTypes,
    });
  };

  const handleExportXLS = async () => {
    const serviceTypes = await runServiceTypesQuery();

    const config = getServiceTypesExcelConfig(serviceTypes, status);
    return performExcelExport(config);
  };

  return (
    <PrintExportButtons
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
      loading={loading}
    />
  );
}
