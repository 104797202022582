import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { MAX_ITEMS } from '@poly/admin-ui';

import { PROJECTS_BY_SEARCH } from '../../modules/core/hooks/projects/queries.js';

export const useLazyProjectQuery = ({ query, sort }) => {
  const searchTerm = useSelector(R.prop('searchText'));

  const [loading, setLoading] = useState(false);

  const searchInput = useMemo(
    () => ({
      query,
      searchTerm,
      sort,
      size: MAX_ITEMS,
    }),
    [searchTerm, sort, query],
  );

  const [runQuery] = useLazyQuery(PROJECTS_BY_SEARCH);

  const runProjectsQuery = async () => {
    setLoading(true);
    const { data } = await runQuery({
      variables: { searchInput },
    });
    setLoading(false);
    return R.pathOr([], ['searchProjects', 'hits'], data);
  };

  return {
    loading,
    runProjectsQuery,
  };
};
