import React from 'react';
import { css } from 'styled-components';
import { shape, string } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import { SubCategoryReportTable } from './SubCategoryReportTable.js';
import { ExportPDFBtn } from '../WorkInProgressReport/WorkInProgressExportPDFBtn.js';
import { useSubCategoryReportLazyQuery } from './useSubCategoryReport.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';

const subCategoryReportPdfStyles = css`
  @page {
    size: landscape;
  }
`;

export function SubCategoryReportPdfExportButton({ filter, ...props }) {
  const Layout = usePrintLayout();
  const queryHandler = useSubCategoryReportLazyQuery();

  const onPrintPDFClick = async () => {
    const report = await queryHandler({ filter });

    await openPrintWindowWithData({
      Layout,
      isPrint: true,
      reportItems: report,
      Table: SubCategoryReportTable,
      fileName: 'sub_category_report',
      customStyles: subCategoryReportPdfStyles,
      metaData: { title: 'Sub-Category Report' },
    });
  };

  return (
    <ExportPDFBtn {...props} onClick={onPrintPDFClick}>
      {PRINT_PDF_CAPTION}
    </ExportPDFBtn>
  );
}

export const subCategoryReportFilterPropTypes = shape({
  client: string,
  project: string,
  supplier: string,
  invoiceDate: shape({ startDate: string, endDate: string }),
});

SubCategoryReportPdfExportButton.propTypes = {
  filter: subCategoryReportFilterPropTypes,
};
