import * as R from 'ramda';
import React from 'react';
import { LinkButtonWithLoader } from '@poly/admin-book';
import { arrayOf, object, oneOfType, string } from 'prop-types';
import {
  formatDate,
  getUserWorkPhoneNumber,
  getUserWorkPhoneNumberExt,
} from '@poly/utils';
import {
  performExcelExport,
  EXPORT_XLS_CAPTION,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';

import { getUserGroupsUIString } from '../../tables/columns/users.js';
import { useStaffDirectoryLazyQuery } from '../../core/hooks/users/useStaffDirectoryLazyQuery.js';

// getStaffDirectoryRowConfig :: Supplier -> [ExcelExportDataCell]
const getStaffDirectoryRowConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 7)),
  R.juxt([
    R.propOr('', 'fullName'),
    getUserGroupsUIString,
    getUserWorkPhoneNumber,
    getUserWorkPhoneNumberExt,
    R.propOr('', 'email'),
    R.ifElse(
      R.pathSatisfies(R.isNil, ['employeeInfo', 'startDate']),
      R.always(''),
      R.compose(formatDate, R.path(['employeeInfo', 'startDate'])),
    ),
    R.propOr('', 'companyLocation'),
  ]),
);

export const getStaffDirectoryXlsExportConfig = ({
  users,
  exportFileName,
}) => ({
  exportFileName,
  columnWidths: [25, 25, 15, 10, 30, 10, 20],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: 'Staff Directory',
      },
    ],
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 7),
      [
        'Name',
        'User Group',
        'Phone',
        'Ext',
        'Email',
        'Hire Date',
        'Office Location',
      ],
    ),
    ...R.map(getStaffDirectoryRowConfig, users),
  ],
});

// getUsersByQueryData :: { searchUsers: { hits: [User] } } -> [User]
const getUsersByQueryData = R.pathOr([], ['searchUsers', 'hits']);

export function StaffDirectoryXlsExportButton({
  query,
  exportSort,
  exportFileName,
}) {
  const queryHandler = useStaffDirectoryLazyQuery();

  const onClick = async () => {
    const { data } = await queryHandler({ query, exportSort });
    const users = getUsersByQueryData(data);
    const excelConfig = getStaffDirectoryXlsExportConfig({
      users,
      exportFileName,
    });
    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}

StaffDirectoryXlsExportButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object,
  exportSort: arrayOf(oneOfType([string, object])),
  exportFileName: string,
};
