import * as R from 'ramda';
import { aminPurchaseOrderStatuses } from '@poly/constants';
import { isNilOrEmpty } from '@poly/utils';

import { getPurchaseOrdersStatusUI } from '../../../../PurchaseOrderSidebar/utils.js';

export const ADMIN_PO_PROPERTIES_WARNING_MSG =
  'The Purchase Order property does not match the project property';

// validateAdminPurchaseOrderStatus :: FormData -> Boolean
const validateAdminPurchaseOrderStatus = R.ifElse(
  R.path(['selectedAdminPO', 'status']),
  R.compose(
    R.equals(aminPurchaseOrderStatuses.ACTIVE),
    R.path(['selectedAdminPO', 'status']),
  ),
  R.T,
);

// getAdminPurchaseOrderPropertiesIds :: FormData -> [String]
const getAdminPurchaseOrderPropertiesIds = R.compose(
  R.pluck('_id'),
  R.defaultTo([]),
  R.path(['selectedAdminPO', 'properties']),
);

// validateAdminPurchaseOrderProperties :: FormData -> Boolean
const validateAdminPurchaseOrderProperties = R.ifElse(
  R.both(
    R.path(['property', '_id']),
    R.compose(R.complement(isNilOrEmpty), getAdminPurchaseOrderPropertiesIds),
  ),
  R.converge(R.includes, [
    R.path(['property', '_id']),
    getAdminPurchaseOrderPropertiesIds,
  ]),
  R.T,
);

// getAdminPurchaseOrderStatusWarningMsg :: FormData -> String
const getAdminPurchaseOrderStatusWarningMsg = R.compose(
  R.concat('The Purchase Order is '),
  getPurchaseOrdersStatusUI,
  R.pathOr('', ['selectedAdminPO', 'status']),
);

// getAdminPurchaseOrderWarningMessage :: FormData -> String
export const getAdminPurchaseOrderWarningMessage = R.cond([
  [
    R.complement(validateAdminPurchaseOrderStatus),
    getAdminPurchaseOrderStatusWarningMsg,
  ],
  [
    R.complement(validateAdminPurchaseOrderProperties),
    R.always(ADMIN_PO_PROPERTIES_WARNING_MSG),
  ],
  [R.T, R.always('')],
]);

// createAminPurchaseOrderValidateFn :: (FormData -> Boolean) -> (String, FormData) -> Boolean
const createAminPurchaseOrderValidateFn =
  (validateFn) => (adminPOId, formData) =>
    R.ifElse(R.always(adminPOId), validateFn, R.always(''))(formData);

// adminPurchaseOrderValidationFunc :: [(String, FormData) -> Boolean] -> String -> FormData -> String
export const adminPurchaseOrderValidationFunc = R.curry(
  (validators, value, allValues) =>
    R.compose(
      R.head,
      R.reject(isNilOrEmpty),
      R.map((validator) => validator(value, allValues)),
    )(validators),
);

export const adminPurchaseOrderValidators = [
  createAminPurchaseOrderValidateFn(getAdminPurchaseOrderWarningMessage),
];
