import React, { useState } from 'react';
import styled from 'styled-components';
import {
  MainHeader,
  ToolBarBtnDivider,
  PageHeaderContainer,
} from '@poly/admin-book';
import { SupplierStatuses } from '@poly/constants';
import { TableSearchInput } from '@poly/admin-ui';

import { SuppliersTab } from '../modules/tabs/suppliersTab/SuppliersTab.js';
import { SuppliersTabTitle } from '../modules/tabs/suppliersTab/SuppliersTabTitle.js';
import { AddNewSupplierBtn } from '../modules/tabs/suppliersTab/SuppliersTabToolbar.js';
import { getSubcontractorSuppliersQuery } from '../modules/core/searchQueries/suppliers.js';
import { PageTabs } from '../modules/tabs/TabsWithRouter.js';
import { SuppliersTabPdfExportButton } from '../modules/tabs/suppliersTab/SuppliersTabPdfExportButton.js';
import { SuppliersTabXlsExportButton } from '../modules/tabs/suppliersTab/SuppliersTabXlsExportButton.js';

const { ACTIVE, BLOCKED } = SupplierStatuses;

const PAGE_TITLE = 'Subcontractor Suppliers';

export const HeaderLinksWrapperS = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin: 0;
  }

  > div:nth-child(3) {
    margin-right: 40px;
  }
`;

export function SuppliersSubcontractorPage() {
  const [exportSort, setExportSort] = useState(null);
  const [currentTab, setCurrentTab] = useState(ACTIVE);

  const activeSuppliersQuery = getSubcontractorSuppliersQuery(ACTIVE);
  const blockedSuppliersQuery = getSubcontractorSuppliersQuery(BLOCKED);

  const exportQuery =
    currentTab === ACTIVE ? activeSuppliersQuery : blockedSuppliersQuery;

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>{PAGE_TITLE}</MainHeader>
        <HeaderLinksWrapperS>
          <SuppliersTabXlsExportButton
            title={PAGE_TITLE}
            query={exportQuery}
            exportSort={exportSort}
            showDateColumn={currentTab === ACTIVE}
            exportFileName={`subcontractor_suppliers_${currentTab}_export.xlsx`}
          />
          <ToolBarBtnDivider />
          <SuppliersTabPdfExportButton
            title={PAGE_TITLE}
            query={exportQuery}
            exportSort={exportSort}
            showDateColumn={currentTab === ACTIVE}
            exportFileName={`subcontractor_suppliers_${currentTab}_export`}
          />
          <AddNewSupplierBtn />
        </HeaderLinksWrapperS>
      </PageHeaderContainer>
      <PageTabs
        toolBar={<TableSearchInput />}
        tabs={[
          [
            <SuppliersTabTitle
              query={activeSuppliersQuery}
              status={SupplierStatuses.ACTIVE}
              key={ACTIVE}
            />,
            ACTIVE,
            <SuppliersTab
              query={activeSuppliersQuery}
              currentTab={ACTIVE}
              setExportSort={setExportSort}
              setCurrentTab={setCurrentTab}
              showDateColumn
              key={ACTIVE}
            />,
          ],
          [
            <SuppliersTabTitle
              status={SupplierStatuses.BLOCKED}
              query={blockedSuppliersQuery}
              key={BLOCKED}
            />,
            BLOCKED,
            <SuppliersTab
              query={blockedSuppliersQuery}
              currentTab={BLOCKED}
              setExportSort={setExportSort}
              setCurrentTab={setCurrentTab}
              key={BLOCKED}
            />,
          ],
        ]}
        defaultValue={ACTIVE}
      />
    </>
  );
}
