import * as R from 'ramda';
import React from 'react';
import arrayMutators from 'final-form-arrays';
import { func, string } from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';
import { useOutSidebarContext } from '@poly/client-utils';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormCreator, Textarea } from '@poly/admin-book';
import {
  MAX_ITEMS,
  commonSidebarFormLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormSectionLayout,
} from '@poly/admin-ui';

import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';
import { FieldsToEditLine } from './FieldsToEditLine.js';
import { BulkEditActionButtons } from './components/BulkEditActionButtons.js';
import { useBulkEditingResultModal } from './useBulkEditingResultModal.js';
import { BulkEditingFormId, BulkEditingSidebarId } from './constants.js';
import {
  bulkEditDocumentsPropType,
  bulkEditQuerySortPropType,
  bulkEditQueryInputPropType,
} from './prop-types.js';
import {
  BulkEditingPageTitleS,
  BulkEditingSidebarHeaderS,
  BulkEditingSidebarWrapperS,
} from './components/styled-components.js';

const bulkEditProjectIdsQuery = gql`
  query bulkEditProjectIdsQuery($input: CollectionSearchParams) {
    searchProjects(input: $input) {
      hits {
        _id
      }
    }
    searchRecurringProjects(input: $input) {
      hits {
        _id
      }
    }
  }
`;

const getBulkEditingFormSections = (objectType) => [
  {
    order: 1,
    layout: { padding: 0 },
    fields: [
      {
        order: 1,
        layout: { row: 1, width: '100%' },
        field: {
          name: 'fieldsToEdit',
          isArrayField: true,
          withChangeFieldValue: true,
          Component: FieldsToEditLine,
          additionalProps: { objectType },
        },
      },
      {
        order: 2,
        label: 'Update Reason',
        required: true,
        layout: { row: 2, width: '100%', padding: '10px 24px' },
        field: {
          name: 'updateReason',
          Component: Textarea,
        },
        validators: [
          [
            R.both(R.is(String), R.compose(R.gt(R.__, 0), R.length, R.trim)),
            'Update Reason is required',
          ],
        ],
      },
      {
        order: 3,
        layout: { row: 3, width: '100%', margin: '0 0 0 24px' },
        field: {
          name: 'actionButtons',
          withFormData: true,
          withChangeFieldValue: true,
          Component: BulkEditActionButtons,
        },
      },
    ],
  },
];

// getTargetDocumentIdsByQueryData :: { searchProjects: { hits: [Project] }, searchRecurringProjects: { hits: [RecurringProject] } } -> [ID]
const getTargetDocumentIdsByQueryData = R.converge(R.concat, [
  R.compose(
    R.map(R.prop('_id')),
    R.pathOr([], ['searchRecurringProjects', 'hits']),
  ),
  R.compose(R.map(R.prop('_id')), R.pathOr([], ['searchProjects', 'hits'])),
]);

export function BulkEditingSidebar({
  querySort,
  queryInput,
  objectType,
  setDocuments,
  setQueryInput,
  setDocumentsCount,
}) {
  const [getTargetProjectIdsQuery] = useLazyQuery(bulkEditProjectIdsQuery);
  const { closeOutSidebar } = useOutSidebarContext();
  const showBulkEditingResultModal = useBulkEditingResultModal();
  const pristineSubscribeProps = usePristineSubscribe({
    id: BulkEditingSidebarId,
  });

  const onClose = () => closeOutSidebar(BulkEditingSidebarId);

  const submitHandler = async (formData) => {
    if (R.isNil(queryInput)) {
      return null;
    }

    const { data } = await getTargetProjectIdsQuery({
      variables: {
        input: {
          ...queryInput,
          from: 0,
          size: MAX_ITEMS,
          sort: querySort,
        },
      },
      fetchPolicy: 'network-only',
    });

    const targetDocumentIds = getTargetDocumentIdsByQueryData(data);

    if (R.isEmpty(targetDocumentIds)) {
      return null;
    }

    onClose();

    return showBulkEditingResultModal({
      formData,
      objectType,
      setDocuments,
      setQueryInput,
      setDocumentsCount,
      targetDocumentIds,
    });
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    BulkEditingFormId,
    submitHandler,
  );

  const initialValues = { fieldsToEdit: [{}, {}] };

  return (
    <BulkEditingSidebarWrapperS>
      <BulkEditingSidebarHeaderS>
        <BulkEditingPageTitleS>Bulk Edit</BulkEditingPageTitleS>
        <SidebarCloseIcon onClick={onClose} />
      </BulkEditingSidebarHeaderS>
      <FormCreator
        initialValues={initialValues}
        onSubmit={onSubmit}
        id={BulkEditingFormId}
        mutators={arrayMutators}
        layout={commonSidebarFormLayout}
        sections={getBulkEditingFormSections(objectType)}
        fieldLayout={commonSidebarFormFieldLayout}
        sectionLayout={commonSidebarFormSectionLayout}
        {...pristineSubscribeProps}
      />
    </BulkEditingSidebarWrapperS>
  );
}

BulkEditingSidebar.propTypes = {
  documents: bulkEditDocumentsPropType,
  querySort: bulkEditQuerySortPropType,
  objectType: string,
  queryInput: bulkEditQueryInputPropType,
  setDocuments: func,
  setQueryInput: func,
  setDocumentsCount: func,
};
