import React from 'react';
import * as R from 'ramda';
import { endOfDay } from 'date-fns';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';
import { shape, number, bool, func, string } from 'prop-types';
import { JournalTypes } from '@poly/constants';
import {
  openPrintWindowWithData,
  applyPathOrNothingUI,
  sqlSortQuery,
} from '@poly/client-utils';
import {
  useMapConfigToTableProps,
  useNotificationState,
  CommonPrintLayout,
  useModalContext,
} from '@poly/admin-ui';
import {
  formatManualJournalNumber,
  alwaysNewDate,
  formatTotal,
  formatDate,
  applyProp,
  toDate,
  propEqLegacy,
} from '@poly/utils';
import {
  getThemeColor,
  defaultTheme,
  FormCreator,
  LinkButton,
  DatePicker,
  Popover,
  Loader,
} from '@poly/admin-book';

import {
  JournalEntryLedgerTableS,
  JournalEntryLedgerPrintTableS,
} from './styled.js';
import { JournalEntryLineTypes } from './constants.js';
import { YellowWarningIcon } from '../../components/Icons.js';
import { commonModalLayout } from '../../modules/forms/common.js';
import { journalEntryLedgerResultPropTypes } from './prop-types.js';
import { JournalEntryReportTablePDF } from './JournalEntryReportTablePDF.js';
import { getCreatorNameUIByJournal } from '../../sidebars/TransactionDetailsSidebar/helpers.js';
import {
  calculateJournalEntryTotalByType,
  prepareJournalEntryLinesByPath,
  extractLinesAccountCodes,
  renameNetAmountProp,
} from './journalEntryUtils.js';

function JournalEntryLink(entry) {
  const onJournalEntryClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      fileName: 'journal_entry_report',
      data: renameNetAmountProp(entry),
      Table: JournalEntryReportTablePDF,
      metaData: { title: 'Journal Entry Report' },
    });

  return (
    <LinkButton onClick={onJournalEntryClick}>
      {formatManualJournalNumber(entry.transaction_number)}
    </LinkButton>
  );
}

const reverseJournalsMutation = gql`
  mutation reverseJournalsMutation($input: ReverseJournalInput!) {
    reverseJournal(input: $input) {
      _id
    }
  }
`;

const {
  colors: { primaryRegular },
} = defaultTheme;
const reverseJournalFormIdId = 'reverseJournalFormIdId';
const reconciledWarningMassage = 'JE was reconciliated. It cannot be reversed.';
const savedForReconciliationWarningMassage =
  'JE was saved for reconciliation. It cannot be reversed until deselected from reconciliation.';

const PopoverContent = styled.div`
  padding: 10px;
  font-size: 12px;
  max-width: 280px;
  line-height: 18px;
  border-radius: 5px;
  color: ${getThemeColor(['white'])};
  background: ${getThemeColor(['primaryRegular'])};
`;

function JournalEntryReverseBtn({
  _id,
  date,
  type,
  refetch,
  wasReconciled,
  reverse_journal,
  hasReconciledStatus,
}) {
  const { openModalForm, closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [postReverseJournal] = useMutation(reverseJournalsMutation);

  const onSubmit = async (formData) => {
    await postReverseJournal({
      variables: {
        input: {
          journalId: _id,
          reverseDate: endOfDay(formData.reverseDate),
        },
      },
    });

    showSuccessNotification('Journal reversed successfully');

    closeModal(reverseJournalFormIdId);

    refetch();
  };

  const onClick = () => {
    openModalForm({
      btnCaption: 'Reverse',
      title: 'Reverse Journal',
      id: reverseJournalFormIdId,
      formId: reverseJournalFormIdId,
      content: (
        <FormCreator
          validateOnBlur
          onSubmit={onSubmit}
          layout={commonModalLayout}
          id={reverseJournalFormIdId}
          initialValues={{ reverseDate: alwaysNewDate() }}
          sections={[
            {
              order: 1,
              layout: { column: 1 },
              fields: [
                {
                  order: 1,
                  label: 'Date of reversal',
                  field: {
                    name: 'reverseDate',
                    Component: DatePicker,
                    additionalProps: {
                      width: '100%',
                      disabledDays: { before: toDate(date) },
                    },
                  },
                },
              ],
            },
          ]}
        />
      ),
    });
  };

  if (type === JournalTypes.REVERSE) {
    return null;
  }

  if (reverse_journal) {
    return formatDate(reverse_journal.date);
  }

  if (hasReconciledStatus) {
    return (
      <YellowWarningIcon
        iconSize={17}
        position="left"
        bgColor={primaryRegular}
        content={
          <PopoverContent>
            {wasReconciled
              ? reconciledWarningMassage
              : savedForReconciliationWarningMassage}
          </PopoverContent>
        }
      />
    );
  }

  return <LinkButton onClick={onClick}>Reverse</LinkButton>;
}

JournalEntryReverseBtn.propTypes = {
  date: string,
  _id: string.isRequired,
  type: string.isRequired,
  refetch: func.isRequired,
  wasReconciled: bool.isRequired,
  hasReconciledStatus: bool.isRequired,
  reverse_journal: shape({ date: string.isRequired }),
};

// isCodesTruncated :: [String] -> Boolean
const isCodesTruncated = R.compose(R.gte(R.__, 8), R.length, R.split(','));

const TruncatedCodeS = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  color: #000;
  font-size: 12px;
  overflow: hidden;
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
`;

const CodesS = styled.div`
  padding: 5px;
  max-width: 400px;
`;

function AccountCodeComp({ journalEntryLineType, isPrint, ...props }) {
  const codes = extractLinesAccountCodes(
    propEqLegacy('type', journalEntryLineType),
  )(props);

  if (isCodesTruncated(codes) && !isPrint) {
    return (
      <Popover
        position="left"
        content={<CodesS>{codes}</CodesS>}
        title={<TruncatedCodeS>{codes}</TruncatedCodeS>}
      />
    );
  }

  return <div>{codes}</div>;
}

AccountCodeComp.propTypes = {
  isPrint: bool,
  journalEntryLineType: string.isRequired,
};

export const getJournalEntriesTableConfig = (isPrint, refetch) => [
  ['JE #', JournalEntryLink, sqlSortQuery('transaction_number', 'numeric')],
  [
    'Created By',
    getCreatorNameUIByJournal,
    sqlSortQuery('created_by_full_name'),
  ],
  [
    '',
    R.ifElse(
      propEqLegacy('type', JournalTypes.RECURRING),
      R.always('Recurring'),
      R.always(''),
    ),
  ],
  ['Date', applyProp(formatDate)('date'), sqlSortQuery('date', 'date')],
  [
    'Description',
    R.either(R.prop('description'), R.path(['lines', 0, 'description'])),
    sqlSortQuery('description'),
  ],
  [
    'Debit account codes',
    (props) => (
      <AccountCodeComp
        {...props}
        isPrint={isPrint}
        journalEntryLineType={JournalEntryLineTypes.DEBIT}
      />
    ),
    sqlSortQuery('debit_codes'),
  ],
  [
    'Credit account codes',
    (props) => (
      <AccountCodeComp
        {...props}
        isPrint={isPrint}
        journalEntryLineType={JournalEntryLineTypes.CREDIT}
      />
    ),
    sqlSortQuery('credit_codes'),
  ],
  [
    'Debit',
    R.compose(
      formatTotal,
      calculateJournalEntryTotalByType(JournalEntryLineTypes.DEBIT),
    ),
    sqlSortQuery('sort_amount', 'numeric'),
  ],
  [
    'Credit',
    R.compose(
      formatTotal,
      calculateJournalEntryTotalByType(JournalEntryLineTypes.CREDIT),
    ),
    sqlSortQuery('sort_amount', 'numeric'),
  ],
  [
    'Date of reversal',
    (journal) =>
      isPrint ? (
        applyPathOrNothingUI(['reverse_journal', 'date'], formatDate)(journal)
      ) : (
        <JournalEntryReverseBtn isPrint {...journal} refetch={refetch} />
      ),
    sqlSortQuery('reversed_date', 'date'),
  ],
];

export function JournalEntryLedgerTable({
  data,
  refetch,
  loading,
  isPrint,
  tableProps,
}) {
  const Table = isPrint
    ? JournalEntryLedgerPrintTableS
    : JournalEntryLedgerTableS;

  const mapConfigTableProps = useMapConfigToTableProps(
    prepareJournalEntryLinesByPath(['getManualJournals', 'hits']),
    getJournalEntriesTableConfig(isPrint, refetch),
    data,
  );

  return loading ? (
    <Loader />
  ) : (
    <Table
      {...mapConfigTableProps}
      {...tableProps}
      showScrollBar
      itemSize={56}
    />
  );
}

JournalEntryLedgerTable.propTypes = {
  refetch: func,
  loading: bool.isRequired,
  isPrint: bool.isRequired,
  tableProps: shape({
    onScroll: func,
    isLoading: bool,
    itemCount: number,
    loadedCount: number,
    loadMoreItems: func,
  }),
  data: shape({
    getManualJournals: journalEntryLedgerResultPropTypes,
  }),
};
