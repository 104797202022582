import * as R from 'ramda';
import { number } from 'prop-types';
import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import { applyProp, formatTotal, formatDate, propEqLegacy } from '@poly/utils';
import { LinkButton } from '@poly/admin-book';
import {
  useTableInfiniteScrollQuery,
  keywordMultiFieldSortQuery,
  commonMultiFieldSortQuery,
  keywordNestedSortQuery,
  useOutSidebarContext,
  useSortableTable,
  commonSortQuery,
  pathOrNothingUI,
} from '@poly/client-utils';
import {
  JournalPaymentStatus,
  ELASTIC_SCORE_FIELD,
  NOTHING_UI_STRING,
} from '@poly/constants';

import { RedSpan } from '../../modules/tables/tablesStyles.js';
import { transactionsDetailsSidebarId } from '../../sidebars/TransactionDetailsSidebar/helpers.js';
import { TransactionsDetailsSidebar } from '../../sidebars/TransactionDetailsSidebar/TransactionDetailsSidebar.js';
import { getVoidSupplierPaymentsSearchQueryByFilters } from './helpers.js';

const supplierPaymentsMoneyTransactionsQuery = gql`
  query supplierPaymentsMoneyTransactionsQuery(
    $input: CollectionSearchParams!
  ) {
    searchMoneyTransactions(input: $input) {
      hits {
        _id
        transaction_number
        check_number
        is_split
        supplierCheck {
          isPrinted
        }
        date
        masterSupplier {
          _id
          company {
            name
          }
        }
        supplier {
          _id
          payByMaster
          company {
            name
          }
          tax {
            id
          }
        }
        lines {
          amount
          account {
            _id
            name
            code
          }
          payment_status
        }
      }
      total
    }
  }
`;

function SplitPaymentComponent({ transaction_number }) {
  const { openOutSidebar } = useOutSidebarContext();

  const onClick = () =>
    openOutSidebar({
      id: transactionsDetailsSidebarId,
      content: (
        <TransactionsDetailsSidebar transactionNumber={transaction_number} />
      ),
      width: 950,
      alwaysFirst: true,
    });

  return <LinkButton onClick={onClick}>Split</LinkButton>;
}

SplitPaymentComponent.propTypes = { transaction_number: number };

// getMasterSupplierName :: { masterSupplier: MasterSupplier } -> String
const getMasterSupplierName = R.pathOr(NOTHING_UI_STRING, [
  'masterSupplier',
  'company',
  'name',
]);

function MasterSupplierNameWithLink(props) {
  return (
    <div>
      {getMasterSupplierName(props)} (<SplitPaymentComponent {...props} />)
    </div>
  );
}

function CreditAmountC({ amount }) {
  return <RedSpan>({formatTotal(amount)})</RedSpan>;
}

CreditAmountC.propTypes = { amount: number };

export const batchPaymentsTableConfig = [
  ['Date', applyProp(formatDate)('date'), commonSortQuery(['date'])],
  [
    'Supplier Name',
    R.ifElse(
      R.prop('is_split'),
      MasterSupplierNameWithLink,
      R.path(['supplier', 'company', 'name']),
    ),
    commonMultiFieldSortQuery([['is_split'], ['payee_name_column']]),
  ],
  [
    'Supplier Tax ID #',
    pathOrNothingUI(['supplier', 'tax', 'id']),
    keywordMultiFieldSortQuery([['supplier_tax_id']]),
  ],
  [
    'Bank',
    pathOrNothingUI(['account', 'name']),
    keywordNestedSortQuery(['lines', 'account_name']),
  ],
  [
    'Check Number',
    R.ifElse(
      propEqLegacy('payment_status', JournalPaymentStatus.RECONCILED),
      R.always('Reconciled'),
      pathOrNothingUI(['check_number']),
    ),
    (order) => [
      ...keywordNestedSortQuery(['lines', 'payment_status'])(order),
      ...keywordMultiFieldSortQuery([['check_number']])(order),
    ],
  ],
  [
    'Amount',
    R.ifElse(
      R.propSatisfies(R.gt(0), 'amount'),
      CreditAmountC,
      R.compose(formatTotal, R.prop('amount')),
    ),
    (order) => [
      ELASTIC_SCORE_FIELD,
      {
        _script: {
          order,
          type: 'number',
          nested: { path: 'lines' },
          script: {
            lang: 'painless',
            source:
              "doc['lines.amount'].size() > 0 ? doc['lines.amount'].stream().mapToDouble(v -> v).sum() : 0",
          },
        },
      },
    ],
  ],
];

export const useVoidSupplierPaymentsQuery = (query) => {
  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: batchPaymentsTableConfig,
  });

  const input = useMemo(
    () => ({
      query: getVoidSupplierPaymentsSearchQueryByFilters(query),
      sort,
    }),
    [query, sort],
  );

  const { data, loading, refetch, tableProps } = useTableInfiniteScrollQuery(
    supplierPaymentsMoneyTransactionsQuery,
    input,
    {
      pageSize: 500,
      endpointName: 'searchMoneyTransactions',
      skip: R.isEmpty(query),
    },
  );

  return {
    data,
    loading,
    refetch,
    tableProps: { ...tableSortProps, ...tableProps },
  };
};
