import React from 'react';
import { object, bool, func } from 'prop-types';
import { TableCard, TableWithPaginationContainer } from '@poly/admin-book';
import { TablePagination } from '@poly/admin-ui/src/components/Pagination.js';

import { StaffTable } from '../../tables/usersTable/UsersTable.js';

export function StaffTabComp({ paginationVisible, setExportSort, query }) {
  return (
    <TableCard>
      <TableWithPaginationContainer {...{ paginationVisible }}>
        <StaffTable {...{ query, setExportSort }} />
      </TableWithPaginationContainer>
      {paginationVisible && <TablePagination />}
    </TableCard>
  );
}

StaffTabComp.propTypes = {
  paginationVisible: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  query: object.isRequired,
  setExportSort: func,
};
