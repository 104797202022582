import React from 'react';
import {
  func,
  string,
  object,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';

import { useRuleValueComponent } from './useRuleValueComponent.js';

export function FieldValueComponent({
  onChange,
  ruleField,
  objectType,
  ruleFieldOperation,
  ...props
}) {
  const { Component, ...componentProps } = useRuleValueComponent({
    ruleField,
    objectType,
    ruleFieldOperation,
  });

  return (
    <Component
      {...props}
      {...componentProps}
      required
      isClearable
      onChange={onChange}
      handleChange={onChange}
    />
  );
}

FieldValueComponent.propTypes = {
  value: oneOfType([string, arrayOf(object), instanceOf(Date)]),
  onChange: func,
  ruleField: string,
  objectType: string,
  ruleFieldOperation: string,
};
