import React from 'react';
import * as R from 'ramda';
import { func, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { ProblemCodeStatuses } from '@poly/constants';
import { Select } from '@poly/admin-book';
import {
  entityToOptionByLabelPath,
  useQueryWithSearch,
} from '@poly/client-utils';

export const PROBLEM_CODES_BY_SEARCH = gql`
  query PROBLEM_CODES_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchProblemCodes(input: $searchInput) {
      hits {
        _id
        name
      }
    }
  }
`;

const PROBLEM_CODE_DEFAULT_QUERY = gql`
  query PROBLEM_CODE_DEFAULT_QUERY($id: ID!) {
    problemCode(id: $id) {
      _id
      name
    }
  }
`;

export const PROBLEM_CODES_BY_SEARCH_SUB = gql`
  subscription PROBLEM_CODES_BY_SEARCH_SUB($input: CollectionSearchParams!) {
    searchProblemCodeChanged(input: $input) {
      id
      type
    }
  }
`;

// prepareProblemCodesOptions :: Input -> [Option]
// Input = {
//    problemCode: ProblemCode
//    searchProblemCodes: { hits: [ProblemCode] }
// }
const prepareProblemCodesOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.reject(R.isNil),
  R.flatten,
  R.juxt([R.prop('problemCode'), R.pathOr([], ['searchProblemCodes', 'hits'])]),
);

export function ProblemCodeSelect({ value, onChange, ...restProps }) {
  const {
    error,
    result,
    onSearchChange,
    loading: problemCodesLoading,
  } = useQueryWithSearch({
    withoutSkip: true,
    gqlSearchQuery: PROBLEM_CODES_BY_SEARCH,
    gqlSearchChangedQuery: PROBLEM_CODES_BY_SEARCH_SUB,
    query: { term: { status: ProblemCodeStatuses.ACTIVE } },
  });

  const { data, loading: defaultProblemCodeLoading } = useQuery(
    PROBLEM_CODE_DEFAULT_QUERY,
    {
      variables: { id: value },
      skip: !value,
    },
  );

  const selectProps = {
    name: 'ProblemCodeSelect',
    placeholder: 'Please select problem code',
    ...restProps,
    error,
    value,
    onChange,
    onInputChange: onSearchChange,
    options: prepareProblemCodesOptions({ ...data, ...result }),
    loading: problemCodesLoading || defaultProblemCodeLoading,
  };

  return <Select {...selectProps} />;
}

ProblemCodeSelect.propTypes = {
  value: string,
  onChange: func.isRequired,
};
