import * as R from 'ramda';
import React, { useEffect } from 'react';
import { func, bool, shape, string, instanceOf } from 'prop-types';
import styled from 'styled-components';
import {
  DatePicker,
  Select,
  LinkButton,
  ToolBarBtnDivider,
} from '@poly/admin-book';
import { AccountingMethods, PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonPrintLayout } from '@poly/admin-ui';

import { openPrintWindowWithData } from '@poly/client-utils';

import { forceTitleCase, formatDate } from '@poly/utils';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { balanceSheetDataPropType } from './prop-types.js';
import { BalanceSheetTable } from './BalanceSheetTable.js';
import { BalanceSheetExportExcelButton } from './BalanceSheetExportExcelButton.js';

const BalanceSheetExportPDFBtnS = styled(LinkButton)`
  padding: 1px 0;
`;

const CenteredSubtitle = styled.div`
  text-align: center;
`;

function BalanceSheetExportPDFBtn({ data, filters }) {
  const onClick = async () => {
    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: BalanceSheetTable,
      fileName: 'balance_sheet',
      metaData: {
        title: 'Balance Sheet',
        additional: (
          <>
            <CenteredSubtitle>
              {`As of ${formatDate(filters.date)}`}
            </CenteredSubtitle>
            <CenteredSubtitle>{forceTitleCase(filters.type)}</CenteredSubtitle>
          </>
        ),
      },
      data,
    });
  };

  return (
    <BalanceSheetExportPDFBtnS onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </BalanceSheetExportPDFBtnS>
  );
}

const balanceSheetFiltersPropType = shape({
  date: instanceOf(Date).isRequired,
  type: string.isRequired,
});

BalanceSheetExportPDFBtn.propTypes = {
  data: balanceSheetDataPropType,
  filters: balanceSheetFiltersPropType,
};

const getBalanceSheetFiltersConfig = () => [
  { name: 'type', defaultValue: AccountingMethods.ACCRUAL_BASIS },
  { name: 'date', defaultValue: new Date() },
];

const balanceSheetTypeOptions = [
  { value: AccountingMethods.ACCRUAL_BASIS, label: 'Accrual Basis' },
  { value: AccountingMethods.CASH_BASIS, label: 'Cash Basis' },
];

// fixDatePickerMess :: {date: Any} -> {date: Date}
const fixDatePickerMess = R.over(
  R.lensProp('date'),
  R.when(R.is(String), R.constructN(1, Date)),
);

export function BalanceSheetHeader({ filters, setFilters, loading, data }) {
  const onSearchHandler = (values) => {
    setFilters(fixDatePickerMess(values));
  };

  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    getBalanceSheetFiltersConfig(),
    onSearchHandler,
  );

  const onResetSearch = () => {
    onReset();
  };

  useEffect(onSearch, []);

  const selectProps = {
    id: 'type',
    name: 'type',
    width: '100%',
    value: searchFilters.type,
    options: balanceSheetTypeOptions,
    onChange: handlers.type,
    required: true,
  };

  return (
    <SearchPageHeaderContainer title="Balance Sheet">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Type"
            titleWidth="50px"
            filterWidth="260px"
            labelForId="type"
          >
            <Select {...selectProps} />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="As Of"
            titleWidth="50px"
            filterWidth="260px"
            labelForId="date"
          >
            <DatePicker
              id="date"
              value={searchFilters.date}
              onChange={handlers.date}
              width="100%"
            />
          </SearchHeaderColumn>
          <FlexCenterAlign>
            <BalanceSheetExportPDFBtn data={data} filters={filters} />
            <ToolBarBtnDivider />
            <BalanceSheetExportExcelButton data={data} filters={filters} />
            <SearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={onResetSearch}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              onClick={onSearch}
              loader={loading}
            >
              Search
            </SearchHeaderButton>
          </FlexCenterAlign>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

BalanceSheetHeader.propTypes = {
  setFilters: func.isRequired,
  loading: bool,
  data: balanceSheetDataPropType,
  // we cannot use internal filters state here
  // as it can be out of date
  // (if search button wasn't pressed after filter change)
  filters: balanceSheetFiltersPropType,
};
