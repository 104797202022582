import React, { useState } from 'react';
import * as R from 'ramda';
import { bool, func, shape, string } from 'prop-types';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';
import { useLocation, useNavigate } from '@poly/client-routing';
import { insertQueryParamsIntoURL } from '@poly/utils';
import { projectEstimateReviewStatuses } from '@poly/constants';
import { Button, Textarea } from '@poly/admin-book';
import { FormField } from '@poly/admin-book/src/Form/FormField.js';
import { VENDOR_INFO_TYPES } from '@poly/admin-ui';

import { routesNames } from '../../routes/index.js';

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${R.propOr('flex-start', 'justifyContent')};
  height: ${R.propOr('0px', 'height')};
  gap: ${R.propOr('0px', 'gap')};
`;

const FormS = styled.form`
  margin: 20px 0px;
`;

const ButtonS = styled(Button)`
  height: 36px;
  padding-left: 40px;
  padding-right: 40px;
`;

const submitProjectEstimateReviewMutation = gql`
  mutation submitProjectEstimateReviewMutation(
    $input: ProjectEstimateReviewInput!
  ) {
    submitProjectEstimateReview(input: $input)
  }
`;

const RadioButtonS = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ff6363;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  :checked::before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #ff6363;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  label {
    font-size: 14px;
  }
`;

function RadioButton({ onChange, label, value, option, name }) {
  return (
    <RadioButtonContainer>
      <RadioButtonS
        value={option}
        type="radio"
        checked={value === option}
        onChange={() => onChange(option)}
        name={name}
      />
      <label>{label}</label>
    </RadioButtonContainer>
  );
}

RadioButton.propTypes = {
  onChange: func.isRequired,
  label: string.isRequired,
  value: string,
  option: string.isRequired,
  name: string.isRequired,
};

function ReviewProjectEstimateFormComp({ form, valid, handleSubmit, values }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (formData) => {
    setIsSubmitting(true);

    if (valid) {
      form.pauseValidation();
    }

    await handleSubmit(formData);
    if (valid) {
      form.restart();
    }
    setIsSubmitting(false);
  };

  const isCommentRequired =
    values?.status === projectEstimateReviewStatuses.ASKED_QUESTION;

  return (
    <FormS onSubmit={onSubmit}>
      <FlexContainer justifyContent="space-between" height="40px">
        <FlexContainer gap="20px">
          <FormField
            additionalProps={{
              option: projectEstimateReviewStatuses.APPROVED,
              label: 'Approve',
            }}
            name="status"
            Component={RadioButton}
          />
          <FormField
            additionalProps={{
              option: projectEstimateReviewStatuses.REJECTED,
              label: 'Reject',
            }}
            name="status"
            Component={RadioButton}
          />
        </FlexContainer>
        <FlexContainer justifyContent="flex-end">
          <FormField
            additionalProps={{
              option: projectEstimateReviewStatuses.ASKED_QUESTION,
              label: 'Ask question',
            }}
            name="status"
            Component={RadioButton}
            require={isCommentRequired}
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer height="80px">
        <FormField
          additionalProps={{
            charactersLimit: 500,
            showCharactersLeft: true,
            characterCountPosition: 'flex-end',
            placeholder: 'Leave a comment',
            required: isCommentRequired,
          }}
          name="comment"
          Component={Textarea}
        />
      </FlexContainer>
      <ButtonS disabled={isSubmitting} loader={isSubmitting} size="small">
        Send
      </ButtonS>
    </FormS>
  );
}

ReviewProjectEstimateFormComp.propTypes = {
  valid: bool,
  handleSubmit: func.isRequired,
  values: shape({}),
  form: shape({
    restart: func.isRequired,
    pauseValidation: func.isRequired,
  }),
};

export function ReviewProjectEstimateForm() {
  const navigate = useNavigate();

  const location = useLocation();

  const [submitProjectEstimateReview] = useMutation(
    submitProjectEstimateReviewMutation,
  );

  const initialValues = {
    status: projectEstimateReviewStatuses.APPROVED,
    comment: '',
  };

  const handleSubmit = async (values) => {
    await submitProjectEstimateReview({
      variables: { input: values },
    });

    navigate(
      insertQueryParamsIntoURL(
        { type: VENDOR_INFO_TYPES.PROJECT_ESTIMATE_SUBMITTED },
        routesNames.VENDOR_INFO,
      ),
      { state: { from: location.pathname } },
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={ReviewProjectEstimateFormComp}
    />
  );
}
