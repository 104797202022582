import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, number, shape, string } from 'prop-types';
import { READ_ACCOUNTS_RECEIVABLE_REPORT_PERMISSION } from '@poly/security';
import { SidebarRow, entities } from '@poly/admin-ui';
import { stringifyQuery } from '@poly/client-routing';
import { LinkButton } from '@poly/admin-book';
import {
  useHasUserAccessWithPermission,
  transformByPathOrNothingUI,
  pathOrNothingUI,
} from '@poly/client-utils';
import {
  ClientSpendReportTypes,
  NOTHING_UI_STRING,
  ProjectType,
} from '@poly/constants';
import {
  formatCurrencyForViewPath,
  insertParamIntoURL,
  forceTitleCase,
  isNilOrEmpty,
} from '@poly/utils';

import {
  SectionLabel,
  UnderlinedLink,
  BlockWithLabel,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../../components/commonSidebarComponents.js';
import { routesNames } from '../../../routes/index.js';
import { TabSectionWrapper } from './commonComponents.js';
import { WarningSection } from '../../../components/WarningSection.js';
import { useAddProjectSidebar } from '../../ProjectSidebar/forms/add/useAddProjectSidebar.js';
import { useHasUserAccessToReadAdminPO } from '../../PurchaseOrderSidebar/common-hooks.js';
import { formatAccountOptionText } from '../../../components/AccountsSelect.js';
import { getEntityAddressPreview } from '../../utils/address.js';
import {
  getSectionOutsideLink,
  getSectionEmail,
  getSectionText,
} from '../../components/commonSidebarSectionFormatters.js';
import {
  SPEND_REPORT_NON_TRANSPARENT_LABEL,
  SPEND_REPORT_TRANSPARENT_LABEL,
} from '../forms/form/components/ClientSpendReportSelect.js';

// transformTerms :: String -> String
const transformTerms = R.compose(
  R.join(' '),
  R.map(forceTitleCase),
  R.split('_'),
);

// prepareDivisionText :: Client -> String
const prepareDivisionText = R.compose(
  R.ifElse(isNilOrEmpty, R.always(NOTHING_UI_STRING), formatAccountOptionText),
  R.path(['financial', 'division']),
);

// prepareVaSplitPercent :: Client -> String
const prepareVaSplitPercent = R.compose(
  R.concat(R.__, ' %'),
  R.toString,
  R.pathOr(0, ['financial', 'vaSplitPercent']),
);

const LinkButtonS = styled(LinkButton)`
  text-decoration: underline;
`;

function InvoiceHQButton({ clientId, isCard }) {
  const openProjectSidebar = useAddProjectSidebar(
    isCard,
    entities.CLIENT,
    clientId,
  );

  const onClick = () =>
    openProjectSidebar({
      projectType: ProjectType.FEE,
      isPropertyDisabled: true,
      clientId,
    });

  return <LinkButtonS onClick={onClick}>Invoice Client HQ</LinkButtonS>;
}

InvoiceHQButton.propTypes = { clientId: string.isRequired, isCard: bool };

// getClientSpendReportType :: Client -> String
const getClientSpendReportType = R.compose(
  R.defaultTo(SPEND_REPORT_NON_TRANSPARENT_LABEL),
  R.prop(R.__, {
    [ClientSpendReportTypes.TRANSPARENT]: SPEND_REPORT_TRANSPARENT_LABEL,
    [ClientSpendReportTypes.NON_TRANSPARENT]:
      SPEND_REPORT_NON_TRANSPARENT_LABEL,
  }),
  R.path(['financial', 'spendReportType']),
);

// getClientInvoicingConfigs :: Client -> ClientInvoicing
const getClientInvoicingConfigs = R.path(['configs', 'clientInvoicing']);

// getClientSapGLCode :: Client -> String
const getClientSapGLCode = R.propOr(NOTHING_UI_STRING, 'sapGLCode');

export function FinancialTab({ client, isCard = false }) {
  const hasAccess = useHasUserAccessWithPermission(
    READ_ACCOUNTS_RECEIVABLE_REPORT_PERMISSION,
  );

  const isSalesTaxExempt = R.pathOr(
    false,
    ['financial', 'exemptSalesTax'],
    client,
  );

  const hasAccessReadAdminPO = useHasUserAccessToReadAdminPO();

  const invoiceAttentionToProjectRequestor = R.path(
    ['financial', 'invoiceAttentionToProjectRequestor'],
    client,
  );

  const warningText = 'This client is exempt from sales tax';

  return (
    <>
      {isSalesTaxExempt && (
        <WarningSection
          skipMargin
          iconSize={11}
          text={warningText}
          iconColor="#F79009"
          iconName="warning-info"
        />
      )}
      <TabSectionWrapper>
        <SidebarRow>
          <SectionLabel>Financial</SectionLabel>
        </SidebarRow>
        <SidebarRow {...threeBlocksWrapperProps}>
          <BlockWithLabel
            id="vendor"
            label="Our Vendor No"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['financial', 'ourvendorno'])(client),
            )}
          />
          <BlockWithLabel
            id="terms"
            label="Terms"
            {...threeBlocksProps}
            Component={getSectionText(
              transformByPathOrNothingUI(['financial', 'terms'])(
                transformTerms,
              )(client),
            )}
          />
          <BlockWithLabel
            id="invoice"
            label="Invoice To"
            {...threeBlocksProps}
            Component={getSectionText(
              transformByPathOrNothingUI(['financial', 'invoiceto'])(
                transformTerms,
              )(client),
            )}
          />
          <BlockWithLabel
            id="attention"
            label="Invoice Att To"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['financial', 'invoiceAttentionTo'])(client),
            )}
          />
          <BlockWithLabel
            id="projectRequestor"
            label="Attn Project Requestor"
            {...threeBlocksProps}
            Component={getSectionText(
              invoiceAttentionToProjectRequestor ? 'On' : 'Off',
            )}
          />
          <BlockWithLabel
            id="sapGLCode"
            label="SAP GL Code"
            {...threeBlocksProps}
            Component={getSectionText(getClientSapGLCode(client))}
          />
          <BlockWithLabel
            id="cost-center"
            label="Cost Center"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['financial', 'costCenter'])(client),
            )}
          />
          <BlockWithLabel
            id="division"
            label="Division"
            {...threeBlocksProps}
            Component={getSectionText(prepareDivisionText(client))}
          />
          <BlockWithLabel
            id="accountingEmail"
            label="Accounting Email"
            {...threeBlocksProps}
            Component={getSectionEmail(
              pathOrNothingUI(['financial', 'accountingEmail'])(client),
            )}
          />
          <BlockWithLabel
            id="portal-spend-report-type"
            label="Spend Report Type"
            {...threeBlocksProps}
            Component={getSectionText(getClientSpendReportType(client))}
          />
          <BlockWithLabel
            id="vaAccountNumber"
            label="VA Account #"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['financial', 'vaAccountNumber'])(client),
            )}
          />
          <BlockWithLabel
            id="vaSplitPercent"
            label="VA Split %"
            {...threeBlocksProps}
            Component={getSectionText(prepareVaSplitPercent(client))}
          />
        </SidebarRow>
        <SidebarRow>
          {!!client?.financial?.exemptSalesTax && (
            <BlockWithLabel
              {...threeBlocksProps}
              id="tax-exempt-file"
              label="Tax Exemption File"
              Component={getSectionOutsideLink(
                R.path(['financial', 'exemptSalesTaxFile', 'url'], client),
                R.path(['financial', 'exemptSalesTaxFile', 'fileName'], client),
              )}
            />
          )}
          <BlockWithLabel
            margin="0"
            id="address"
            label="Invoicing Address"
            Component={getSectionText(
              getEntityAddressPreview(getClientInvoicingConfigs(client)),
            )}
          />
        </SidebarRow>
        {hasAccess && (
          <SidebarRow>
            <UnderlinedLink
              href={`${routesNames.AR_AGING_REPORT}?${stringifyQuery({
                clientId: client._id,
              })}`}
            >
              {`AR Aging Report (${formatCurrencyForViewPath([
                'accountsReceivableBalance',
              ])(client)})`}
            </UnderlinedLink>
          </SidebarRow>
        )}
        {hasAccess && (
          <SidebarRow>
            <UnderlinedLink
              href={`${routesNames.CLIENT_INVOICE_HISTORY}?${stringifyQuery({
                clientId: client._id,
              })}`}
            >
              Invoice History
            </UnderlinedLink>
          </SidebarRow>
        )}
        <SidebarRow>
          <InvoiceHQButton clientId={client._id} isCard={isCard} />
        </SidebarRow>
        {client.enableGlCodes && (
          <SidebarRow>
            <UnderlinedLink
              href={insertParamIntoURL(
                'clientId',
                routesNames.CLIENT_GL_CODES,
                client._id,
              )}
            >
              GL Codes
            </UnderlinedLink>
          </SidebarRow>
        )}
        {client?.configs?.enablePurchaseOrder && hasAccessReadAdminPO && (
          <SidebarRow>
            <UnderlinedLink
              href={`${routesNames.PURCHASE_ORDERS}?${stringifyQuery({
                clientId: client._id,
              })}`}
            >
              PO Balance
            </UnderlinedLink>
          </SidebarRow>
        )}
      </TabSectionWrapper>
    </>
  );
}

FinancialTab.propTypes = {
  isCard: bool,
  client: shape({
    _id: string.isRequired,
    financial: shape({
      terms: string,
      invoiceto: string,
      costCenter: string,
      aacDivision: string,
      ourvendorno: string,
      vaAccountNumber: string,
      vaSplitPercent: number,
    }),
  }),
};
