import * as R from 'ramda';
import { applyPathOrNothingUI, pathOrNothingUI } from '@poly/client-utils';
import {
  formatDate,
  formatRawNumber,
  getUserWorkPhoneNumberExt,
  getUserWorkPhoneObject,
} from '@poly/utils';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { getUserGroupsUIString } from '../../modules/tables/columns/users.js';

// getStaffUsersRowConfig :: User -> [ExcelExportDataCell]
const getStaffUsersRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 7),
  ]),
  R.juxt([
    pathOrNothingUI(['fullName']),
    getUserGroupsUIString,
    R.compose(
      R.when(R.identity, formatRawNumber),
      R.propOr('', 'number'),
      getUserWorkPhoneObject,
    ),
    getUserWorkPhoneNumberExt,
    pathOrNothingUI(['email']),
    applyPathOrNothingUI(['employeeInfo', 'startDate'], formatDate),
    pathOrNothingUI(['companyLocation']),
  ]),
);

export const getXlsStaffUsersConfig = (users) => ({
  exportFileName: 'reassign_user_export.xls',
  columnWidths: [25, 25, 15, 10, 25, 15, 15],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 7)],
      [
        'Name',
        'User Group',
        'Phone',
        'Ext',
        'Email',
        'Hire Date',
        'Office Location',
      ],
    ),
    ...R.map(getStaffUsersRowConfig, users),
  ],
});
