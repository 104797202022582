import React from 'react';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';

import { PrintExportButtons } from '../../components/ExportButtons.js';
import { usePrintedSupplierBatchesLazyQuery } from './usePrintedSupplierBatches.js';
import { getPrintedSupplierBatchesExcelConfig } from './getPrintedSupplierBatchesExcelConfig.js';
import { DailySupplierInvoicesBatchTable } from './DailySupplierInvoicesBatchTable.js';

export function PrintedSupplierBatchesExportButtons(props) {
  const { runPrintedSupplierBatchesQuery, loading } =
    usePrintedSupplierBatchesLazyQuery(props);

  const handlePrintPDF = async () => {
    const printedSupplierBatches = await runPrintedSupplierBatchesQuery();

    await openPrintWindowWithData({
      printedSupplierBatches,
      Layout: CommonPrintLayout,
      Table: DailySupplierInvoicesBatchTable,
      fileName: 'daily_supplier_invoices_batch_export.pdf',
      metaData: { title: 'Daily Supplier Invoices Batch' },
      isPrint: true,
    });
  };

  const handleExportXLS = async () => {
    const printedSupplierBatches = await runPrintedSupplierBatchesQuery();

    const config = getPrintedSupplierBatchesExcelConfig(printedSupplierBatches);

    return performExcelExport(config);
  };

  return (
    <PrintExportButtons
      loading={loading}
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
    />
  );
}
