import * as R from 'ramda';
import { isNilOrEmpty } from '@poly/utils';
import { AccountingStatus, WorkOrderStatus } from '@poly/constants';
import { endOfDay, startOfDay } from 'date-fns';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  MAX_ITEMS,
  useSetItemsCount,
  usePaginationParams,
  ALL,
} from '@poly/admin-ui';

const { PENDING, READY_TO_INVOICE } = AccountingStatus;

const mondelezProjectRMStatusReportQuery = gql`
  query mondelezProjectRMStatusReportQuery(
    $searchInput: CollectionSearchParams!
  ) {
    searchProjects(input: $searchInput) {
      total
      hits {
        _id
        type
        notRM
        spendType
        projectId
        clientReferenceNumber
        adminPurchaseOrder {
          _id
          poNumber
          displayName
        }
      }
    }
  }
`;

const prepareMondelezProjectRMStatusReportInput = ({
  notRM,
  dateTo,
  dateFrom,
  spendType,
  projectId,
}) => ({
  query: {
    bool: {
      must: [
        { terms: { accountingStatus: [PENDING, READY_TO_INVOICE] } },
        { term: { 'client.cardNumber': '20507' } },
        ...(!spendType ? [] : [{ term: { spendType } }]),
        ...(!projectId ? [] : [{ term: { _id: projectId } }]),
        ...(notRM === ALL ? [] : [{ term: { notRM } }]),
      ],
      must_not: [{ term: { status: WorkOrderStatus.BLOCKED } }],
      ...(!dateFrom && !dateTo
        ? {}
        : {
            filter: [
              {
                range: {
                  startDate: {
                    ...(!dateFrom
                      ? {}
                      : { gte: startOfDay(new Date(dateFrom)) }),
                    ...(!dateTo ? {} : { lte: endOfDay(new Date(dateTo)) }),
                  },
                },
              },
            ],
          }),
    },
  },
});

export const useMondelezProjectRMStatusReportQuery = ({
  sort,
  queryFilters,
  isUserWithPermission,
}) => {
  const paginationParams = usePaginationParams();

  const { data, loading } = useQuery(mondelezProjectRMStatusReportQuery, {
    variables: {
      searchInput: {
        sort,
        ...prepareMondelezProjectRMStatusReportInput(queryFilters),
        ...paginationParams,
      },
    },
    fetchPolicy: 'network-only',
    skip: isNilOrEmpty(queryFilters) || !isUserWithPermission,
  });

  useSetItemsCount(R.pathOr(0, ['searchProjects', 'total']), data);

  return { data, loading };
};

export const useMondelezProjectRMStatusReportLazyQuery = () => {
  const [queryHandler] = useLazyQuery(mondelezProjectRMStatusReportQuery);

  const handler = async ({ filter, sort }) => {
    const response = await queryHandler({
      fetchPolicy: 'network-only',
      variables: {
        searchInput: {
          ...prepareMondelezProjectRMStatusReportInput(filter),
          sort,
          size: MAX_ITEMS,
        },
      },
    });

    return response;
  };

  return handler;
};
