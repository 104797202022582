import * as R from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';
import { LinkButtonWithLoader } from '@poly/admin-book';
import { arrayOf, object, string } from 'prop-types';
import { EXPORT_XLS_CAPTION, performExcelExport } from '@poly/client-utils';

import { getClientSidebarProjectsXlsExportConfig } from './getClientSidebarProjectsXlsExportConfig.js';

const LinkButtonWithLoaderS = styled(LinkButtonWithLoader)`
  width: 20px;
  min-width: unset;
  position: relative;
`;

export function ClientSidebarProjectsXlsExportButton({
  currentTab,
  foundProjects,
}) {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    const xlsConfig = getClientSidebarProjectsXlsExportConfig(
      currentTab,
      foundProjects,
    );

    performExcelExport(xlsConfig);
    setLoading(false);
  };

  return (
    <LinkButtonWithLoaderS
      disabled={R.isEmpty(foundProjects)}
      loader={loading}
      onClick={onClick}
    >
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoaderS>
  );
}

ClientSidebarProjectsXlsExportButton.propTypes = {
  currentTab: string,
  // eslint-disable-next-line react/forbid-prop-types
  foundProjects: arrayOf(object),
};
