import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { number, shape, string, bool, func } from 'prop-types';
import { CREATE_CONTACT_USER_PERMISSION } from '@poly/security';
import { formatCount, forceTitleCase } from '@poly/utils';
import { Loader, Status } from '@poly/admin-book';
import { peopleStatuses } from '@poly/constants';
import {
  useHasUserAccessWithPermission,
  useOutSidebarContext,
} from '@poly/client-utils';
import {
  SidebarTabTableWrapper,
  peopleStatusesColors,
  SidebarTabToolbar,
  useSearchTabLogic,
  SidebarTabHeader,
  useTableSorting,
  SearchInputComp,
  SidebarSubTabs,
  SidebarWidth,
  entities,
} from '@poly/admin-ui';

import {
  SectionLabel,
  DoubleTabsWrapper,
  CommonSidebarSubEntityTable,
} from '../commonSidebarComponents.js';
import { SidebarIDs } from '../../constants.js';
import { AddContactButton } from '../../ContactSidebar/forms/add/AddContactButton.js';
import { SidebarContactsTabXlsExportButton } from './SidebarContactsTabXlsExportButton/SidebarContactsTabXlsExportButton.js';
import {
  useSearchSubTabsContactsQuery,
  contactsTableConfig,
} from './useSearchSubTabsContactsQuery.js';

const ContactTabs = R.map(R.concat('contact-'), peopleStatuses);

const ContactsSubTabTableS = styled(CommonSidebarSubEntityTable)`
  th:last-child,
  td:last-child {
    width: 30px;
  }
`;

const DoubleTabsWrapperS = styled(DoubleTabsWrapper)`
  overflow-y: auto;
`;

const ContactsButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

// checkIsClientContact :: Entity -> Boolean
const checkIsClientContact = (entity) =>
  R.any(R.equals(entity.name), [entities.CLIENT, entities.PROPERTY]);

function CommonContactsTabTitle({ status, ...props }) {
  const { count } = useSearchSubTabsContactsQuery({ status, ...props });

  const title = `${forceTitleCase(status)} (${formatCount(count)})`;

  return <Status color={peopleStatusesColors[status]} text={title} />;
}

CommonContactsTabTitle.propTypes = { status: string.isRequired };

function CommonContactsTab({ tableSortingProps, ...props }) {
  const { loading, contacts, tableProps } =
    useSearchSubTabsContactsQuery(props);

  const { status, setCurrentTab, setFoundContacts } = props;

  useEffect(() => {
    if (setCurrentTab) {
      setCurrentTab(status);
    }
  }, [status]);

  useEffect(() => {
    if (setFoundContacts) {
      setFoundContacts(contacts);
    }
  }, [contacts?.length]);

  if (loading) return <Loader />;

  return <ContactsSubTabTableS {...tableProps} {...tableSortingProps} />;
}

CommonContactsTab.propTypes = {
  status: string,
  setCurrentTab: func,
  setFoundContacts: func,
  tableSortingProps: shape({ isSortable: bool }),
};

export function SidebarContactsTab({
  entity,
  isCard,
  tabWidth,
  tabTitlePrefix,
}) {
  const [currentTab, setCurrentTab] = useState(ContactTabs.ACTIVE);
  const [foundContacts, setFoundContacts] = useState([]);
  const { isSidebarOpened } = useOutSidebarContext();
  const { searchTerm, ...inputProps } = useSearchTabLogic('contacts');
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: contactsTableConfig,
    column: 1,
  });

  const hasPermissionToAddContact = useHasUserAccessWithPermission(
    CREATE_CONTACT_USER_PERMISSION,
  );

  const commonProps = {
    sort,
    entity,
    searchTerm,
    setCurrentTab,
    setFoundContacts,
  };

  const isBlurred = isSidebarOpened(SidebarIDs.contact);

  const titlePrefix = tabTitlePrefix || forceTitleCase(entity.name);

  const title = `${titlePrefix} Contacts`;

  return (
    <DoubleTabsWrapperS width={tabWidth} isCard={isCard} isBlurred={isBlurred}>
      <SidebarTabHeader justify>
        <SectionLabel>{title}</SectionLabel>
        <ContactsButtonsWrapper>
          {entity.name === entities.CLIENT && (
            <SidebarContactsTabXlsExportButton
              foundContacts={foundContacts}
              currentTab={currentTab}
            />
          )}
          {hasPermissionToAddContact && (
            <AddContactButton
              entity={entity}
              isCard={isCard}
              isClientContact={checkIsClientContact(entity)}
            />
          )}
        </ContactsButtonsWrapper>
      </SidebarTabHeader>
      <SidebarTabToolbar>
        <SearchInputComp {...inputProps} />
      </SidebarTabToolbar>
      <SidebarTabTableWrapper offset={96}>
        <SidebarSubTabs
          tabs={[
            [
              <CommonContactsTabTitle
                {...commonProps}
                status={peopleStatuses.ACTIVE}
              />,
              ContactTabs.ACTIVE,
              <CommonContactsTab
                {...commonProps}
                status={peopleStatuses.ACTIVE}
                tableSortingProps={tableSortingProps}
              />,
            ],
            [
              <CommonContactsTabTitle
                {...commonProps}
                status={peopleStatuses.INACTIVE}
              />,
              ContactTabs.INACTIVE,
              <CommonContactsTab
                {...commonProps}
                status={peopleStatuses.INACTIVE}
                tableSortingProps={tableSortingProps}
              />,
            ],
          ]}
          defaultValue={ContactTabs.ACTIVE}
        />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapperS>
  );
}

SidebarContactsTab.propTypes = {
  isCard: bool,
  tabWidth: number,
  tabTitlePrefix: string,
  entity: shape({ _id: string.isRequired, name: string.isRequired }).isRequired,
};

SidebarContactsTab.defaultProps = { tabWidth: SidebarWidth * 2 };
