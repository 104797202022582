import * as R from 'ramda';
import { useReactiveQuery } from '@poly/client-utils';
import { useMemo, useState } from 'react';
import { BulkEditObjectType } from '@poly/constants';
import {
  CLIENTS_BY_SEARCH_SUB,
  CLIENTS_SEARCH_FOR_SELECT,
} from '@poly/admin-ui';

import { defaultFieldValueComponentConfig } from '../FieldValueComponent/default-field-value-component-config.js';
import { getProjectFieldToEditValueComponentConfig } from './project-field-to-edit-value-component-config.js';

// getFieldToEditValueComponentConfig :: { objectType: String, selectedField: String } -> FieldConfig
// FieldConfig = Object
const getFieldToEditValueComponentConfig = R.ifElse(
  R.propEq(BulkEditObjectType.PROJECTS, 'objectType'),
  getProjectFieldToEditValueComponentConfig,
  R.always(defaultFieldValueComponentConfig),
);

export const useFieldToEditValueComponentProps = ({
  objectType,
  selectedField,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const {
    onChange,
    Component,
    getOptions,
    placeholder,
    gqlSearchQuery,
    getGqlQueryVariables,
    gqlSearchChangedQuery,
  } = getFieldToEditValueComponentConfig({
    objectType,
    selectedField,
  });

  const queryOptions = useMemo(
    () => ({
      variables: !getGqlQueryVariables ? {} : getGqlQueryVariables(searchTerm),
      skip: !gqlSearchQuery,
    }),
    [searchTerm, gqlSearchQuery, getGqlQueryVariables],
  );

  const { data } = useReactiveQuery(
    gqlSearchQuery || CLIENTS_SEARCH_FOR_SELECT,
    gqlSearchChangedQuery || CLIENTS_BY_SEARCH_SUB,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  return {
    Component,
    placeholder,
    ...(!onChange ? {} : { onChange }),
    ...(!getOptions ? {} : { options: getOptions(data) }),
    ...(!getOptions ? {} : { onInputChange: setSearchTerm }),
  };
};
