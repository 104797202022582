import * as R from 'ramda';
import styled from 'styled-components';
import { func, bool, node, string } from 'prop-types';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { insertQueryParamsIntoURL, renameProp } from '@poly/utils';
import { Checkbox, Select } from '@poly/admin-book';
import { Link } from '@poly/client-routing';
import {
  progressiveRangePickerOptions,
  progressiveOptionsToDates,
  ProgressiveRangePicker,
  MasterSupplierSelect,
  FinancialTermsSelect,
  ClientSelect,
  SupplierSelect,
  ServiceTypesSelect,
} from '@poly/admin-ui';

import {
  FlexColumn,
  FlexContainer,
  FlexCenterAlign,
} from '../../components/FlexContainer.js';
import { routesNames } from '../../routes/index.js';
import { FiltersContainer } from './PaySuppliersComponents.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { searchInvoicesFilters } from './paySuppliersConstants.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { InvoicesSelect } from '../../modules/selects/InvoicesSelect.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { PaySupplierInvoicesPdfExportBtn } from './PaySupplierInvoicesPdfExportBtn.js';
import { isCreditsMemoAvailable } from './paySuppliersUtils/payInvoicesUtils.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { paySupplierInvoiceFiltersPropTypes } from './propTypes.js';
import { PaySupplierInvoicesXlsExportBtn } from './PaySupplierInvoicesXlsExportBtn.js';
import { paySupplierOptions } from './paySuppliersUtils/generateSearchInvoicesQuery.js';
import { supplierPaymentTypeUICaptions } from './usePaySupplierInvoicesExportData.js';

const CreditsAvailableLinkS = styled(Link)`
  font-size: 15px;
`;

const FiltersColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

function CreditSAvailableLink() {
  return (
    <CreditsAvailableLinkS
      target="_blank"
      href={insertQueryParamsIntoURL(
        { creditsOnly: true },
        routesNames.SEARCH_SUPPLIER_INVOICES,
      )}
    >
      View open supplier credits
    </CreditsAvailableLinkS>
  );
}

const ResponsiveFiltersWrapper = styled(FlexSpaceBetween)`
  @media (max-width: 1700px) {
    flex-direction: column;
  }
`;

const ResponsiveContainer = styled(FlexColumn)`
  @media (max-width: 1700px) {
    flex-direction: row;
  }
`;

// prepareDueDateFilterValues :: FilterValues -> FilterValues
const prepareDueDateFilterValues = R.compose(
  renameProp('dueDateOption', 'option'),
  renameProp('endDueDate', 'endDate'),
  renameProp('startDueDate', 'startDate'),
);

const headerConfig = [
  {
    name: searchInvoicesFilters.invoiceDate,
    nestedFields: ['startDate', 'endDate', 'option'],
    defaultValue: {
      ...progressiveOptionsToDates[progressiveRangePickerOptions.NO_RANGE],
      option: progressiveRangePickerOptions.NO_RANGE,
    },
  },
  { name: searchInvoicesFilters.supplierId },
  {
    name: searchInvoicesFilters.masterSupplierId,
    subscribers: [[searchInvoicesFilters.supplierId, R.always('')]],
  },
  { name: searchInvoicesFilters.clientId },
  { name: searchInvoicesFilters.terms },
  { name: searchInvoicesFilters.paymentType },
  { name: searchInvoicesFilters.serviceTypeId },
  { name: searchInvoicesFilters.projectId },
  { name: searchInvoicesFilters.invoiceId },
  {
    name: searchInvoicesFilters.paymentDueDate,
    nestedFields: ['startDueDate', 'endDueDate', 'dueDateOption'],
    defaultValue: {
      ...progressiveOptionsToDates[progressiveRangePickerOptions.NO_RANGE],
      dueDateOption: progressiveRangePickerOptions.NO_RANGE,
    },
  },
  { name: searchInvoicesFilters.pastDueDate, defaultValue: true },
  { name: searchInvoicesFilters.showOnlyWarnings, defaultValue: false },
  { name: searchInvoicesFilters.w9Absent, defaultValue: false },
];

const FlexCenterAlignRelative = styled(FlexCenterAlign)`
  position: relative;
`;

const ButtonsContainer = styled(FlexContainer)`
  justify-content: flex-end;
  margin-bottom: 11px;
`;

function FilterContainer({ children, titleWidth, filterWidth, ...props }) {
  return (
    <SearchHeaderColumn
      titleWidth={titleWidth}
      filterWidth={filterWidth}
      {...props}
    >
      {children}
    </SearchHeaderColumn>
  );
}

FilterContainer.propTypes = {
  children: node.isRequired,
  titleWidth: string,
  filterWidth: string,
};

FilterContainer.defaultProps = {
  titleWidth: '110px',
  filterWidth: '215px',
};

const paymentTypeOptions = [
  {
    value: paySupplierOptions.CHECK,
    label: supplierPaymentTypeUICaptions[paySupplierOptions.CHECK],
  },
  {
    value: paySupplierOptions.PAY_BY_CREDIT_CARD,
    label: supplierPaymentTypeUICaptions[paySupplierOptions.PAY_BY_CREDIT_CARD],
  },
  {
    value: paySupplierOptions.PAY_BY_ACH,
    label: supplierPaymentTypeUICaptions[paySupplierOptions.PAY_BY_ACH],
  },
];

const dueDateFilterOptions = [
  progressiveRangePickerOptions.CUSTOM,
  progressiveRangePickerOptions.NO_RANGE,
  progressiveRangePickerOptions.THIS_WEEK,
  progressiveRangePickerOptions.NEXT_WEEK,
  progressiveRangePickerOptions.THIS_MONTH,
];

export function PaySuppliersPageHeader({
  filters: appliedFilters,
  setFilters,
  loading,
}) {
  const invoices = useSelector((state) => state.invoices);
  const [creditsAvailable, setCreditsAvailable] = useState(false);
  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    headerConfig,
    setFilters,
  );

  useEffect(() => {
    setCreditsAvailable(isCreditsMemoAvailable(invoices));
  }, [invoices]);

  const suppliersQuery = searchFilters.masterSupplierId
    ? { term: { masterSupplierId: searchFilters.masterSupplierId } }
    : null;

  const onResetSearch = () => {
    onReset();
    setFilters(null);
  };

  const paymentTypeSelectProps = {
    options: paymentTypeOptions,
    value: searchFilters.paymentType,
    onChange: handlers.paymentType,
    name: 'acceptCreditCardsSelect',
    width: '100%',
    size: 'small',
    isClearable: true,
  };

  const onChangeDueDate = ({ startDate, endDate, option }) => {
    handlers.paymentDueDate({
      startDueDate: startDate,
      endDueDate: endDate,
      dueDateOption: option,
    });
  };

  return (
    <SearchPageHeaderContainer title="Pay Suppliers">
      <FiltersContainer>
        <FlexSpaceBetween>
          <ResponsiveFiltersWrapper>
            <FiltersColumn>
              <FlexColumn>
                <FilterContainer title="Supplier">
                  <SupplierSelect
                    value={searchFilters.supplierId}
                    onChange={handlers.supplierId}
                    query={suppliersQuery}
                    size="small"
                    isClearable
                  />
                </FilterContainer>
                <FilterContainer title="Supplier Master">
                  <MasterSupplierSelect
                    size="small"
                    width="100%"
                    isClearable
                    onChange={handlers.masterSupplierId}
                    value={searchFilters.masterSupplierId}
                  />
                </FilterContainer>
                <FilterContainer title="Client">
                  <ClientSelect
                    value={searchFilters.clientId}
                    onChange={handlers.clientId}
                    placeholder="Start Typing Client Name"
                    width="100%"
                    size="small"
                    isClearable
                  />
                </FilterContainer>
              </FlexColumn>
              <FlexColumn>
                <FilterContainer title="Payment Terms">
                  <FinancialTermsSelect
                    value={searchFilters.terms}
                    onChange={handlers.terms}
                    width="100%"
                    size="small"
                    isClearable
                  />
                </FilterContainer>
                <FilterContainer title="Payment Type">
                  <Select {...paymentTypeSelectProps} />
                </FilterContainer>
                <FilterContainer title="Service Type">
                  <ServiceTypesSelect
                    value={searchFilters.serviceTypeId}
                    onChange={handlers.serviceTypeId}
                    width="100%"
                    size="small"
                    name="serviceTypesSelect"
                    isClearable
                  />
                </FilterContainer>
              </FlexColumn>
              <FlexColumn>
                <FilterContainer title="Project No">
                  <ProjectSelect
                    value={searchFilters.projectId}
                    onChange={handlers.projectId}
                    placeholder="Start Typing Project Number"
                    width="100%"
                    size="small"
                    isClearable
                  />
                </FilterContainer>
                <FilterContainer title="Invoice No">
                  <InvoicesSelect
                    value={searchFilters.invoiceId}
                    onChange={handlers.invoiceId}
                    placeholder="Start Typing Invoice Number"
                    width="100%"
                    size="small"
                    isClearable
                  />
                </FilterContainer>
                <FilterContainer title="Invoice Date">
                  <ProgressiveRangePicker
                    name="invoiceDate"
                    value={searchFilters.invoiceDate}
                    onChange={handlers.invoiceDate}
                    enableAllOption
                    width="100%"
                    size="small"
                    required
                  />
                </FilterContainer>
              </FlexColumn>
            </FiltersColumn>
            <FiltersColumn>
              <ResponsiveContainer>
                <FilterContainer title="Due Date">
                  <ProgressiveRangePicker
                    name="paymentDueDate"
                    value={prepareDueDateFilterValues(
                      searchFilters.paymentDueDate,
                    )}
                    onChange={onChangeDueDate}
                    enableAllOption
                    width="100%"
                    size="small"
                    required
                    customOptions={dueDateFilterOptions}
                    isDisabledDays={false}
                  />
                </FilterContainer>
                <FilterContainer
                  titleWidth="125px"
                  filterWidth="200px"
                  title="Include Past Due"
                >
                  <Checkbox
                    name="pastDueDate"
                    value={searchFilters.pastDueDate}
                    onChange={handlers.pastDueDate}
                  />
                </FilterContainer>
                <FilterContainer
                  titleWidth="180px"
                  filterWidth="30px"
                  title="Show Only With Warnings"
                >
                  <Checkbox
                    name="showOnlyWarnings"
                    value={searchFilters.showOnlyWarnings}
                    onChange={handlers.showOnlyWarnings}
                  />
                </FilterContainer>
                <FilterContainer
                  titleWidth="100px"
                  filterWidth="30px"
                  title="W-9 absence"
                >
                  <Checkbox
                    name="w9Absent"
                    value={searchFilters.w9Absent}
                    onChange={handlers.w9Absent}
                  />
                </FilterContainer>
              </ResponsiveContainer>
            </FiltersColumn>
          </ResponsiveFiltersWrapper>
          <ButtonsColumn>
            <PaySupplierInvoicesPdfExportBtn
              invoices={invoices}
              filters={appliedFilters || {}}
            />
            <PaySupplierInvoicesXlsExportBtn
              invoices={invoices}
              filters={appliedFilters || {}}
            />
            {creditsAvailable && <CreditSAvailableLink />}
            <ButtonsContainer>
              <FlexCenterAlignRelative>
                <SearchHeaderButton
                  size="small"
                  styleType="basicPrimary"
                  onClick={onResetSearch}
                >
                  Reset
                </SearchHeaderButton>
                <SearchHeaderButton
                  loader={loading}
                  size="small"
                  onClick={onSearch}
                >
                  Search
                </SearchHeaderButton>
              </FlexCenterAlignRelative>
            </ButtonsContainer>
          </ButtonsColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

PaySuppliersPageHeader.propTypes = {
  loading: bool,
  setFilters: func.isRequired,
  filters: paySupplierInvoiceFiltersPropTypes,
};
