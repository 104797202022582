import React from 'react';
import { arrayOf, oneOfType, shape, string } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { MasterSuppliersPrintTable } from '@poly/admin-ui/src/modules/masterSupplier/masterSupplierForm/MasterSuppliersTable.js';
import { useRouterQuery } from '@poly/client-routing';
import { MasterSupplierStatus } from '@poly/constants';
import { forceTitleCase } from '@poly/utils';

import { useMasterSuppliersLazyQuery } from './useMasterSuppliersLazyQuery.js';
import { PrintExportButtons } from '../../components/ExportButtons.js';
import {
  getMasterSupplierExportFileName,
  getMasterSuppliersExcelConfig,
} from './getMasterSuppliersExcelConfig.js';
import { routesNames } from '../../routes/constants.js';

export function MasterSuppliersExportButtons({ sort, type }) {
  const { tab } = useRouterQuery(['tab']);

  const activeTab = tab || MasterSupplierStatus.ACTIVE;

  const title = `Master Suppliers ${forceTitleCase(type)}`;

  const { runMasterSuppliersQuery, loading } = useMasterSuppliersLazyQuery({
    type,
    tableSort: sort,
    status: activeTab,
  });

  const handlePrintPDF = async () => {
    const masterSuppliers = await runMasterSuppliersQuery();

    await openPrintWindowWithData({
      fileName: getMasterSupplierExportFileName({
        tab: activeTab,
        type,
        ext: 'pdf',
      }),
      Layout: CommonPrintLayout,
      Table: MasterSuppliersPrintTable,
      metaData: {
        title,
      },

      masterSupplierRootUrl: routesNames.MASTER_SUPPLIER,
      masterSuppliers,
    });
  };

  const handleExportXLS = async () => {
    const masterSuppliers = await runMasterSuppliersQuery();

    const config = getMasterSuppliersExcelConfig({
      tab: activeTab,
      type,
      masterSuppliers,
    });
    return performExcelExport(config);
  };

  return (
    <PrintExportButtons
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
      loading={loading}
    />
  );
}

MasterSuppliersExportButtons.propTypes = {
  sort: arrayOf(oneOfType([shape({}), string])),
  type: string,
};
