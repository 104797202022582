import React, { useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@poly/admin-book';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonPrintLayout } from '@poly/admin-ui';
import { openPrintWindowWithData } from '@poly/client-utils';
import { arrayOf, bool, object, oneOfType, string } from 'prop-types';

import { ExportPDFBtn } from '../../../pages/WorkInProgressReport/WorkInProgressExportPDFBtn.js';
import { useSuppliersLazyQuery } from './useSuppliersLazyQuery.js';
import { SuppliersTablePrintPdf } from '../../tables/suppliersTable/SuppliersTable.js';

const LoaderWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  min-width: 40px;
`;

export function SuppliersTabPdfExportButton({
  title,
  query,
  exportSort,
  showDateColumn,
  exportFileName,
}) {
  const [printing, setPrinting] = useState(false);
  const queryHandler = useSuppliersLazyQuery();

  const onClick = async () => {
    setPrinting(true);

    const { data } = await queryHandler({ query, exportSort });

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: SuppliersTablePrintPdf,
      fileName: exportFileName,
      metaData: { title },
      data,
      showDateColumn,
    });

    setPrinting(false);
  };

  if (printing) {
    return (
      <LoaderWrapperS>
        <Loader size={16} />
      </LoaderWrapperS>
    );
  }

  return <ExportPDFBtn onClick={onClick}>{PRINT_PDF_CAPTION}</ExportPDFBtn>;
}

SuppliersTabPdfExportButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object,
  title: string,
  exportSort: arrayOf(oneOfType([string, object])),
  showDateColumn: bool,
  exportFileName: string,
};
