import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { usePaginationParams } from '@poly/admin-ui/src/hooks/usePaginationParams.js';
import { useSetItemsCount } from '@poly/admin-ui/src/hooks/useSetItemsCount.js';
import { useReactiveQuery } from '@poly/client-utils/src/hooks/useReactiveQuery.js';

export const ASSET_MANUFACTURERS_QUERY = gql`
  query ASSET_MANUFACTURERS_QUERY($input: CollectionSearchParams) {
    searchAssetManufactures(input: $input) {
      hits {
        _id
        name
        replacementCost
      }
      total
    }
  }
`;

const ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION = gql`
  subscription ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAssetManufacturerChanged(input: $input) {
      id
      type
    }
  }
`;

export const useAssetManufacturersQuery = (sort) => {
  const searchTerm = useSelector((state) => state.searchText);

  const paginationParams = usePaginationParams();

  const queryOptions = {
    variables: {
      input: { searchTerm, sort, ...paginationParams },
    },
    fetchPolicy: 'network-only',
  };

  const { data, loading } = useReactiveQuery(
    ASSET_MANUFACTURERS_QUERY,
    ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const assetManufacturers = R.pathOr(
    [],
    ['searchAssetManufactures', 'hits'],
    data,
  );

  useSetItemsCount(R.pathOr(0, ['searchAssetManufactures', 'total']), data);

  return { assetManufacturers, loading };
};
