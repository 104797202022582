import * as R from 'ramda';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';

import { getDailySupplierInvoicesBatchTableConfig } from './DailySupplierInvoicesBatchTable.js';

const tableConfig = getDailySupplierInvoicesBatchTableConfig(null, true);

// getPrintedSupplierBatchExcelConfig :: PrintedSupplierBatch -> [ExcelExportDataCell]
const getPrintedSupplierBatchExcelConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 4)),
  R.juxt(R.map(R.nth(1), tableConfig)),
);

export const getPrintedSupplierBatchesExcelConfig = (
  printedSupplierBatches,
) => ({
  exportFileName: 'daily_supplier_invoices_batch_export.xls',
  columnWidths: [15, 25, 15, 20],
  rows: [
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 4),
      R.map(R.head, tableConfig),
    ),
    ...R.map(getPrintedSupplierBatchExcelConfig, printedSupplierBatches),
  ],
});
