import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { string, bool, func, arrayOf, oneOfType } from 'prop-types';
import { PasswordInputSection } from '@poly/client-utils';
import { TOKEN_EXPIRED_ERROR } from '@poly/constants';
import { validatePassword } from '@poly/utils';

import {
  Label,
  Footer,
  ErrorText,
  LoginButton,
  MatchedIcon,
  UnmatchedIcon,
  LoginPageLayout,
} from '../Components.js';
import { ButtonLoader } from '../Loader.js';

const CustomValidationRules = [
  { keyWord: '8', text: 'At least 8 characters' },
  { keyWord: 'number', text: 'At least one number' },
  { keyWord: 'upper', text: 'Uppercase letter' },
  { keyWord: 'lower', text: 'Lowercase letter' },
  { keyWord: 'special', text: 'At least one special character' },
];

// getFormValue :: (String, Event) -> String
export const getFormValue = (name, event) =>
  R.path(['target', 'elements', name, 'value'], event);

// isTokenExpiredError :: String -> Boolean
const isTokenExpiredError = R.equals(R.prop('message', TOKEN_EXPIRED_ERROR));

const LabelS = styled(Label)`
  cursor: pointer;
  color: #cfcfcf;
  :hover {
    color: #9f9d9d;
    text-decoration: underline;
  }
`;

const CustomPasswordValidationWrapperS = styled.div`
  gap: 15px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  margin-bottom: 20px;
  flex-direction: column;
  padding: 12px 12px 6px 12px;
  border: 1px solid #f0f1f1;
`;

const CustomPasswordValidationRowS = styled.div`
  display: flex;
  flex-direction: row;
  ${({ wrapRow }) => wrapRow && 'flex-wrap: wrap;'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;

const CustomPasswordValidationTextS = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 12px;
`;

const CustomPasswordValidationRuleS = styled.div`
  width: 50%;
  display: flex;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 6px;
  color: ${({ isMatched }) => (isMatched ? '#333741' : '#94969c')};

  &:last-child {
    width: 100%;
  }

  > svg {
    margin-right: 5px;
  }
`;

// getStepColor :: { isMatched: Boolean, isValid: Boolean } -> String
const getStepColor = R.cond([
  [R.prop('isValid'), R.always('#099250')],
  [R.prop('isMatched'), R.always('#fdb022')],
  [R.T, R.always('#ececed')],
]);

const CustomPasswordValidationStepS = styled.div`
  width: 18%;
  height: 2px;
  border-radius: 24px;
  background-color: ${getStepColor};
`;

// checkIfAllRulesMatched :: [PasswordValidator] -> Boolean
// PasswordValidator = [Boolean, String]
const checkIfAllRulesMatched = R.compose(
  R.isEmpty,
  R.reject(R.propEq(true, 0)),
);

// getValidStepsNumber :: [PasswordValidator] -> Number
const getValidStepsNumber = R.compose(R.length, R.filter(R.propEq(true, 0)));

// getValidationText :: [PasswordValidator] -> String
const getValidationText = R.compose(
  R.cond([
    [R.equals(0), R.always('Amazing 🎉')],
    [R.equals(5), R.always('Set password 🤔')],
    [R.T, R.always('Like something is missing 👀')],
  ]),
  R.length,
  R.reject(R.propEq(true, 0)),
);

// checkIfRuleMatchByKeyword :: String -> [PasswordValidator] -> Boolean
const checkIfRuleMatchByKeyword = (keyword) =>
  R.compose(R.prop(0), R.find(R.propSatisfies(R.includes(keyword), 1)));

function CustomPasswordValidation({ passwordValidators }) {
  const validationText = getValidationText(passwordValidators);
  const validStepsNumber = getValidStepsNumber(passwordValidators);
  const isPasswordValid = checkIfAllRulesMatched(passwordValidators);

  return (
    <CustomPasswordValidationWrapperS>
      <CustomPasswordValidationRowS>
        <CustomPasswordValidationTextS>
          {validationText}
        </CustomPasswordValidationTextS>
      </CustomPasswordValidationRowS>
      <CustomPasswordValidationRowS justify="space-between">
        {passwordValidators.map(([, rule], index) => (
          <CustomPasswordValidationStepS
            key={rule}
            isValid={isPasswordValid}
            isMatched={index < validStepsNumber}
          />
        ))}
      </CustomPasswordValidationRowS>
      <CustomPasswordValidationRowS wrapRow>
        {CustomValidationRules.map(({ keyWord, text }) => {
          const isMatched =
            checkIfRuleMatchByKeyword(keyWord)(passwordValidators);

          return (
            <CustomPasswordValidationRuleS key={keyWord} isMatched={isMatched}>
              {isMatched ? <MatchedIcon /> : <UnmatchedIcon />}
              {text}
            </CustomPasswordValidationRuleS>
          );
        })}
      </CustomPasswordValidationRowS>
    </CustomPasswordValidationWrapperS>
  );
}

CustomPasswordValidation.propTypes = {
  passwordValidators: arrayOf(arrayOf(oneOfType([bool, string]))).isRequired,
};

function ChangePasswordFormError({
  errorMsg,
  isTokenExpired,
  passwordValidators,
  withCustomValidation,
  navigateToForgotPassword,
}) {
  if (isTokenExpired) {
    return (
      <>
        <ErrorText>
          Your invite has expired. Please Reset your password to get a new
          invite.
        </ErrorText>
        <LabelS onClick={navigateToForgotPassword}>Reset Your Password</LabelS>
      </>
    );
  }

  return (
    <>
      {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
      {withCustomValidation && (
        <CustomPasswordValidation passwordValidators={passwordValidators} />
      )}
    </>
  );
}

ChangePasswordFormError.propTypes = {
  errorMsg: string,
  withCustomValidation: bool,
  isTokenExpired: bool.isRequired,
  navigateToForgotPassword: func.isRequired,
  passwordValidators: arrayOf(arrayOf(oneOfType([bool, string]))).isRequired,
};

export function ChangePasswordForm({
  title,
  error,
  loading,
  subTitle,
  handleSubmit,
  withCustomValidation,
  navigateToForgotPassword,
}) {
  const [passwordInput, setPasswordInput] = useState('');
  const [invalidCustom, setInvalidCustom] = useState(!!withCustomValidation);

  const onSubmit = (event) => {
    event.preventDefault();

    const creds = {
      password: getFormValue('password', event),
      confirmPassword: getFormValue('confirmPassword', event),
    };

    handleSubmit(creds);
  };

  const isTokenExpired = isTokenExpiredError(error);

  const passwordValidators = validatePassword(passwordInput);

  useEffect(() => {
    if (checkIfAllRulesMatched(passwordValidators)) {
      setInvalidCustom(false);
    }
  }, [passwordValidators]);

  return (
    <LoginPageLayout
      darkLogo={!!withCustomValidation}
      {...{ title, subTitle, onSubmit }}
    >
      <PasswordInputSection
        title="New Password"
        inputProps={{
          required: true,
          autoFocus: true,
          name: 'password',
          value: passwordInput,
          placeholder: 'New Password',
          onChange: (e) => setPasswordInput(e.target.value),
        }}
      />
      <PasswordInputSection
        title="Confirm Password"
        inputProps={{
          required: true,
          name: 'confirmPassword',
          placeholder: 'Confirm Password',
        }}
      />
      <ChangePasswordFormError
        errorMsg={error}
        isTokenExpired={isTokenExpired}
        passwordValidators={passwordValidators}
        withCustomValidation={withCustomValidation}
        navigateToForgotPassword={navigateToForgotPassword}
      />
      {!isTokenExpired && (
        <Footer>
          <LoginButton type="submit" disabled={loading || invalidCustom}>
            {loading && <ButtonLoader />}
            {withCustomValidation ? 'Continue' : 'Update Password'}
          </LoginButton>
        </Footer>
      )}
    </LoginPageLayout>
  );
}

ChangePasswordForm.propTypes = {
  error: string,
  subTitle: string,
  loading: bool.isRequired,
  title: string.isRequired,
  withCustomValidation: bool,
  handleSubmit: func.isRequired,
  navigateToForgotPassword: func.isRequired,
};

ChangePasswordForm.defaultProps = { error: null };
