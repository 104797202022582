import React from 'react';
import { number } from 'prop-types';
import styled from 'styled-components';
import { HeaderLinksWrapper } from '@poly/admin-book';

import {
  SupplierSearchShowInMapBtn,
  SearchSuppliersPrintPDFBtn,
  SupplierSearchExportToXLSBtn,
} from './SupplierSearchTableToolBar.js';
import { SearchPageTableComp } from '../commonTableComps.js';
import { SupplierSearchTableS } from './supplierSearchTableUtils.js';
import { ToolBarBtnDivider } from '../../core/tableToolbar/tableToolBarStyles.js';

const HeaderLinksWrapperS = styled(HeaderLinksWrapper)`
  > div {
    margin-right: 0;
  }
`;

export function SupplierSearchTableComp({ counts, ...props }) {
  return (
    <SearchPageTableComp
      {...{ counts }}
      ToolBar={
        <HeaderLinksWrapperS>
          <SupplierSearchShowInMapBtn {...props} />
          <ToolBarBtnDivider />
          <SearchSuppliersPrintPDFBtn {...props} />
          <ToolBarBtnDivider />
          <SupplierSearchExportToXLSBtn {...props} />
        </HeaderLinksWrapperS>
      }
    >
      <SupplierSearchTableS {...props} moreScrollPosition="0px" />
    </SearchPageTableComp>
  );
}

SupplierSearchTableComp.propTypes = { counts: number.isRequired };
