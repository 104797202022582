import * as R from 'ramda';
import { formatPhoneNumber } from '@poly/utils';
import {
  pathOrNothingUI,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';

import {
  getContactCellPhone,
  getContactWorkPhone,
} from '../useSearchSubTabsContactsQuery.js';

// getSidebarContactsTabXlsExportRowConfig :: User -> [ExcelExportDataCell]
const getSidebarContactsTabXlsExportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 5),
  ]),
  R.juxt([
    pathOrNothingUI(['fullName']),
    pathOrNothingUI(['title']),
    R.compose(formatPhoneNumber('-', 'x '), getContactWorkPhone),
    R.compose(formatPhoneNumber('-', 'x '), getContactCellPhone),
    pathOrNothingUI(['email']),
  ]),
);

export const getSidebarContactsTabUsersXlsExportConfig = (
  currentTab,
  foundContacts,
) => ({
  exportFileName: `client_contacts_${currentTab}_export.xlsx`,
  columnWidths: [20, 30, 15, 15, 35],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 5)],
      ['Name', 'Title', 'Phone', 'Mobile', 'Email'],
    ),
    ...R.map(getSidebarContactsTabXlsExportRowConfig, foundContacts),
  ],
});
