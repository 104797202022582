import { node } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { LISTING_PROJECT_SPECIFIC_FIELDS } from '@poly/constants';
import { debounce } from '@poly/utils';

import { SidebarTableFormColumn } from '../../sidebarComponents.js';
import { EditTaskForm } from '../../../modules/forms/taskForm/EditTaskForm.js';
import { CompleteTaskForm } from '../../../modules/forms/taskForm/complete/index.js';
import { PostTaskUpdateForm } from '../../../modules/forms/taskForm/PostTaskUpdateForm.js';
import { useScrollIntoViewByRef } from '../../../hooks/useScrollIntoViewByRef.js';
import { useSidebarTableFormContext } from '../../SidebarWrapperForTableForm.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';
import { getTaskActionCustomConfig } from './getCompleteTaskCustomConfig.js';
import { getCompleteTaskValidation } from './getCompleteTaskValidation.js';
import { getTaskTypeByCollection } from './getTaskTypeByCollection.js';
import { useModalContext } from '../../../components/Modal/ModalProvider.js';

const removeSupplierInvoiceTaskMutation = gql`
  mutation REMOVE_SUPPLIER_INVOICE_TASK($id: ID!) {
    removeSupplierInvoiceTask(id: $id) {
      _id
    }
  }
`;

const voidReportOnlyClientInvoiceMutation = gql`
  mutation VOID_REPORT_ONLY_CLIENT_INVOICE_MUTATION($id: ID!) {
    voidReportOnlyClientInvoice(id: $id) {
      _id
    }
  }
`;

const editTaskFormId = 'edit_task_form_id';
const completeTaskFormId = 'complete_task_form_id';
const postTaskUpdateFormId = 'post_task_update_form_id';

export function CommonTableFormWrapper({ children, ...props }) {
  const { ref } = useScrollIntoViewByRef();

  return (
    <SidebarTableFormColumn {...props} ref={ref}>
      {children}
    </SidebarTableFormColumn>
  );
}

CommonTableFormWrapper.propTypes = {
  children: node.isRequired,
};

export const useSidebarTasksTableHandlers = (task) => {
  const [loading, setLoading] = useState(false);
  const { openConfirmSubmitFormModal } = useModalContext();
  const { showWarningNotification, showSuccessNotification } =
    useNotificationState();
  const { formSetter } = useSidebarTableFormContext();
  const [removeSupplierInvoiceTask] = useMutation(
    removeSupplierInvoiceTaskMutation,
  );
  const [voidReportOnlyClientInvoice] = useMutation(
    voidReportOnlyClientInvoiceMutation,
  );

  const {
    _id,
    taskId,
    action,
    project,
    supplier,
    documentId,
    collection,
    isHQProject,
    scheduleDate,
    supplierArrivedAt,
    readFromCacheParams,
    SupplierInvoiceForm,
    ClientInvoiceTaskForm,
    onCompleteHashedTask,
    openAssignSupplierForm,
    setIsSupplierInvoiceTaskDisabled,
    supplierInvoice,
  } = task;

  const taskType = getTaskTypeByCollection(collection);

  const onCancel = () => formSetter(null);

  useEffect(() => () => onCancel(), []);

  const renderForm = (Form, skipMargin) =>
    formSetter({
      elementId: _id,
      Content: (
        <CommonTableFormWrapper
          {...{ onCancel, ...(skipMargin ? { skipMargin } : {}) }}
        >
          {Form}
        </CommonTableFormWrapper>
      ),
    });

  const commonPayload = {
    project,
    taskType,
    onCancel,
    collection,
    _id: taskId,
    entity: { _id: documentId },
  };

  const onCompleteTask = useCallback(
    (callHashComplete) => {
      const {
        isCloseProjectTask,
        isSupplierAssignTask,
        isSupplierInvoiceTask,
        isClientInvoiceTask,
        validateBeforeComplete,
        isSupplierArrivalTask,
      } = getCompleteTaskValidation(action, project, showWarningNotification);

      validateBeforeComplete();

      if (isSupplierAssignTask && !!openAssignSupplierForm) {
        return openAssignSupplierForm(taskId);
      }

      const payload = {
        taskId,
        scheduleDate,
        supplierArrivedAt,
        ...commonPayload,
        ...(callHashComplete
          ? {
              onCancel: () => {
                commonPayload.onCancel();
                if (onCompleteHashedTask) {
                  onCompleteHashedTask();
                }
              },
            }
          : {}),
        ...(readFromCacheParams ? { readFromCacheParams } : {}),
      };

      if (isSupplierInvoiceTask && !!SupplierInvoiceForm) {
        const supplierInvoicePayload = {
          ...payload,
          taskSupplier: supplier,
        };

        return renderForm(
          <SupplierInvoiceForm payload={task} {...supplierInvoicePayload} />,
          true,
        );
      }

      if (isClientInvoiceTask && !!ClientInvoiceTaskForm) {
        return renderForm(
          <ClientInvoiceTaskForm {...payload} payload={task} />,
          true,
        );
      }

      return renderForm(
        <CompleteTaskForm
          payload={payload}
          formId={completeTaskFormId}
          isTaskUpdateMessageOptional={
            isCloseProjectTask || isSupplierArrivalTask
          }
          setIsSupplierInvoiceTaskDisabled={setIsSupplierInvoiceTaskDisabled}
          customFormConfig={getTaskActionCustomConfig(project, action)}
        />,
      );
    },
    [task],
  );

  const onEditTask = useCallback(
    () =>
      renderForm(
        <EditTaskForm
          formId={editTaskFormId}
          payload={commonPayload}
          isHQProject={isHQProject}
          customTasksFields={LISTING_PROJECT_SPECIFIC_FIELDS}
        />,
      ),
    [],
  );

  const onAddTaskUpdate = useCallback(
    () =>
      renderForm(
        <PostTaskUpdateForm
          payload={commonPayload}
          isHQProject={isHQProject}
          formId={postTaskUpdateFormId}
        />,
      ),
    [commonPayload],
  );

  const setLoadingDebounced = useCallback(debounce(2000)(setLoading), []);

  const handleRemoveSupplierInvoiceTask = async (id) => {
    setLoading(true);
    await removeSupplierInvoiceTask({ variables: { id } });
    showSuccessNotification('Task was successfully deleted');
    setLoadingDebounced(false);
  };

  const onRemoveSupplierInvoiceTask = ({ taskId: id }) => {
    if (supplierInvoice?._id) {
      openConfirmSubmitFormModal({
        content:
          'Are you sure you want to remove the invoice task? Invoice request might be cancelled',
        btnCaption: 'Yes, Remove',
        processId: 'removeSupplierInvoiceTaskProcessId',
        onConfirm: (closeConfirmModal) => async () => {
          await handleRemoveSupplierInvoiceTask(id);
          closeConfirmModal();
        },
      });
    } else {
      handleRemoveSupplierInvoiceTask(id);
    }
  };

  const onVoidClientInvoice = (reportOnlyClientInvoiceId) => {
    openConfirmSubmitFormModal({
      content: 'Are you sure you want to void client invoice?',
      btnCaption: 'Yes, Void',
      processId: 'voidReportOnlyClientInvoiceProcessId',
      onConfirm: (closeConfirmModal) => async () => {
        await voidReportOnlyClientInvoice({
          variables: {
            id: reportOnlyClientInvoiceId,
          },
        });
        showSuccessNotification(
          'Report Only Client Invoice successfully voided!',
        );
        closeConfirmModal();
      },
    });
  };

  const onUpdateClientInvoice = useCallback(
    (props) =>
      renderForm(
        <ClientInvoiceTaskForm
          {...props}
          {...commonPayload}
          taskId={taskId}
          payload={task}
        />,
        true,
      ),
    [task],
  );

  return {
    loading,
    onEditTask,
    onCompleteTask,
    onAddTaskUpdate,
    onVoidClientInvoice,
    onUpdateClientInvoice,
    onRemoveSupplierInvoiceTask,
  };
};
