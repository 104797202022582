import * as R from 'ramda';
import { gql } from '@apollo/client';
import { NormalBalanceTypes } from '@poly/constants';
import {
  ofArrayLegacy,
  pathEqLegacy,
  propEqLegacy,
  assocBy,
} from '@poly/utils';

export const moneyTransactionCommonFragment = gql`
  fragment moneyTransactionCommonFragment on MoneyTransaction {
    _id
    type
    date
    transaction_number
    check_number
    deposit_number
    payment_mode
    payee_name
    is_split
    reference_column
    lines {
      account_id
      amount
      payment_status
      reconciled_at
      description
      bank_cleared_date
    }
    supplier {
      _id
      company {
        name
      }
    }
    masterSupplier {
      _id
      company {
        name
      }
    }
  }
`;

export const bankRegisterAccountDetailsQuery = gql`
  query bankRegisterAccountDetails($accountId: ID!, $fromDate: DateTime!) {
    getSingleAccount(input: { accountId: $accountId }) {
      _id
      name
      accountType {
        _id
        normal_balance
      }
      accrualBalance(date: $fromDate)
    }
  }
`;

export const bankRegisterTransactionsQuery = gql`
  query bankRegisterTransactions($input: CollectionSearchParams!) {
    searchMoneyTransactions(input: $input) {
      hits {
        ...moneyTransactionCommonFragment
      }
      total
    }
  }

  ${moneyTransactionCommonFragment}
`;

// getAccountTransactionsByData :: QueryData -> [MoneyTransaction]
//   QueryData = {
//     searchMoneyTransactions: {hits: [MoneyTransaction], total: Int}
//   }
export const getAccountTransactionsByData = R.pathOr(
  [],
  ['searchMoneyTransactions', 'hits'],
);

// getTransactionsWithAmountsByAccountId :: ID ->
//   QueryData -> [MoneyTransaction]
const getTransactionsWithAmountsByAccountId = (accountId) =>
  R.compose(
    R.map(
      R.compose(
        R.converge(R.mergeLeft, [
          R.identity,
          R.compose(
            R.defaultTo({}),
            R.find(propEqLegacy('account_id', accountId)),
            R.propOr([], 'lines'),
          ),
        ]),
        assocBy('date', R.compose(R.constructN(1, Date), R.prop('date'))),
      ),
    ),
    getAccountTransactionsByData,
  );

// getTransactionAmount :: MoneyTransaction -> Number
const getTransactionAmount = R.prop('amount');

// getInitialBalanceByQueryData :: QueryData -> Number
const getInitialBalanceByQueryData = R.compose(
  R.converge(R.multiply, [
    R.propOr(0, 'accrualBalance'),
    R.ifElse(
      pathEqLegacy(
        ['accountType', 'normal_balance'],
        NormalBalanceTypes.CREDIT,
      ),
      R.always(-1),
      R.always(1),
    ),
  ]),
  R.propOr({}, 'getSingleAccount'),
);

// convertQueryDataToTransactions :: ID -> QueryData -> [MoneyTransaction]
export const convertQueryDataToTransactions = (accountId) =>
  R.compose(
    R.tail,
    R.converge(
      R.reduce((acc, transaction) => [
        ...acc,
        {
          ...transaction,
          currentBalance:
            R.last(acc).currentBalance + getTransactionAmount(transaction),
        },
      ]),
      [
        R.compose(
          ofArrayLegacy,
          R.objOf('currentBalance'),
          getInitialBalanceByQueryData,
        ),
        getTransactionsWithAmountsByAccountId(accountId),
      ],
    ),
  );

const getSearchInputByQuery = ({
  sort,
  toDate,
  fromDate,
  accountId,
  searchTerm,
}) => ({
  sort,
  searchTerm,
  query: {
    bool: {
      filter: [
        {
          range: {
            date: {
              gte: fromDate,
              lte: toDate,
            },
          },
        },
        {
          nested: {
            path: 'lines',
            query: {
              term: { 'lines.account_id': accountId },
            },
          },
        },
      ],
    },
  },
});

// getRegistersPageQueryVariables :: BankRegistersFilters -> RegistersQueryVariables
//   RegistersQueryVariables = {
//     input: CollectionSearchParams
//     accountId: ID
//     fromDate: DateTime
//   }
export const getRegistersPageQueryVariables = R.ifElse(
  R.isNil,
  R.always({}),
  R.compose(
    R.mergeAll,
    R.juxt([
      R.compose(R.objOf('input'), getSearchInputByQuery),
      R.pick(['accountId', 'fromDate']),
    ]),
  ),
);
