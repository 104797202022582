import React from 'react';
import { string, shape, bool } from 'prop-types';
import { SidebarRow } from '@poly/admin-ui';

import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapperWithoutBorder,
} from '../../components/commonSidebarComponents.js';
import { getSectionDivision } from '../../components/commonSidebarSectionFormatters.js';
import {
  isProjectClientGlCodeEnabled,
  getProjectClientGLCode,
  ClientGLCode,
} from '../../ProjectSidebar/tabs/ProjectSidebarFinancialTab.js';

function GLCode({ clientGLCode }) {
  return <ClientGLCode glCode={clientGLCode} />;
}

GLCode.propTypes = {
  clientGLCode: shape({
    _id: string,
    code: string,
    name: string,
  }),
};
export function MasterRecurringProjectSidebarFinancialTab({
  project,
  withoutLabel,
}) {
  const clientGLCode = getProjectClientGLCode(project);
  const isGlCodeEnabled = isProjectClientGlCodeEnabled(project);

  return (
    <SectionWrapperWithoutBorder>
      {!withoutLabel && (
        <SidebarRow>
          <SectionLabel>Financial</SectionLabel>
        </SidebarRow>
      )}
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          width="50%"
          label="Division"
          id="project-division"
          Component={getSectionDivision(project)}
        />
        {isGlCodeEnabled && (
          <BlockWithLabel
            Component={GLCode}
            id="client-gl-code"
            label="Client GL Code"
            clientGLCode={clientGLCode}
          />
        )}
      </SidebarRow>
    </SectionWrapperWithoutBorder>
  );
}

MasterRecurringProjectSidebarFinancialTab.propTypes = {
  withoutLabel: bool,
  project: shape({ _id: string.isRequired }),
};
