import * as R from 'ramda';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { arrayOf, func, shape } from 'prop-types';
import { duplicateField } from '@poly/client-utils/src/objects.js';
import { mongoSortQuery } from '@poly/client-utils/src/sorting.js';
import { useHighlightMatchesBySearch } from '@poly/client-utils/src/hooks/useHighlightMatchesBySearch.js';
import { useModalContext } from '@poly/admin-ui/src/components/Modal/ModalProvider.js';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { useTableSorting } from '@poly/admin-ui/src/hooks/useTableSorting.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { IconButton } from '@poly/admin-book/src/IconButton/index.js';
import { Table } from '@poly/admin-book/src/Table/Table.js';
import { NOTHING_UI_STRING } from '@poly/constants/src/misc.js';
import { centsToDollarsWithFormat } from '@poly/utils/src/converters.js';

import { AssetTypesForm } from './AssetTypesForm.js';
import { editAssetTypesFormId } from './constants.js';
import { useAssetTypesQuery } from './useAssetTypesQuery.js';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

const AssetTypesTableS = styled(Table)`
  th:nth-child(4),
  td:nth-child(4) {
    width: 40px;
  }
`;

function EditButton(props) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: editAssetTypesFormId,
      title: 'Edit Asset Type',
      formId: editAssetTypesFormId,
      btnCaption: 'Save',
      content: <AssetTypesForm isNewType={false} assetType={props} />,
    });

  return <IconButton size={15} name="edit" onClick={onClick} />;
}

const assetTypesCommonTableConfig = [
  [
    'Life Expectancy',
    R.propOr(NOTHING_UI_STRING, 'lifeExpectancy'),
    mongoSortQuery('lifeExpectancy'),
  ],
  [
    'Asset Replacement Cost',
    R.compose(centsToDollarsWithFormat, R.prop('replacementCost')),
  ],
];

const assetTypesPrintTableConfig = [
  ['Name', R.prop('name')],
  ...assetTypesCommonTableConfig,
];

export function AssetTypesPrintTable({ assetTypes }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    assetTypesPrintTableConfig,
    assetTypes,
  );

  return <AssetTypesTableS {...tableProps} />;
}

const assetTypesPageTableConfig = [
  ['Name', R.prop('nameUI'), mongoSortQuery('name')],
  ...assetTypesCommonTableConfig,
  ['', EditButton],
];

AssetTypesPrintTable.propTypes = {
  assetTypes: arrayOf(shape({})),
};

// getAssetTypes :: AssetTypesSearchResult -> [AssetType]
const getAssetTypes = R.compose(
  R.map(duplicateField('name', 'nameUI')),
  R.pathOr([], ['assetTypes', 'hits']),
);

export function AssetTypesPageTable({ setSort }) {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: assetTypesPageTableConfig,
    column: 1,
  });

  useEffect(() => {
    setSort(sort);
  }, [sort]);

  const { data, loading } = useAssetTypesQuery(sort);

  const pagination = useSelector(R.prop('pagination'));

  const { rows, ...tableProps } = useMapConfigToTableProps(
    getAssetTypes,
    assetTypesPageTableConfig,
    data,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['nameUI']],
    rows,
  );

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <AssetTypesTableS
        {...tableProps}
        {...tableSortingProps}
        isLoading={loading}
        pagination={pagination}
        rows={highlightedRows}
      />
    </Container>
  );
}

AssetTypesPageTable.propTypes = {
  setSort: func.isRequired,
};
