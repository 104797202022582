import React, { useEffect } from 'react';
import * as R from 'ramda';
import { object, string, shape, arrayOf, oneOfType, func } from 'prop-types';
import { FULL_ACCESS_PERMISSION } from '@poly/security';
import { Loader } from '@poly/admin-book';
import { formatDate } from '@poly/utils';
import {
  useHasUserAccessWithPermission,
  useHighlightMatchesBySearch,
  applyPathOrNothingUI,
  commonSortQuery,
} from '@poly/client-utils';
import {
  useSetInitialPaginationOnMount,
  useMapConfigToTableProps,
  userWorkPhoneExtColumn,
  usersEmailColumn,
  usersPhoneColumn,
  useSetItemsCount,
  useTableSorting,
} from '@poly/admin-ui';

import {
  usersNameColumn,
  usersRoleColumn,
  usersLocationColumn,
  UserEmploymentStatus,
} from '../columns/users.js';
import { UsersTableComp } from './UsersTableComp.js';
import { useSaveQueryForXLSExport } from '../../core/tableToolbar/TableExportToXLSBtn.js';
import { useStaffUsersBySearchTable } from '../../core/hooks/users/index.js';

const userHireDateColumn = [
  'Hire Date',
  applyPathOrNothingUI(['employeeInfo', 'startDate'], formatDate),
  commonSortQuery(['employeeInfo', 'startDate']),
];

export const getStaffUsersTableConfig = (isPrint) => [
  ['', UserEmploymentStatus],
  ...(isPrint ? [['Name', R.prop('fullName')]] : [usersNameColumn]),
  usersRoleColumn,
  usersPhoneColumn,
  userWorkPhoneExtColumn,
  usersEmailColumn,
  userHireDateColumn,
  usersLocationColumn,
];

// getUsersTotal :: SearchUsersQueryResult -> Number
const getUsersTotal = R.pathOr(0, ['searchUsers', 'total']);

// getUsers :: SearchUsersQueryResult :: -> [User]
const getUsers = R.pathOr([], ['searchUsers', 'hits']);

export function StaffTable({ setExportSort, ...props }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    FULL_ACCESS_PERMISSION,
  );

  useSetInitialPaginationOnMount();

  const tableConfig = getStaffUsersTableConfig(false);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    column: 2,
  });

  useEffect(() => {
    if (setExportSort) {
      setExportSort(sort);
    }
  }, [sort, setExportSort]);

  const { loading, result } = useStaffUsersBySearchTable({
    query: props.query,
    sort,
    alias: 'useStaffUsersBySearchTable',
  });

  useSaveQueryForXLSExport(props);

  useSetItemsCount(getUsersTotal, result);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getUsers,
    tableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['fullName'], ['address', 'formatted_address'], ['status'], ['email']],
    R.map(R.mergeRight({ asText: R.equals(ifHasPermission, false) }), rows),
  );

  if (loading) return <Loader />;

  return (
    <UsersTableComp
      {...props}
      {...tableSortingProps}
      {...restTableProps}
      rows={highlightedRows}
    />
  );
}

StaffTable.displayNAme = 'UsersTable';

StaffTable.propTypes = {
  setExportSort: func,
  query: shape({
    bool: shape({
      filter: arrayOf(oneOfType([string, object])),
      must: arrayOf(oneOfType([object])),
    }),
  }),
};

export function StaffTablePrintPdf({ data, ...props }) {
  const tableConfig = getStaffUsersTableConfig(true);

  const tableProps = useMapConfigToTableProps(getUsers, tableConfig, data);

  return <UsersTableComp {...props} {...tableProps} />;
}

StaffTablePrintPdf.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object,
};
