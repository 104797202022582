export const PRINT_TYPES = {
  UPDATES: 'UPDATES',
  MY_PROJECTS: 'MY_PROJECTS',
  PROJECTS: 'PROJECTS',
  REPORT_1099: 'REPORT_1099',
  REPORT_SUPPLIER_STATUS: 'REPORT_SUPPLIER_TAX',
  RECENT_ACTIVE_PROJECTS: 'RECENT_ACTIVE_PROJECTS',
  RECENT_COMPLETED_PROJECTS: 'RECENT_COMPLETED_PROJECTS',
  PROPERTY_UPDATES: 'PROPERTY_UPDATES',
  TIME_SHEET_REPORT: 'TIME_SHEET_REPORT',
  PROPERTY_INVOICES: 'PROPERTY_INVOICES',
  SUPPLIER_INVOICES_TIAA: 'SUPPLIER_INVOICES_TIAA',
  SUPPLIER_INVOICES: 'SUPPLIER_INVOICES',
  MASTER_SUPPLIER_INVOICES: 'MASTER_SUPPLIER_INVOICES',
  RECALLED_PROJECTS: 'RECALLED_PROJECTS',
  QUOTE_DISCREPANCIES: 'QUOTE_DISCREPANCIES',
};

export const EXPORT_XLS_CAPTION = 'XLS';
export const PRINT_PDF_CAPTION = 'PDF';
