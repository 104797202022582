import React, { useState } from 'react';
import styled from 'styled-components';
import {
  PageHeaderContainer,
  MainHeader,
  ToolBarBtnDivider,
} from '@poly/admin-book';
import { UserEmployeeInfoStatus } from '@poly/constants';
import {
  getAdminUsersByEmploymentStatusQuery,
  adminActiveContractorsQuery,
  adminActiveStaffQuery,
  employeeStatusColors,
  TableSearchInput,
} from '@poly/admin-ui';
import { PageTabs } from '../modules/tabs/TabsWithRouter.js';
import { StaffTab } from '../modules/tabs/staffTab/StaffTab.js';
import {
  StaffTabTitle,
  CustomTabTitle,
} from '../modules/tabs/staffTab/StaffTabTitle.js';
import { AddNewStaffBtn } from '../modules/tabs/staffTab/StaffTabToolbar.js';
import { StaffDirectoryPdfExportButton } from '../modules/tabs/staffTab/StaffDirectoryPdfExportButton.js';
import { StaffDirectoryXlsExportButton } from '../modules/tabs/staffTab/StaffDirectoryXlsExportButton.js';

const HeaderLinksWrapperS = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin: 0;
  }

  > div:nth-child(3) {
    margin-right: 40px;
  }
`;

const { ACTIVE, INACTIVE, LEAVE } = UserEmployeeInfoStatus;

const staffDirectoryTabs = {
  ACTIVE_TAB: ACTIVE,
  STAFF_TAB: 'Staff',
  CONTRACTORS_TAB: 'Contractors',
  INACTIVE_TAB: INACTIVE,
  LEAVE_TAB: LEAVE,
};

const { ACTIVE_TAB, STAFF_TAB, CONTRACTORS_TAB, INACTIVE_TAB, LEAVE_TAB } =
  staffDirectoryTabs;

const exportQueryConfig = {
  [ACTIVE_TAB]: getAdminUsersByEmploymentStatusQuery(ACTIVE),
  [STAFF_TAB]: adminActiveStaffQuery,
  [CONTRACTORS_TAB]: adminActiveContractorsQuery,
  [INACTIVE_TAB]: getAdminUsersByEmploymentStatusQuery(INACTIVE),
  [LEAVE_TAB]: getAdminUsersByEmploymentStatusQuery(LEAVE),
};

export function StaffDirectoryPage() {
  const [exportSort, setExportSort] = useState(null);
  const [currentTab, setCurrentTab] = useState(ACTIVE);

  const exportQuery = exportQueryConfig[currentTab];

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Staff Directory</MainHeader>
        <HeaderLinksWrapperS>
          <StaffDirectoryXlsExportButton
            query={exportQuery}
            exportSort={exportSort}
            exportFileName={`staff_directory_${currentTab}_export.xlsx`}
          />
          <ToolBarBtnDivider />
          <StaffDirectoryPdfExportButton
            query={exportQuery}
            exportSort={exportSort}
            exportFileName={`staff_directory_${currentTab}_export`}
          />
          <AddNewStaffBtn />
        </HeaderLinksWrapperS>
      </PageHeaderContainer>
      <PageTabs
        toolBar={<TableSearchInput />}
        tabs={[
          [
            <StaffTabTitle
              key={`staff-table-${ACTIVE}`}
              query={getAdminUsersByEmploymentStatusQuery(ACTIVE)}
              status={ACTIVE}
            />,
            ACTIVE,
            <StaffTab
              key={ACTIVE}
              query={getAdminUsersByEmploymentStatusQuery(ACTIVE)}
              currentTab={ACTIVE_TAB}
              setExportSort={setExportSort}
              setCurrentTab={setCurrentTab}
            />,
          ],
          [
            <CustomTabTitle
              query={adminActiveStaffQuery}
              text="Staff"
              color={employeeStatusColors[UserEmployeeInfoStatus.ACTIVE]}
              key="staff-tab-title"
            />,
            'Staff',
            <StaffTab
              key="staff-tab"
              query={adminActiveStaffQuery}
              currentTab={STAFF_TAB}
              setExportSort={setExportSort}
              setCurrentTab={setCurrentTab}
            />,
          ],
          [
            <CustomTabTitle
              query={adminActiveContractorsQuery}
              text="Contractors"
              color={employeeStatusColors[UserEmployeeInfoStatus.ACTIVE]}
              key="contractors-tab-title"
            />,
            'Contractors',
            <StaffTab
              key="contractors-tab"
              query={adminActiveContractorsQuery}
              currentTab={CONTRACTORS_TAB}
              setExportSort={setExportSort}
              setCurrentTab={setCurrentTab}
            />,
          ],
          [
            <StaffTabTitle
              key={`staff-table-${INACTIVE}`}
              query={getAdminUsersByEmploymentStatusQuery(INACTIVE)}
              status={INACTIVE}
            />,
            INACTIVE,
            <StaffTab
              key={INACTIVE}
              query={getAdminUsersByEmploymentStatusQuery(INACTIVE)}
              currentTab={INACTIVE_TAB}
              setExportSort={setExportSort}
              setCurrentTab={setCurrentTab}
            />,
          ],
          [
            <StaffTabTitle
              key={`staff-table-${LEAVE}`}
              query={getAdminUsersByEmploymentStatusQuery(LEAVE)}
              status={LEAVE}
            />,
            LEAVE,
            <StaffTab
              key={LEAVE}
              query={getAdminUsersByEmploymentStatusQuery(LEAVE)}
              currentTab={LEAVE_TAB}
              setExportSort={setExportSort}
              setCurrentTab={setCurrentTab}
            />,
          ],
        ]}
        defaultValue={ACTIVE}
      />
    </>
  );
}
