import * as R from 'ramda';
import React, { useEffect } from 'react';
import { string, bool, arrayOf, shape, func } from 'prop-types';
import { Loader } from '@poly/admin-book';

import { TaskFormWrapper } from '../form/index.js';
import { confirmCancelFormPayload } from '../../../../components/common.js';
import { useNotificationState } from '../../../../hooks/useNotificationState.js';
import { useModalContext } from '../../../../components/Modal/ModalProvider.js';
import { getTaskUpdateFormInitValues } from '../form/taskFormUtils.js';
import { useProcessState } from '../../../../hooks/useProcessState.js';
import { useTaskUpdatesById } from '../../../../hooks/tasks/index.js';
import {
  useProjectCompleteTaskErrorHelpers,
  useCloseProjectTaskErrorHelpers,
  useUpdateTaskHelpers,
  useProjectTaskInfo,
} from '../form/hooks.js';
import {
  getProjectEntityByCollection,
  prepareDetailsString,
} from '../../../../utils/tasks.js';

const warningMessage =
  'Oops, something went wrong. You may be able to reload page and try again. If the the problem continues, please contact support.';

export function WarningComponent({ onCancel }) {
  const { showWarningNotification } = useNotificationState();

  useEffect(() => {
    showWarningNotification(warningMessage);
    onCancel();
  }, []);
  return null;
}

WarningComponent.propTypes = { onCancel: func.isRequired };

export function CompleteTaskForm(props) {
  const {
    formId,
    payload: {
      _id,
      collection,
      scheduleDate,
      supplierArrivedAt,
      onCancel: onCancelFromPayload,
      readFromCacheParams,
    },
    onCancel: onCancelFromProps,
    customTasksFields,
  } = props;

  const modalContext = useModalContext();

  const { process } = useProcessState(formId);

  const { task, loading } = useTaskUpdatesById(_id);

  const { completeTask, completeDefaultTask, isDefaultTask } =
    useUpdateTaskHelpers({ task, readFromCacheParams, customTasksFields });

  const { loading: closeProjectTaskLoading, taskError: closeTaskError } =
    useCloseProjectTaskErrorHelpers(task, props?.payload);

  const { loading: completeProjectTaskLoading, taskError: completeTaskError } =
    useProjectCompleteTaskErrorHelpers(task, props?.payload);

  const { data } = useProjectTaskInfo({
    collection,
    task,
  });

  const onCancel = () => {
    modalContext.closeModal(formId);

    const onCancelHandler = onCancelFromPayload || onCancelFromProps;

    if (onCancelHandler) {
      onCancelHandler();
    }
  };

  const openConfirmModal = () =>
    modalContext.openConfirmModal({
      ...confirmCancelFormPayload,
      onConfirm: (closeConfirmModal) => R.compose(closeConfirmModal, onCancel),
    });

  const projectId = R.path(
    ['document', getProjectEntityByCollection(collection), 'projectId'],
    task,
  );

  const projectDbId = R.path(
    ['document', getProjectEntityByCollection(collection), '_id'],
    task,
  );

  const mutate = isDefaultTask ? completeDefaultTask : completeTask;

  const taskError = closeTaskError || completeTaskError;

  const initialValues = getTaskUpdateFormInitValues({
    ...task,
    project: data?.project,
    taskError,
    collection,
    scheduleDate,
    isDefaultTask,
    supplierArrivedAt,
    isComplete: true,
  });

  const additionalProps = isDefaultTask
    ? {
        entityInfo: R.pipe(R.prop('document'), prepareDetailsString)(task),
      }
    : {};

  if (loading || closeProjectTaskLoading || completeProjectTaskLoading)
    return <Loader />;

  if (!task) {
    return <WarningComponent onCancel={onCancel} />;
  }

  return (
    <TaskFormWrapper
      {...props}
      {...additionalProps}
      task={task}
      error={taskError}
      isComplete
      submitCaption="Complete"
      title={task?.description}
      updates={task?.documentUpdates}
      suppliers={data?.project?.suppliers || []}
      projectType={data?.project?.type}
      projectId={projectId}
      projectDbId={projectDbId}
      mutate={mutate}
      initialValues={initialValues}
      actionInProgress={process}
      onCancel={onCancel}
      openConfirmModal={openConfirmModal}
    />
  );
}

CompleteTaskForm.displayName = 'CompleteTaskForm';

CompleteTaskForm.propTypes = {
  formId: string.isRequired,
  isTaskUpdateMessageOptional: bool,
  customTasksFields: arrayOf(string),
  onCancel: func,
  payload: shape({
    onCancel: func,
    _id: string,
    collection: string,
  }),
};
