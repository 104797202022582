import React from 'react';
import { IconButton } from '@poly/admin-book';
import { func, number } from 'prop-types';

export function DeletePreAssignedUserGroupButton({
  index,
  onDeletePreAssignedUserGroup,
}) {
  return (
    <IconButton
      size={12}
      name="delete"
      hoverColor="red"
      onClick={() => onDeletePreAssignedUserGroup(index)}
    />
  );
}

DeletePreAssignedUserGroupButton.propTypes = {
  index: number,
  onDeletePreAssignedUserGroup: func,
};
