import * as R from 'ramda';
import React from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { LinkButtonWithLoader } from '@poly/admin-book';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';

import { printChecksTableConfig } from './PrintChecksTableConfig.js';
import { getCheckAmountCellAttrsByCheck } from '../PrintChecksDetailsPage/SupplierCheckAmountTableCell.js';

// getPrintChecksXlsExportConfigRow :: AACCheck -> [ExcelExportDataCell]
const getPrintChecksXlsExportConfigRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 5),
    ExcelExportCellType.defaultCurrency,
    ExcelExportCellType.default,
  ]),
  R.juxt([
    printChecksTableConfig[0][1],
    printChecksTableConfig[1][1],
    printChecksTableConfig[2][1],
    printChecksTableConfig[3][1],
    printChecksTableConfig[4][1],
    R.compose(R.prop('children'), getCheckAmountCellAttrsByCheck),
    printChecksTableConfig[6][1],
  ]),
);

export const getPrintChecksXlsExportConfig = (checks) => ({
  exportFileName: 'print_checks_export.xlsx',
  columnWidths: [15, 30, 15, 15, 20, 20, 15],
  rows: [
    [
      {
        value: 'Print Checks',
        cellType: ExcelExportCellType.title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 5),
        ExcelExportCellType.tableCurrencyHeader,
        ExcelExportCellType.tableHeader,
      ],
      [
        'Payment Date',
        'Supplier Name',
        'Supplier Status',
        'Supplier Tax ID #',
        'Bank',
        'Check Amount',
        'Check Number',
      ],
    ),
    ...R.map(getPrintChecksXlsExportConfigRow, checks),
  ],
});

export function PrintChecksXlsExportButton({ adminChecks, disabled }) {
  const onClick = () => {
    const excelConfig = getPrintChecksXlsExportConfig(adminChecks);

    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader disabled={disabled} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}

PrintChecksXlsExportButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  adminChecks: arrayOf(object),
  disabled: bool,
};
