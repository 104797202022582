import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DefaultBodyWrapper } from '@poly/admin-book';
import { BulkEditObjectType } from '@poly/constants';
import { UPDATE_PROJECT_PERMISSION } from '@poly/security';
import {
  TableCardWithPaginationContainer,
  useSetItemsCount,
} from '@poly/admin-ui';
import {
  useSortableTable,
  useHasUserAccessWithPermission,
} from '@poly/client-utils';

import { BulkEditFooter } from './BulkEditFooter.js';
import { BulkEditingTable } from './BulkEditingTable.js';
import { BulkEditingPageHeader } from './BulkEditingPageHeader.js';
import { getProjectsByQueryData } from './helpers/query-data.js';
import { useBulkEditProjectsQuery } from './useBulkEditProjectsQuery.js';
import { bulkEditProjectTableConfig } from './table-config/bulkEditProjectTableConfig.js';

const HEADER_HEIGHT = '80px';
const FOOTER_HEIGHT = '60px';

// countFoundDocuments :: { searchProjects: { hits: [Project] }, searchRecurringProjects: { hits: [RecurringProject] } } -> Int
const countFoundDocuments = R.converge(R.add, [
  R.pathOr(0, ['searchRecurringProjects', 'total']),
  R.pathOr(0, ['searchProjects', 'total']),
]);

export function BulkEditingPage() {
  const [input, setInput] = useState({});
  const [documents, setDocuments] = useState([]);
  const [objectType, setObjectType] = useState(BulkEditObjectType.PROJECTS);
  const [documentsCount, setDocumentsCount] = useState(0);

  useSetItemsCount(R.identity, documentsCount);

  const isUserWithUpdateProjectPermission = useHasUserAccessWithPermission(
    UPDATE_PROJECT_PERMISSION,
  );

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: bulkEditProjectTableConfig,
  });

  const pagination = useSelector(R.prop('pagination'));

  const { data, loading } = useBulkEditProjectsQuery({
    sort,
    input,
    isUserWithUpdateProjectPermission,
  });

  useEffect(() => {
    if (!loading) {
      setDocuments(getProjectsByQueryData(data));
      setDocumentsCount(countFoundDocuments(data));
    }
  }, [data, loading]);

  return (
    <>
      <BulkEditingPageHeader
        sort={sort}
        input={input}
        height={HEADER_HEIGHT}
        setInput={setInput}
        setObjectType={setObjectType}
        documentsCount={documentsCount}
      />
      <DefaultBodyWrapper
        height={`calc(100% - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`}
      >
        <TableCardWithPaginationContainer>
          <BulkEditingTable
            documents={documents}
            pagination={pagination}
            {...tableSortProps}
          />
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
      <BulkEditFooter
        height={FOOTER_HEIGHT}
        disabled={documentsCount === 0}
        querySort={sort}
        objectType={objectType}
        queryInput={input}
        setDocuments={setDocuments}
        setQueryInput={setInput}
        setDocumentsCount={setDocumentsCount}
      />
    </>
  );
}
