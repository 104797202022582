import React from 'react';
import { useDispatch } from 'react-redux';
import { string, array, object, oneOfType } from 'prop-types';
import { CommonTablePrintBtn, openModal } from '@poly/admin-ui';
import { PRINT_TYPES } from '@poly/constants';

import {
  PrintProjectsTable,
  PrintRecalledProjectsTable,
} from '../../tables/projectsTable/printPDFUtils.js';
import {
  PrintRecentActiveProjectsTable,
  PrintRecentCompletedProjectsTable,
} from '../../tables/recentProjectsTable/printPDFUtils.js';
import { PrintMyProjectsTable } from '../../tables/myProjectsTable/printPDFUtils.js';
import { PrintTimeSheetReportTable } from '../../tables/timeSheetReportTable/printPDFUtils.js';
import { SupplierInvoicesPrintTable } from '../../tables/supplierInvoicesTable/SupplierInvoicesTable.js';
import { PrintMasterSupplierInvoicesTable } from '../../../pages/MasterSupplier/masterSupplierTabs/MasterSupplierInvoicesTab.js';
import { usePrintLayout } from '../../../hooks/usePrintLayout.js';
import { modalNames } from '../../modals/index.js';
import { QuoteDiscrepanciesPageTable } from '../../../pages/QuoteDiscrepancies/QuoteDiscrepanciesPageTable.js';

export const printTables = {
  [PRINT_TYPES.PROJECTS]: PrintProjectsTable,
  [PRINT_TYPES.MY_PROJECTS]: PrintMyProjectsTable,
  [PRINT_TYPES.TIME_SHEET_REPORT]: PrintTimeSheetReportTable,
  [PRINT_TYPES.SUPPLIER_INVOICES]: SupplierInvoicesPrintTable,
  [PRINT_TYPES.RECALLED_PROJECTS]: PrintRecalledProjectsTable,
  [PRINT_TYPES.RECENT_ACTIVE_PROJECTS]: PrintRecentActiveProjectsTable,
  [PRINT_TYPES.MASTER_SUPPLIER_INVOICES]: PrintMasterSupplierInvoicesTable,
  [PRINT_TYPES.RECENT_COMPLETED_PROJECTS]: PrintRecentCompletedProjectsTable,
  [PRINT_TYPES.QUOTE_DISCREPANCIES]: QuoteDiscrepanciesPageTable,
};

export const printTableFileNames = {
  [PRINT_TYPES.PROJECTS]: 'projects',
  [PRINT_TYPES.MY_PROJECTS]: 'my_projects',
  [PRINT_TYPES.TIME_SHEET_REPORT]: 'timesheet_report',
  [PRINT_TYPES.SUPPLIER_INVOICES]: 'supplier_invoices',
  [PRINT_TYPES.RECALLED_PROJECTS]: 'recalled_projects',
  [PRINT_TYPES.RECENT_ACTIVE_PROJECTS]: 'recent_active_projects',
  [PRINT_TYPES.MASTER_SUPPLIER_INVOICES]: 'master_supplier_invoices',
  [PRINT_TYPES.RECENT_COMPLETED_PROJECTS]: 'recent_completed_projects',
  [PRINT_TYPES.QUOTE_DISCREPANCIES]: 'quote_discrepancies',
};

export function TablePrintBtn(props) {
  const { data, metaData, printType } = props;

  const Layout = usePrintLayout();
  const dispatch = useDispatch();

  const openWarningModal = () =>
    dispatch(
      openModal({
        name: modalNames.CONFIRM_PRINT,
        payload: {
          data,
          metaData,
          printType,
        },
      }),
    );

  const btnProps = {
    ...props,
    Layout,
    openWarningModal,
    Table: printTables[printType],
    fileName: printTableFileNames[printType],
  };

  return <CommonTablePrintBtn {...btnProps} />;
}

TablePrintBtn.displayName = 'TablePrintBtn';
/* eslint-disable react/forbid-prop-types */
TablePrintBtn.propTypes = {
  data: oneOfType([array, object]),
  metaData: object,
  printType: string.isRequired,
};
