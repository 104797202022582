import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { object, bool, func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { formatTotal, tryCallFunction, debounce } from '@poly/utils';
import { Button, Loader } from '@poly/admin-book';
import {
  useNotificationState,
  useProcessState,
  useModalContext,
} from '@poly/admin-ui';

import {
  BottomPanelBody,
  BottomPanelContainer,
} from '../../components/BottomPanel.js';
import {
  PageWithSearchHeader,
  PageWithSearchHeaderContext,
} from '../../components/PageWithSearchHeader.js';
import {
  WhiteBodyWrapper,
  PayInvoicesTableCard,
} from '../PaySuppliersPage/PaySuppliersComponents.js';
import { TableAndPanelWrapper } from './styles.js';
import { paymentsDataPropTypes } from './propTypes.js';
import { VoidSupplierPaymentsTable } from './VoidSupplierPaymentsTable.js';
import { VoidSupplierPaymentsHeader } from './VoidSupplierPaymentsHeader.js';
import { useVoidSupplierPaymentsQuery } from './useVoidSupplierPaymentsQuery.js';
import {
  convertPaymentsIdsToBatchNumbers,
  calculateSelectedPaymentsTotal,
  getBatchPaymentsByData,
} from './helpers.js';

const VoidButtonS = styled(Button)`
  align-self: flex-end;
`;

const voidSupplierPaymentMutationQuery = gql`
  mutation VoidSupplierPayment($input: VoidSupplierPaymentsInput!) {
    voidSupplierPayments(input: $input) {
      transactionNumbers
    }
  }
`;

const VOID_PAYMENT_PROCESS_ID = 'void_payment_process_id';

function VoidSupplierPaymentsBody({ data, loading, refetch, tableProps }) {
  const [isRefetching, setIsRefetching] = useState(false);
  const { openConfirmSubmitFormModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const { isVoidInProcess } = useProcessState(VOID_PAYMENT_PROCESS_ID);

  const [voidSupplierPaymentsMutation] = useMutation(
    voidSupplierPaymentMutationQuery,
  );

  const [selectedPayments, setSelectedPayments] = useState([]);

  useEffect(() => {
    setSelectedPayments([]);
  }, [data]);

  const batchPayments = useMemo(() => getBatchPaymentsByData(data), [data]);

  const debouncedRefetch = useCallback(
    debounce(2000)(() => tryCallFunction(refetch)()),
    [refetch],
  );

  const onVoidSupplierPayments = async () => {
    const total = calculateSelectedPaymentsTotal(
      batchPayments,
      selectedPayments,
    );

    return openConfirmSubmitFormModal({
      content: (
        <>
          <div>
            {/* eslint-disable-next-line @cspell/spellchecker */}
            <span>You&apos;re about to void&nbsp;</span>
            <strong>{selectedPayments.length}</strong>
            <span>&nbsp;payments worth&nbsp;</span>
            <strong>{formatTotal(total)}</strong>
          </div>
          <div>Are you sure?</div>
        </>
      ),
      btnCaption: 'Yes, Void',
      processId: VOID_PAYMENT_PROCESS_ID,
      onConfirm: (closeConfirmModal) => async () => {
        await voidSupplierPaymentsMutation({
          variables: {
            input: {
              transactionNumbers:
                convertPaymentsIdsToBatchNumbers(selectedPayments),
            },
          },
        });
        setIsRefetching(true);
        setSelectedPayments([]);
        showSuccessNotification('Payments are successfully voided!');
        closeConfirmModal();
        // we need to timeout this to get actual data after mutation
        debouncedRefetch();
      },
    });
  };

  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  useEffect(() => {
    setIsRefetching(false);
  }, [batchPayments]);

  return loading ? (
    <Loader />
  ) : (
    <WhiteBodyWrapper height={contentHeight}>
      <TableAndPanelWrapper>
        <PayInvoicesTableCard height="calc(100% - 70px)">
          <VoidSupplierPaymentsTable
            isRefetching={isRefetching}
            batchPayments={batchPayments}
            selectedPayments={selectedPayments}
            setSelectedPayments={setSelectedPayments}
            {...tableProps}
          />
        </PayInvoicesTableCard>
        <BottomPanelContainer height="70px">
          <BottomPanelBody>
            <VoidButtonS
              size="small"
              loader={isVoidInProcess}
              onClick={onVoidSupplierPayments}
              disabled={!selectedPayments.length || isVoidInProcess}
            >
              Void
            </VoidButtonS>
          </BottomPanelBody>
        </BottomPanelContainer>
      </TableAndPanelWrapper>
    </WhiteBodyWrapper>
  );
}

VoidSupplierPaymentsBody.propTypes = {
  loading: bool,
  refetch: func.isRequired,
  data: paymentsDataPropTypes,
  // eslint-disable-next-line react/forbid-prop-types
  tableProps: object.isRequired,
};

export function VoidSupplierPaymentsPage() {
  const [query, setQuery] = useState({});

  const { data, loading, refetch, tableProps } =
    useVoidSupplierPaymentsQuery(query);

  return (
    <PageWithSearchHeader headerHeight="165px">
      <VoidSupplierPaymentsHeader {...{ setQuery, loading, data }} />
      <VoidSupplierPaymentsBody
        data={data}
        loading={loading}
        refetch={refetch}
        tableProps={tableProps}
      />
    </PageWithSearchHeader>
  );
}
