import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { arrayOf, func, shape, string } from 'prop-types';
import {
  keywordSortQuery,
  highlightMatchesInObject,
  commonSortQuery,
} from '@poly/client-utils';
import { Loader, Table } from '@poly/admin-book';
import { assocBy } from '@poly/utils';
import { MasterSupplierLink } from '../../../components/Links.js';
import { getServiceTypes } from '../../tables/index.js';
import { useTableSorting } from '../../../hooks/useTableSorting.js';
import { useSearchMasterSuppliersQuery } from '../hooks/useSearchMasterSuppliersQuery.js';
import { useSetItemsCount } from '../../../hooks/useSetItemsCount.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';

const MasterSuppliersTableS = styled(Table)`
  td:last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

// highlightMasterSuppliersMatches :: String -> Supplier -> Supplier
const highlightMasterSuppliersMatches = (search) => (object) =>
  highlightMatchesInObject({
    object,
    paths: [
      ['company', 'name'],
      ['company', 'address', 'formatted_address'],
      ['serviceTypesStr'],
    ],
    search,
  });

// prepareMasterSuppliersForTable :: String -> MasterSuppliersSearchResult -> [MasterSupplier]
const prepareMasterSuppliersForTable = (searchTerm) =>
  R.compose(
    R.map(
      R.compose(
        highlightMasterSuppliersMatches(searchTerm),
        assocBy('serviceTypesStr', getServiceTypes),
      ),
    ),
    R.pathOr([], ['searchMasterSuppliers', 'hits']),
  );

const masterSuppliersTableConfig = (masterSupplierRootUrl) => [
  [
    'Name',
    (props) => (
      <MasterSupplierLink
        masterSupplierRootUrl={masterSupplierRootUrl}
        {...props}
      />
    ),
    keywordSortQuery(['company', 'name']),
  ],
  [
    'Address',
    R.path(['company', 'address', 'formatted_address']),
    keywordSortQuery(['company', 'address', 'formatted_address']),
  ],
  [
    'Service Types',
    R.prop('serviceTypesStr'),
    commonSortQuery(['serviceTypesNamesStr']),
  ],
];

// eslint-disable-next-line import/no-unused-modules
export function MasterSuppliersPrintTable({
  masterSupplierRootUrl,
  masterSuppliers,
}) {
  const tableConfig = masterSuppliersTableConfig(masterSupplierRootUrl);

  const tableProps = useMapConfigToTableProps(
    R.map(assocBy('serviceTypesStr', getServiceTypes)),
    tableConfig,
    masterSuppliers,
  );

  return <MasterSuppliersTableS {...tableProps} />;
}

MasterSuppliersPrintTable.propTypes = {
  masterSupplierRootUrl: string.isRequired,
  masterSuppliers: arrayOf(
    shape({
      _id: string.isRequired,
      company: shape({ name: string.isRequired }),
    }),
  ),
};

export function MasterSuppliersTable({
  status,
  type,
  masterSupplierRootUrl,
  setSort,
}) {
  const tableConfig = masterSuppliersTableConfig(masterSupplierRootUrl);
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    column: 1,
  });

  useEffect(() => {
    if (setSort) {
      setSort(sort);
    }
  }, [sort]);

  const searchTerm = useSelector(R.prop('searchText'));

  const { data, loading } = useSearchMasterSuppliersQuery(status, type, sort);

  useSetItemsCount(R.pathOr(0, ['searchMasterSuppliers', 'total']), data);

  const tableProps = useMapConfigToTableProps(
    prepareMasterSuppliersForTable(searchTerm),
    tableConfig,
    data,
  );

  return loading ? (
    <Loader />
  ) : (
    <MasterSuppliersTableS {...tableSortingProps} {...tableProps} />
  );
}

MasterSuppliersTable.propTypes = {
  status: string.isRequired,
  type: string.isRequired,
  masterSupplierRootUrl: string.isRequired,
  setSort: func,
};
