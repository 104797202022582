import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import React, { useState, useEffect } from 'react';
import { useLocation, useUpdateQueryParams } from '@poly/client-routing';
import { Loader } from '@poly/admin-book';
import { paginationToQueryParams, useSortableTable } from '@poly/client-utils';
import {
  useSetInitialPaginationOnMount,
  useSetItemsCount,
  TableCardWithPaginationContainer,
} from '@poly/admin-ui';

import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { defaultFilters } from './constants.js';
import {
  CustomerHistoryTable,
  customerTransactionHistoryConfig,
} from './CustomerHistoryTable.js';
import {
  CustomerHistoryPageHeader,
  customerHistoryPageHeaderConfig,
} from './CustomerHistoryPageHeader.js';
import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
} from '../../components/PageWithSearchHeader.js';

// prepareQueryInput :: PageFilters -> CustomerHistoryInput
// PageFilters = {
//   invoiceDate: {
//     startDate: String
//     endDate: String
//   },
//   invoiceId: String
//   invoiceStatus: String
//   clientId: String
// }
const prepareQueryInput = R.compose(
  R.mergeAll,
  R.juxt([R.omit(['invoiceDate']), R.propOr({}, 'invoiceDate')]),
);

export const GET_CUSTOMER_HISTORY_QUERY = gql`
  query GET_CUSTOMER_HISTORY($input: CustomerHistoryInput!) {
    getCustomerHistory(input: $input) {
      client {
        name
      }
      transactions {
        total
        hits {
          _id
          date
          type
          accountName
          invoice {
            _id
            number
          }
          project {
            _id
            projectId
          }
          invoiceAmount
          paymentAmount
        }
      }
    }
  }
`;

export function ClientHistoryPage() {
  const pagination = useSelector(R.prop('pagination'));
  const updateQueryParams = useUpdateQueryParams();
  const location = useLocation();

  const [filters, setFilters] = useState(null);

  const { searchFilters } = useSearchFilters(customerHistoryPageHeaderConfig);

  useSetInitialPaginationOnMount();

  useEffect(() => {
    if (R.isEmpty(location.search)) {
      updateQueryParams(defaultFilters);
    } else {
      setFilters(searchFilters);
    }
  }, []);

  const { sort, ...tableSortProps } = useSortableTable({
    column: 2,
    tableConfig: customerTransactionHistoryConfig,
  });

  const variables = {
    input: {
      ...prepareQueryInput(filters),
      ...paginationToQueryParams(pagination),
      sort,
    },
  };
  const skip = !filters?.clientId;
  const { data, loading } = useQuery(GET_CUSTOMER_HISTORY_QUERY, {
    variables,
    skip,
  });

  useSetItemsCount(
    R.pathOr(0, ['getCustomerHistory', 'transactions', 'total']),
    data,
  );
  const transactions = R.pathOr(
    [],
    ['getCustomerHistory', 'transactions', 'hits'],
    data,
  );
  const customerName = R.pathOr(
    '',
    ['getCustomerHistory', 'client', 'name'],
    data,
  );

  const exportProps = { transactions, customerName, filters };

  return (
    <PageWithSearchHeader headerHeight="220px">
      <CustomerHistoryPageHeader
        loading={loading}
        setFilters={setFilters}
        exportProps={exportProps}
      />
      <SearchPageContentWrapper>
        <TableCardWithPaginationContainer>
          {loading ? (
            <Loader />
          ) : (
            <CustomerHistoryTable
              transactions={transactions}
              {...tableSortProps}
            />
          )}
        </TableCardWithPaginationContainer>
      </SearchPageContentWrapper>
    </PageWithSearchHeader>
  );
}
