import * as R from 'ramda';
import React from 'react';

import { arrayOf, bool, oneOfType, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';
import { ClientStatuses } from '@poly/constants';
import { useRouterQuery } from '@poly/client-routing/src/hooks.js';

import { PageTabMap } from './constants.js';
import { useClientsLazyQuery } from './useClientsLazyQuery.js';
import { getXlsClientsConfig } from './getXlsClientsConfig.js';
import { ClientsPrintTable } from '../../modules/tables/clientsTable/ClientsTable.js';
import { PrintExportButtons } from '../../components/ExportButtons.js';

const { ACTIVE, INACTIVE } = ClientStatuses;

const PageTabToStatusMap = {
  [PageTabMap.ACTIVE_CLIENTS]: ACTIVE,
  [PageTabMap.INACTIVE_CLIENTS]: INACTIVE,
};

// getQueryByTab :: String -> String
const getStatusByTab = R.compose(
  R.defaultTo(ACTIVE),
  R.prop(R.__, PageTabToStatusMap),
);

const getExportFineNameByStatus = ({ withStatus, title, status }) =>
  withStatus
    ? `${R.toLower(title)}_${status}_export`
    : `${R.toLower(title)}_export`;

export function ClientsExportButtonsBase({ sort, status, withStatus }) {
  const totalCount = useSelector(R.path(['pagination', 'allItemsCount']));

  const { runClientsQuery, loading } = useClientsLazyQuery({
    sort,
    status,
  });

  const title = withStatus ? 'Clients' : 'Prospects';

  const exportFileName = getExportFineNameByStatus({
    withStatus,
    status,
    title,
  });

  const handlePrintPDF = async () => {
    const clients = await runClientsQuery();

    await openPrintWindowWithData({
      fileName: `${exportFileName}.pdf`,
      Layout: CommonPrintLayout,
      Table: ClientsPrintTable,
      metaData: {
        title,
      },
      clients,
    });
  };

  const handleExportXLS = async () => {
    const clients = await runClientsQuery();

    const exportConfig = getXlsClientsConfig({
      clients,
      exportFileName,
      withStatus,
    });
    return performExcelExport(exportConfig);
  };

  return (
    <PrintExportButtons
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
      loading={loading}
      disabled={totalCount === 0}
    />
  );
}

ClientsExportButtonsBase.propTypes = {
  sort: arrayOf(oneOfType([string, shape({})])),
  status: string.isRequired,
  withStatus: bool,
};

export function ClientsExportButtons(props) {
  const { tab } = useRouterQuery(['tab']);
  const status = getStatusByTab(tab);

  return <ClientsExportButtonsBase {...props} status={status} withStatus />;
}
