export const JournalTypes = {
  // For client invoices:
  ACC_RECEIVABLE: 'ACC_RECEIVABLE', // Accounts Receivable Invoice
  ACC_RECEIVABLE_PAYMENT: 'ACC_RECEIVABLE_PAYMENT', // Payment on an Accounts Receivable Invoice

  // For supplier invoices:
  ACC_PAYABLE: 'ACC_PAYABLE', // Accounts Payable Invoice
  ACC_PAYABLE_PAYMENT: 'ACC_PAYABLE_PAYMENT', // Payment on an Accounts Payable Invoice
  ACC_PAYABLE_PAYMENT_VOID: 'ACC_PAYABLE_PAYMENT_VOID', // Entry that voids AP payment journal

  DEPOSIT: 'DEPOSIT_JOURNAL',
  MANUAL: 'MANUAL_JOURNAL',
  TRANSFER: 'TRANSFER',
  PAYROLL: 'PAYROLL',
  WORK_IN_PROGRESS: 'WORK_IN_PROGRESS_JOURNAL',
  CLOSING: 'CLOSING_JOURNAL',
  RECURRING: 'RECURRING_JOURNAL',
  REVERSE: 'REVERSE_JOURNAL',
};

export const JournalPaymentStatus = {
  PENDING: 'pendingPaymentJournal',
  RECONCILED: 'reconciledPaymentJournal',
  VOIDED: 'voidedPaymentJournal',
  WRITTEN_OFF: 'writtenOffPaymentJournal',
};

export const JournalPaymentMode = {
  CHECK: 'checkPaymentMode',
  ACH: 'achPaymentMode',
  WIRE: 'wirePaymentMode',
  CREDIT_CARD: 'creditCardPaymentMode',
};

export const JournalQueryTypes = {
  BANK_DEPOSIT: 'BANK_DEPOSIT_QUERY',
  BANK_CREDIT: 'BANK_CREDIT_QUERY',
  SUPPLIER_PAYMENTS: 'SUPPLIER_PAYMENTS_QUERY',
};

export const editableClientPaymentsSqlSortFields = {
  DATE: 'date',
  CLIENT_NAME: 'client_name_by_sort',
  PROJECT_ID: 'project_id_by_sort',
  CLIENT_INVOICE_NUMBER: 'client_invoice_number_by_sort',
  CLIENT_INVOICE_AMOUNT: 'client_invoice_amount_by_sort',
};

export const REF_NUMBER_LENGTH_LIMIT = 200;

export const accountsPayableReportSqlSortFields = {
  AMOUNT: 'amount',
  SUPPLIER_NAME: 'supplier_name_by_sort',
};

export const accountsReceivableReportSqlSortFields = {
  AMOUNT: 'amount',
  CLIENT_NAME: 'client_name_by_sort',
};
