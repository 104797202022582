import React from 'react';
import { Loader } from '@poly/admin-book';
import styled from 'styled-components';
import { VendorInfoSection } from '@poly/admin-ui';

import { useAuthUserByTemporalAccess } from '../DocumentsRequest/DocumentsRequestPage.js';
import { useReviewProjectEstimate } from './useReviewProjectEstimate.js';
import {
  ContentWrapper,
  PageContainer,
} from '../DocumentsRequest/components.js';
import { ReviewProjectEstimateInfo } from './ReviewProjectEstimateInfo.js';
import { ReviewProjectEstimateForm } from './ReviewProjectEstimateForm.js';

const PageContainerS = styled(PageContainer)`
  background-color: #fafafa;
`;

const ContentWrapperS = styled(ContentWrapper)`
  width: 615px;
`;

export function ReviewProjectEstimatePage() {
  const { isAuthorized } = useAuthUserByTemporalAccess();

  const { projectEstimate, loading } = useReviewProjectEstimate(isAuthorized);

  if (!isAuthorized) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <PageContainerS>
      <ContentWrapperS>
        <ReviewProjectEstimateInfo projectEstimate={projectEstimate} />
        <ReviewProjectEstimateForm />
        <VendorInfoSection withInsuranceLink={false} />
      </ContentWrapperS>
    </PageContainerS>
  );
}
