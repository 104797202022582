import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState, useMemo } from 'react';
import { string, bool, arrayOf, shape } from 'prop-types';
import { getThemeColor } from '@poly/admin-book/src/utils.js';

import { UpdateImageLink } from './UpdateImageLink.js';
import { GalleryModalComp } from '../../modals/GalleryModal.js';
import { useModalContext } from '../../../components/Modal/ModalProvider.js';

const showImagesLimit = 6;
const updateImagesModalId = 'update_images_modal_id';

const UpdateImagesSectionWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ImagesRowS = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;

  > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const CollapseButtonWrapperS = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
`;

const CollapseButtonS = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: ${getThemeColor(['primaryLight'])};
`;

const GalleryWrapperS = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);

  > div:last-child {
    width: calc(100% - 100px);
    background-color: #777777;
    border-radius: 5px;
  }
`;

// getGalleryModalProps :: Payload -> GalleryModalProps
const getGalleryModalProps = R.compose(
  R.over(
    R.lensProp('settings'),
    R.mergeDeepRight({
      speed: 500,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    }),
  ),
  R.applySpec({
    settings: {
      initialSlide: R.propOr(0, 'index'),
    },
    images: R.compose(R.defaultTo([]), R.prop('images')),
  }),
);

export function UpdateImagesSection({ images, isEmailAttachment }) {
  const { openModal, closeModal } = useModalContext();
  const [isExtended, setIsExtended] = useState(false);

  const shouldCollapse = useMemo(
    () => images.length > showImagesLimit,
    [images],
  );

  const preparedImages = useMemo(
    () => (isExtended ? images : R.take(showImagesLimit, images)),
    [images, isExtended],
  );

  const onImageClick = (payload) =>
    openModal({
      simple: true,
      id: updateImagesModalId,
      Layout: GalleryWrapperS,
      content: (
        <GalleryModalComp
          {...getGalleryModalProps(payload)}
          closeGallery={() => closeModal(updateImagesModalId)}
        />
      ),
    });

  return (
    <UpdateImagesSectionWrapperS>
      {shouldCollapse ? (
        <CollapseButtonWrapperS>
          <CollapseButtonS onClick={() => setIsExtended(!isExtended)}>
            {isExtended ? 'hide images' : 'show all images'}
          </CollapseButtonS>
        </CollapseButtonWrapperS>
      ) : null}
      <ImagesRowS>
        {preparedImages.map((image, index) => (
          <UpdateImageLink
            {...image}
            key={image.url}
            isEmailAttachment={isEmailAttachment}
            onClick={() => onImageClick({ index, images })}
          />
        ))}
      </ImagesRowS>
    </UpdateImagesSectionWrapperS>
  );
}

UpdateImagesSection.propTypes = {
  isEmailAttachment: bool.isRequired,
  images: arrayOf(shape({ _id: string.isRequired, url: string.isRequired })),
};
