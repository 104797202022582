import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { LISTING_PROJECT_SPECIFIC_FIELDS_UI_MAP as LISTING_LABELS } from '@poly/constants';
import { DatePicker, Select } from '@poly/admin-book';
import { isChildRecurringProject } from '@poly/client-utils';
import { isUrgentOrEmergencyProject } from '@poly/utils';
import {
  commonSidebarFormFieldLayout,
  getContactSelect,
  MoneyInput,
} from '@poly/admin-ui';

import { SupplierArrivalTimePicker } from '@poly/admin-ui/src/components/SupplierArrivalTimePicker.js';

import {
  StartDatePicker,
  endDateHalfFormFieldConfig,
  endDateThirdFormFieldConfig,
  startDateHalfFormFieldConfig,
  workPriorityFormFieldConfig,
  startDateThirdFormFieldConfig,
  workCompletionDateThirdFormField,
  workCompletionDateHalfFormField,
} from './sections/planningSection.js';
import {
  getSuppliersNTESection,
  bidAmountFormFieldConfig,
  clientRefNoPoFormFieldConfig,
  clientNotToExceedFormFieldConfig,
  clientPOSelectFormFieldConfig,
} from './sections/notToExceedSection.js';
import {
  endDateRecurringFormFieldConfig,
  isNeverRecurringFormFieldConfig,
  contractRecurringFormFieldConfig,
  scheduleRecurringFormFieldConfig,
  startDateRecurringFormFieldConfig,
} from './sections/recurringSection.js';
import {
  notRMFormField,
  clientFormField,
  statusFormField,
  feeTypeFormField,
  costTypeFormField,
  taskTypeFormField,
  propertyFormField,
  requesterFormField,
  aacManagerFormField,
  occurrenceFormField,
  descriptionFormField,
  serviceTypeFormField,
  siteContactFormField,
  problemCodeFormField,
  clientInfoHolderField,
  clientManagerFormField,
  clientGLCodesSelectConfig,
  projectSpendTypeFormField,
  masterProjectSuppliersField,
  feeTypeDescriptionFormField,
  projectSubCategoryFormField,
  preventativeRepairChildProjectField,
  preventativeRepairRelatedAssetField,
  callbackFormFieldConfig,
  recallReferenceProjectField,
  enableCollectDocumentsTaskFormFieldConfig,
} from './sections/generalInformationSection.js';
import {
  divisionSelectConfig,
  feeAmountFormFieldConfig,
  costCenterFormFieldConfig,
  nonBillableFormFieldConfig,
  nonBillableReasonFormConfigs,
  exemptSalesTaxFormFieldConfig,
  afterHoursCallFormFieldConfig,
  clientHourlyRateFormFieldConfig,
  weeklyPorterHoursFormFieldConfig,
  supplierHourlyRateFormFieldConfig,
  bidAmountFinancialFormFieldConfig,
  clientRefNoPoFinancialFormFieldConfig,
  monthlyHousekeepingClientFormFieldConfig,
  clientNotToExceedFinancialFormFieldConfig,
  monthlyHousekeepingSupplierFormFieldConfig,
  clientPOFinancialFormFieldConfig,
} from './sections/finacialInfoSection.js';
import { EMPTY_PROPERTY_LOCATION_VALUE } from './utils/constants.js';
import { CheckboxWithLabel } from './components/CheckboxWithLabel.js';
import { halfWidth, thirdWidth } from '../../../../modules/forms/common.js';
import { invoiceDescriptionFormFieldConfig } from './sections/invoiceDescriptionSection.js';
import { RecurringScheduler } from '../../../../components/RecurringScheduler/RecurringScheduler.js';
import {
  renderNotToExceedSection,
  renderClientEmailSection,
  isRecurringOrPMFormData,
  renderPlanningSection,
  isListingProjectType,
  isNotFeeProjectType,
  isHousekeepingProjectType,
} from './utils/sections.js';
import { subPropertyFormField } from './sections/subPropertyFormField.js';
import { adminPOInfoHolderField } from './components/AdminPOSelector.js';

function RecurringSchedulerTop(props) {
  return <RecurringScheduler selectorDirection="up" {...props} />;
}

function DateSelectorFullWidth(props) {
  return <DatePicker {...props} width="100%" leftMove="-20px" />;
}

function CloneWithoutReferenceCheckbox({ formData, ...props }) {
  return (
    <CheckboxWithLabel
      {...props}
      label={`Create without reference (${formData?.cloneFromProjectNumber})`}
    />
  );
}

CloneWithoutReferenceCheckbox.propTypes = {
  formData: shape({
    cloneFromProjectNumber: string.isRequired,
  }),
};

const sectionBorderLayout = {
  borderBottom: '1px solid',
  borderBottomColor: 'light',
};

const sectionWithoutMarginLayout = { margin: 0 };

const propertyLocationQuery = gql`
  query propertyLocationQuery($propertyId: ID!) {
    property(id: $propertyId) {
      _id
      locationHierarchy {
        _id
        locations {
          id
          name
          path
        }
      }
    }
  }
`;

// getPropertyLocations :: Object -> [PropertyLocation]
const getPropertyLocations = R.pathOr(
  [],
  ['property', 'locationHierarchy', 'locations'],
);

// formatPropertyLocationOption :: PropertyLocationHierarchyNode -> Pair String String
const formatPropertyLocationOption = R.juxt([R.prop('id'), R.prop('path')]);

// formatLocationOptions :: [PropertyLocationHierarchyNode] -> Pair String String
const formatLocationOptions = (locations, currentLocation) =>
  R.compose(
    R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
    R.append([EMPTY_PROPERTY_LOCATION_VALUE, 'Other']),
    R.map(formatPropertyLocationOption),
    R.when(
      R.always(currentLocation),
      R.compose(R.uniqBy(R.prop('id')), R.prepend(currentLocation)),
    ),
  )(locations);

// getPropertyId :: FormValues -> String
const getPropertyId = R.path(['property', '_id']);

// getCurrentLocation :: FormValues -> PropertyLocation
const getCurrentLocation = R.prop('propertyLocation');

function PropertyLocationSelector({ formData, ...props }) {
  const { loading, data } = useQuery(propertyLocationQuery, {
    variables: { propertyId: getPropertyId(formData) },
    fetchPolicy: 'network-only',
  });

  const locations = getPropertyLocations(data);
  const currentLocation = getCurrentLocation(formData);
  const options = formatLocationOptions(locations, currentLocation);

  const selectProps = {
    ...props,
    options,
    loading,
  };

  return <Select {...selectProps} />;
}

PropertyLocationSelector.propTypes = {
  formData: shape({
    propertyId: string,
  }),
};

export const sideBarProjectFormSections = (
  suppliers,
  disableOccurrenceField,
  onChangePersistentValue,
  isCreate,
) => [
  {
    order: 1,
    layout: sectionBorderLayout,
    fields: [
      {
        ...clientFormField,
        order: 1,
        layout: { row: 1, isWrapped: true },
      },
      {
        ...propertyFormField,
        order: 2,
        layout: { row: 1 },
      },
      {
        order: 3,
        layout: { row: 2, width: '100%' },
        ...subPropertyFormField,
      },
      {
        order: 4,
        label: 'Property Location',
        layout: { row: 1 },
        field: {
          name: 'propertyLocationId',
          withFormData: true,
          Component: PropertyLocationSelector,
        },
        renderIf: R.both(
          R.path(['client', 'enablePropertyLocationHierarchy']),
          getPropertyId,
        ),
      },
      {
        ...occurrenceFormField,
        order: 5,
        layout: { row: 3, width: halfWidth },
        disabled: disableOccurrenceField,
      },
      {
        ...costTypeFormField,
        order: 6,
        layout: { row: 3, width: halfWidth },
      },
      {
        ...serviceTypeFormField,
        order: 7,
        layout: { row: 4, width: halfWidth, margin: '0 10px 0 0' },
      },
      {
        ...feeTypeFormField,
        order: 8,
        layout: { row: 4, width: halfWidth },
      },
      {
        ...projectSpendTypeFormField,
        ...(isCreate
          ? {
              order: 8,
              layout: { row: 4, width: halfWidth, margin: '0 0 0 10px' },
            }
          : { order: 18, layout: { row: 9, width: halfWidth } }),
      },
      {
        ...feeTypeDescriptionFormField,
        order: 9,
        layout: { row: 4, width: halfWidth, margin: '0 0 0 10px' },
      },
      {
        ...statusFormField,
        order: 10,
        layout: { row: 4, width: halfWidth },
      },
      {
        ...taskTypeFormField,
        order: 11,
        layout: { row: 5, width: halfWidth },
      },
      {
        ...enableCollectDocumentsTaskFormFieldConfig,
        order: 12,
        layout: { row: 6 },
      },
      {
        ...notRMFormField,
        order: 13,
        layout: { row: 4, width: halfWidth, margin: '0 0 0 10px' },
      },
      {
        ...problemCodeFormField,
        order: 14,
        layout: { row: 5, width: halfWidth },
      },
      {
        ...clientGLCodesSelectConfig,
        order: 15,
        layout: { row: 7, width: halfWidth },
      },
      {
        ...projectSubCategoryFormField,
        order: 16,
        layout: { row: 7, width: halfWidth },
      },
      {
        ...masterProjectSuppliersField,
        order: 17,
        layout: { row: 8, width: '100%' },
      },
      {
        ...preventativeRepairChildProjectField,
        order: 19,
        layout: { row: 10, width: halfWidth },
      },
      {
        ...preventativeRepairRelatedAssetField,
        order: 20,
        layout: { row: 10, width: halfWidth },
      },
      {
        ...clientInfoHolderField,
        order: 21,
        layout: { row: 11, padding: 0 },
      },
      {
        ...callbackFormFieldConfig,
        order: 22,
        layout: { row: 12, padding: 0 },
      },
      {
        ...recallReferenceProjectField,
        order: 23,
        layout: { row: 12, padding: 0 },
      },
    ],
  },
  {
    order: 2,
    layout: sectionBorderLayout,
    fields: [
      {
        ...descriptionFormField,
        order: 1,
        layout: { row: 5, width: '100%' },
      },
    ],
  },
  {
    order: 3,
    layout: sectionBorderLayout,
    fields: [
      {
        ...aacManagerFormField,
        order: 1,
        layout: { row: 2, width: halfWidth },
      },
      {
        ...clientManagerFormField,
        layout: { row: 2, width: halfWidth },
        order: 2,
      },
      {
        order: 1,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'siteContact',
          withFormData: true,
          subFields: [
            ...getContactSelect({
              ...siteContactFormField,
              order: 1,
              layout: {
                row: 3,
                label: { ...commonSidebarFormFieldLayout.label },
              },
            }),
          ],
        },
      },
      {
        order: 2,
        layout: { row: 3 },
        field: {
          name: 'contact',
          withFormData: true,
          subFields: [
            ...getContactSelect({
              ...requesterFormField,
              order: 1,
              layout: {
                row: 3,
                label: { ...commonSidebarFormFieldLayout.label },
              },
            }),
          ],
        },
      },
    ],
  },
  {
    order: 4,
    label: 'Not to exceed',
    layout: sectionWithoutMarginLayout,
    renderIf: renderNotToExceedSection,
    leaveValues: true,
    fields: [
      {
        ...clientNotToExceedFormFieldConfig,
        layout: { row: 1, width: halfWidth },
        order: 1,
      },
      {
        ...clientRefNoPoFormFieldConfig,
        layout: { row: 1, width: halfWidth },
        order: 2,
      },
      {
        ...clientPOSelectFormFieldConfig,
        layout: { row: 1, width: halfWidth },
        order: 2,
      },
      bidAmountFormFieldConfig,
      ...getSuppliersNTESection(2, suppliers),
    ],
  },
  {
    order: 5,
    label: 'Planning',
    layout: sectionWithoutMarginLayout,
    renderIf: renderPlanningSection,
    withFromData: true,
    leaveValues: true,
    fields: [
      {
        ...workPriorityFormFieldConfig,
        layout: {
          row: 1,
          width: 'calc(32% - 10px)',
        },
        order: 1,
      },
      startDateThirdFormFieldConfig,
      endDateThirdFormFieldConfig,
      {
        ...startDateHalfFormFieldConfig,
        field: {
          ...startDateHalfFormFieldConfig.field,
          Component: (props) => <StartDatePicker {...props} leftMove="10px" />,
        },
      },
      endDateHalfFormFieldConfig,
      {
        label: 'Supplier Expected Arrival Time',
        order: 1,
        layout: { row: 2, width: 'calc(32% - 7px)' },
        renderIf: isUrgentOrEmergencyProject,
        disabled: true,
        field: {
          name: 'supplierArrivalTime.expectedAt',
          withFormData: true,
          Component: SupplierArrivalTimePicker,
          additionalProps: { leftMove: '0' },
        },
      },
      {
        label: '',
        order: 1,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'emptyField',
          Component: () => null,
        },
      },
      {
        label: 'Schedule Date',
        order: 2,
        layout: { row: 3, width: halfWidth },
        renderIf: R.allPass([
          isNotFeeProjectType,
          isChildRecurringProject,
          R.complement(isHousekeepingProjectType),
        ]),
        field: {
          name: 'scheduleDate',
          Component: DatePicker,
          additionalProps: { width: '100%', showResetBtn: true },
        },
      },
      {
        label: '',
        order: 1,
        layout: { row: 4, width: thirdWidth },
        field: {
          name: 'emptyField',
          Component: () => null,
        },
      },
      workCompletionDateThirdFormField,
      workCompletionDateHalfFormField,
      {
        label: LISTING_LABELS.bpoListPrice,
        order: 1,
        layout: {
          row: 5,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'bpoListPrice',
          Component: MoneyInput,
        },
      },
      {
        label: LISTING_LABELS.listPrice,
        order: 2,
        layout: {
          row: 5,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'listPrice',
          Component: MoneyInput,
        },
      },
      {
        label: LISTING_LABELS.underContractPrice,
        order: 3,
        layout: {
          row: 5,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'underContractPrice',
          Component: MoneyInput,
        },
      },

      {
        label: LISTING_LABELS.bpoHigh,
        order: 1,
        layout: {
          row: 6,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'bpoHigh',
          Component: MoneyInput,
        },
      },
      {
        label: LISTING_LABELS.bpoLow,
        order: 2,
        layout: {
          row: 6,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'bpoLow',
          Component: MoneyInput,
        },
      },
      {
        label: LISTING_LABELS.bpoDate,
        order: 3,
        layout: {
          row: 6,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'bpoDate',
          Component: DateSelectorFullWidth,
        },
      },
      {
        label: LISTING_LABELS.salesAddendumDate,
        order: 1,
        layout: {
          row: 7,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'salesAddendumDate',
          Component: DateSelectorFullWidth,
        },
      },

      {
        label: LISTING_LABELS.listAgreementExp,
        order: 2,
        layout: {
          row: 7,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'listAgreementExp',
          Component: DateSelectorFullWidth,
        },
      },

      {
        label: LISTING_LABELS.listDate,
        order: 3,
        layout: {
          row: 7,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'listDate',
          Component: DateSelectorFullWidth,
        },
      },
      {
        label: LISTING_LABELS.underContractDate,
        order: 1,
        layout: {
          row: 8,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'underContractDate',
          Component: DateSelectorFullWidth,
        },
      },
      {
        label: LISTING_LABELS.dueDiligenceExp,
        order: 2,
        layout: {
          row: 8,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'dueDiligenceExp',
          Component: DateSelectorFullWidth,
        },
      },
      {
        label: LISTING_LABELS.expectedCloseDate,
        order: 3,
        layout: {
          row: 8,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'expectedCloseDate',
          Component: DateSelectorFullWidth,
        },
      },
      {
        label: LISTING_LABELS.referralFee,
        order: 1,
        layout: {
          row: 9,
          width: thirdWidth,
        },
        renderIf: isListingProjectType,
        field: {
          name: 'referralFee',
          Component: MoneyInput,
        },
      },
    ],
  },
  {
    label: 'Invoice Description',
    layout: sectionWithoutMarginLayout,
    order: 6,
    fields: [
      {
        ...invoiceDescriptionFormFieldConfig(onChangePersistentValue),
        layout: { width: '100%' },
      },
    ],
  },
  {
    label: 'Financial',
    layout: sectionWithoutMarginLayout,
    order: 7,
    fields: [
      exemptSalesTaxFormFieldConfig,
      divisionSelectConfig,
      afterHoursCallFormFieldConfig,
      clientNotToExceedFinancialFormFieldConfig,
      bidAmountFinancialFormFieldConfig,
      feeAmountFormFieldConfig,
      monthlyHousekeepingClientFormFieldConfig,
      monthlyHousekeepingSupplierFormFieldConfig,
      clientHourlyRateFormFieldConfig,
      supplierHourlyRateFormFieldConfig,
      weeklyPorterHoursFormFieldConfig,
      clientRefNoPoFinancialFormFieldConfig,
      clientPOFinancialFormFieldConfig,
      nonBillableFormFieldConfig,
      ...nonBillableReasonFormConfigs,
      costCenterFormFieldConfig,
      { ...adminPOInfoHolderField, layout: { row: 11, padding: 0 } },
    ],
  },
  {
    label: 'Client Service Request Confirmation Email',
    layout: sectionWithoutMarginLayout,
    order: 8,
    renderIf: renderClientEmailSection,
    fields: [
      {
        order: 1,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'notificationConfig.toSiteContact',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Site Contact" />
          ),
        },
      },
      {
        order: 2,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'notificationConfig.toRequester',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Requester" />
          ),
        },
      },
      {
        order: 3,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'notificationConfig.toClientManager',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Client Manager" />
          ),
        },
      },
    ],
  },
  {
    label: 'Planning',
    layout: sectionWithoutMarginLayout,
    order: 9,
    renderIf: isRecurringOrPMFormData,
    leaveValues: true,
    fields: [
      startDateRecurringFormFieldConfig,
      endDateRecurringFormFieldConfig,
      isNeverRecurringFormFieldConfig,
      {
        ...scheduleRecurringFormFieldConfig,
        layout: { row: 2, width: '100%' },
        field: {
          ...scheduleRecurringFormFieldConfig.field,
          Component: RecurringSchedulerTop,
        },
      },
      contractRecurringFormFieldConfig,
    ],
  },
  {
    layout: sectionWithoutMarginLayout,
    order: 10,
    renderIf: R.prop('cloneFromProjectId'),
    fields: [
      {
        order: 1,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'cloneWithoutReference',
          withFormData: true,
          Component: CloneWithoutReferenceCheckbox,
        },
      },
    ],
  },
];
