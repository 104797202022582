import React, { useEffect } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import styled from 'styled-components';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { ToolBarBtnDivider } from '@poly/admin-book/src/Table/styles.js';
import { FlexContainer } from '@poly/admin-ui/src/components/common.js';
import { useRouterQuery } from '@poly/client-routing/src/hooks.js';
import { isNilOrEmpty } from '@poly/utils/src/general.js';
import { TitleWithBackButton } from '@poly/admin-ui/src/components/pagesHeadersComps.js';

import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { PurchaseOrdersXLSButton } from './PurchaseOrdersXLSButton.js';
import { PurchaseOrdersPDFButton } from './PurchaseOrdersPDFButton.js';
import { useAddPurchaseOrderSidebar } from '../../sidebars/PurchaseOrderSidebar/form/useAddPurchaseOrderSidebar.js';
import { PurchaseOrderClientSelect } from '../../sidebars/PurchaseOrderSidebar/form/purchaseOrderFormSections.js';
import { useHasUserAccessToCreateAdminPO } from '../../sidebars/PurchaseOrderSidebar/common-hooks.js';

const POBalanceTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
`;

function POBalanceTitleComponent() {
  const { clientId } = useRouterQuery(['clientId']);

  const hasAccessToCreateAdminPO = useHasUserAccessToCreateAdminPO();

  const openAddPurchaseOrderSidebar = useAddPurchaseOrderSidebar();

  const handleClick = () => openAddPurchaseOrderSidebar(clientId);

  return (
    <POBalanceTitleWrapper>
      <TitleWithBackButton title="PO Balance" />
      <FlexContainer>
        {hasAccessToCreateAdminPO && (
          <LinkButton disabled={isNilOrEmpty(clientId)} onClick={handleClick}>
            Add new PO
          </LinkButton>
        )}
        <ToolBarBtnDivider />
      </FlexContainer>
    </POBalanceTitleWrapper>
  );
}

const clientInvoiceHistoryFilterConfig = [{ name: 'clientId' }];

export function PurchaseOrdersHeader({
  setClientFilter,
  purchaseOrders,
  loading,
}) {
  const onSearchHandler = (values) => {
    if (values.clientId) {
      setClientFilter(values);
    }
  };

  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    clientInvoiceHistoryFilterConfig,
    onSearchHandler,
  );

  const onResetSearch = () => {
    onReset();
    setClientFilter(null);
  };
  useEffect(onSearch, []);

  return (
    <SearchPageHeaderContainer TitleComp={POBalanceTitleComponent}>
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn filterWidth="260px">
            <PurchaseOrderClientSelect
              value={searchFilters.clientId}
              onChange={handlers.clientId}
              width="100%"
              includeAllOption={false}
              withoutSkip
              required
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="0px" filterWidth="300px">
            <PurchaseOrdersPDFButton purchaseOrders={purchaseOrders} />
            <ToolBarBtnDivider />
            <PurchaseOrdersXLSButton purchaseOrders={purchaseOrders} />
            <FlexCenterAlign>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

PurchaseOrdersHeader.propTypes = {
  setClientFilter: func.isRequired,
  loading: bool,
  purchaseOrders: arrayOf(shape({})),
};
