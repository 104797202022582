import * as R from 'ramda';
import {
  differenceInHours,
  getDay,
  getHours,
  getMinutes,
  isWithinInterval,
  set,
} from 'date-fns';
import { ensureIsDate } from '@poly/utils/src/dates.js';
import { isNilOrEmpty } from '@poly/utils/src/general.js';

import { scheduleDaysArray } from '../../../../../../../core/constants/schedule.js';

// getProjectDateByProp :: String -> {project: Project} -> Date
const getProjectDateByProp = (propName) =>
  R.compose(ensureIsDate, R.path(['project', propName]));

// isProjectTimeMoreThanOneDay :: {project: Project} -> Boolean
const isProjectTimeMoreThanOneDay = R.compose(
  R.gt(R.__, 24),
  R.converge(
    (endDate, startDate) => differenceInHours(endDate, startDate),
    [getProjectDateByProp('endDate'), getProjectDateByProp('startDate')],
  ),
);

// isProjectWithStartAndEndDates :: {project: Project} -> Boolean
const isProjectWithoutStartOrEndDates = R.complement(
  R.both(R.path(['project', 'startDate']), R.path(['project', 'endDate'])),
);

// getDayOfWeek :: Number -> String
const getDayOfWeek = (day) =>
  R.compose(
    R.prop('fullName'),
    R.find(R.propEq(day, 'day')),
  )(scheduleDaysArray);

// getSupplierOfficeHoursByDay :: (Number, [OfficeHour]) => OfficeHour
const getSupplierOfficeHoursByDay = (day, officeHours) =>
  R.find(R.propEq(getDayOfWeek(day), 'day'), officeHours);

// getSupplierOfficeHoursByDateProp :: String -> SupplierWithProject -> OfficeHour
const getSupplierOfficeHoursByDateProp = (propName) =>
  R.converge(getSupplierOfficeHoursByDay, [
    R.compose((date) => getDay(date), getProjectDateByProp(propName)),
    R.prop('officeHours'),
  ]);

// isSupplierOfficeHoursNotActive :: SupplierWithProject -> Boolean
const isSupplierOfficeHoursNotActive = R.compose(
  R.any(R.equals(false)),
  R.pluck('isActive'),
  R.juxt([
    getSupplierOfficeHoursByDateProp('startDate'),
    getSupplierOfficeHoursByDateProp('endDate'),
  ]),
);

// isOfficeHoursMissing :: SupplierWithProject -> Boolean => SupplierWithProject -> Date
const isOfficeHoursMissing = R.compose(isNilOrEmpty, R.prop('officeHours'));

// getStarOrEndOfWork :: (String, String) =>
const getStarOrEndOfWork = (dateProp, officeTimeProp) =>
  R.converge(
    (date, { hours, minutes }) => set(date, { hours, minutes }),
    [
      getProjectDateByProp(dateProp),
      R.compose(
        R.applySpec({
          hours: (date) => getHours(date),
          minutes: (date) => getMinutes(date),
        }),
        ensureIsDate,
        R.prop(officeTimeProp),
        getSupplierOfficeHoursByDateProp(dateProp),
      ),
    ],
  );

// checkIfDateInOfficeHoursInterval :: String -> SupplierWithProject -> Boolean
const checkIfDateInOfficeHoursInterval = (dateProp) =>
  R.converge(
    (dateToCheck, startOfWork, endOfWork) =>
      isWithinInterval(dateToCheck, { start: startOfWork, end: endOfWork }),
    [
      getProjectDateByProp(dateProp),
      getStarOrEndOfWork(dateProp, 'startTime'),
      getStarOrEndOfWork(dateProp, 'endTime'),
    ],
  );

// checkIfProjectTimeInOfficeHoursInterval :: SupplierWithProject -> Boolean
const checkIfProjectTimeInOfficeHoursInterval = R.ifElse(
  R.either(isSupplierOfficeHoursNotActive, isOfficeHoursMissing),
  R.F,
  R.both(
    checkIfDateInOfficeHoursInterval('startDate'),
    checkIfDateInOfficeHoursInterval('endDate'),
  ),
);

// isSupplierWorkingHoursMatching :: SupplierWithProject -> Boolean
// SupplierWithProject = {service_24_7: Boolean, officeHours: [OfficeHour], project: Project }
export const isSupplierWorkingHoursMatching = R.anyPass([
  R.prop('service_24_7'),
  isProjectWithoutStartOrEndDates,
  isProjectTimeMoreThanOneDay,
  checkIfProjectTimeInOfficeHoursInterval,
]);
