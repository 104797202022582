import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { string, bool, number, func } from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { useTrimValueByComponentProps } from '@poly/client-utils';

import { Icon } from '../Icon/index.js';
import { InputErrorMsg } from '../Input/index.js';
import { ColumnDirection } from '../lib/index.js';
import { CharacterCount } from '../CharacterCount/CharacterCount.js';
import { defaultTheme } from '../ThemeProvider/index.js';
import { getThemeColor, getThemeFont } from '../utils.js';
import { markAsRequired } from '../lib/inputs.js';

const { secondaryMid } = defaultTheme.colors;

const textAreaLineHeight = 18;

const getCurrentRows = (element) =>
  // eslint-disable-next-line no-bitwise
  ~~(element.scrollHeight / textAreaLineHeight);

const ColumnDirectionS = styled(ColumnDirection)`
  width: 100%;
`;

const LabelS = styled.label`
  margin-bottom: 10px;
  display: block;
  height: 15px;
  opacity: 0.7;
  font-weight: ${getThemeFont(['semibold'])};
  font-size: 12px;
  color: ${getThemeColor(['primaryLighter2'])};
`;

const getTextareaBorderColor = R.ifElse(
  R.anyPass([R.prop('hasError'), R.prop('error')]),
  getThemeColor(['secondaryMid']),
  getThemeColor(['primaryLighter8']),
);

const textareaCommonStyles = css`
  color: ${getThemeColor(['primary'])};
  background-color: ${getThemeColor(['white'])};
  border: solid 1px ${getTextareaBorderColor};
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: ${getThemeFont(['medium'])};

  &::placeholder {
    font-weight: 400;
    opacity: 0.6;
  }
`;

const TextareaCompS = styled.textarea`
  resize: vertical;
  line-height: ${textAreaLineHeight}px;
  font-size: 12px;
  letter-spacing: 0.6px;
  padding: 6px 8px;
  overflow: auto;
  height: ${R.propOr('auto', 'height')};
  ${markAsRequired}

  ${textareaCommonStyles};
`;

const RequiredIconS = styled(Icon)`
  width: 16px;
  z-index: 2;
  position: absolute;
  right: 10px;
  top: 8px;
`;

// getDefaultValueLength :: { value: String } -> Int
const getDefaultValueLength = R.compose(
  R.length,
  R.defaultTo(''),
  R.prop('value'),
);

const TextareaComp = React.forwardRef(
  (
    {
      name,
      error,
      label,
      hasError,
      className,
      charactersLimit,
      showCharactersLeft,
      characterCountPosition,
      ...props
    },
    ref,
  ) => {
    const [characterCount, setCharacterCount] = useState(
      getDefaultValueLength(props),
    );

    const onKeyUp = (event) => {
      setCharacterCount(event.target.value.length);
    };

    return (
      <ColumnDirectionS className={className}>
        {error && !props.value && (
          <RequiredIconS name="required" color={secondaryMid} />
        )}
        {label ? <LabelS htmlFor={name}>{label}</LabelS> : null}
        <TextareaCompS
          {...{
            ...props,
            error,
            name,
            onKeyUp,
            ...(showCharactersLeft ? { maxLength: charactersLimit } : {}),
          }}
          ref={ref}
        />
        {error ? <InputErrorMsg>{error}</InputErrorMsg> : ''}
        {showCharactersLeft && (
          <CharacterCount
            limit={charactersLimit}
            length={characterCount}
            characterCountPosition={characterCountPosition}
          />
        )}
      </ColumnDirectionS>
    );
  },
);

TextareaComp.propTypes = {
  label: string,
  error: string,
  value: string,
  hasError: bool,
  className: string,
  name: string.isRequired,
  charactersLimit: number,
  showCharactersLeft: bool,
  characterCountPosition: string,
};

TextareaComp.defaultProps = {
  error: '',
  label: '',
  className: '',
  hasError: false,
  charactersLimit: 100,
  showCharactersLeft: false,
};

function ExtendableTextArea({
  rows,
  value,
  minRows,
  maxRows,
  onChange: onChangeBase,
  ...props
}) {
  const [stateRows, setStateRows] = useState(rows || 1);

  const ref = useRef(null);

  useEffect(() => {
    if (props.autoFocus) {
      ref.current.focus();
    }
  }, []);

  const onChange = (event) => {
    /* eslint-disable no-param-reassign */
    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = getCurrentRows(event.target);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    setStateRows(currentRows < maxRows ? currentRows : maxRows);
    onChangeBase(event);
  };

  useEffect(() => {
    if (value) {
      setStateRows(getCurrentRows(ref.current));
    }
  }, []);

  const textareaProps = {
    ...props,
    value,
    minRows,
    maxRows,
    onChange,
    rows: stateRows,
  };

  return <TextareaComp {...textareaProps} ref={ref} />;
}

ExtendableTextArea.propTypes = {
  rows: number,
  value: string,
  minRows: number,
  maxRows: number,
  autoFocus: bool,
  onChange: func.isRequired,
};

ExtendableTextArea.defaultProps = {
  rows: 1,
  minRows: 1,
  maxRows: 1000,
  autoFocus: false,
};

export const Textarea = React.forwardRef(({ extendable, ...props }, ref) => {
  const propsByTrimmedValue = useTrimValueByComponentProps(props);

  const preparedProps = { ...props, ...propsByTrimmedValue };

  return extendable ? (
    <ExtendableTextArea {...preparedProps} />
  ) : (
    <TextareaComp {...preparedProps} ref={ref} />
  );
});

Textarea.displayName = 'Textarea';
Textarea.propTypes = { extendable: bool };
