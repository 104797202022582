import styled from 'styled-components';
import { getThemeColor, Table, LinkButton } from '@poly/admin-book';
import { UserSelect } from '@poly/admin-ui';

import {
  checkBoxColumn,
  dateColumnStyles,
  textDateColumnStyles,
  documentIdColumnStyles,
} from '../../modules/tables/tablesStyles.js';
import { FlexContainer } from '../../components/FlexContainer.js';

export const UndoButton = styled(LinkButton)`
  font-size: 14px;
  padding-left: 15px;
`;

export const ButtonsWrapper = styled(FlexContainer)`
  & > div:first-child {
    margin-right: 11px;
  }
`;

export const TooltipSelect = styled(UserSelect)`
  margin-bottom: 200px;

  .select__placeholder {
    font-size: 12px;
    color: ${getThemeColor(['mid'])};
  }

  .select__dropdown-indicator > svg {
    fill: ${getThemeColor(['mid'])};
  }

  .select__control,
  .select__control:hover,
  .select__control:focus,
  .select__control--is-focused {
    min-height: 35px;
    border: none;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  .select__menu {
    margin: 0;
    box-shadow: none;
  }

  .select__menu-list {
    height: 200px;
    padding: 0;
  }

  .select__option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 8px 10px;
    cursor: pointer;
  }

  .select__single-value {
    font-size: 12px;
  }

  .select__option--is-selected {
    color: ${getThemeColor(['primary'])};
    background-color: ${getThemeColor(['accent1Lighter3'])};
  }
`;

export const ManagerOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ManagerLetter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 1;
  border-radius: 50%;
  color: ${getThemeColor(['white'])};
  background-color: ${getThemeColor(['secondaryMid'])};
  margin-right: 10px;
`;

export const UnassignedProjectsTableComp = styled(Table)`
  ${dateColumnStyles(1)};
  ${textDateColumnStyles(2)};
  ${documentIdColumnStyles(3)};

  th:nth-child(5),
  td:nth-child(5) {
    width: 170px;
  }

  ${checkBoxColumn(7)};
`;
