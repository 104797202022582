import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo } from 'react';
import { NOTHING_UI_STRING, ELASTIC_SCORE_FIELD } from '@poly/constants';
import { assocBy } from '@poly/utils';
import {
  useInfiniteScrollQueryWithSubscription,
  highlightMatchesInObjectCurried,
} from '@poly/client-utils';
import {
  prepareSortingWithSearchText,
  useMapConfigToTableProps,
  useTableSorting,
} from '@poly/admin-ui';

import { SupplierLink } from '../../../components/Links.js';
import { recurringQueries } from '../../../modules/tabs/common.js';
import { RECURRING_PROJECTS_BY_SEARCH } from '../../../modules/core/hooks/projects/queries.js';
import { commonProjectTableConfig } from '../../../modules/tables/projectsTable/ProjectsTable.js';
import { RECURRING_PROJECTS_BY_SEARCH_SUB } from '../../../modules/core/hooks/projects/subscriptions.js';
import { getProjectsQueryByEntityQuery } from './getProjectsQueryByEntityQuery.js';
import {
  getSubPropertiesElasticQuery,
  searchProjectTextPaths,
} from './useSearchSubTabsProjectsQuery.js';

const SuppliersColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// getSuppliersByMaster :: RecurringProject -> [Supplier]
export const getSuppliersByMaster = R.compose(
  R.reject(R.propSatisfies(R.isNil, '_id')),
  R.converge(R.pathOr([]), [
    R.compose(
      R.append('suppliers'),
      R.prepend('payload'),
      R.of(Array),
      R.prop('childType'),
    ),
    R.identity,
  ]),
);

function SuppliersColumn(props) {
  const suppliers = getSuppliersByMaster(props);

  if (suppliers.length === 0) {
    return NOTHING_UI_STRING;
  }

  return (
    <SuppliersColumnWrapper>
      {suppliers.map((supplier) => {
        const name = supplier?.company?.name;
        return (
          <SupplierLink
            {...supplier}
            name={name}
            key={`${supplier._id}_${name}`}
          />
        );
      })}
    </SuppliersColumnWrapper>
  );
}

const masterProjectsSuppliersSortQuery = (order) => [
  ELASTIC_SCORE_FIELD,
  {
    'payload.suppliers.company.name.keyword': {
      order,
      nested: { path: 'payload.suppliers' },
    },
  },
];

const masterProjectsSuppliersColumn = [
  'Suppliers',
  SuppliersColumn,
  masterProjectsSuppliersSortQuery,
];

// masterProjectsTableConfig :: [TableConfig] -> [TableConfig]
const masterProjectsTableConfig = R.insert(
  4,
  masterProjectsSuppliersColumn,
)(commonProjectTableConfig);

// prepareMastersForTable :: String -> SearchRecurringProjectsResult -> [RecurringProject]
const prepareMastersForTable = (searchTerm) =>
  R.compose(
    R.map(highlightMatchesInObjectCurried(searchProjectTextPaths, searchTerm)),
    R.map(assocBy('projectLinkId', R.prop('projectId'))),
    R.pathOr([], ['searchRecurringProjects', 'hits']),
  );

export const useSearchSubTabsMastersQuery = ({
  status,
  entity,
  searchTerm = '',
}) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: masterProjectsTableConfig,
    column: 8,
  });

  const { _id, name, queryField } = entity;

  const entityQueryField = queryField || `${name}Id`;

  const statusQuery = recurringQueries[status];

  const entityQuery = [
    {
      bool: {
        should: [
          { match: { [entityQueryField]: _id } },
          ...getSubPropertiesElasticQuery(name, _id),
        ],
      },
    },
  ];

  const searchInput = useMemo(
    () => ({
      searchTerm,
      sort: prepareSortingWithSearchText(searchTerm)(sort),
      query: getProjectsQueryByEntityQuery(entityQuery)(statusQuery),
    }),
    [sort, status, entity, searchTerm],
  );

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    RECURRING_PROJECTS_BY_SEARCH,
    searchInput,
    {
      endpointName: 'searchRecurringProjects',
      inputName: 'searchInput',
    },
    RECURRING_PROJECTS_BY_SEARCH_SUB,
    { searchInput },
  );

  const dataTableProps = useMapConfigToTableProps(
    prepareMastersForTable(searchTerm),
    masterProjectsTableConfig,
    data,
  );

  const count = R.pathOr(0, ['searchRecurringProjects', 'total'], data);
  const masterProjects = R.pathOr(
    [],
    ['searchRecurringProjects', 'hits'],
    data,
  );

  return {
    count,
    loading,
    masterProjects,
    tableProps: {
      ...tableProps,
      ...dataTableProps,
      ...tableSortingProps,
    },
  };
};
