import * as R from 'ramda';
import { USAStates, UserEmployeeInfoStatus } from '@poly/constants';
import { DatePicker, Input, Select } from '@poly/admin-book';
import {
  entityToOptionByLabelPath,
  formatSelectOptionsByConstants,
} from '@poly/client-utils';
import {
  MAX_ITEMS,
  USERS_BY_SEARCH,
  MultiSelectDropDown,
  USER_SEARCH_CHANGED,
  CLIENTS_BY_SEARCH_SUB,
  SERVICE_TYPES_BY_SEARCH,
  CLIENTS_SEARCH_FOR_SELECT,
  SERVICE_TYPES_BY_SEARCH_SUB,
  getAdminUsersByEmploymentStatusQuery,
} from '@poly/admin-ui';

import { MultiInput } from '../MultiInput.js';
import { ProjectStatusOptions } from './constants/project-status-options.js';
import { PROPERTIES_BY_SEARCH_SUB } from '../../../../modules/core/hooks/properties/subscriptions.js';
import { ProjectTypeSelectOptions } from './constants/project-type-select-options.js';
import { PROPERTIES_SEARCH_FOR_SELECT } from '../../../UserGroupManagement/form/components/entitySelectPropsByScopeMap.js';
import { ProjectCostTypeSelectOptions } from './constants/project-cost-type-select-options.js';
import { ProjectAccountingStatusOptions } from './constants/project-accounting-status-options.js';
import { defaultFieldValueComponentConfig } from './default-field-value-component-config.js';
import { FieldOperation, BulkEditProjectFieldValues } from '../../constants.js';

// isMultiOperation :: String -> Boolean
const isMultiOperation = R.includes(R.__, [
  FieldOperation.IN,
  FieldOperation.NIN,
]);

// getSelectComponentByOperation :: { ruleFieldOperation: String } -> ReactComponent
const getSelectComponentByOperation = R.ifElse(
  R.propSatisfies(isMultiOperation, 'ruleFieldOperation'),
  R.always(MultiSelectDropDown),
  R.always(Select),
);

// getInputComponentByOperation :: { ruleFieldOperation: String } -> ReactComponent
const getInputComponentByOperation = R.ifElse(
  R.propSatisfies(isMultiOperation, 'ruleFieldOperation'),
  R.always(MultiInput),
  R.always(Input),
);

export const getProjectRuleValueComponentConfig = R.cond([
  [
    R.propEq(BulkEditProjectFieldValues.STATUS, 'ruleField'),
    R.compose(
      R.mergeLeft({
        getOptions: R.always(ProjectStatusOptions),
        placeholder: 'Select status',
        gqlSearchQuery: null,
        getGqlQueryVariables: null,
        gqlSearchChangedQuery: null,
      }),
      R.objOf('Component'),
      getSelectComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.ACCOUNTING_STATUS, 'ruleField'),
    R.compose(
      R.mergeLeft({
        getOptions: R.always(ProjectAccountingStatusOptions),
        placeholder: 'Select accounting',
        gqlSearchQuery: null,
        getGqlQueryVariables: null,
        gqlSearchChangedQuery: null,
      }),
      R.objOf('Component'),
      getSelectComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.PROJECT_TYPE, 'ruleField'),
    R.compose(
      R.mergeLeft({
        getOptions: R.always(ProjectTypeSelectOptions),
        placeholder: 'Select project type',
        gqlSearchQuery: null,
        getGqlQueryVariables: null,
        gqlSearchChangedQuery: null,
      }),
      R.objOf('Component'),
      getSelectComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.COST_TYPE, 'ruleField'),
    R.compose(
      R.mergeLeft({
        getOptions: R.always(ProjectCostTypeSelectOptions),
        placeholder: 'Select cost type',
        gqlSearchQuery: null,
        getGqlQueryVariables: null,
        gqlSearchChangedQuery: null,
      }),
      R.objOf('Component'),
      getSelectComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.CLIENT_NAME, 'ruleField'),
    R.compose(
      R.mergeLeft({
        placeholder: 'Enter value',
        gqlSearchQuery: CLIENTS_SEARCH_FOR_SELECT,
        gqlSearchChangedQuery: CLIENTS_BY_SEARCH_SUB,
        getGqlQueryVariables: R.assocPath(['searchInput', 'searchTerm'], R.__, {
          searchInput: { size: MAX_ITEMS },
        }),
        getOptions: R.compose(
          R.map(entityToOptionByLabelPath(['name'])),
          R.pathOr([], ['searchClients', 'hits']),
        ),
      }),
      R.objOf('Component'),
      getSelectComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.SCR_MANAGER, 'ruleField'),
    R.compose(
      R.mergeLeft({
        placeholder: 'Enter value',
        gqlSearchQuery: USERS_BY_SEARCH,
        gqlSearchChangedQuery: USER_SEARCH_CHANGED,
        getGqlQueryVariables: R.assocPath(['searchInput', 'searchTerm'], R.__, {
          searchInput: {
            query: getAdminUsersByEmploymentStatusQuery(
              UserEmployeeInfoStatus.ACTIVE,
            ),
            size: MAX_ITEMS,
          },
        }),
        getOptions: R.compose(
          R.map(entityToOptionByLabelPath(['fullName'])),
          R.pathOr([], ['searchUsers', 'hits']),
        ),
      }),
      R.objOf('Component'),
      getSelectComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.PROPERTY_NAME, 'ruleField'),
    R.compose(
      R.mergeLeft({
        placeholder: 'Enter value',
        gqlSearchQuery: PROPERTIES_SEARCH_FOR_SELECT,
        gqlSearchChangedQuery: PROPERTIES_BY_SEARCH_SUB,
        getGqlQueryVariables: R.assocPath(['searchInput', 'searchTerm'], R.__, {
          searchInput: { size: MAX_ITEMS },
        }),
        getOptions: R.compose(
          R.map(entityToOptionByLabelPath(['name'])),
          R.pathOr([], ['searchProperties', 'hits']),
        ),
      }),
      R.objOf('Component'),
      getSelectComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.SERVICE_TYPE, 'ruleField'),
    R.compose(
      R.mergeLeft({
        placeholder: 'Enter value',
        gqlSearchQuery: SERVICE_TYPES_BY_SEARCH,
        gqlSearchChangedQuery: SERVICE_TYPES_BY_SEARCH_SUB,
        getGqlQueryVariables: R.assocPath(['searchInput', 'searchTerm'], R.__, {
          searchInput: { size: MAX_ITEMS },
        }),
        getOptions: R.compose(
          R.map(entityToOptionByLabelPath(['name'])),
          R.pathOr([], ['searchServiceTypes', 'hits']),
        ),
      }),
      R.objOf('Component'),
      getSelectComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.KEYWORD, 'ruleField'),
    R.compose(
      R.mergeLeft({
        getOptions: null,
        placeholder: 'Enter value',
        gqlSearchQuery: null,
        getGqlQueryVariables: null,
        gqlSearchChangedQuery: null,
      }),
      R.objOf('Component'),
      getInputComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.STATE, 'ruleField'),
    R.compose(
      R.mergeLeft({
        placeholder: 'Enter value',
        gqlSearchQuery: null,
        getGqlQueryVariables: null,
        gqlSearchChangedQuery: null,
        getOptions: R.compose(
          formatSelectOptionsByConstants,
          R.always(USAStates),
        ),
      }),
      R.objOf('Component'),
      getSelectComponentByOperation,
    ),
  ],
  [
    R.propEq(BulkEditProjectFieldValues.CITY, 'ruleField'),
    R.compose(
      R.mergeLeft({
        getOptions: null,
        placeholder: 'Enter value',
        gqlSearchQuery: null,
        getGqlQueryVariables: null,
        gqlSearchChangedQuery: null,
      }),
      R.objOf('Component'),
      getInputComponentByOperation,
    ),
  ],
  [
    R.propSatisfies(
      R.includes(R.__, [
        BulkEditProjectFieldValues.START,
        BulkEditProjectFieldValues.END,
      ]),
      'ruleField',
    ),
    R.always({
      leftMove: '-135px',
      getOptions: null,
      placeholder: 'Select date',
      gqlSearchQuery: null,
      getGqlQueryVariables: null,
      gqlSearchChangedQuery: null,
      Component: DatePicker,
    }),
  ],
  [R.T, R.always(defaultFieldValueComponentConfig)],
]);
