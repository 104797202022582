import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TableCard } from '@poly/admin-book/src/Card.js';
import { DESC_SORT_ORDER } from '@poly/constants';
import { useDispatch, useSelector } from 'react-redux';
import { ALL, ClientSelect, useTableSorting } from '@poly/admin-ui';
import {
  MainHeader,
  ToolBarBtnDivider,
  DefaultBodyWrapper,
  PageHeaderContainer,
  useNotificationContext,
} from '@poly/admin-book';

import { processes } from '../../redux/processes/processesReducer.js';
import { setProcesses } from '../../redux/processes/index.js';
import { setSelectedRows } from '../../redux/selectedRowsReducer.js';
import { useSortingByReduxSearch } from '../../modules/core/hooks/useSortingByReduxSearch.js';
import { UnassignedProjectsTable } from './UnassignedProjectsTable.js';
import { UnassignedProjectsPDFBtn } from './UnassignedProjectsPDFBtn.js';
import { UnassignedProjectsXLSBtn } from './UnassignedProjectsXLSBtn.js';
import { useUnassignedProjectsQuery } from './useUnassignedProjectsQuery.js';
import { unassignedProjectsTableConfig } from './tableConfig.js';
import {
  AssignUnassignedProjectBtn,
  PickupUnassignedProjectBtn,
} from './UnassignedProjectsButtons.js';

const TableCardWithoutTabs = styled(TableCard)`
  padding-top: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 40%;
`;

const FlexRowItemsRight = styled(FlexRow)`
  justify-content: flex-end;
`;

const ToolBarBtnDividerS = styled(ToolBarBtnDivider)`
  margin: 0;
`;

export function UnassignedProjectsPage() {
  const dispatch = useDispatch();
  const selectedRows = useSelector((state) => state.selectedRows);
  const [clientId, setClientId] = useState(ALL);
  const { showSuccessNotification } = useNotificationContext();
  const [allSelected, setAllSelected] = useState(false);

  const dispatchSelectedRows = (rows) => dispatch(setSelectedRows(rows));
  const dispatchProcess = (value) =>
    dispatch(setProcesses({ [processes.UNASSIGNED_PROJECTS_LOADING]: value }));

  const { sort: tableSort, ...tableSortingProps } = useTableSorting({
    order: DESC_SORT_ORDER,
    unassignedProjectsTableConfig,
    column: 1,
  });

  const { sort } = useSortingByReduxSearch({ sort: tableSort });

  const { total, projects, refetch, tableProps } = useUnassignedProjectsQuery({
    clientId,
    sort,
  });

  useEffect(() => {
    if (allSelected && projects.length === total) {
      const selectedIds = projects.map((x) => x._id);

      dispatchSelectedRows(selectedIds);

      if (selectedIds.length > 0) {
        showSuccessNotification(
          `All ${selectedIds.length} Projects In Queue Selected`,
        );
      }

      dispatchProcess(false);

      setAllSelected(false);
    }
  }, [projects, allSelected]);

  const toggleRow = (rowId) => {
    if (selectedRows.includes(rowId)) {
      dispatchSelectedRows(selectedRows.filter((x) => x !== rowId));
    } else {
      dispatchSelectedRows([...selectedRows, rowId]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedRows.length === total) {
      dispatchSelectedRows([]);
    } else {
      dispatchProcess(true);
      refetch({ searchInput: { size: total } });
      setAllSelected(true);
    }
  };

  return (
    <>
      <PageHeaderContainer>
        <FlexRow>
          <MainHeader>Unassigned</MainHeader>
          <ClientSelect
            value={clientId}
            onChange={setClientId}
            placeholder="Start Typing Client Name"
            width="50%"
            size="small"
            isClearable
          />
        </FlexRow>

        <FlexRowItemsRight>
          <UnassignedProjectsPDFBtn clientId={clientId} sort={sort} />
          <ToolBarBtnDividerS />
          <UnassignedProjectsXLSBtn clientId={clientId} sort={sort} />

          <AssignUnassignedProjectBtn />
          <PickupUnassignedProjectBtn />
        </FlexRowItemsRight>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCardWithoutTabs>
          <UnassignedProjectsTable
            selectedRowsInEnd
            projects={projects}
            toggleRow={toggleRow}
            selectedRows={selectedRows}
            toggleSelectAll={toggleSelectAll}
            {...tableProps}
            {...tableSortingProps}
          />
        </TableCardWithoutTabs>
      </DefaultBodyWrapper>
    </>
  );
}
