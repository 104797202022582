import React from 'react';
import { arrayOf, bool, shape } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';

import { PrintExportButtons } from '../../components/ExportButtons.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';
import { MyTasksTablePrint } from './MyTasksTable.js';
import { getTasksExcelConfig } from './getTasksExcelConfig.js';

export function MyTasksExportButtons({ tasks, loading }) {
  const Layout = usePrintLayout();

  const handlePrintPDF = async () => {
    await openPrintWindowWithData({
      tasks,
      Layout,
      fileName: 'my_tasks_export.pdf',
      Table: MyTasksTablePrint,
      metaData: {
        title: 'My Tasks',
      },
    });
  };

  const handleExportXLS = () => {
    const config = getTasksExcelConfig(tasks);
    return performExcelExport(config);
  };

  return (
    <PrintExportButtons
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
      loading={loading}
      disabled={!tasks || tasks.length === 0}
    />
  );
}

MyTasksExportButtons.propTypes = {
  tasks: arrayOf(shape({})),
  loading: bool,
};
