import * as R from 'ramda';
import { keywordSortQuery } from '@poly/client-utils';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { clientColumn } from '../../modules/tables/columns/clients.js';
import {
  projectExpiresColumn,
  projectPriorityColumn,
} from '../../modules/tables/columns/projects.js';

export const unassignedProjectsTableConfig = [
  projectPriorityColumn,
  projectExpiresColumn,
  ['Project ID', ProjectLink, keywordSortQuery(['projectId'])],
  ['Description', R.prop('description'), keywordSortQuery(['description'])],
  clientColumn,
  [
    'Property',
    R.path(['property', 'name']),
    keywordSortQuery(['property', 'name']),
  ],
];
