import * as R from 'ramda';
import React from 'react';
import { string } from 'prop-types';
import {
  applyProp,
  formatTotal,
  formatTaxId,
  formatSocialSecurity,
  forceTitleCase,
} from '@poly/utils';
import { FoodbuyVendorTypeUI } from '@poly/constants';

import { Supplier1099ReportLink } from './Supplier1099ReportLink.js';
import { prepare1099ReportSupplier } from './prepare1099ReportSupplier.js';

function Supplier1099ReportLinkC({ dba, ...props }) {
  return <Supplier1099ReportLink {...props} name={dba} />;
}

Supplier1099ReportLinkC.propTypes = {
  dba: string,
};

function SupplierBusinessName1099ReportLinkC(props) {
  return <Supplier1099ReportLink {...props} taxName={null} />;
}

/**
 * PreparedSupplier = {
 *   _id: String
 *   paidByCC: Money
 *   paidByOthers: Money
 *   total: Money
 *   name: String
 *   nameForSorting: String
 *   taxId: String
 *   is1099: String
 *   address: String
 *   addressTwo: String
 *   city: String
 *   state: String
 *   zip: String
 *   isWbe: String
 *   mbe: String
 *   foodBuyVendorType: String
 *   foodBuyVaPercentage: Number
 * }
 */

/**
 * prepareSupplier1099ReportData :: SuppliersAAC1099ReportResult -> [PreparedSupplier]
 */
export const prepareSupplier1099ReportData = R.compose(
  R.map(
    R.converge(R.mergeDeepLeft, [
      R.pick(['_id', 'paidByCC', 'paidByOthers', 'isMasterSupplier']),
      R.applySpec({
        dba: R.path(['supplier', 'company', 'dba']),
        name: R.path(['supplier', 'company', 'name']),
        taxId: R.ifElse(
          R.path(['supplier', 'tax', 'id']),
          R.compose(formatTaxId, R.path(['supplier', 'tax', 'id'])),
          R.compose(
            formatSocialSecurity,
            R.path(['supplier', 'tax', 'socialSecurityNumber']),
          ),
        ),
        is1099: R.ifElse(
          R.path(['supplier', 'tax', 'is1099']),
          R.always('1099'),
          R.always(''),
        ),
        taxName: R.path(['supplier', 'tax', 'name']),
        address: R.converge(R.concat, [
          R.pathOr('', [
            'supplier',
            'address',
            'address_parts',
            'street_number',
          ]),
          R.pipe(
            R.pathOr('', ['supplier', 'address', 'address_parts', 'route']),
            R.concat(' '),
          ),
        ]),
        addressTwo: R.pathOr('', ['supplier', 'address', 'addressTwo']),
        city: R.pathOr('', [
          'supplier',
          'address',
          'address_parts',
          'locality',
        ]),
        state: R.pathOr('', [
          'supplier',
          'address',
          'address_parts',
          'administrative_area_level_1',
        ]),
        zip: R.pathOr('', [
          'supplier',
          'address',
          'address_parts',
          'postal_code',
        ]),
        total: R.converge(R.add, [R.prop('paidByCC'), R.prop('paidByOthers')]),
        isWbe: R.ifElse(
          R.pathOr(false, ['supplier', 'mwbe', 'isWBE']),
          R.always('Yes'),
          R.always('No'),
        ),
        mbe: R.compose(
          forceTitleCase,
          R.pathOr('', ['supplier', 'mwbe', 'minority']),
        ),
        foodBuyVendorType: R.compose(
          R.prop(R.__, FoodbuyVendorTypeUI),
          R.path(['supplier', 'foodBuy', 'vendorType']),
        ),
        foodBuyVaPercentage: R.ifElse(
          R.path(['supplier', 'foodBuy', 'isEnabled']),
          R.path(['supplier', 'foodBuy', 'vaPercentage']),
          R.always(null),
        ),
        isFoodBuy: R.ifElse(
          R.path(['supplier', 'foodBuy', 'isEnabled']),
          R.always('Yes'),
          R.always('No'),
        ),
      }),
    ]),
  ),
  R.map(prepare1099ReportSupplier),
  R.defaultTo([]),
  R.prop('suppliersAAC1099Report'),
);

// supplier1099ReportTableConfig :: [TableRow]
// TableRow = [String, Component, String]
export const supplier1099ReportTableConfig = [
  ['Name', Supplier1099ReportLinkC, R.either(R.prop('taxName'), R.prop('dba'))],
  ['Business Name', SupplierBusinessName1099ReportLinkC, R.prop('name')],
  ['Tax ID', R.prop('taxId'), R.prop('taxId')],
  ['1099', R.prop('is1099'), R.prop('is1099')],
  ['Address', R.prop('address'), R.prop('address')],
  ['Address Two', R.prop('addressTwo'), R.prop('addressTwo')],
  ['City', R.prop('city'), R.prop('city')],
  ['State', R.prop('state'), R.prop('state')],
  ['Zip', R.prop('zip'), R.prop('zip')],
  ['WBE', R.prop('isWbe'), R.prop('isWbe')],
  ['MBE', R.prop('mbe'), R.prop('mbe')],
  ['Paid By CC', applyProp(formatTotal)('paidByCC'), R.prop('paidByCC')],
  [
    'Paid By Others',
    applyProp(formatTotal)('paidByOthers'),
    R.prop('paidByOthers'),
  ],
  ['Amount', applyProp(formatTotal)('total'), R.prop('total')],
];
