import * as R from 'ramda';
import { gql, useApolloClient } from '@apollo/client';

const getAssetsByQrCodeIdQuery = gql`
  query getAssetsByQrCodeIdQuery($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        name
        qrCodeId
      }
    }
  }
`;

// getFoundAsset :: SearchAssetsQueryResult -> Asset
const getFoundAsset = R.compose(R.head, R.pathOr([], ['searchAssets', 'hits']));

export const useValidateAssetForm = (asset = {}) => {
  const client = useApolloClient();

  return async ({ qrCodeId }) => {
    if (!qrCodeId || qrCodeId.length !== 6 || qrCodeId === asset.qrCodeId) {
      return {};
    }

    const { data } = await client.query({
      query: getAssetsByQrCodeIdQuery,
      variables: {
        input: {
          from: 0,
          size: 1,
          query: {
            term: { qrCodeId },
          },
        },
      },
    });

    const foundAsset = getFoundAsset(data);

    if (foundAsset && !!qrCodeId && foundAsset.qrCodeId === qrCodeId) {
      return { qrCodeId: 'This QR Code already exists' };
    }

    return {};
  };
};
