import { gql } from '@apollo/client';
import {
  commonProjectFields,
  commonRecurringProjectFields,
  projectDetailsFields,
} from './fragments.js';

export const PROJECTS_BY_SEARCH = gql`
  query PROJECTS_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        type
        blockedAt
        projectId
        status
        createdAt
        description
        startDate
        endDate
        expiresAt
        workCompletionDate
        priority {
          id
          name
        }
        property {
          _id
          name
        }
        client {
          _id
          name
          nickName
        }
        manager {
          _id
          fullName
        }
      }
      total
    }
  }
`;

export const MY_PROJECTS = gql`
  query MY_PROJECTS($searchInput: CollectionSearchParams!) {
    me {
      _id
      searchManagerProjects(input: $searchInput) {
        total
        hits {
          ...commonProjectFields
        }
      }
    }
  }

  ${commonProjectFields}
`;

export const MY_RECURRING_PROJECTS = gql`
  query MY_RECURRING_PROJECTS($searchInput: CollectionSearchParams!) {
    me {
      _id
      searchManagerRecurringProjects(input: $searchInput) {
        total
        hits {
          ...commonRecurringProjectFields
        }
      }
    }
  }

  ${commonRecurringProjectFields}
`;

export const RECURRING_PROJECTS_BY_SEARCH = gql`
  query RECURRING_PROJECTS_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchRecurringProjects(input: $searchInput) {
      hits {
        ...commonRecurringProjectFields
      }
      total
    }
  }

  ${commonRecurringProjectFields}
`;

export const SUPPLIER_RECURRING_PROJECTS_BY_SEARCH = gql`
  query SUPPLIER_RECURRING_PROJECTS_BY_SEARCH(
    $documentId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    supplier(id: $documentId) {
      _id
      company {
        name
      }
      searchRecurringProjects(input: $searchInput) {
        hits {
          ...commonRecurringProjectFields
        }
        total
      }
    }
  }

  ${commonRecurringProjectFields}
`;

export const PROJECT_DETAILS = gql`
  query PROJECT_DETAILS($projectId: ID, $id: ID) {
    project(projectId: $projectId, id: $id) {
      ...projectDetailsFields
    }
  }

  ${projectDetailsFields}
`;

export const PROJECT_ASSIGN_SUPPLIERS_QUERY = gql`
  query PROJECT_ASSIGN_SUPPLIERS_QUERY($id: ID!) {
    project(id: $id) {
      _id
      type
      startDate
      endDate
      property {
        _id
        name
        alreadyUsedSupplierIds
        address {
          geo {
            coordinates
          }
        }
      }
      client {
        _id
        name
        alreadyUsedHQProjectSupplierIds
        address {
          geo {
            coordinates
          }
        }
      }
      parent {
        _id
        projectId
      }
      projectId
      suppliers {
        _id
      }
      adminPurchaseOrder {
        _id
        currentBalance
      }
    }
  }
`;
