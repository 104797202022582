import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { string, func, shape, bool } from 'prop-types';
import { ProjectType, RecurringProjectTypes } from '@poly/constants';
import { pathEqLegacy, propEqLegacy } from '@poly/utils';
import {
  TriangleCommon,
  getThemeColor,
  getThemeProp,
  LinkButton,
  Button,
  Checkbox,
} from '@poly/admin-book';
import {
  useNotificationState,
  TooltipButton,
  SidebarRow,
  entities,
} from '@poly/admin-ui';

import { BlockWithLabel } from '../../components/commonSidebarComponents.js';
import { getSectionSidebarLink } from '../../components/commonSidebarSectionFormatters.js';
import { useAddProjectSidebar } from '../forms/add/useAddProjectSidebar.js';
import { useSidebarLogicContext } from '../../SidebarLogicContext.js';
import {
  TooltipWrapper as ClonePMTooltipWrapper,
  ClonePMProjectButton,
} from '../../MasterRecurringProjectSidebar/ClonePMProjectButton.js';

export const checkFollowedUpProjectQuery = gql`
  query checkFollowedUpProjectQuery($searchInput: CollectionSearchParams) {
    searchProjects(input: $searchInput) {
      hits {
        _id
      }
    }
  }
`;

export const TooltipWrapper = styled.div`
  bottom: -10px;
  left: -55px;
  position: absolute;
  background: white;
  width: 150px;
  box-shadow: ${getThemeProp(['defaultBoxShadow'])};
  padding-bottom: 5px;
  border-radius: 5px;
  & > div {
    font-size: 12px;
    line-height: 18px;
  }

  :after {
    ${TriangleCommon};
    left: 75px;
    bottom: -27px;
    transform: rotate(-135deg);
    margin: 0;
    border-width: 8px;
    border-color: ${getThemeColor(['white'])} ${getThemeColor(['transparent'])}
      ${getThemeColor(['transparent'])} ${getThemeColor(['white'])};
    box-shadow: -3px -3px 5px 0 #dbdde6;
  }
`;

const FollowUpTooltipWrapper = styled(ClonePMTooltipWrapper)`
  display: flex;
  flex-direction: column;
  width: 225px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

function TooltipButtons({ onConfirm, onCancel }) {
  return (
    <ButtonWrapper>
      <Button onClick={onConfirm} size="small">
        Yes
      </Button>
      <Button
        onClick={onCancel}
        size="small"
        styleType="basicSecondary"
        type="button"
      >
        No
      </Button>
    </ButtonWrapper>
  );
}

TooltipButtons.propTypes = {
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
};

function CloneButtonTooltip({
  cloneFrom,
  type,
  isCard,
  closeTooltip,
  skipAssetOption,
}) {
  const [isCopyAsset, setIsCopyAsset] = useState(false);
  const [isCopySuppliers, setIsCopySuppliers] = useState(true);
  const [isPreventativeRepair, setIsPreventativeRepair] = useState(true);
  const [isCallback, setIsCallback] = useState(false);

  const openAddProjectSidebar = useAddProjectSidebar(
    isCard,
    entities.project,
    cloneFrom,
    type,
  );

  const onCloneProject = (e) => {
    closeTooltip(e);
    openAddProjectSidebar({
      isCallback,
      cloneFromProjectId: cloneFrom,
      cloneWithoutAssets: !isCopyAsset,
      cloneWithoutSuppliers: !isCopySuppliers,
      cloneAsPreventativeRepair: isPreventativeRepair,
    });
  };

  return (
    <FollowUpTooltipWrapper>
      <Checkbox
        value={isPreventativeRepair}
        name="is-preventative-repair"
        onChange={setIsPreventativeRepair}
        label="Create as Preventative Repair"
      />
      <Checkbox
        value={isCopySuppliers}
        name="is-copy-suppliers"
        onChange={setIsCopySuppliers}
        label="Copy Suppliers"
      />
      <Checkbox
        value={isCallback}
        name="clone-project-as-callback"
        onChange={setIsCallback}
        label="Clone project as callback"
      />
      {!skipAssetOption && (
        <Checkbox
          value={isCopyAsset}
          name="is-copy-asset"
          onChange={setIsCopyAsset}
          label="Copy Asset"
        />
      )}
      <Button onClick={onCloneProject} size="small">
        Clone
      </Button>
    </FollowUpTooltipWrapper>
  );
}

CloneButtonTooltip.propTypes = {
  isCard: bool,
  skipAssetOption: bool,
  type: string.isRequired,
  cloneFrom: string.isRequired,
  closeTooltip: func.isRequired,
};

// getCloneProjectInfo :: Project -> Object
const getCloneProjectInfo = R.applySpec({
  _id: R.prop('_id'),
  type: R.prop('type'),
  isChild: R.pathSatisfies(R.complement(R.isNil), ['parent', 'projectId']),
  chowClonePM: R.either(
    pathEqLegacy(
      ['parent', 'type'],
      RecurringProjectTypes.preventiveMaintenanceProject,
    ),
    R.pathSatisfies(R.gt(R.__, 0), ['searchAssets', 'total']),
  ),
  showCloneBtn: R.complement(
    R.both(R.prop('parent'), propEqLegacy('type', ProjectType.HOUSEKEEPING)),
  ),
});

// isPreventiveRepairProject :: Project -> Boolean
const isPreventiveRepairProject = R.pathOr(false, [
  'preventativeRepair',
  'isPreventativeRepair',
]);

// isAssetOptionShouldBeSkipped :: Project -> Boolean
const isAssetOptionShouldBeSkipped = R.compose(
  propEqLegacy('length', 0),
  R.pathOr([], ['searchAssets', 'hits']),
);

export function CloneProjectButton({ project, isCard }) {
  const { ProjectLink } = useSidebarLogicContext();

  const { _id, type, isChild, showCloneBtn, chowClonePM } =
    getCloneProjectInfo(project);

  const { showWarningNotification } = useNotificationState();
  const { data } = useQuery(checkFollowedUpProjectQuery, {
    variables: {
      searchInput: {
        query: { bool: { must: [{ match: { cloneFromProjectId: _id } }] } },
        size: 1,
      },
    },
    fetchPolicy: 'network-only',
    skip: !isChild,
  });

  if (!showCloneBtn) return null;

  const title = isChild ? 'Follow Up Project' : 'Clone Project';
  const result = R.pathOr([], ['searchProjects', 'hits'], data);

  const tooltipBtnProps = {
    style: { position: 'relative' },
    tooltipProps: { position: 'right', top: '-20px' },
    Wrapper: LinkButton,
    Tooltip: CloneButtonTooltip,
    tooltipBtnProps: {
      type,
      isCard,
      isChild,
      cloneFrom: _id,
      skipAssetOption: isAssetOptionShouldBeSkipped(project),
    },
    ...(result.length > 0
      ? {
          onClick: () =>
            showWarningNotification(
              'A follow up project has already been created',
            ),
        }
      : {}),
  };

  return (
    <>
      {!!project.cloneFromProject && !isPreventiveRepairProject(project) && (
        <SidebarRow>
          <BlockWithLabel
            id="reference-project"
            label="Reference Project"
            Component={getSectionSidebarLink(
              project.cloneFromProject,
              ProjectLink,
            )}
          />
        </SidebarRow>
      )}
      <SidebarRow>
        {chowClonePM && isChild ? (
          <ClonePMProjectButton
            entity={{ _id, name: entities.PROJECT }}
            title="Follow Up Project"
          />
        ) : (
          <TooltipButton {...tooltipBtnProps}>{title}</TooltipButton>
        )}
      </SidebarRow>
    </>
  );
}

CloneProjectButton.propTypes = {
  isCard: bool,
  project: shape({ _id: string.isRequired }),
};
