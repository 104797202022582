import React from 'react';
import * as R from 'ramda';
import { bool } from 'prop-types';
import styled, { css } from 'styled-components';

import { Icon } from '../Icon/index.js';
import { defaultTheme } from '../ThemeProvider/index.js';
import { InputWithIcon } from '../InputWithIcon/index.js';
import { getThemeColor, getThemeFont, getThemeProp } from '../utils.js';

export const CalendarIcon = styled(Icon).attrs((props) => ({
  name: 'calendar',
  color: getThemeColor(['inputIcon'])(props),
  size: 12,
}))``;

const withoutBorder = css`
  input {
    border-top: none;
    border-bottom: none;
  }
`;

export const Container = styled.div`
  position: relative;

  ${({ error, hasError }) => (error || hasError) && withoutBorder};
`;

export const InputWithIconS = styled(InputWithIcon)`
  width: ${R.propOr('185px', 'width')};

  input:focus {
    outline: none;
  }

  input::placeholder {
    color: ${getThemeColor(['midDark'])};
  }
`;

const DatePickerLocationTop = css`
  bottom: 40px;
`;

const DatePickerLocationBottom = css`
  top: 40px;
`;

export const DatePickerAligner = styled.div`
  ${({ position }) => {
    switch (position) {
      case 'top':
        return DatePickerLocationTop;
      case 'bottom':
        return DatePickerLocationBottom;
      default:
        return DatePickerLocationBottom;
    }
  }};
  position: absolute;
  left: ${({ leftMove }) => leftMove || '-65px'};
  z-index: 3;
`;

const DefaultDayPickerStyles = css`
  position: relative;
  width: 320px;
  background-color: ${getThemeColor(['white'])};
  border-radius: 2px;
  box-shadow: ${getThemeProp(['defaultBoxShadow'])};
  padding: 14px;

  .rdp-month_caption {
    display: none;
  }

  table,
  thead,
  tbody {
    width: 100%;
    border-spacing: 0;
  }

  thead {
    display: table-header-group !important;
  }

  .rdp-week {
    padding: 2px 0;
  }

  .rdp-weekday,
  .rdp-day {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px !important;
    height: 26px !important;
    margin: 7px !important;
    padding: 0 !important;
    font-weight: ${getThemeFont(['regular'])};
    font-size: 12px;
    line-height: 18px;

    & > button {
      cursor: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      border: none;
      background-color: transparent;
      padding: 0;
      width: inherit;
      height: inherit;
    }
  }

  .rdp-disabled {
    cursor: not-allowed;
    color: ${getThemeColor(['midDark'])};
  }

  .rdp-day:not(.rdp-disabled) {
    cursor: pointer;
    color: ${getThemeColor(['black'])};

    &.rdp-day.rdp-today {
      color: ${getThemeColor(['secondaryMid'])};
    }

    &.rdp-day.rdp-outside {
      color: ${getThemeColor(['midDark'])};
    }

    &.rdp-selected {
      color: ${getThemeColor(['white'])} !important;
      background-color: ${getThemeColor(['primaryRegular'])};
    }
  }

  .rdp-weekdays {
    padding: 15px 0 0 0 !important;
    color: ${getThemeColor(['midDark'])};
    background-color: #fff;

    .rdp-weekday {
      abbr {
        text-decoration: none;
      }
    }
  }

  .rdp-weekdays,
  .rdp-week {
    display: flex;
    justify-content: space-between;
  }

  .rdp-week > .rdp-day.rdp-today,
  .rdp-week > .rdp-day.rdp-selected {
    border-radius: 50%;
  }

  .rdp-week > .rdp-day.rdp-today {
    color: ${getThemeColor(['black'])} !important;
    border: 1px solid ${getThemeColor(['midDark'])};
  }

  .rdp-week > .rdp-day.rdp-selected {
    color: ${getThemeColor(['white'])} !important;
    background-color: ${getThemeColor(['primaryRegular'])};
  }

  .rdp-week > .rdp-day.rdp-modified {
    border-radius: unset;
    font-weight: ${getThemeFont(['regular'])};
    color: ${getThemeColor(['primaryLighter2'])};
    background-color: ${getThemeColor(['accent2Lighter6'])};
  }
`;

const MultipleDayPickerStyles = css`
  width: 640px;

  & > div:after {
    left: 30%;
  }

  .rdp-months {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .rdp-month {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 47%;
  }

  .rdp-month:first-child {
    & > .rdp-month_caption {
      display: flex;
      justify-content: center;
      padding-left: 40px;
    }
  }

  .rdp-month:last-child {
    & > .rdp-month_caption {
      display: flex;
      justify-content: center;
      padding-right: 40px;
    }
  }

  .rdp-week > .rdp-day.rdp-selected {
    margin: 0;
    padding: 0 8px;
    border-radius: unset;
    color: ${getThemeColor(['primaryLighter2'])} !important;
    background-color: ${getThemeColor(['accent2Lighter6'])};
  }

  .rdp-week > .rdp-day.rdp-outPoint {
    background-color: ${getThemeColor(['accent2Lighter7'])};
    color: ${getThemeColor(['white'])};
    border-radius: 3px;
    margin: 0 8px;
    padding: 0;
  }
`;

export const DatePickerContainer = styled.div`
  ${DefaultDayPickerStyles};
  ${({ isMultiple }) => !!isMultiple && MultipleDayPickerStyles};
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DayWithButtonsWrapper = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 15px;
  }

  & > div:last-child {
    margin-left: 15px;
  }
`;

export const MultiNavBarWrapper = styled(NavbarContainer)`
  position: absolute;
  width: 100%;

  & > div:last-child {
    justify-content: flex-end;
  }
`;

export const MultiNavBarBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;

  & > div {
    margin: 0 10px;
  }
`;

export const CurrentMonthText = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${getThemeColor(['dark'])};
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

function ArrowBackIcon() {
  return <Icon name="arrow-back-calendar" size={18} />;
}

export function BackBtn({ isMulti, ...props }) {
  return (
    <BtnContainer {...props}>
      <ArrowBackIcon />
      {isMulti && <ArrowBackIcon />}
    </BtnContainer>
  );
}

BackBtn.propTypes = {
  isMulti: bool,
};

export const NextBtn = styled(BackBtn)`
  transform: rotate(180deg);
`;

export function MultiBackBtn(props) {
  return (
    <BtnContainer {...props}>
      <Icon name="arrow-back" size={14} color={defaultTheme.colors.midDark} />
    </BtnContainer>
  );
}

export const MultiNextBtn = styled(MultiBackBtn)`
  transform: rotate(180deg);
`;

export const DatePickerFooter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;

  & > div {
    margin-right: 10px;
  }
`;

export const DatePickerFooterBtn = styled.div`
  display: flex;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${getThemeColor(['accent2Lighter7'])};
  background-color: ${getThemeColor(['accent2Lighter6'])};
  color: ${getThemeColor(['accent2Lighter7'])};
  font-weight: bold;
`;
