import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { commonEmailValidators } from '@poly/client-utils/src/formValidators.js';
import { validateEmail, propEqLegacy } from '@poly/utils';
import { UserStatuses } from '@poly/constants';
import {
  CLIENT_ID_AIT,
  PROPERTY_ID_AIT,
  EQUALS_AIT_OPERATOR,
  CLIENT_PORTAL_APP_NAME,
  ASSET_SCANNER_APP_NAME,
  FLIPPED_CONTAINS_AIT_OPERATOR,
} from '@poly/security';
import {
  useOnSubmitSetStopSubmitting,
  InternationalPhoneInput,
  useNotificationState,
  PhoneNumberInput,
  FlexContainer,
  FlexColumn,
  halfWidth,
  ExtInput,
  UserGroupSelect,
} from '@poly/admin-ui';
import {
  useForgotPasswordLogic,
  capitalizeFirstLetter,
  validatePhone,
  ifNotEmpty,
} from '@poly/client-utils';
import {
  useNotificationContext,
  FormCreator,
  LinkButton,
  Select,
  Button,
  Loader,
  Input,
  Text,
} from '@poly/admin-book';

import { Label } from '../../../pages/DocumentsRequest/components.js';
import { AddUserGroupLinkButton } from './components/AddUserGroupLinkButton.js';
import {
  CloseSidebarFormIcon,
  CloseSidebarFormButton,
} from '../../components/commonSidebarFormComponents.js';
import { ExistingContactMessage } from './components/ExistingContactMessage.js';
import { ErrorMessage } from '../../../modules/pagesHeaders/SupplierSearchPageHeader/filter/styles.js';
import {
  isClientPortalUserGroupUser,
  isKitchenUserGroupUser,
} from './form/formatUserInput.js';
import { SelectPropertiesComponent } from './components/SelectPropertiesComponent.js';
import { UserGroupsTableComponent } from './components/UserGroupsTable/UserGroupsTableComponent.js';

const LabelS = styled(Label)`
  font-weight: 600;
  font-size: 13px;
`;

const MULTIPLE_USER_GROUPS_ASSIGNED_ERROR = `
  Unable edit this user,
  because it has multiple user groups assigned.
  Please, use User Groups management UI or contact Super Admin
`;

const statusesOptions = [
  {
    value: UserStatuses.ACTIVE,
    label: capitalizeFirstLetter(UserStatuses.ACTIVE),
  },
  {
    value: UserStatuses.INACTIVE,
    label: capitalizeFirstLetter(UserStatuses.INACTIVE),
  },
];

function StatusDropDown(props) {
  return <Select {...props} options={statusesOptions} />;
}

// isEmailRequiredByFormValues :: [UserGroup] -> FormValues -> Bool
const isEmailRequiredByFormValues = (userGroupList) =>
  isClientPortalUserGroupUser(userGroupList);

const EmailInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const EmailLabel = styled(Text)`
  font-size: 12px;
  padding-bottom: 8px;
`;

// validateUserEmail :: String -> Boolean
const validateUserEmail = R.complement(validateEmail);

export function SendPasswordResetButton({ email }) {
  const { showSuccessNotification, showErrorNotification } =
    useNotificationContext();

  const showSuccessMessage = () =>
    showSuccessNotification(`Password reset has been sent to ${email}`);

  const { loading, onSubmit, error } =
    useForgotPasswordLogic(showSuccessMessage);

  useEffect(() => {
    if (error) {
      showErrorNotification(error);
    }
  }, [error]);

  const disabled = validateUserEmail(email);

  const handleClick = async (e) => {
    e.preventDefault();
    await onSubmit(email);
  };

  return (
    <LinkButton onClick={handleClick} loader={loading} disabled={disabled}>
      Send Password Reset
    </LinkButton>
  );
}

SendPasswordResetButton.propTypes = {
  email: string,
};

function ClientUserEmailInput({
  formData,
  withResetPasswordBtn,
  userGroupList,
  ...props
}) {
  const showResetPasswordBtn =
    withResetPasswordBtn && formData.isClientPortalUser;

  return (
    <EmailInputWrapper>
      <LabelWrapper>
        <EmailLabel>Email</EmailLabel>
        {showResetPasswordBtn && <SendPasswordResetButton {...formData} />}
      </LabelWrapper>

      <Input
        {...props}
        placeholder="Email"
        required={isEmailRequiredByFormValues(userGroupList)(formData)}
      />
    </EmailInputWrapper>
  );
}

ClientUserEmailInput.propTypes = {
  formData: shape({}),
  withResetPasswordBtn: bool,
  userGroupList: arrayOf(shape({})),
};

// filterOptionsForEnabledApps :: (Boolean, Boolean) -> [UserGroup] -> [UserGroup]
const filterOptionsForEnabledApps = (
  isAssetScannerEnabled,
  isClientPortalEnabled,
) =>
  R.compose(
    R.flatten,
    R.juxt([
      R.ifElse(
        R.always(isAssetScannerEnabled),
        R.filter(propEqLegacy('defaultLoginApp', ASSET_SCANNER_APP_NAME)),
        R.always([]),
      ),
      R.ifElse(
        R.always(isClientPortalEnabled),
        R.filter(propEqLegacy('defaultLoginApp', CLIENT_PORTAL_APP_NAME)),
        R.always([]),
      ),
    ]),
  );

// getPreAssignedUserGroups :: { userGroups: [UserUserGroupInput] } -> [ID]
const getPreAssignedUserGroups = R.compose(
  R.map(R.prop('userGroupId')),
  R.propOr([], 'userGroups'),
);

function UserGroupSelectComponent({
  formData,
  changeFieldValue,
  filterUserGroupsByEnabledApps,
  ...props
}) {
  const preAssignedUserGroupIds = getPreAssignedUserGroups(formData);

  const userGroupSelectProps = {
    ...props,
    filterUserGroups: R.compose(
      R.reject(
        R.propSatisfies(R.includes(R.__, preAssignedUserGroupIds), '_id'),
      ),
      filterUserGroupsByEnabledApps,
    ),
  };

  return <UserGroupSelect {...userGroupSelectProps} />;
}

UserGroupSelectComponent.propTypes = {
  onChange: func,
  // eslint-disable-next-line react/forbid-prop-types
  formData: object,
  changeFieldValue: func,
  filterUserGroupsByEnabledApps: func,
};

const getClientPortalUsersFormSections = ({
  onCancel,
  onConvertUser,
  withResetPasswordBtn,
  userGroupList,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        order: 0,
        layout: { row: 0 },
        renderIf: R.prop('isUserWithMoreThenOneUserGroup'),
        field: {
          name: 'warningMessage',
          Component: ErrorMessage,
          additionalProps: { children: MULTIPLE_USER_GROUPS_ASSIGNED_ERROR },
        },
      },
      {
        label: 'Status',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'status',
          Component: StatusDropDown,
        },
        required: true,
      },
      {
        order: 2,
        layout: { row: 1, width: halfWidth, padding: '0 0 5px 0' },
        field: {
          name: 'email',
          withFormData: true,
          additionalProps: { withResetPasswordBtn, userGroupList },
          Component: ClientUserEmailInput,
        },
        validators: commonEmailValidators,
      },
      {
        label: 'Login Cell Phone Number',
        order: 3,
        layout: { row: 2, width: halfWidth, padding: '0 0 5px 0' },
        field: {
          name: 'loginCellPhoneNumber',
          Component: InternationalPhoneInput,
        },
        renderIf: isKitchenUserGroupUser(userGroupList),
        required: true,
      },
      {
        order: 4,
        layout: { row: 3 },
        field: {
          name: 'contact',
          withFormData: true,
          withChangeFieldValue: true,
          additionalProps: { onCancel, onConvertUser, userGroupList },
          Component: ExistingContactMessage,
        },
        renderIf: R.prop('isCreateMode'),
      },
      {
        label: 'First Name',
        order: 5,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'firstName',
          Component: (props) => <Input {...props} placeholder="First Name" />,
        },
        validators: [[R.identity, 'You must enter a name']],
        required: true,
      },
      {
        label: 'Last Name',
        order: 6,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'lastName',
          Component: (props) => <Input {...props} placeholder="Last Name" />,
        },
        validators: [[R.identity, 'Last name is required']],
        required: true,
      },
      {
        label: 'Work Phone',
        order: 7,
        layout: {
          row: 5,
          width: 'calc(30% - 10px)',
        },
        field: {
          name: 'workPhone',
          Component: (props) => (
            <PhoneNumberInput
              name="workPhone"
              placeholder="Work Phone"
              {...props}
            />
          ),
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
      {
        label: 'Ext',
        order: 8,
        layout: {
          row: 5,
          width: 'calc(20% - 20px)',
        },
        field: {
          name: 'ext',
          Component: ExtInput,
        },
      },
      {
        label: 'Mobile Phone',
        order: 9,
        layout: { row: 5, width: halfWidth },
        field: {
          name: 'mobilePhone',
          Component: (props) => (
            <PhoneNumberInput
              name="mobilePhone"
              placeholder="Mobile Phone"
              {...props}
            />
          ),
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
      {
        order: 10,
        layout: { row: 6, padding: '10px 0 5px 0' },
        field: {
          name: 'emptyComponent',
          Component: LabelS,
          additionalProps: { children: 'User Groups' },
        },
      },
      {
        order: 11,
        layout: { row: 6, width: '105px', padding: '10px 0 5px 0' },
        field: {
          name: 'addUserGroupLinkButton',
          withFormData: true,
          withChangeFieldValue: true,
          Component: AddUserGroupLinkButton,
        },
      },
      {
        label: 'User Group',
        order: 12,
        layout: { row: 7 },
        field: {
          name: 'userGroupId',
          withFormData: true,
          withChangeFieldValue: true,
          Component: UserGroupSelectComponent,
          additionalProps: {
            filterUserGroupsByEnabledApps: filterOptionsForEnabledApps(
              isAssetScannerEnabled,
              isClientPortalEnabled,
            ),
          },
        },
      },
      {
        order: 13,
        layout: { row: 8, padding: '0 0 5px 0' },
        field: {
          name: 'userGroups',
          withFormData: true,
          withChangeFieldValue: true,
          Component: UserGroupsTableComponent,
        },
        renderIf: R.complement(R.prop('isUserWithMoreThenOneUserGroup')),
        validators: [
          [R.complement(R.isEmpty), 'At least one user group must be set up'],
        ],
      },
      {
        order: 14,
        layout: { row: 9, padding: '10px 0 5px 0' },
        field: {
          name: 'selectedPropertyIds',
          withFormData: true,
          Component: SelectPropertiesComponent,
        },
        renderIf: R.both(
          R.compose(R.complement(R.isEmpty), R.prop('userGroups')),
          R.complement(R.prop('isUserWithMoreThenOneUserGroup')),
        ),
      },
    ],
  },
];

const clientAppsQuery = gql`
  query clientAppsQuery($clientId: ID!) {
    client(id: $clientId) {
      _id
      apps
    }
  }
`;

const Container = styled(FlexColumn)`
  justify-content: flex-start;
  height: 100%;
  padding: 24px 24px 10px 24px;
`;

const ButtonsContainer = styled(FlexContainer)`
  height: 40px;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
`;

const FormCreatorBase = styled(FormCreator)`
  height: auto;
`;

// highLevelValidateEmail :: [UserGroup] -> FormValues -> {email: String}
const highLevelValidateEmail = (userGroupList) =>
  R.ifElse(
    R.compose(
      R.both(
        isEmailRequiredByFormValues(userGroupList),
        R.propSatisfies(R.either(R.isEmpty, R.isNil), 'email'),
      ),
    ),
    R.always({ email: 'Email is required' }),
    R.always({}),
  );

// getClientApps :: { client: Client } -> [String]
const getClientApps = R.pathOr([], ['client', 'apps']);

// prepareUserGroups :: FormData -> [UserUserGroupInput]
const prepareUserGroups = ({ userGroups, ...otherProps }) =>
  R.map(
    R.applySpec({
      userGroupId: R.prop('userGroupId'),
      variables: R.compose(
        R.reject(R.isEmpty),
        R.juxt([
          R.applySpec({
            variableId: R.always(`${CLIENT_ID_AIT}_id1`),
            value: {
              [CLIENT_ID_AIT]: { [EQUALS_AIT_OPERATOR]: R.prop('clientId') },
            },
          }),
          R.ifElse(
            R.propSatisfies(R.isEmpty, 'selectedPropertyIds'),
            R.always([]),
            R.applySpec({
              variableId: R.always(`${PROPERTY_ID_AIT}_id1`),
              value: {
                [PROPERTY_ID_AIT]: {
                  [FLIPPED_CONTAINS_AIT_OPERATOR]: R.prop(
                    'selectedPropertyIds',
                  ),
                },
              },
            }),
          ),
        ]),
        R.always(otherProps),
      ),
    }),
  )(userGroups);

// prepareValues :: FormData -> CreateUserInput
const prepareValues = R.compose(
  R.mergeAll,
  R.juxt([
    R.omit(['userGroups']),
    R.compose(R.assoc('userGroups', R.__, {}), prepareUserGroups),
  ]),
);

export function ClientUserFormBase({
  title,
  formId,
  mutate,
  clientId,
  onCancel,
  initialValues,
  userGroupList,
  onConvertUser,
  successMessage,
  withResetPasswordBtn,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const { showSuccessNotification } = useNotificationState();
  const { [formId]: loading } = useSelector(R.prop('processes'));

  const { isUserWithMoreThenOneUserGroup } = initialValues;

  useEffect(() => {
    if (!clientId) {
      onCancel();
    }
  }, [clientId]);

  const { data, loading: fetchingClient } = useQuery(clientAppsQuery, {
    variables: { clientId },
    skip: !clientId,
  });
  const clientApps = getClientApps(data);

  const onSubmitHandler = async (values) => {
    const preparedValues = prepareValues(values);

    await mutate(preparedValues);

    showSuccessNotification(successMessage);
    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const formSectionsProps = {
    onCancel,
    onConvertUser,
    withResetPasswordBtn,
    userGroupList,
    isAssetScannerEnabled,
    isClientPortalEnabled,
  };

  if (fetchingClient) {
    return <Loader />;
  }

  return (
    <Container>
      <HeaderContainer>
        <span>{title}</span>
        <CloseSidebarFormIcon onCancel={onCancel} />
      </HeaderContainer>
      <FormCreatorBase
        id={formId}
        onSubmit={onSubmit}
        layout={{ card: false }}
        validate={highLevelValidateEmail(userGroupList)}
        initialValues={{ ...initialValues, clientApps }}
        sections={getClientPortalUsersFormSections(formSectionsProps)}
      />
      <ButtonsContainer>
        <Button
          size="small"
          form={formId}
          loader={loading}
          disabled={loading || isUserWithMoreThenOneUserGroup}
        >
          {title}
        </Button>
        <CloseSidebarFormButton size="small" onCancel={onCancel} />
      </ButtonsContainer>
    </Container>
  );
}

ClientUserFormBase.propTypes = {
  clientId: string.isRequired,
  formId: string.isRequired,
  initialValues: shape({
    status: string.isRequired,
  }).isRequired,
  mutate: func.isRequired,
  title: string.isRequired,
  successMessage: string,
  onCancel: func.isRequired,
  onConvertUser: func,
  withResetPasswordBtn: bool,
  userGroupList: arrayOf(shape({})),
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};
