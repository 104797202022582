import * as R from 'ramda';
import React, { useMemo } from 'react';
import { arrayOf, number, shape, string, bool } from 'prop-types';
import { collectionNames, AccountingStatus } from '@poly/constants';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_PROJECT_PERMISSION } from '@poly/security';

import { BidRulesTab } from '../../ClientSidebar/financialTabs/ClientBidRulesTab/BidRulesTab.js';

// checkIfProjectWithoutAssets :: Project -> Boolean
const checkIfProjectWithoutAssets = R.compose(
  R.equals(0),
  R.length,
  R.defaultTo([]),
  R.prop('assetIds'),
);

// checkIfBidRulesInheritedByEntity :: String -> Project -> Boolean
const checkIfBidRulesInheritedByEntity = (entity) =>
  R.converge(R.equals, [
    R.propOr([], 'bidRules'),
    R.pathOr([], [entity, 'bidRules']),
  ]);

// getWarningMessageByEntity :: String -> Project -> String
const getWarningMessageByEntity = (entity) => () =>
  `Bid Rules Inherited from ${entity}`;

// getInheritedRulesWarning :: Project -> String
const getInheritedRulesWarning = R.cond([
  [
    R.both(
      R.propSatisfies(R.complement(R.isNil), 'parent'),
      checkIfBidRulesInheritedByEntity('parent'),
    ),
    getWarningMessageByEntity('Master Project'),
  ],
  [
    checkIfBidRulesInheritedByEntity('property'),
    getWarningMessageByEntity('Property'),
  ],
  [R.T, R.always(null)],
]);

// checkIfProjectAlreadyInvoiced :: Project -> Boolean
const checkIfProjectAlreadyInvoiced = R.compose(
  R.includes(R.__, [AccountingStatus.INVOICED, AccountingStatus.PAID]),
  R.prop('accountingStatus'),
);

export function ProjectSidebarBidRulesTab({ project }) {
  const hasPermission = useHasUserAccessWithPermission(
    UPDATE_PROJECT_PERMISSION,
  );

  const isProjectInvoiced = useMemo(
    () => checkIfProjectAlreadyInvoiced(project),
    [project],
  );

  const showAssetsWarning = useMemo(
    () => checkIfProjectWithoutAssets(project),
    [project],
  );

  const rulesInheritedWarning = useMemo(
    () => getInheritedRulesWarning(project),
    [project],
  );

  const tabProps = {
    id: project._id,
    showAssetsWarning,
    rulesInheritedWarning,
    collection: collectionNames.projects,
    bidRules: R.pathOr([], ['bidRules'], project),
    hasAccess: hasPermission && !isProjectInvoiced,
  };

  return <BidRulesTab {...tabProps} />;
}

ProjectSidebarBidRulesTab.propTypes = {
  project: shape({
    _id: string.isRequired,
    accountingStatus: string,
    assetIds: arrayOf(string),
    bidRules: arrayOf(
      shape({
        type: string.isRequired,
        label: string.isRequired,
        amount: number.isRequired,
        isRuleActive: bool.isRequired,
      }),
    ),
  }),
};
