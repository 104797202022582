import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { Icon, Table } from '@poly/admin-book';
import {
  calculateTotal,
  centsToDollarsWithFormat,
  mapIndexed,
} from '@poly/utils';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import {
  getProjectEstimateTimeEntriesFormatted,
  getProjectEstimateTimeTotalFormatted,
} from '@poly/utils/src/project-estimate.js';

import { ProjectEstimateRowInfo, SubTitleTitle } from './components.js';

const TableS = styled(Table)`
  min-height: auto;
  td:last-child,
  th:last-child {
    width: 85px;
    text-align: right;
  }
`;

// assocId :: [Object] -> [Object]
const assocId = mapIndexed((item, index) => ({
  ...item,
  _id: index,
}));

// calculateTotalWithFormat :: Object] -> String
const calculateTotalWithFormat = R.compose(
  centsToDollarsWithFormat,
  calculateTotal(R.prop('total')),
);

export function ManualEntriesTable({ manualEntries }) {
  const tableConfig = [
    ['Description', R.prop('description')],
    ['Quantity', R.prop('quantity')],
    ['Cost Per', R.compose(centsToDollarsWithFormat, R.prop('rate'))],
    ['Total', R.compose(centsToDollarsWithFormat, R.prop('total'))],
  ];

  const tableProps = useMapConfigToTableProps(
    assocId,
    tableConfig,
    manualEntries,
  );

  return (
    <>
      <SubTitleTitle> Line Items </SubTitleTitle>
      <TableS {...tableProps} enableHoverEffect={false} />{' '}
      <ProjectEstimateRowInfo
        justifyContent="flex-end"
        label="Total"
        value={calculateTotalWithFormat(manualEntries)}
      />
    </>
  );
}

ManualEntriesTable.propTypes = {
  manualEntries: arrayOf(
    shape({
      description: string,
      quantity: number,
      rate: number,
      total: number,
    }),
  ),
};

export function TimeEntriesTable({ timeEntries, afterHoursCall }) {
  const tableConfig = [
    ['Type', R.prop('type')],
    ['Description', R.prop('description')],
    ['Total Time', R.prop('totalTime')],
    ['Total', R.prop('total')],
  ];

  const tableProps = useMapConfigToTableProps(
    R.compose(assocId, getProjectEstimateTimeEntriesFormatted),
    tableConfig,
    R.defaultTo([], timeEntries),
  );

  return (
    <>
      <SubTitleTitle> Time </SubTitleTitle>
      <TableS {...tableProps} enableHoverEffect={false} />
      {afterHoursCall && (
        <ProjectEstimateRowInfo
          justifyContent="flex-end"
          label="Incl. After Hours"
          value={centsToDollarsWithFormat(afterHoursCall)}
        />
      )}
      <ProjectEstimateRowInfo
        justifyContent="flex-end"
        label="Total"
        value={getProjectEstimateTimeTotalFormatted({
          timeEntries,
          afterHoursCall,
        })}
      />
    </>
  );
}

TimeEntriesTable.propTypes = {
  afterHoursCall: number,
  timeEntries: arrayOf(
    shape({
      description: string,
      type: string,
      totalMinutes: number,
      total: number,
    }),
  ),
};

export function MarkupEntriesTable({ markupEntries, isTransparent }) {
  const tableConfig = [
    ['Name', R.prop('supplierName')],
    ...(isTransparent
      ? [
          ['Amount', R.compose(centsToDollarsWithFormat, R.prop('amount'))],
          ['Markup', R.compose(centsToDollarsWithFormat, R.prop('markup'))],
        ]
      : []),
    ['Total', R.compose(centsToDollarsWithFormat, R.prop('total'))],
  ];

  const tableProps = useMapConfigToTableProps(
    assocId,
    tableConfig,
    markupEntries,
  );

  return (
    <>
      <SubTitleTitle> Suppliers </SubTitleTitle>
      <TableS {...tableProps} enableHoverEffect={false} />
      <ProjectEstimateRowInfo
        justifyContent="flex-end"
        label="Total"
        value={calculateTotalWithFormat(markupEntries)}
      />
    </>
  );
}

MarkupEntriesTable.propTypes = {
  isTransparent: bool,
  markupEntries: arrayOf(
    shape({
      supplierName: string,
      markup: number,
      amount: number,
      total: number,
    }),
  ),
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

const FileLink = styled.a`
  color: #f15642;
  font-size: 14px;
  :hover {
    text-decoration: none;
    color: #ff0000;
  }
`;

export function ProjectEstimateDocuments({ attachments }) {
  return (
    <>
      <SubTitleTitle> Important Project Document </SubTitleTitle>
      {attachments.map(({ fileName, url }) => (
        <FlexContainer key={url}>
          <Icon size={20} name="defaultFile" />

          <FileLink target="_blank" href={url}>
            {fileName}
          </FileLink>
        </FlexContainer>
      ))}
    </>
  );
}

ProjectEstimateDocuments.propTypes = {
  attachments: arrayOf(shape({ fileName: string, url: string.isRequired })),
};
