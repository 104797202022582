import React, { useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@poly/admin-book';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonPrintLayout } from '@poly/admin-ui';
import { arrayOf, bool, object, oneOfType, shape, string } from 'prop-types';

import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';

import { ExportPDFBtn } from '../WorkInProgressReport/WorkInProgressExportPDFBtn.js';
import { MondelezProjectRMStatusPageTable } from './MondelezProjectRMStatusPageTable.js';
import { useMondelezProjectRMStatusReportLazyQuery } from './useMondelezProjectRMStatusReportQuery.js';

const LoaderWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  min-width: 40px;
`;

export function MondelezProjectRMStatusPDFBtn({ sort, filter, ...props }) {
  const [printing, setPrinting] = useState(false);
  const queryHandler = useMondelezProjectRMStatusReportLazyQuery();

  const onPrintPDFClick = async () => {
    setPrinting(true);

    const { data } = await queryHandler({ filter, sort });

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: MondelezProjectRMStatusPageTable,
      fileName: 'mondelez_project_rm_status',
      metaData: { title: 'Mondelez Project R&M status' },
      isPrintPDF: true,
      data,
    });

    setPrinting(false);
  };

  if (printing) {
    return (
      <LoaderWrapperS>
        <Loader size={16} />
      </LoaderWrapperS>
    );
  }

  return (
    <ExportPDFBtn {...props} onClick={onPrintPDFClick}>
      {PRINT_PDF_CAPTION}
    </ExportPDFBtn>
  );
}

MondelezProjectRMStatusPDFBtn.propTypes = {
  loader: bool,
  filter: shape({}),
  sort: arrayOf(oneOfType([string, object])),
};
