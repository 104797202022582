import React from 'react';
import { string, node, func, bool } from 'prop-types';
import styled from 'styled-components';

export const LoginButton = styled.button`
  background: #ff6363;
  border-radius: 5px;
  padding: 9px 10px;
  position: relative;
  height: 34px;
  color: #fff;
  border: none;
  cursor: pointer;
  &[disabled] {
    &,
    &:hover {
      cursor: not-allowed;
      user-select: none;
    }
  }
`;

const FormS = styled.form`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0e285d;
`;

const Container = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LogoWrapper = styled(Container)`
  width: 100%;
  height: 125px;
  flex-direction: row;
  justify-content: center;
`;

const LinkS = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  & > img {
    max-width: 100%;
    max-height: calc(100% - 50px);
    width: 100%;
    object-fit: contain;
  }
`;

const Text = styled.div`
  display: flex;
  font-size: 28px;
  color: #78828c;
  font-weight: 500;
  text-transform: uppercase;
  flex-shrink: 0;
  align-self: center;
  padding: 10px 0;
`;

function Img({ href, src, title, logoTitle }) {
  return (
    <LinkS {...{ href }} rel="noopener noreferrer" target="_blank">
      <img {...{ src, title }} alt={title} />
      <Text>{logoTitle}</Text>
    </LinkS>
  );
}

Img.propTypes = {
  src: string.isRequired,
  href: string.isRequired,
  title: string.isRequired,
  logoTitle: string.isRequired,
};

export const Label = styled.div`
  font-size: 14px;
  color: #78828c;
  font-weight: 500;
  margin-bottom: 15px;
  margin-right: 5px;
`;

export const ErrorText = styled(Label)`
  color: #dd6263;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function Logo({ darkLogo }) {
  return (
    <svg
      width="193"
      height="94"
      viewBox="0 0 193 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3088_35402)">
        <path
          d="M80.2011 80.3549H46.7393V60.7782H60.2211L60.302 46.9714H80.2011V80.3549ZM52.862 74.2466H74.0759V53.0797H60.422L60.5446 61.1425H52.862V74.244V74.2466Z"
          fill={darkLogo ? '#0e285d' : '#00dedc'}
        />
        <path
          d="M47.0002 47.0286H13.5384V13.6452H47.0002V47.0286ZM19.661 40.9204H40.8776V19.7535H19.661V40.9204Z"
          fill="#5363A1"
        />
        <path
          d="M47.0002 30.2836V36.3919H57.3854V57.5588H36.1715V47.0287H30.0489V63.667H63.5106V30.2836H47.0002Z"
          fill={darkLogo ? '#00dedc' : '#fff'}
        />
      </g>
      <g clipPath="url(#clip1_3088_35402)">
        <path
          d="M101.819 57.8924V28.8065H114.236C120.222 28.8065 124.736 32.9826 124.736 38.5207C124.736 44.0588 120.222 48.235 114.236 48.235H107.901V57.892H101.819V57.8924ZM107.901 42.8721H114.023C116.62 42.8721 118.653 40.9609 118.653 38.5207C118.653 36.0805 116.62 34.1693 114.023 34.1693H107.901V42.8721Z"
          fill={darkLogo ? '#0e285d' : '#fff'}
        />
        <path
          d="M137.096 57.8859C131.276 57.8859 126.17 52.9544 126.17 47.3319C126.17 41.7095 131.276 36.7779 137.096 36.7779C142.916 36.7779 148.022 41.7098 148.022 47.3319C148.022 52.954 142.916 57.8859 137.096 57.8859ZM137.096 41.7209C134.141 41.7209 131.826 44.1856 131.826 47.3319C131.826 50.4783 134.141 52.9433 137.096 52.9433C140.051 52.9433 142.366 50.4783 142.366 47.3319C142.366 44.1856 140.051 41.7209 137.096 41.7209Z"
          fill={darkLogo ? '#0e285d' : '#fff'}
        />
        <path
          d="M156.448 28.8065H151.005V57.8924H156.448V28.8065Z"
          fill={darkLogo ? '#0e285d' : '#fff'}
        />
        <path
          d="M164.658 66.7097C162.881 66.7097 161.546 66.2612 161.191 66.1287V61.425C161.531 61.5533 162.237 61.767 163.167 61.767C165.523 61.767 166.537 60.2628 166.931 59.3658L167.596 57.8382L159.558 37.6241H165.188L170.199 51.5753L175.21 37.6241H180.843L172.611 59.2513C170.674 64.3401 168.147 66.7101 164.659 66.7101L164.658 66.7097Z"
          fill={darkLogo ? '#0e285d' : '#fff'}
        />
      </g>
      <defs>
        <clipPath id="clip0_3088_35402">
          <rect
            width="66.8661"
            height="66.7097"
            fill="white"
            transform="translate(13.6772 13.6452)"
          />
        </clipPath>
        <clipPath id="clip1_3088_35402">
          <rect
            width="79.0236"
            height="37.9032"
            fill="white"
            transform="translate(101.819 28.8065)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

Logo.propTypes = { darkLogo: bool };

const AppTitle = styled.span`
  font-family: 'TT Norms', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #fff;
`;

const SubTitleS = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 10px 0 20px 0;
  color: #94969c;
  text-align: center;
`;

export function LoginPageLayout({
  title,
  subTitle,
  hideLogo,
  darkLogo,
  children,
  onSubmit,
  className,
}) {
  return (
    <FormS onSubmit={onSubmit} className={className}>
      <Container>
        {!hideLogo && (
          <LogoWrapper>
            <Logo darkLogo={darkLogo} />
          </LogoWrapper>
        )}
        {title && <AppTitle>{title}</AppTitle>}
        {subTitle && <SubTitleS>{subTitle}</SubTitleS>}
        {children}
      </Container>
    </FormS>
  );
}

LoginPageLayout.propTypes = {
  title: string,
  hideLogo: bool,
  darkLogo: bool,
  subTitle: string,
  className: string,
  onSubmit: func.isRequired,
  children: node.isRequired,
};

export function UnmatchedIcon() {
  return (
    <svg
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1665 6.85449C1.1665 6.61287 1.36238 6.41699 1.604 6.41699H12.3957C12.6373 6.41699 12.8332 6.61287 12.8332 6.85449C12.8332 7.09612 12.6373 7.29199 12.3957 7.29199H1.604C1.36238 7.29199 1.1665 7.09612 1.1665 6.85449Z"
        fill="#94969C"
      />
    </svg>
  );
}

export function MatchedIcon() {
  return (
    <svg
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.64286 7.56532C2.472 7.39446 2.19499 7.39446 2.02414 7.56532C1.85328 7.73617 1.85328 8.01318 2.02414 8.18403L4.64914 10.809C4.81999 10.9799 5.097 10.9799 5.26786 10.809L11.6845 4.39237C11.8554 4.22151 11.8554 3.9445 11.6845 3.77365C11.5137 3.60279 11.2367 3.60279 11.0658 3.77365L4.9585 9.88096L2.64286 7.56532Z"
        fill="#099250"
      />
    </svg>
  );
}
