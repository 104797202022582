import * as R from 'ramda';
import { useMemo } from 'react';
import { ALL, MAX_ITEMS } from '@poly/admin-ui';
import { useLazyQuery } from '@apollo/client';
import { useInfiniteScrollQueryWithSubscription } from '@poly/client-utils';

import { PROJECTS_BY_SEARCH } from '../../modules/core/hooks/projects/queries.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../modules/core/hooks/projects/subscriptions.js';
import { getUnassignedProjectsByQueryData } from './helpers.js';

const prepareSearchInput = ({ clientId, sort }) => ({
  sort,
  query: {
    bool: {
      ...(clientId && clientId !== ALL
        ? { must: { match: { clientId } } }
        : {}),
      must_not: { exists: { field: 'manager' } },
    },
  },
});

export const useUnassignedProjectsQuery = ({ clientId, sort }) => {
  const searchInput = useMemo(
    () => prepareSearchInput({ clientId, sort }),
    [clientId, sort],
  );

  const { data, refetch, tableProps } = useInfiniteScrollQueryWithSubscription(
    PROJECTS_BY_SEARCH,
    searchInput,
    {
      pageSize: 25,
      inputName: 'searchInput',
      endpointName: 'searchProjects',
    },
    PROJECTS_BY_SEARCH_SUB,
    { searchInput },
  );

  const projects = useMemo(
    () => getUnassignedProjectsByQueryData(data),
    [data],
  );

  const total = useMemo(
    () => R.pathOr(0, ['searchProjects', 'total'])(data),
    [data],
  );

  return {
    total,
    refetch,
    projects,
    tableProps,
  };
};

export const useUnassignedProjectsLazyQuery = () => {
  const [queryHandler] = useLazyQuery(PROJECTS_BY_SEARCH);

  const handler = async ({ clientId, sort }) => {
    const response = await queryHandler({
      fetchPolicy: 'network-only',
      variables: {
        searchInput: {
          ...prepareSearchInput({ clientId, sort }),
          size: MAX_ITEMS,
        },
      },
    });

    return response;
  };

  return handler;
};
