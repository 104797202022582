import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';

import { RadioButtons } from '../../../../components/RadioButtons.js';

const RadioButtonsS = styled(RadioButtons)`
  padding: 10px 0 0 0;
`;

export function YesNoRadioButtons({ onChange, ...props }) {
  const RadioButtonsProps = {
    ...props,
    onChange: (value) => () => onChange(value),
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  };

  return <RadioButtonsS {...RadioButtonsProps} />;
}

YesNoRadioButtons.propTypes = {
  onChange: func,
};
