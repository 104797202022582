import * as R from 'ramda';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';

import { getAccountTypesTableConfig } from './table/accountTypesTableConfig.js';
import { prepareAccountTypesTableData } from './table/prepareAccountTypesTableData.js';

const tableConfig = getAccountTypesTableConfig(true);

// getAccountTypeRowExcelConfig :: AccountType -> [ExcelExportDataCell]
const getAccountTypeRowExcelConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 4)),
  R.juxt(R.map(R.nth(1), tableConfig)),
);

export const getAccountTypesExcelConfig = (data) => ({
  exportFileName: 'account_types_export.xls',
  columnWidths: [25, 15, 15, 15],
  rows: [
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 4),
      R.map(R.head, tableConfig),
    ),
    ...R.compose(
      R.map(getAccountTypeRowExcelConfig),
      prepareAccountTypesTableData,
    )(data),
  ],
});
