import * as R from 'ramda';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { InvoicesStatuses, NOTHING_UI_STRING } from '@poly/constants';
import { pathOrNothingUI } from '@poly/client-utils';
import { convertCentsToDollars } from '@poly/utils';

const SupplierInvoicesStatusesUi = {
  [InvoicesStatuses.PAID]: 'Paid',
  [InvoicesStatuses.VOIDED]: 'Voided',
  [InvoicesStatuses.PENDING]: 'Pending',
  [InvoicesStatuses.RECEIVED]: 'Received',
  [InvoicesStatuses.REJECTED]: 'Rejected',
  [InvoicesStatuses.REQUESTED]: 'Requested',
  [InvoicesStatuses.REPORT_ONLY]: 'Report Only',
  [InvoicesStatuses.PARTIALLY_PAID]: 'Partially Paid',
  [InvoicesStatuses.MANUAL_FOLLOW_UP]: 'Manual Follow Up',
};

// getSupplierNTEField :: Invoice -> Float
const getSupplierNTEField = R.compose(
  R.defaultTo(NOTHING_UI_STRING),
  R.converge(R.compose(R.prop('nte'), R.find), [
    R.compose(R.propEq(R.__, '_id'), R.path(['supplier', '_id'])),
    R.pathOr([], ['project', 'suppliers']),
  ]),
);

// getApproveSupplierInvoiceRowConfig :: Invoice -> [ExcelExportDataCell]
const getApproveSupplierInvoiceRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 5),
    ...R.repeat(ExcelExportCellType.dollarCurrency, 2),
  ]),
  R.juxt([
    R.ifElse(
      R.propIs(String, 'status'),
      R.compose(R.prop(R.__, SupplierInvoicesStatusesUi), R.prop('status')),
      R.always(NOTHING_UI_STRING),
    ),
    pathOrNothingUI(['project', 'projectId']),
    pathOrNothingUI(['supplier', 'company', 'name']),
    pathOrNothingUI(['project', 'property', 'name']),
    pathOrNothingUI(['client', 'name']),
    getSupplierNTEField,
    R.compose(convertCentsToDollars, R.defaultTo(0), R.prop('total')),
  ]),
);

export const getApproveSupplierInvoicesXLSConfig = ({
  invoices,
  exportFileName,
}) => ({
  exportFileName: `${exportFileName}.xlsx`,
  columnWidths: [10, 15, 25, 25, 25, 20, 20],
  rows: [
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 7),
      [
        'Status',
        'Work Order',
        'Supplier',
        'Property',
        'Client',
        // eslint-disable-next-line @cspell/spellchecker
        'SNTE',
        'Invoice Amount',
      ],
    ),
    ...R.map(getApproveSupplierInvoiceRowConfig, invoices),
  ],
});
