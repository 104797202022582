import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { array, func, number, shape, string } from 'prop-types';
import { isRMProject, propEqLegacy } from '@poly/utils';
import { useRouterQuery } from '@poly/client-routing';
import {
  omitAdministrativeAndBlockedSuppliersQuery,
  showError as showErrorAction,
  ServiceTypesSelect,
  SupplierSelect,
  FlexContainer,
  ClientSelect,
  MoneyInput,
} from '@poly/admin-ui';
import {
  commonFileValidators,
  validateFilesFunc,
  keywordSortQuery,
  useHasUserAccessWithPermission,
} from '@poly/client-utils';
import {
  FieldLayout,
  LinkButton,
  FilePicker,
  Select,
  Input,
} from '@poly/admin-book';
import {
  ProjectSpendTypesUI,
  ProjectSpendTypes,
  ASC_SORT_ORDER,
  ClientStatuses,
  AssetStatuses,
  ProjectType,
  FeeTypesUI,
  FeeTypes,
} from '@poly/constants';
import { ClientContactSelect } from '@poly/admin-ui/src/modules/forms/fields/ContactSelect/components/ClientContactSelect.js';
import { isProjectPropertyNotAllowedToEdit } from '@poly/utils/src/projects/common.js';
import { FORCE_UPDATE_PROPERTY_PROJECT_PERMISSION } from '@poly/security';

import {
  CLIENT_DETAILS,
  CLIENT_AND_PROPERTY_DETAILS,
} from '../../../../../modules/core/hooks/clients/queries.js';
import { formTypeConstants } from '../utils/constants.js';
import { halfWidth } from '../../../../../modules/forms/common.js';
import { PropertySelector } from '../components/PropertySelector.js';
import { CheckboxWithLabel } from '../components/CheckboxWithLabel.js';
import { AssetSelect } from '../../../../../components/AssetSelect.js';
import { ProjectTypeSelect } from '../components/ProjectTypeSelector.js';
import { AACManagerSelector } from '../components/AACManagerSelector.js';
import { ProjectSelect } from '../../../../../components/ProjectsSelect.js';
import { DeleteLineIcon } from '../../../../../components/DeleteLineIcon.js';
import { RecurringTaskSelect } from '../components/RecurringTaskSelector.js';
import { ProjectStatusSelect } from '../components/ProjectStatusSelector.js';
import { OccurrenceSelect } from '../../../../../components/OccurenceSelector.js';
import { ProblemCodeSelect } from '../../../../../components/ProblemCodeSelect.js';
import { ProjectSubCategorySelect } from '../components/ProjejctSubCategorySelect.js';
import { ProjectOccurrence } from '../../../../../modules/core/constants/projects.js';
import { ProjectClientManagerSelect } from '../components/ProjectClientManagerSelect.js';
import { FlexSpaceBetween } from '../../../../../modules/forms/assignSupplierForm/styles.js';
import { MultiWOCEmailsInputComp } from '../../../../../modules/forms/assignSupplierForm/AssignSupplierFormComp.js';
import { useClientGLCodes } from '../../../../../pages/ClientGLCodesPage/useClientGLCodes.js';
import { isProjectSubCategoryEnabled } from '../../add/helpers.js';
import {
  generateProjectInvoiceDescription,
  generateProjectDescription,
} from '../components/utils.js';
import {
  isNotRecurringAndFeeProject,
  checkIsProjectWithProperty,
  isRecurringFormData,
  isNotFeeProjectType,
  isFeeProjectType,
  isPMFormData,
  isFormType,
  isRecurringOrPMFormData,
  isNotPaidOrInvoicedAccountingStatus,
} from '../utils/sections.js';
import {
  applyWidth,
  CheckBoxS,
  TextAreaS,
} from '../../../../../modules/forms/formsStyles.js';

// checkIfPMWithAssets :: FormData -> Boolean
const checkIfPMWithAssets = R.both(
  isPMFormData,
  R.compose(R.gt(R.__, 0), R.pathOr(0, ['searchAssets', 'total'])),
);

// isNoClientSelected :: FormData -> Boolean
const isNoClientSelected = R.propSatisfies(
  R.either(R.isNil, R.isEmpty),
  'clientId',
);

// getAdditionalParams :: Object -> Object
const getAdditionalParams = R.compose(
  R.mergeRight({ selectedOptionAtOnChange: true, onlyActive: true }),
  R.objOf('disabled'),
  R.not,
  R.either(isFormType(formTypeConstants.CREATE), isNotFeeProjectType),
  R.prop('formData'),
);

function ServiceTypeSelectWithPrePopulation({
  onChange,
  changeFieldValue,
  ...restProps
}) {
  const onChangeHandler = (newServiceId, selectedOption) => {
    onChange(newServiceId);
    if (selectedOption) {
      const selectedOptionValue = selectedOption[1];
      changeFieldValue('serviceTypeName', selectedOptionValue);
    }
  };

  const additionalProps = getAdditionalParams(restProps);

  return (
    <ServiceTypesSelect
      {...restProps}
      {...additionalProps}
      onChange={onChangeHandler}
    />
  );
}

ServiceTypeSelectWithPrePopulation.displayName =
  'ServiceTypeSelectWithPrePopulation';

ServiceTypeSelectWithPrePopulation.propTypes = {
  onChange: func,
  changeFieldValue: func,
};

// getClientAndPropertyByResponse :: QueryResult -> { client: Client, property: Property }
const getClientAndPropertyByResponse = R.compose(
  R.pick(['client', 'property']),
  R.defaultTo({}),
  R.prop('data'),
);

// getDefaultProjectTypeByClient :: Client -> String
const getDefaultProjectTypeByClient = R.path(['configs', 'defaultProjectType']);

// getNotificationConfigsByClient :: Client -> ClientSRCEmailConfigs
const getNotificationConfigsByClient = R.compose(
  R.applySpec({
    toSiteContact: R.propOr(false, 'toSiteContact'),
    toRequester: R.propOr(false, 'toRequester'),
    toClientManager: R.propOr(false, 'toClientManager'),
  }),
  R.pathOr({}, ['configs', 'notifications', 'serviceRequestConfirmationEmail']),
);

const useAllowedPropertyEdit = (formData) => {
  const hasPermissionForceUpdateProperty = useHasUserAccessWithPermission(
    FORCE_UPDATE_PROPERTY_PROJECT_PERMISSION,
  );

  const { isPropertyDisabled } = useRouterQuery(['isPropertyDisabled']);

  const isNotAllowedToEdit = isProjectPropertyNotAllowedToEdit(formData);

  const isPMWithAssets = checkIfPMWithAssets(formData);

  return {
    isPropertyDisabled:
      isPropertyDisabled ||
      isPMWithAssets ||
      (isNotAllowedToEdit && !hasPermissionForceUpdateProperty),
    isPMWithAssets,
  };
};

// setClientDefaultProjectTypeP :: (String, ApolloClient, ((String, Any) -> _), (String -> _)) -> Promise _
const setClientDefaultProjectTypeP = (
  { clientId, propertyId },
  apolloClient,
  changeFieldValue,
  showError,
) =>
  clientId
    ? apolloClient
        .query({
          query: propertyId ? CLIENT_AND_PROPERTY_DETAILS : CLIENT_DETAILS,
          variables: { clientId, propertyId },
        })
        .then((response) => {
          const { client, property } = getClientAndPropertyByResponse(response);
          if (client) {
            changeFieldValue('client', client);
            const clientDivisionAccountId = R.path(
              ['financial', 'division', '_id'],
              client,
            );
            const clientExemptSalesTax = R.path(
              ['financial', 'exemptSalesTax'],
              client,
            );
            changeFieldValue('exemptSalesTax', clientExemptSalesTax);
            changeFieldValue('property.isMaster', property?.isMaster);
            changeFieldValue('divisionAccountId', clientDivisionAccountId);
            if (client.enableCostCenter) {
              const costCenterByProperty = R.prop('costCenter')(property);
              const costCenterByClient = R.path(
                ['financial', 'costCenter'],
                client,
              );

              changeFieldValue(
                'costCenter',
                costCenterByProperty || costCenterByClient,
              );
            }
            const defaultProjectType = getDefaultProjectTypeByClient(client);
            if (defaultProjectType) {
              changeFieldValue('type', defaultProjectType);
              changeFieldValue('serviceTypeId', null);
            }
            changeFieldValue(
              'notificationConfig',
              getNotificationConfigsByClient(client),
            );
          }
        })
        .catch(R.compose(showError, R.unless(R.is(String), R.toString)))
    : Promise.resolve(null);

function PropertySelectWithoutAllOption({
  onChange,
  formData,
  changeFieldValue,
  ...props
}) {
  const apolloClient = useApolloClient();

  const { isPropertyDisabled, isPMWithAssets } =
    useAllowedPropertyEdit(formData);

  const onChangeHandler = (propertyId, property) => {
    onChange(propertyId);

    if (changeFieldValue) {
      changeFieldValue(
        'description',
        generateProjectDescription({ formData, property }),
      );
      changeFieldValue(
        'invoiceDescription',
        generateProjectInvoiceDescription({ formData, property }),
      );
      changeFieldValue('property.isMaster', property?.isMaster);

      if (isNoClientSelected(formData)) {
        changeFieldValue('clientId', R.path(['client', '_id'], property));
        changeFieldValue('client', R.path(['client'], property));
      }

      if (R.path(['client', 'enableCostCenter'], formData)) {
        changeFieldValue('costCenter', R.prop('costCenter', property));
        changeFieldValue('property', property);
      }

      setClientDefaultProjectTypeP(
        { clientId: property?.client?._id, propertyId: property?._id },
        apolloClient,
        changeFieldValue,
        showErrorAction,
      ).then(() => onChange(propertyId));
    }
  };

  const selectorProps = {
    ...props,
    formData,
    changeFieldValue,
    fetchSize: 1000,
    withoutSkip: true,
    includeAllOption: false,
    onChange: onChangeHandler,
    required: !isPropertyDisabled,
    disabled: isPropertyDisabled,
    isClearable: isFormType(formTypeConstants.CREATE)(formData),
    additionalSearchParams: {
      sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
    },
    ...(isPMWithAssets
      ? { title: "You can't change property when assets attached" }
      : {}),
  };

  return <PropertySelector {...selectorProps} />;
}

PropertySelectWithoutAllOption.propTypes = {
  changeFieldValue: func,
  onChange: func.isRequired,
  formData: shape({ searchAssets: shape({ total: number.isRequired }) }),
};

function ClientSelectWithoutAllOption({
  changeFieldValue,
  onChange,
  ...restProps
}) {
  const apolloClient = useApolloClient();

  const onChangeHandler = (clientId) => {
    changeFieldValue('property', null);
    changeFieldValue('preventativeRepair.childProjectId', null);
    setClientDefaultProjectTypeP(
      { clientId },
      apolloClient,
      changeFieldValue,
      showErrorAction,
    ).then(() => onChange(clientId));
  };

  const query = {
    bool: {
      must: {
        term: {
          'configs.adminApp.allowProjectCreation': true,
        },
      },
      must_not: {
        term: {
          status: ClientStatuses.INACTIVE,
        },
      },
    },
  };

  return (
    <ClientSelect
      {...restProps}
      includeAllOption={false}
      onChange={onChangeHandler}
      query={query}
    />
  );
}

ClientSelectWithoutAllOption.propTypes = {
  changeFieldValue: func,
  onChange: func,
};

// isNonFeeRecurringOrPMProject :: Project -> Boolean
const isNonFeeRecurringOrPMProject = R.both(
  R.either(isRecurringFormData, isPMFormData),
  isNotFeeProjectType,
);

// getFeeTypeSelectOptions :: Object -> [SelectOption]
const getFeeTypeSelectOptions = R.compose(
  R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
  R.toPairs,
);

function FeeTypeSelector(props) {
  const { formData, onChange, changeFieldValue } = props;

  const onChangeValue = (feeType) => {
    onChange(feeType);

    if (changeFieldValue) {
      changeFieldValue(
        'description',
        generateProjectDescription({
          formData: { ...formData, feeType },
          property: formData.fullProperty || formData.property,
        }),
      );
      changeFieldValue(
        'invoiceDescription',
        generateProjectInvoiceDescription({
          formData: { ...formData, feeType },
          property: formData.fullProperty || formData.property,
        }),
      );
    }
  };

  const selectProps = {
    ...props,
    options: getFeeTypeSelectOptions(FeeTypesUI),
    onChange: onChangeValue,
  };

  return <Select {...selectProps} />;
}

const commonPropTypes = {
  formData: shape({
    fullProperty: shape({ name: string.isRequired }),
    property: shape({ name: string, _id: string.isRequired }),
  }),
  onChange: func.isRequired,
  changeFieldValue: func,
};

FeeTypeSelector.propTypes = commonPropTypes;

function CustomFeeDescriptionInput(props) {
  const { formData, onChange, changeFieldValue } = props;

  const onChangeValue = (event) => {
    onChange(event);

    if (changeFieldValue) {
      changeFieldValue(
        'description',
        generateProjectDescription({
          formData: { ...formData, customFeeDescription: event.target.value },
          property: formData.fullProperty || formData.property,
        }),
      );
      changeFieldValue(
        'invoiceDescription',
        generateProjectInvoiceDescription({
          formData: { ...formData, customFeeDescription: event.target.value },
          property: formData.fullProperty || formData.property,
        }),
      );
    }
  };

  return <Input {...props} onChange={onChangeValue} />;
}

CustomFeeDescriptionInput.propTypes = commonPropTypes;

export const clientFormField = {
  label: 'Client',
  field: {
    name: 'clientId',
    Component: ClientSelectWithoutAllOption,
    withChangeFieldValue: true,
  },
  required: true,
  validators: [[R.identity, 'Client name is required']],
  renderIf: isFormType(formTypeConstants.CREATE),
  leaveValues: true,
};

export const propertyFormField = {
  label: 'Property',
  field: {
    name: 'property._id',
    key: 'property._id',
    withFormData: true,
    withChangeFieldValue: true,
    Component: PropertySelectWithoutAllOption,
  },
  renderIf: checkIsProjectWithProperty,
  validators: [[R.identity, 'Property is required']],
  required: true,
};

export const occurrenceFormField = {
  label: 'Occurrence',
  field: {
    name: 'occurrence',
    withFormData: true,
    withChangeFieldValue: true,
    Component: OccurrenceSelect,
  },
  renderIf: isFormType(formTypeConstants.CREATE),
  required: true,
  leaveValues: true,
};

export const costTypeFormField = {
  label: 'Cost Type',
  order: 4,
  layout: { row: 3, width: halfWidth },
  field: {
    name: 'type',
    withFormData: true,
    Component: ProjectTypeSelect,
    withChangeFieldValue: true,
  },
  renderIf: isFormType(formTypeConstants.CREATE),
  required: true,
  leaveValues: true,
};

export const serviceTypeFormField = {
  label: 'Service Type',
  field: {
    name: 'serviceTypeId',
    withFormData: true,
    withChangeFieldValue: true,
    Component: ServiceTypeSelectWithPrePopulation,
  },
  renderIf: R.complement(isFeeProjectType),
  validators: [[R.identity, 'Service type is required']],
  leaveValues: true,
  required: true,
};

export const feeTypeFormField = {
  label: 'Fee Type',
  field: {
    name: 'feeType',
    withFormData: true,
    withChangeFieldValue: true,
    Component: FeeTypeSelector,
  },
  renderIf: isFeeProjectType,
  leaveValues: true,
  required: true,
  validators: [[R.identity, 'Fee Type is required']],
};

export const feeTypeDescriptionFormField = {
  label: 'Custom Fee Description',
  field: {
    name: 'customFeeDescription',
    withFormData: true,
    withChangeFieldValue: true,
    Component: CustomFeeDescriptionInput,
  },
  renderIf: R.both(
    isFeeProjectType,
    propEqLegacy('feeType', FeeTypes.CUSTOM_FEE),
  ),
  leaveValues: true,
  required: true,
  validators: [[R.identity, 'Custom Fee Description is required']],
};

export const statusFormField = {
  label: 'Status',
  order: 6,
  layout: { row: 4, width: halfWidth },
  field: {
    name: 'status',
    withFormData: true,
    Component: ProjectStatusSelect,
  },
  renderIf: isFormType(formTypeConstants.EDIT),
  required: true,
  leaveValues: true,
};

export const taskTypeFormField = {
  label: 'Task Type',
  field: {
    name: 'taskListType',
    Component: RecurringTaskSelect,
  },
  required: true,
  renderIf: isNonFeeRecurringOrPMProject,
};

export const notRMFormField = {
  label: 'Not R&M',
  field: {
    name: 'notRM',
    Component: CheckBoxS,
  },
  renderIf: isProjectSubCategoryEnabled,
};

export const descriptionFormField = {
  label: 'Description',
  field: {
    name: 'description',
    Component: Input,
  },
  validators: [[R.identity, 'Description is required']],
  required: true,
};

const DeleteLineIconS = styled(DeleteLineIcon)`
  position: unset;
  margin-left: 5px;
`;

const NteInputContainer = styled(FlexContainer)`
  margin-left: 15px;
  flex-grow: 1;
`;

function AddNewSupplierBtn({ add }) {
  return (
    <LinkButton
      type="button"
      onClick={() => add({ disableWOCEmail: true, attachments: [] })}
    >
      Assign Another Supplier
    </LinkButton>
  );
}

AddNewSupplierBtn.propTypes = {
  add: func.isRequired,
};

const AddSupplierButtonContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: flex-end;
`;

const isSupplierWithAutoWOC = (index) =>
  R.complement(
    R.pathOr(false, ['masterProjectSuppliers', index, 'disableWOCEmail']),
  );

// isNotHousekeepingProjectType :: Project -> Boolean
const isNotHousekeepingProjectType = R.complement(
  propEqLegacy('type', ProjectType.HOUSEKEEPING),
);

function SendSupplierWOCCheckBox(props) {
  return (
    <CheckboxWithLabel
      {...props}
      label="Uncheck to Send Auto Supplier Work Order Confirmation"
    />
  );
}

function SupplierField({
  name,
  index,
  fields: { value, push, remove },
  formData,
}) {
  return (
    <>
      <FlexSpaceBetween>
        <FieldLayout
          label="Supplier"
          layout={{ width: 'calc(48% - 5px)' }}
          field={{
            name: `${name}._id`,
            additionalProps: {
              isClearable: true,
              query: omitAdministrativeAndBlockedSuppliersQuery,
            },
            Component: SupplierSelect,
          }}
        />
        <NteInputContainer>
          {formData.type !== ProjectType.HOUSEKEEPING && (
            <FieldLayout
              label="Supplier Not to Exceed"
              layout={{ width: 'calc(30% - 5px)' }}
              field={{
                name: `${name}.nte`,
                Component: MoneyInput,
              }}
            />
          )}
          <FieldLayout
            label=""
            layout={{ width: 'calc(85% - 7px)', padding: '28px 0 0 10px' }}
            field={{
              name: `${name}.disableWOCEmail`,
              Component: SendSupplierWOCCheckBox,
            }}
          />
          {index > 0 && (
            <DeleteLineIconS
              name="delete"
              size={14}
              onClick={() => remove(index)}
            />
          )}
        </NteInputContainer>
      </FlexSpaceBetween>
      {isSupplierWithAutoWOC(index)(formData) && (
        <FlexSpaceBetween>
          <FieldLayout
            label="Send the WOC to"
            field={{
              name: `${name}.additionalSupplierWOCEmails`,
              Component: MultiWOCEmailsInputComp,
              additionalProps: {
                supplierId: value[index]?._id,
                hideLabel: true,
                skipAutofill: formData.formType === formTypeConstants.EDIT,
              },
            }}
          />
        </FlexSpaceBetween>
      )}
      {isSupplierWithAutoWOC(index)(formData) && (
        <FlexSpaceBetween>
          <FieldLayout
            label="Supplier Instructions"
            field={{
              name: `${name}.supplierNote`,
              Component: TextAreaS,
              additionalProps: {
                formData,
                placeholder: 'Enter supplier instructions',
                rows: 4,
              },
            }}
          />
        </FlexSpaceBetween>
      )}
      {isSupplierWithAutoWOC(index)(formData) && (
        <FlexSpaceBetween>
          <FieldLayout
            label="Attach Files"
            field={{
              name: `${name}.attachments`,
              Component: FilePicker,
            }}
            validateFunction={validateFilesFunc}
            validators={commonFileValidators}
          />
        </FlexSpaceBetween>
      )}
      {isNotHousekeepingProjectType(formData) && (
        <AddSupplierButtonContainer>
          {index === value.length - 1 && <AddNewSupplierBtn add={push} />}
        </AddSupplierButtonContainer>
      )}
    </>
  );
}

SupplierField.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    // eslint-disable-next-line react/forbid-prop-types
    value: array.isRequired,
    remove: func.isRequired,
    push: func,
  }),
  formData: shape({ type: string }).isRequired,
};

export const masterProjectSuppliersField = {
  label: 'Suppliers',
  field: {
    name: 'masterProjectSuppliers',
    isArrayField: true,
    withFormData: true,
    Component: SupplierField,
  },
  renderIf: isNonFeeRecurringOrPMProject,
};

export const aacManagerFormField = {
  label: 'Assigned CSR',
  field: {
    name: 'managerId',
    withFormData: true,
    Component: applyWidth(AACManagerSelector),
  },
};

export const clientManagerFormField = {
  label: 'Client Manager',
  field: {
    withFormData: true,
    name: 'clientManagerId',
    Component: ProjectClientManagerSelect,
  },
  renderIf: isNotFeeProjectType,
};

export const siteContactFormField = {
  label: 'Site Contact',
  labelSwitchAddNewMode: "Didn't find the site contact?",
  labelSwitchFindMode: 'New site contact will be added when WO is created.',
  labelNotBelongProperty:
    'This contact is associated with another property. Would you like to move them to this property?',
  name: 'siteContact',
  getClientId: R.pathOr('', ['clientId']),
  getPropertyId: R.pathOr('', ['property', '_id']),
  metaFieldName: 'meta.sendSiteContactServiceConfirmationEmail',
  renderIf: checkIsProjectWithProperty,
  SelectComponent: ClientContactSelect,
};

export const requesterFormField = {
  label: 'Requester',
  placeholder: 'Start typing requesters',
  labelSwitchAddNewMode: "Didn't find the requester?",
  labelSwitchFindMode: 'New requester will be added when WO is created.',
  labelNotBelongProperty:
    'This contact is associated with another property. Would you like to move them to this property?',
  name: 'contact',
  getClientId: R.pathOr('', ['clientId']),
  getPropertyId: R.pathOr('', ['property', '_id']),
  metaFieldName: 'meta.sendRequesterServiceConfirmationEmail',
  renderIf: R.allPass([
    checkIsProjectWithProperty,
    isNotRecurringAndFeeProject,
  ]),
  SelectComponent: ClientContactSelect,
};

export const projectSubCategoryFormField = {
  label: 'Sub Category',
  field: {
    withFormData: true,
    name: 'subCategoryId',
    Component: ProjectSubCategorySelect,
  },
  renderIf: R.both(
    R.path(['client', 'configs', 'enableProjectSubCategory']),
    isRMProject,
  ),
};

// clientGLCodesToSelectOptions :: [ClientGLCode] -> [[String, String]]
const clientGLCodesToSelectOptions = R.map(
  R.applySpec({
    value: R.prop('_id'),
    label: R.compose(
      R.join(' '),
      R.juxt([
        R.prop('code'),
        R.compose(R.concat(R.__, ')'), R.concat('('), R.prop('name')),
      ]),
    ),
  }),
);

function ClientGLCodeSelect({ formData, ...props }) {
  const clientId = R.path(['client', '_id'], formData);
  const { glCodes, loading } = useClientGLCodes(clientId, true);

  const options = clientGLCodesToSelectOptions(glCodes);

  const selectProps = {
    ...props,
    loading,
    options,
    placeholder: 'Start typing GL code',
  };

  return <Select {...selectProps} />;
}

ClientGLCodeSelect.propTypes = {
  onChange: func.isRequired,
  formData: shape({
    client: shape({ _id: string.isRequired }),
  }).isRequired,
};

export const clientGLCodesSelectConfig = {
  label: 'Client GL Codes',
  field: {
    name: 'clientGLCodeId',
    withFormData: true,
    Component: ClientGLCodeSelect,
  },
  renderIf: R.path(['client', 'enableGlCodes']),
};

// shouldDisplayPreventativeRepairSection :: { parent: Project, occurrence: String, formType: String }
// -> Boolean
const shouldDisplayPreventativeRepairSection = R.either(
  R.both(
    propEqLegacy('occurrence', ProjectOccurrence.ONE_TIME_OCCURRENCE),
    propEqLegacy('formType', formTypeConstants.CREATE),
  ),
  R.both(
    propEqLegacy('occurrence', ProjectOccurrence.ONE_TIME_OCCURRENCE),
    R.pathSatisfies(R.isNil, ['parent', '_id']),
  ),
);

// preparePreventativeRepairChildProjectQuery :: { formData: FormData } -> Object
const preparePreventativeRepairChildProjectQuery = ({ formData }) => {
  const clientId = formData?.client?._id;

  return {
    bool: {
      must: [
        { exists: { field: 'parentId' } },
        ...(clientId ? [{ match: { clientId } }] : []),
      ],
    },
  };
};

export const preventativeRepairChildProjectField = {
  label: 'Child Project',
  field: {
    name: 'preventativeRepair.childProjectId',
    withFormData: true,
    Component: ProjectSelect,
    additionalProps: {
      includeAllOption: false,
      getQuery: preparePreventativeRepairChildProjectQuery,
    },
  },
  renderIf: R.allPass([
    propEqLegacy('spendType', ProjectSpendTypes.PREVENTIVE_REPAIR),
    shouldDisplayPreventativeRepairSection,
  ]),
};

// preparePreventativeRepairRelatedAssetQuery :: { formData: FormData } -> Object
const preparePreventativeRepairRelatedAssetQuery = ({ formData }) => {
  const propertyId = formData?.property?._id;

  return {
    bool: {
      must: [
        { match: { status: AssetStatuses.ACTIVE } },
        ...(propertyId ? [{ match: { propertyId } }] : []),
      ],
    },
  };
};

// preparePreventativeRepairRelatedAssetSkipQuery :: { formData: FormData } -> Boolean
const preparePreventativeRepairRelatedAssetSkipQuery = R.pathSatisfies(
  R.isNil,
  ['formData', 'property', '_id'],
);

export const preventativeRepairRelatedAssetField = {
  label: 'Related Asset',
  field: {
    name: 'preventativeRepair.relatedAssetId',
    withFormData: true,
    Component: AssetSelect,
    additionalProps: {
      getQuery: preparePreventativeRepairRelatedAssetQuery,
      getSkipQuery: preparePreventativeRepairRelatedAssetSkipQuery,
    },
  },
  renderIf: R.allPass([
    propEqLegacy('spendType', ProjectSpendTypes.PREVENTIVE_REPAIR),
    propEqLegacy('occurrence', ProjectOccurrence.ONE_TIME_OCCURRENCE),
  ]),
};

// getProjectSpendTypeSelectOptions ::  Object -> [SelectOption]
const getProjectSpendTypeSelectOptions = (types) =>
  R.compose(
    R.map(R.applySpec({ value: R.head, label: R.last })),
    R.toPairs,
  )(types);

function ProjectSpendTypeSelect({ formData, ...props }) {
  const selectProps = {
    ...props,
    options: getProjectSpendTypeSelectOptions(ProjectSpendTypesUI),
  };

  return <Select {...selectProps} />;
}

ProjectSpendTypeSelect.propTypes = {
  formData: shape({}),
};

export const projectSpendTypeFormField = {
  label: 'Spend Type',
  field: {
    name: 'spendType',
    withFormData: true,
    Component: ProjectSpendTypeSelect,
  },
  required: true,
  validators: [[R.identity, 'Spend Type is required']],
};

// we need this component to keep client data at form values on form reinitialize
export const clientInfoHolderField = {
  field: {
    name: 'client',
    Component: () => null,
  },
  leaveValues: true,
};

export const callbackFormFieldConfig = {
  field: {
    name: 'isCallback',
    Component: (props) => <CheckboxWithLabel {...props} label="Callback" />,
  },
  renderIf: R.allPass([
    R.complement(isRecurringOrPMFormData),
    isNotPaidOrInvoicedAccountingStatus,
    R.either(
      R.complement(R.path(['parent', '_id'])),
      R.prop('cloneFromProjectId'),
    ),
  ]),
};

function RecallReferenceProjectSelect({ formData, ...props }) {
  const isRequired = !!formData?.workCompletionDate;

  const getQuery = () => {
    const clientId = formData?.client?._id;
    return clientId ? { term: { clientId } } : null;
  };

  return (
    <ProjectSelect
      isClearable={!isRequired}
      includeAllOption={false}
      getQuery={getQuery}
      {...props}
      required={isRequired}
    />
  );
}

RecallReferenceProjectSelect.propTypes = {
  formData: shape({
    _id: string,
    client: shape({ _id: string }),
  }),
};

export const recallReferenceProjectField = {
  label: 'Recall Reference',
  field: {
    name: 'recallReferenceProjectId',
    withFormData: true,
    Component: RecallReferenceProjectSelect,
  },
  renderIf: R.prop('isCallback'),
};

export const problemCodeFormField = {
  label: 'Problem Code',
  field: {
    name: 'problemCodeId',
    Component: ProblemCodeSelect,
  },
};

export const enableCollectDocumentsTaskFormFieldConfig = {
  field: {
    name: 'enableCollectDocumentsTask',
    Component: (props) => (
      <CheckboxWithLabel {...props} label="Enable Collect Documents Task" />
    ),
  },
  renderIf: isRecurringOrPMFormData,
};
