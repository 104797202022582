import React from 'react';
import styled from 'styled-components';
import { WorkOrderPriority, WorkOrderStatus } from '@poly/constants';
import { TableSearchInput, TablePagination } from '@poly/admin-ui';
import {
  TableWithPaginationContainer,
  PageHeaderContainer,
  DefaultBodyWrapper,
  MainHeader,
  TableCard,
} from '@poly/admin-book';

import { ProjectsPageTable } from '../../modules/tables/projectsTable/ProjectsTable.js';
import { EmergencyProjectsExportButtons } from './EmergencyProjectsExportButtons.js';

const query = {
  bool: {
    must: [
      { match: { status: WorkOrderStatus.ACTIVE } },
      { match: { priorityId: WorkOrderPriority.EMERGENCY } },
    ],
  },
};

const HeaderSectionWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 30px;
  }
`;

export function EmergencyProjectsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Emergency Projects</MainHeader>
        <HeaderSectionWrapperS>
          <EmergencyProjectsExportButtons query={query} />
          <TableSearchInput />
        </HeaderSectionWrapperS>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCard>
          <TableWithPaginationContainer paginationVisible>
            <ProjectsPageTable query={query} />
          </TableWithPaginationContainer>
          <TablePagination />
        </TableCard>
      </DefaultBodyWrapper>
    </>
  );
}
