// App Names
export const SITE_APP_NAME = 'site';
export const MOBILE_APP_NAME = 'mobile';
export const ADMIN_APP_NAME = 'admin';
export const TIAA_PORTAL_APP_NAME = 'tiaaPortal';
export const ASSET_SCANNER_APP_NAME = 'assetScannerApp';
export const CLIENT_PORTAL_APP_NAME = 'clientPortalApp';
export const REQUESTS_APP_NAME = 'requestsApp';
export const VENDOR_PORTAL_APP_NAME = 'vendorPortalApp';

export const ClientApps = {
  Admin: ADMIN_APP_NAME,
  Site: SITE_APP_NAME,
  Mobile: MOBILE_APP_NAME,
  TiaaPortal: TIAA_PORTAL_APP_NAME,
  AssetScanner: ASSET_SCANNER_APP_NAME,
  ClientPortal: CLIENT_PORTAL_APP_NAME,
  VendorPortal: VENDOR_PORTAL_APP_NAME,
  Requests: REQUESTS_APP_NAME,
};
