import { gql } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import * as R from 'ramda';
import { isNilOrEmpty } from '@poly/utils';
import { DESC_SORT_ORDER } from '@poly/constants';
import {
  useSortableTable,
  useTableInfiniteScrollQuery,
} from '@poly/client-utils';
import { TableCardWithoutTabs } from '@poly/admin-book';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import {
  JournalEntryLedgerTable,
  getJournalEntriesTableConfig,
} from './JournalEntryLedgerTable.js';
import { JournalEntryLedgerHeader } from './JournalEntryLedgerHeader.js';

const journalEntriesQuery = gql`
  query JournalEntries($input: JournalEntryLedgerInput!) {
    getManualJournals(input: $input) {
      hits {
        _id
        transaction_number
        date
        reverse_journal {
          _id
          date
        }
        description
        type
        lines {
          cash_amount
          accrual_amount
          description
          payment_status
          reconciled_at
          account {
            code
            name
          }
        }
        created_by {
          _id
          profile {
            fullName
          }
        }
      }
      total
    }
  }
`;

export function JournalEntryLedgerPage() {
  const [query, setQuery] = useState({});

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    order: DESC_SORT_ORDER,
    tableConfig: getJournalEntriesTableConfig(false, R.identity),
  });

  const input = useMemo(() => ({ ...query, sort }), [query, sort]);

  const { data, loading, tableProps, refetch } = useTableInfiniteScrollQuery(
    journalEntriesQuery,
    input,
    {
      pageSize: 50,
      endpointName: 'getManualJournals',
      fetchPolicy: 'network-only',
      skip: isNilOrEmpty(query),
    },
  );

  return (
    <PageWithSearchHeader headerHeight="114px">
      <JournalEntryLedgerHeader
        query={query}
        setQuery={setQuery}
        data={data}
        loading={loading}
      />
      <TableCardWithoutTabs>
        <JournalEntryLedgerTable
          data={data}
          loading={loading}
          refetch={refetch}
          tableProps={{ ...tableProps, ...tableSortProps }}
          isPrint={false}
        />
      </TableCardWithoutTabs>
    </PageWithSearchHeader>
  );
}
