import React from 'react';
import * as R from 'ramda';
import {
  arrayOf,
  bool,
  instanceOf,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import styled from 'styled-components';
import { Table } from '@poly/admin-book/src/Table/Table.js';
import { singleButtonColumnStyles } from '@poly/admin-book/src/Table/columnStyles.js';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { RecurringJournalStatuses } from '@poly/constants/src/recurringJournal.js';
import { Link } from '@poly/client-routing/src/generator.js';
import { applyPathOrNothingUI } from '@poly/client-utils/src/objects.js';
import { mongoSortQuery } from '@poly/client-utils/src/sorting.js';
import { applyPath } from '@poly/utils/src/general.js';
import { formatDate } from '@poly/utils/src/dates.js';
import { formatTotal } from '@poly/utils/src/formatters.js';
import { insertParamsIntoURL } from '@poly/utils/src/url.js';

import { routesNames } from '../../routes/index.js';
import {
  calculateLineTotalBase,
  isDebitLine,
} from './recurringJournalEntryHelpers.js';

function EditRecurringJournalBtn({ _id }) {
  const href = insertParamsIntoURL(
    { journalId: _id },
    routesNames.EDIT_RECURRING_JOURNAL,
  );
  return <Link href={href}>Edit</Link>;
}

EditRecurringJournalBtn.propTypes = {
  _id: string.isRequired,
};

const recurringJournalsStatusesUI = {
  [RecurringJournalStatuses.active]: 'Active',
  [RecurringJournalStatuses.inactive]: 'Inactive',
};

export const getRecurringJournalsTableConfig = (
  isPrint = false,
  format = true,
) => [
  [
    'JE #',
    R.compose(R.concat('RJE-'), R.prop('reference_id')),
    mongoSortQuery('reference_id'),
  ],
  [
    'Status',
    R.compose(R.prop(R.__, recurringJournalsStatusesUI), R.prop('status')),
    mongoSortQuery('status'),
  ],
  [
    'Start Date',
    applyPath(formatDate, ['start_date']),
    mongoSortQuery('start_date'),
  ],
  [
    'End Date',
    applyPathOrNothingUI(['end_date'], formatDate),
    mongoSortQuery('end_date'),
  ],
  [
    'Debit',
    R.compose(
      R.when(R.always(format), formatTotal),
      calculateLineTotalBase(isDebitLine),
    ),
    mongoSortQuery('amountSort'),
  ],
  [
    'Credit',
    R.compose(
      R.when(R.always(format), formatTotal),
      calculateLineTotalBase(R.complement(isDebitLine)),
    ),
    mongoSortQuery('amountSort'),
  ],
  ...(isPrint ? [] : [['', EditRecurringJournalBtn]]),
];

const TableS = styled(Table)`
  ${singleButtonColumnStyles(7)}
`;

export function RecurringJournalsTable({
  recurringJournals,
  isPrint,
  ...tableSortingProps
}) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    getRecurringJournalsTableConfig(isPrint),
    recurringJournals,
  );

  return <TableS {...tableProps} {...tableSortingProps} />;
}

RecurringJournalsTable.propTypes = {
  isPrint: bool,
  recurringJournals: arrayOf(
    shape({
      _id: string.isRequired,
      start_date: oneOfType([instanceOf(Date), string]),
      end_date: oneOfType([instanceOf(Date), string]),
      reference_id: string,
      status: string,
      lines: arrayOf(
        shape({
          cash_amount: number,
          accrual_amount: number,
          account: shape({
            code: string,
            accountType: shape({
              _id: string.isRequired,
              normal_balance: string,
            }),
          }),
        }),
      ),
    }),
  ),
};
