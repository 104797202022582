import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils/src/hooks/useReactiveQuery.js';
import { useTableSearch } from '@poly/admin-ui/src/redux/search-text.js';
import { useSetItemsCount } from '@poly/admin-ui/src/hooks/useSetItemsCount.js';
import { usePaginationParams } from '@poly/admin-ui/src/hooks/usePaginationParams.js';

const GET_ACCOUNT_TYPES_QUERY = gql`
  query GET_ACCOUNT_TYPES($input: AccountTypesInput) {
    accountTypes(input: $input) {
      hits {
        _id
        name
        status
        category
        normal_balance
        system_type
      }
      total
    }
  }
`;

const ACCOUNT_TYPES_CHANGED_SUBSCRIPTION = gql`
  subscription ACCOUNT_TYPES_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAccountTypeChanged(input: $input) {
      id
      type
    }
  }
`;

export const useAccountTypesQuery = (sort) => {
  const { searchText } = useTableSearch();

  const paginationParams = usePaginationParams();

  const queryOptions = useMemo(
    () => ({
      variables: { input: { searchText, sort, ...paginationParams } },
      fetchPolicy: 'network-only',
    }),
    [sort, searchText, paginationParams],
  );

  const { data, loading } = useReactiveQuery(
    GET_ACCOUNT_TYPES_QUERY,
    ACCOUNT_TYPES_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: {} },
  );

  useSetItemsCount(R.pathOr(0, ['accountTypes', 'total']), data);

  return {
    data,
    loading,
  };
};

export const useAccountTypesLazyQuery = ({ sort }) => {
  const [loading, setLoading] = useState(false);

  const { searchText } = useTableSearch();

  const input = useMemo(() => ({ searchText, sort }), [searchText, sort]);

  const [runQuery] = useLazyQuery(GET_ACCOUNT_TYPES_QUERY);

  const runAccountTypesQuery = async () => {
    setLoading(true);

    const { data } = await runQuery({ variables: { input } });

    setLoading(false);

    return data;
  };

  return {
    loading,
    runAccountTypesQuery,
  };
};
