import React from 'react';
import * as R from 'ramda';
import { useModalContext } from '@poly/admin-ui';
import { IconButton } from '@poly/admin-book';
import { mongoSortQuery } from '@poly/client-utils';

import { editDivisionFormId } from './constants.js';
import { DivisionForm } from './forms/DivisionForm.js';

function EditButton(props) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: editDivisionFormId,
      title: 'Edit Division',
      formId: editDivisionFormId,
      btnCaption: 'Save',
      content: <DivisionForm isNewDivision={false} document={props} />,
    });

  return <IconButton size={15} name="edit" onClick={onClick} />;
}

export const divisionsTableConfig = [
  ['Name', R.propOr('', 'nameUI'), mongoSortQuery('name')],
  ['Status', R.propOr('', 'statusUI'), mongoSortQuery('status')],
  ['', EditButton],
];
