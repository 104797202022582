import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { MAX_ITEMS } from '@poly/admin-ui/src/constants/general.js';
import { useMasterSuppliersSearchInput } from '@poly/admin-ui/src/modules/masterSupplier/hooks/useMasterSuppliersSearchInput.js';
import { SEARCH_MASTER_SUPPLIERS } from '@poly/admin-ui/src/modules/masterSupplier/hooks/useSearchMasterSuppliersQuery.js';

export const useMasterSuppliersLazyQuery = ({ tableSort, status, type }) => {
  const [loading, setLoading] = useState(false);

  const { query, sort, searchTerm } = useMasterSuppliersSearchInput({
    query: {
      bool: {
        filter: [{ term: { status } }, ...(type ? [{ term: { type } }] : [])],
      },
    },
    sort: tableSort,
  });

  const input = useMemo(
    () => ({
      query,
      searchTerm,
      sort,
      size: MAX_ITEMS,
    }),
    [searchTerm, sort, query],
  );

  const [runQuery] = useLazyQuery(SEARCH_MASTER_SUPPLIERS);

  const runMasterSuppliersQuery = async () => {
    setLoading(true);
    const { data } = await runQuery({
      variables: { input },
    });
    setLoading(false);
    return R.pathOr([], ['searchMasterSuppliers', 'hits'], data);
  };

  return {
    loading,
    runMasterSuppliersQuery,
  };
};
