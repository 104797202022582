import * as R from 'ramda';
import React from 'react';
import { startOfYear } from 'date-fns';
import { alwaysNewDate } from '@poly/utils';
import { ClientSelect } from '@poly/admin-ui';
import { bool, func } from 'prop-types';
import { ProjectTypeToNameMap, ProjectType } from '@poly/constants';
import { DateRangePicker, Select } from '@poly/admin-book';

import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FlexColumn } from '../../components/FlexContainer.js';
import { SearchHeaderColumn } from '../../components/SearchHeaderColumn.js';
import {
  BatchInvoiceReportsFiltersContainerS,
  BatchInvoiceReportsSearchHeaderButton,
} from './components.js';

// projectTypeSelectOptions :: [Pair String String]
const projectTypeSelectOptions = R.compose(
  R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
  R.toPairs,
  R.pick([
    ProjectType.WORK_ORDER,
    ProjectType.HOUSEKEEPING,
    ProjectType.PASS_THROUGH,
    ProjectType.FEE,
    ProjectType.BID,
    ProjectType.LISTING,
  ]),
)(ProjectTypeToNameMap);

const batchInvoiceReportsFilters = {
  clientId: 'clientId',
  type: 'type',
  clientInvoiceDate: 'clientInvoiceDate',
  startDate: 'startDate',
  endDate: 'endDate',
};

export const defaultBatchInvoiceReportsFilters = {
  [batchInvoiceReportsFilters.startDate]: startOfYear(
    alwaysNewDate(),
  ).toDateString(),
  [batchInvoiceReportsFilters.endDate]: alwaysNewDate().toDateString(),
};

export const batchInvoiceReportsPageHeaderConfig = [
  { name: batchInvoiceReportsFilters.clientId },
  { name: batchInvoiceReportsFilters.type },
  {
    name: batchInvoiceReportsFilters.clientInvoiceDate,
    nestedFields: ['startDate', 'endDate'],
    defaultValue: {
      startDate: startOfYear(alwaysNewDate()).toDateString(),
      endDate: alwaysNewDate().toDateString(),
    },
  },
];

export function BatchInvoiceReportsHeader({ loading, setFilters }) {
  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    batchInvoiceReportsPageHeaderConfig,
    setFilters,
  );

  const onResetFilters = () => {
    onReset();
    setFilters(null);
  };

  const selectProps = {
    name: 'type',
    width: '110%',
    value: searchFilters.type,
    onChange: handlers.type,
    isClearable: true,
    options: projectTypeSelectOptions,
  };

  return (
    <SearchPageHeaderContainer title="Batch Invoice Reports">
      <BatchInvoiceReportsFiltersContainerS>
        <FlexSpaceBetween>
          <FlexColumn>
            <SearchHeaderColumn titleWidth="120px" title="Client">
              <ClientSelect
                value={searchFilters.clientId}
                onChange={handlers.clientId}
                includeAllOption={false}
                width="100%"
                required
              />
            </SearchHeaderColumn>
          </FlexColumn>
          <FlexColumn>
            <SearchHeaderColumn
              titleWidth="120px"
              filterWidth="320px"
              title="Date"
            >
              <DateRangePicker
                value={searchFilters.clientInvoiceDate}
                onChange={handlers.clientInvoiceDate}
                width="100%"
              />
            </SearchHeaderColumn>
          </FlexColumn>
          <FlexColumn>
            <SearchHeaderColumn titleWidth="120px" title="Cost Type">
              <Select {...selectProps} />
            </SearchHeaderColumn>
          </FlexColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn />
          <SearchHeaderColumn filterWidth="190px">
            <BatchInvoiceReportsSearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={onResetFilters}
            >
              Reset
            </BatchInvoiceReportsSearchHeaderButton>
            <BatchInvoiceReportsSearchHeaderButton
              size="small"
              loader={loading}
              onClick={onSearch}
              disabled={!searchFilters.clientId}
            >
              Search
            </BatchInvoiceReportsSearchHeaderButton>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </BatchInvoiceReportsFiltersContainerS>
    </SearchPageHeaderContainer>
  );
}

BatchInvoiceReportsHeader.propTypes = {
  setFilters: func.isRequired,
  loading: bool,
};
