import React from 'react';
import styled from 'styled-components';
import {
  PageGeneralTabsWrapper,
  TabsWrapper,
} from '@poly/admin-book/src/Tabs/components.js';
import {
  PageHeaderContainer,
  MainHeader,
} from '@poly/admin-book/src/Header/index.js';
import { TableSearchInput } from '@poly/admin-ui/src/components/TableSearchInput.js';

import { projectsQueries } from '../modules/tabs/common.js';
import { projectUIStatuses } from '../modules/core/constants/projects.js';
import { ProjectsPageProjectsTab } from '../modules/tabs/projectsTab/ProjectsTab.js';
import { ToolBarBtnDivider } from '../modules/core/tableToolbar/tableToolBarStyles.js';
import { RecalledProjectsPrintPDFBtn } from '../modules/tables/projectsTable/ProjectsTableToolBar.js';
import { RecalledProjectTableExportToXLSBtn } from '../modules/core/tableToolbar/TableExportToXLSBtn.js';
import { FlexCenterWrapper } from '../components/ExportButtons.js';

const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
`;

const TabsWrapperC = styled(TabsWrapper)`
  margin-top: -10px;
`.withComponent('div');

export function RecalledProjectsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Recalled Projects</MainHeader>
        <FlexColumnWrapper>
          <TableSearchInput />
          <FlexCenterWrapper gap="5px">
            <RecalledProjectsPrintPDFBtn
              query={projectsQueries[projectUIStatuses.RECALLED]}
            />
            <ToolBarBtnDivider />
            <RecalledProjectTableExportToXLSBtn />
          </FlexCenterWrapper>
        </FlexColumnWrapper>
      </PageHeaderContainer>
      <PageGeneralTabsWrapper>
        <TabsWrapperC>
          <ProjectsPageProjectsTab
            query={projectsQueries[projectUIStatuses.RECALLED]}
            additionalProps={{ fetchPolicy: 'network-only' }}
          />
        </TabsWrapperC>
      </PageGeneralTabsWrapper>
    </>
  );
}
