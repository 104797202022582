import * as R from 'ramda';
import { assocBy } from '@poly/utils/src/ramda.js';

import {
  accountTypeCategoryOptions,
  accountTypeNormalBalanceOptions,
} from '../forms/AccountTypeFormSections.js';

// getAccountTypesFieldUI :: (String, [{label: String, value: String}]) -> AccountType -> String
const getAccountTypesFieldUI = (fieldName, config) =>
  R.compose(
    R.propOr('', 'label'),
    R.find(R.__, config),
    R.propEq(R.__, 'value'),
    R.prop(fieldName),
  );

// prepareAccountTypesTableData :: AccountTypesQueryResult -> [AccountType]
export const prepareAccountTypesTableData = R.compose(
  R.map(
    R.compose(
      assocBy(
        'categoryUI',
        getAccountTypesFieldUI('category', accountTypeCategoryOptions),
      ),
      assocBy(
        'normalBalanceUI',
        getAccountTypesFieldUI(
          'normal_balance',
          accountTypeNormalBalanceOptions,
        ),
      ),
      assocBy('accountType', R.identity),
    ),
  ),
  R.pathOr([], ['accountTypes', 'hits']),
);
