import React, { useState } from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { TableCardWithPaginationContainer } from '@poly/admin-ui/src/components/Table.js';

import { AccountTypesPageHeader } from './AccountTypesPageHeader.js';
import { AccountTypesTable } from './table/AccountTypesTable.js';

export function AccountTypesPage() {
  const [sort, setSort] = useState(null);

  return (
    <>
      <AccountTypesPageHeader sort={sort} />
      <DefaultBodyWrapper height="calc(100% - 85px)">
        <TableCardWithPaginationContainer>
          <AccountTypesTable setSort={setSort} />
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </>
  );
}
