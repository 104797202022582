import { CommonTasksActionsTypesUIMap, taskActionTypes } from '../tasks.js';
import { RWOTaskListType } from './recurring.js';

const defaultRecurringShortList = [
  // we add this task if enabled collect documents task
  {
    description:
      CommonTasksActionsTypesUIMap[taskActionTypes.COLLECT_DOCUMENTS],
    action: taskActionTypes.COLLECT_DOCUMENTS,
  },
  {
    description: CommonTasksActionsTypesUIMap[taskActionTypes.PROJECT_COMPLETE],
    action: taskActionTypes.PROJECT_COMPLETE,
  },
  {
    description: CommonTasksActionsTypesUIMap[taskActionTypes.CLOSE_PROJECT],
    action: taskActionTypes.CLOSE_PROJECT,
  },
];

const defaultRecurringLongList = [
  {
    description:
      CommonTasksActionsTypesUIMap[taskActionTypes.SUPPLIER_SCHEDULING],
    action: taskActionTypes.SUPPLIER_SCHEDULING,
    dueDateConfig: {
      default: 0,
    },
  },
  {
    description:
      CommonTasksActionsTypesUIMap[taskActionTypes.CONFIRM_SATISFACTION],
    action: taskActionTypes.CONFIRM_SATISFACTION,
  },
  ...defaultRecurringShortList,
];

export const defaultRecurringProjectTasksConfig = {
  [RWOTaskListType.LONG_LIST]: defaultRecurringLongList,
  [RWOTaskListType.SHORT_LIST]: defaultRecurringShortList,
};
