import React, { useState } from 'react';
import {
  PageHeaderContainer,
  MainHeader,
  ToolBarBtnDivider,
} from '@poly/admin-book';
import { TableSearchInput } from '@poly/admin-ui';
import { SupplierStatuses } from '@poly/constants';

import { AddNewSupplierBtn } from '../modules/tabs/suppliersTab/SuppliersTabToolbar.js';
import { SuppliersTabTitle } from '../modules/tabs/suppliersTab/SuppliersTabTitle.js';
import { SuppliersTab } from '../modules/tabs/suppliersTab/SuppliersTab.js';
import { getAdminSuppliersQuery } from '../modules/core/searchQueries/suppliers.js';
import { PageTabs } from '../modules/tabs/TabsWithRouter.js';
import { HeaderLinksWrapperS } from './SuppliersSubcontractorPage.js';
import { SuppliersTabXlsExportButton } from '../modules/tabs/suppliersTab/SuppliersTabXlsExportButton.js';
import { SuppliersTabPdfExportButton } from '../modules/tabs/suppliersTab/SuppliersTabPdfExportButton.js';

const { ACTIVE, BLOCKED } = SupplierStatuses;

const PAGE_TITLE = 'Suppliers Administrative';

export function SuppliersAdminAACPage() {
  const [exportSort, setExportSort] = useState(null);
  const [currentTab, setCurrentTab] = useState(ACTIVE);

  const activeSuppliersQuery = getAdminSuppliersQuery(ACTIVE);
  const blockedSuppliersQuery = getAdminSuppliersQuery(BLOCKED);

  const exportQuery =
    currentTab === ACTIVE ? activeSuppliersQuery : blockedSuppliersQuery;

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>{PAGE_TITLE}</MainHeader>
        <HeaderLinksWrapperS>
          <SuppliersTabXlsExportButton
            title={PAGE_TITLE}
            query={exportQuery}
            exportSort={exportSort}
            exportFileName={`suppliers_administrative_${currentTab}_export.xlsx`}
          />
          <ToolBarBtnDivider />
          <SuppliersTabPdfExportButton
            title={PAGE_TITLE}
            query={exportQuery}
            exportSort={exportSort}
            exportFileName={`suppliers_administrative_${currentTab}_export`}
          />
          <AddNewSupplierBtn />
        </HeaderLinksWrapperS>
      </PageHeaderContainer>
      <PageTabs
        toolBar={<TableSearchInput />}
        tabs={[
          [
            <SuppliersTabTitle
              query={activeSuppliersQuery}
              status={SupplierStatuses.ACTIVE}
              key={ACTIVE}
            />,
            ACTIVE,
            <SuppliersTab
              query={activeSuppliersQuery}
              key={ACTIVE}
              currentTab={ACTIVE}
              setExportSort={setExportSort}
              setCurrentTab={setCurrentTab}
            />,
          ],
          [
            <SuppliersTabTitle
              query={blockedSuppliersQuery}
              status={SupplierStatuses.BLOCKED}
              key={BLOCKED}
            />,
            BLOCKED,
            <SuppliersTab
              query={blockedSuppliersQuery}
              key={BLOCKED}
              currentTab={BLOCKED}
              setExportSort={setExportSort}
              setCurrentTab={setCurrentTab}
            />,
          ],
        ]}
        defaultValue={ACTIVE}
      />
    </>
  );
}
