import * as R from 'ramda';
import React, { useState } from 'react';
import { func, string, bool } from 'prop-types';
import { jsonPostRequest, isErrorEqualTwoFAError } from '@poly/client-utils';
import {
  getPasswordValidationError,
  graphqlSetPasswordRoute,
} from '@poly/utils';

import { TwoFAConfirmForm } from '../TwoFAConfirmForm.js';
import { ChangePasswordForm } from './ChangePasswordForm.js';

const { GRAPHQL_HTTP_URL = '' } = process.env;

export function ChangePasswordPageBase({
  token,
  title,
  subTitle,
  onSuccess,
  withCustomValidation,
  navigateToForgotPassword,
}) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({});
  const [is2FAError, setIs2FAError] = useState(false);

  const handleSubmit = async (newCredentials) => {
    setCredentials(newCredentials);
    const { password, confirmPassword } = newCredentials;

    const validationError = getPasswordValidationError(password);
    if (validationError) {
      setError(validationError);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      await jsonPostRequest(graphqlSetPasswordRoute(GRAPHQL_HTTP_URL), {
        ...R.dissoc('confirmPassword', newCredentials),
        token,
      });
      onSuccess();
    } catch (err) {
      setError(err);
      if (isErrorEqualTwoFAError(err)) {
        setIs2FAError(true);
      }
    }
    setLoading(false);
  };

  const backToChangePassword = () => {
    setIs2FAError(false);
    setError(null);
    setCredentials({});
  };

  return is2FAError ? (
    <TwoFAConfirmForm
      title={title}
      errorMsg={error}
      loading={loading}
      setErrorMsg={setError}
      credentials={credentials}
      handleSubmit={handleSubmit}
      submitBtnText="Set Password"
      goBack={backToChangePassword}
    />
  ) : (
    <ChangePasswordForm
      title={title}
      error={error}
      loading={loading}
      subTitle={subTitle}
      handleSubmit={handleSubmit}
      withCustomValidation={withCustomValidation}
      navigateToForgotPassword={navigateToForgotPassword}
    />
  );
}

ChangePasswordPageBase.propTypes = {
  subTitle: string,
  token: string.isRequired,
  title: string.isRequired,
  onSuccess: func.isRequired,
  withCustomValidation: bool,
  navigateToForgotPassword: func.isRequired,
};
