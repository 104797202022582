import * as R from 'ramda';
import { startOfDay, endOfDay } from 'date-fns';
import { CHILD_PROJECT_ELASTIC_QUERY } from '@poly/client-utils';
import {
  applyIfOr,
  getNestedQuery,
  isNilOrEmpty,
  propEqLegacy,
} from '@poly/utils';
import { ALL } from '@poly/admin-ui';
import {
  RecurringProjectStatuses,
  onHoldWorkOrderStatuses,
  RecurringProjectTypes,
  AccountingStatus,
  WorkOrderStatus,
} from '@poly/constants';

import {
  ProjectFilterTypes,
  ProjectFilterStatuses,
} from '../../pagesHeaders/ProjectSearchPageHeader/filter/constants.js';
import { MAIN_ACCOUNT_OPTION_VALUE } from '../../selects/PropertySelect/PropertySelect.js';
import { FilterStatusPath } from '../../core/constants/search.js';
import {
  getQueryFromCheckboxes,
  getFuzzinessQuery,
  getEntityQuery,
  ifNotEmpty,
} from '../commonTableUtils.js';

// applyIfOr :: (a -> Boolean) -> (a -> b) -> a -> b
const applyIfOrNull = applyIfOr(R.__, R.__, null);

// isRecurringOrPM :: Filters -> Boolean
const isRecurringOrPM = R.either(
  R.prop(ProjectFilterTypes.MASTER_RECURRING),
  R.prop(ProjectFilterTypes.PREVENTIVE_MAINTENANCE),
);

// eslint-disable-next-line import/no-unused-modules
export const searchProjectsFiltersNames = {
  clientId: 'clientId',
  managerId: 'managerId',
  propertyId: 'propertyId',
  state: 'state',
  city: 'city',
  keywords: 'keywords',
  type: 'type',
  serviceTypeId: 'serviceTypeId',
  assetTypeId: 'assetTypeId',
  isCallback: 'isCallback',
  problemCodeId: 'problemCodeId',
  startDate: 'startDate',
  endDate: 'endDate',
};

// mapUIProjectStatuses :: UIStatus -> Status || [Status]
// eslint-disable-next-line import/no-unused-modules
export const mapUIProjectStatuses = R.cond([
  [R.equals(ProjectFilterStatuses.ACTIVE), R.always(WorkOrderStatus.ACTIVE)],
  [R.equals(ProjectFilterStatuses.PENDING), R.always(WorkOrderStatus.PENDING)],
  [
    R.equals(ProjectFilterStatuses.COMPLETED),
    R.always(WorkOrderStatus.COMPLETED),
  ],
  [
    R.equals(ProjectFilterStatuses.CANCELLED),
    R.always(WorkOrderStatus.BLOCKED),
  ],
  [R.equals(ProjectFilterStatuses.ON_HOLD), R.always(onHoldWorkOrderStatuses)],
  [R.T, R.identity],
]);

// mapUIProjectTypes :: UIType -> Type || [Type]
const mapUIProjectTypes = R.cond([
  [
    R.equals(ProjectFilterTypes.MASTER_RECURRING),
    R.always({ match: { type: RecurringProjectTypes.recurringProject } }),
  ],
  [
    R.equals(ProjectFilterTypes.PREVENTIVE_MAINTENANCE),
    R.always({
      match: { type: RecurringProjectTypes.preventiveMaintenanceProject },
    }),
  ],
  [
    R.equals(ProjectFilterTypes.CHILD_RECURRING),
    R.always({
      bool: {
        must: [
          CHILD_PROJECT_ELASTIC_QUERY,
          { match: { 'parent.type': RecurringProjectTypes.recurringProject } },
        ],
      },
    }),
  ],
  [
    R.equals(ProjectFilterTypes.CHILD_PREVENTIVE_MAINTENANCE),
    R.always({
      bool: {
        must: [
          CHILD_PROJECT_ELASTIC_QUERY,
          {
            match: {
              'parent.type': RecurringProjectTypes.preventiveMaintenanceProject,
            },
          },
        ],
      },
    }),
  ],
  [
    R.equals(ProjectFilterTypes.REACTIVE),
    R.always({ bool: { must_not: [CHILD_PROJECT_ELASTIC_QUERY] } }),
  ],
  [R.T, R.always(null)],
]);

// getRecurrenceQuery :: Filters -> Object
const getRecurrenceQuery = R.compose(
  R.unless(R.path(['bool', 'should', '0']), R.always(null)),
  R.objOf('bool'),
  R.objOf('should'),
  R.flatten,
  R.map(mapUIProjectTypes),
  R.keys,
  R.filter(R.identity),
  R.pick(R.values(ProjectFilterTypes)),
);

// generateStatusQuery :: Filters -> Object
const generateStatusQuery = R.compose(
  getQueryFromCheckboxes(FilterStatusPath, mapUIProjectStatuses),
  R.ifElse(
    isRecurringOrPM,
    R.pick(R.values(RecurringProjectStatuses)),
    R.pick(R.values(ProjectFilterStatuses)),
  ),
);

// generateAccountingStatusQuery :: Filters -> Object
const generateAccountingStatusQuery = applyIfOrNull(
  R.complement(isRecurringOrPM),
  R.compose(
    getQueryFromCheckboxes('accountingStatus', R.identity),
    R.when(
      R.prop(AccountingStatus.NON_BILLABLE),
      R.assoc(AccountingStatus.NON_BILLABLE_CLOSED, true),
    ),
    R.pick(R.values(AccountingStatus)),
  ),
);

// getProjectTypeQuery :: Filters -> Object
const getProjectTypeQuery = applyIfOrNull(
  R.complement(propEqLegacy(searchProjectsFiltersNames.type, ALL)),
  R.ifElse(
    isRecurringOrPM,
    getEntityQuery(searchProjectsFiltersNames.type, 'childType'),
    getEntityQuery(searchProjectsFiltersNames.type, 'type'),
  ),
);

const getPropertyTermQuery = R.ifElse(
  propEqLegacy('propertyId', MAIN_ACCOUNT_OPTION_VALUE),
  R.always({
    bool: {
      must_not: [
        {
          exists: {
            field: 'propertyId',
          },
        },
      ],
    },
  }),
  getEntityQuery(searchProjectsFiltersNames.propertyId, 'propertyId'),
);

// getAssetTypeNestedQuery :: Filters -> QueryObject
export const getAssetTypeNestedQuery = R.ifElse(
  R.prop(searchProjectsFiltersNames.assetTypeId),
  R.compose(
    getNestedQuery('assets', 'typeId'),
    R.prop(searchProjectsFiltersNames.assetTypeId),
  ),
  R.always(null),
);

/**
 * getMustQuery :: DataObject -> QueryObjectMust
 */
const getMustQuery = R.compose(
  R.objOf('must'),
  R.when(R.isEmpty, R.always(null)),
  R.reject(R.isNil),
  R.converge(Array.of, [
    getAssetTypeNestedQuery,
    generateStatusQuery,
    getRecurrenceQuery,
    generateAccountingStatusQuery,
    getProjectTypeQuery,
    getPropertyTermQuery,
    getEntityQuery(searchProjectsFiltersNames.clientId, 'clientId'),
    getEntityQuery(searchProjectsFiltersNames.managerId, 'managerId'),
    getEntityQuery(searchProjectsFiltersNames.serviceTypeId, 'serviceTypeId'),
    getEntityQuery(searchProjectsFiltersNames.problemCodeId, 'problemCodeId'),
    getEntityQuery(
      searchProjectsFiltersNames.state,
      'property.address.address_parts.administrative_area_level_1',
    ),
    getFuzzinessQuery(
      searchProjectsFiltersNames.city,
      'property.address.address_parts.locality',
    ),
    R.ifElse(
      R.prop(searchProjectsFiltersNames.isCallback),
      getEntityQuery(searchProjectsFiltersNames.isCallback, 'isCallback'),
      R.always(null),
    ),
  ]),
);

// addJustDateIfPresent :: DateFormatter -> String -> String
const addJustDateIfPresent = (handler) =>
  R.unless(
    isNilOrEmpty,
    R.compose(R.invoker(0, 'toISOString'), handler, R.constructN(1, Date)),
  );

// handleDateRangeToFilter :: (String, String, String) -> DataObject -> QueryObjectFilter
const handleDateRangeToFilter = (dateProp, fromProp, toProp) =>
  R.compose(
    ifNotEmpty(R.assocPath(['range', dateProp], R.__, {})),
    R.reject(isNilOrEmpty),
    R.converge(R.mergeRight, [
      R.compose(
        R.objOf('gte'),
        addJustDateIfPresent(startOfDay),
        R.path([dateProp, fromProp]),
      ),
      R.compose(
        R.objOf('lte'),
        addJustDateIfPresent(endOfDay),
        R.path([dateProp, toProp]),
      ),
    ]),
  );

/**
 * getFilterQuery :: DataObject -> QueryObjectFilter
 */
const getFilterQuery = R.compose(
  R.assoc('filter', R.__, {}),
  R.reject(isNilOrEmpty),
  R.juxt([
    handleDateRangeToFilter('startDate', 'startDateFrom', 'startDateTo'),
    handleDateRangeToFilter('endDate', 'endDateFrom', 'endDateTo'),
  ]),
);

// generateSearchQueryByConfig :: DataObject -> QueryObject
export const generateSearchQueryByConfig = R.compose(
  ifNotEmpty(R.objOf('bool')),
  R.reject(R.isNil),
  R.converge(R.mergeRight, [getMustQuery, getFilterQuery]),
);
