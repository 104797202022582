import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, number, bool } from 'prop-types';
import { Icon, Text } from '@poly/admin-book';

const WarningSectionWrapperS = styled.div`
  width: 100%;
  display: flex;
  max-height: 48px;
  padding: 8px 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffeec4;
  gap: ${({ iconSize }) => (iconSize < 20 ? '5px' : '10px')};
  margin-bottom: ${({ skipMargin }) => (skipMargin ? '0' : '15px')};
`;

const WarningSectionTextS = styled(Text)`
  font-size: 12px;
  color: ${R.prop('iconColor')};
`;

export function WarningSection({
  text,
  skipMargin,
  iconSize = 22,
  iconColor = '#d19c19',
  iconName = 'yellowWarning',
}) {
  return (
    <WarningSectionWrapperS {...{ skipMargin, iconSize }}>
      <Icon name={iconName} size={iconSize} color={iconColor} />
      <WarningSectionTextS iconColor={iconColor}>{text}</WarningSectionTextS>
    </WarningSectionWrapperS>
  );
}

WarningSection.propTypes = {
  skipMargin: bool,
  iconName: string,
  iconSize: number,
  iconColor: string,
  text: string.isRequired,
};
