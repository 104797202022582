import React, { useEffect } from 'react';
import { usePagination } from '@poly/admin-ui';
import { func, string } from 'prop-types';

import { StaffTabComp } from './StaffTabComp.js';

export function StaffTab({ currentTab, setCurrentTab, ...props }) {
  const { pagination } = usePagination();

  useEffect(() => {
    if (setCurrentTab) {
      setCurrentTab(currentTab);
    }
  }, []);

  return <StaffTabComp {...props} pagination={pagination} paginationVisible />;
}

StaffTab.displayName = 'StaffTab';
StaffTab.propTypes = {
  currentTab: string,
  setCurrentTab: func,
};
