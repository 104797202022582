import { string, arrayOf, shape, number } from 'prop-types';

export const paymentsDataPropTypes = shape({
  searchMoneyTransactions: shape({
    hits: arrayOf(shape({ _id: string.isRequired })),
  }),
});

export const preparedPaymentsPropTypes = arrayOf(
  shape({
    checkNumber: number,
    _id: string.isRequired,
    amount: number.isRequired,
    transactionNumber: number,
    account: shape({ name: string.isRequired }).isRequired,
    supplier: shape({
      company: shape({ name: string.isRequired }).isRequired,
    }).isRequired,
  }),
).isRequired;
