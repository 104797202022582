import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { useHighlightMatchesBySearch } from '@poly/client-utils';
import { TableSearchInput, useTableSearch } from '@poly/admin-ui';
import {
  PageHeaderContainer,
  MainHeader,
} from '@poly/admin-book/src/Header/index.js';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { TableCardWithPaginationContainer } from '@poly/admin-ui/src/components/Table.js';
import { useTableSorting } from '@poly/admin-ui/src/hooks/useTableSorting.js';
import { DESC_SORT_ORDER } from '@poly/constants/src/misc.js';

import {
  DailySupplierInvoicesBatchTable,
  getDailySupplierInvoicesBatchTableConfig,
} from './DailySupplierInvoicesBatchTable.js';
import { usePrintedSupplierBatches } from './usePrintedSupplierBatches.js';
import { PrintedSupplierBatchesExportButtons } from './PrintedSupplierBatchesExportButtons.js';
import { FlexCenterWrapper } from '../../components/ExportButtons.js';

const FlexCenterWrapperS = styled(FlexCenterWrapper)`
  gap: 20px;
`;

export function DailySupplierInvoicesBatchPage() {
  const { searchText } = useTableSearch();

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: getDailySupplierInvoicesBatchTableConfig(),
    order: DESC_SORT_ORDER,
    column: 1,
  });

  const { printedSupplierBatches, loading, refetch } =
    usePrintedSupplierBatches({ sort, searchText });

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [
      ['statusToHighlight'],
      ['printedAtToHighlight'],
      ['printedByToHighlight'],
      ['dateToHighlight'],
    ],
    printedSupplierBatches,
  );

  return (
    <>
      <PageHeaderContainer height="90px">
        <MainHeader>Daily Supplier Invoices Batch</MainHeader>
        <FlexCenterWrapperS>
          <PrintedSupplierBatchesExportButtons
            sort={sort}
            searchText={searchText}
          />
          <TableSearchInput />
        </FlexCenterWrapperS>
      </PageHeaderContainer>
      <DefaultBodyWrapper height="calc(100% - 90px)">
        <TableCardWithPaginationContainer>
          {loading ? (
            <Loader />
          ) : (
            <DailySupplierInvoicesBatchTable
              printedSupplierBatches={highlightedRows}
              refetch={refetch}
              {...tableSortingProps}
            />
          )}
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </>
  );
}
