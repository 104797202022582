import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { usePaginationParams } from '@poly/admin-ui';

import { SUPPLIERS_BY_SEARCH_TABLE } from '../../core/hooks/suppliers/queries.js';

export const useSuppliersLazyQuery = () => {
  const [queryHandler] = useLazyQuery(SUPPLIERS_BY_SEARCH_TABLE);
  const paginationParams = usePaginationParams();

  const searchText = useSelector(R.prop('searchText'));

  const searchTextOptions = searchText ? { searchTerm: searchText } : {};

  const handler = async ({ query, exportSort }) => {
    const response = await queryHandler({
      fetchPolicy: 'network-only',
      variables: {
        searchInput: {
          query,
          sort: exportSort,
          ...paginationParams,
          ...searchTextOptions,
        },
      },
    });

    return response;
  };

  return handler;
};
