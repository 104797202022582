import React from 'react';
import { LinkButton } from '@poly/admin-book';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { arrayOf, bool, object } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils';
import { CommonPrintLayout } from '@poly/admin-ui';

import { PrintChecksTableComponent } from './PrintChecksTable.js';

export function PrintChecksPdfExportButton({ disabled, adminChecks }) {
  const onPrint = async () => {
    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: PrintChecksTableComponent,
      metaData: { title: 'Print Checks' },
      fileName: 'print_checks_export',
      adminChecks,
      isPrint: true,
    });
  };

  return (
    <LinkButton disabled={disabled} onClick={onPrint}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

PrintChecksPdfExportButton.propTypes = {
  disabled: bool,
  // eslint-disable-next-line react/forbid-prop-types
  adminChecks: arrayOf(object),
};
