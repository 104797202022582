import { gql } from '@apollo/client';

const childPropertyHierarchySpendReportFragment = gql`
  fragment childPropertyHierarchySpendReportFragment on PropertySpendReportResult {
    propertyName
    propertyId
    invoicesTotal
    clientInvoicesTotal
    markupsTotal
    taxesTotal
    client {
      _id
      name
      cardNumber
      financial {
        spendReportType
      }
    }
    propertyInvoicesReport {
      _id
      spendType
      projectId
      projectType
      description
      projectNumber
      clientInvoicesAmount
      markupAmount
      taxAmount
      invoices {
        _id
        date
        supplier
        supplierId
        invoiceNumber
        total
        serviceType
        file
        taxAmount
      }
      tmMarkupRules {
        upTo
        percent
      }
      exemptSalesTax
    }
  }
`;

const propertyHierarchySpendReportFragment = gql`
  fragment propertyHierarchySpendReportFragment on PropertyHierarchySpendReportResult {
    _id
    name
    properties {
      ...childPropertyHierarchySpendReportFragment
      childProperties {
        ...childPropertyHierarchySpendReportFragment
      }
    }
  }

  ${childPropertyHierarchySpendReportFragment}
`;

export const PREVIEW_CLIENT_SPEND_REPORT_BY_HIERARCHY = gql`
  query PREVIEW_CLIENT_SPEND_REPORT(
    $input: ClientSpendReportByHierarchyInput!
  ) {
    previewClientSpendReportByHierarchy(input: $input) {
      ...propertyHierarchySpendReportFragment
      children {
        ...propertyHierarchySpendReportFragment
        children {
          ...propertyHierarchySpendReportFragment
          children {
            ...propertyHierarchySpendReportFragment
            children {
              ...propertyHierarchySpendReportFragment
              children {
                ...propertyHierarchySpendReportFragment
                children {
                  ...propertyHierarchySpendReportFragment
                  children {
                    ...propertyHierarchySpendReportFragment
                    children {
                      ...propertyHierarchySpendReportFragment
                      children {
                        ...propertyHierarchySpendReportFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ${propertyHierarchySpendReportFragment}
`;
