import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  WISCONSIN_STATE_SHORTNAME,
  NEW_YORK_STATE_SHORTNAME,
} from '@poly/constants';

import { SalesTaxReportHeader } from './SalesTaxReportHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { SalesTaxReportTableContainer } from './SalesTaxReportTable.js';

import { useSalesTaxReport } from './useSalesTaxReport.js';

function SalesTaxReportPage({ state }) {
  const [filters, setFilters] = useState(null);

  const { report, loading, tableProps, query } = useSalesTaxReport({
    filters,
    state,
  });

  return (
    <PageWithSearchHeader headerHeight="110px">
      <SalesTaxReportHeader
        state={state}
        filters={filters}
        setFilters={setFilters}
        exportProps={{ report, query }}
        loading={loading}
      />
      <SalesTaxReportTableContainer
        {...{ state, report, loading, ...tableProps }}
      />
    </PageWithSearchHeader>
  );
}

SalesTaxReportPage.propTypes = { state: string };

export function WisconsinSalesTaxReportPage() {
  return <SalesTaxReportPage state={WISCONSIN_STATE_SHORTNAME} />;
}

export function NewYorkSalesTaxReportPage() {
  return <SalesTaxReportPage state={NEW_YORK_STATE_SHORTNAME} />;
}

export function SalesTaxReportByStatePage() {
  return <SalesTaxReportPage />;
}
