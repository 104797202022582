import * as R from 'ramda';
import { WorkOrderPriority } from '@poly/constants';

// isUrgentOrEmergencyProject :: Project -> Boolean
export const isUrgentOrEmergencyProject = R.compose(
  R.includes(R.__, [WorkOrderPriority.EMERGENCY, WorkOrderPriority.URGENT]),
  // R.path - used for UI project
  // R.prop - used for graph
  R.either(R.prop('priorityId'), R.path(['priority', 'id'])),
);
