import * as R from 'ramda';
import { MAX_ITEMS } from '@poly/admin-ui';
import { gql, useLazyQuery } from '@apollo/client';

const PRINT_PROJECT_TO_PDF_QUERY = gql`
  query PRINT_PROJECT_TO_PDF_QUERY(
    $id: ID!
    $searchAssetsInput: CollectionSearchParams
    $tasksInput: TasksInput!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    project(id: $id) {
      _id
      nte
      type
      status
      endDate
      startDate
      projectId
      spendType
      description
      accountingStatus
      workCompletionDate
      invoiceDescription
      clientReferenceNumber
      clientInvoicesBase64Strings
      supplierInvoicesBase64Strings
      priority {
        id
        name
      }
      client {
        _id
        name
      }
      property {
        _id
        name
        phone
      }
      subProperties {
        _id
        name
      }
      cloneFromProject {
        _id
        projectId
      }
      serviceType {
        _id
        name
      }
      problemCode {
        _id
        name
      }
      adminPurchaseOrder {
        _id
        poNumber
        displayName
      }
      manager {
        _id
        fullName
      }
      clientManager {
        _id
        fullName
      }
      contact {
        _id
        fullName
      }
      siteContact {
        _id
        fullName
      }
      suppliers {
        _id
        nte
        statusInProject
        company {
          name
          phones {
            phone
          }
        }
      }
      division {
        _id
        name
        code
      }
      estimates {
        _id
        total
        manualEntries {
          rate
          total
          quantity
          description
        }
        markupEntries {
          total
          amount
          markup
          supplier {
            company {
              name
            }
          }
        }
      }
      bidRules {
        type
        label
        amount
        isRuleActive
      }
      invoices {
        _id
        status
        total
      }
      clientInvoices {
        _id
        amount
      }
      searchAssets(input: $searchAssetsInput) {
        hits {
          _id
          serial
          qrCodeId
          location
          warrantyEnd
          equipmentType
          purchasePrice
          replacementCost
          commissioningDate
          photo {
            url
          }
          type {
            name
            lifeExpectancy
          }
          modelDoc {
            name
            lifeExpectancy
          }
          manufacturerDoc {
            name
          }
        }
      }
      tasks(input: $tasksInput) {
        hits {
          _id
          complete
          description
          manager {
            _id
            fullName
          }
          documentUpdates(input: $documentUpdatesInput) {
            hits {
              _id
              message
            }
          }
        }
      }
    }
  }
`;

// prepareProjectToPrintPDF :: { project: Project } -> Project
const prepareProjectToPrintPDF = R.propOr({}, 'project');

export const useLazyProjectPrintToPDFQuery = () => {
  const [printProjectQuery] = useLazyQuery(PRINT_PROJECT_TO_PDF_QUERY);

  const runQuery = async (projectId) => {
    const { data } = await printProjectQuery({
      fetchPolicy: 'network-only',
      variables: {
        id: projectId,
        tasksInput: { documentId: projectId, size: MAX_ITEMS },
        documentUpdatesInput: { sort: { createdAt: -1 }, size: MAX_ITEMS },
        searchAssetsInput: { size: MAX_ITEMS },
      },
    });

    const project = prepareProjectToPrintPDF(data);

    return project;
  };

  return runQuery;
};
