import React from 'react';
import { Editor } from '@poly/admin-book';
import { func, number } from 'prop-types';

export function DescriptionFieldToEditEditor({ onChange, rowIndex, ...props }) {
  const handleOnChange = (value) => onChange(value);

  return (
    <Editor
      {...props}
      onChange={handleOnChange}
      id={`quill-editor-id-${rowIndex}`}
      name={`quill-editor-name-${rowIndex}`}
    />
  );
}

DescriptionFieldToEditEditor.propTypes = {
  onChange: func,
  rowIndex: number,
};
