import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, instanceOf, number, oneOfType, shape, string } from 'prop-types';
import { formatDate } from '@poly/utils/src/dates.js';
import { ESFMFullLogo } from '@poly/admin-ui/src/components/Logo.js';
import { centsToDollarsWithFormat } from '@poly/utils';
import { Icon, ReadOnlyEditor, Text } from '@poly/admin-book';
import { isNotNillOrEmptyPath } from '@poly/utils/src/general.js';

import {
  HeaderWrapper,
  Title,
} from '../DocumentsRequest/DocumentRequestHeader.js';
import { propOrNothingUI } from '../../modules/core/utils/general.js';
import {
  ManualEntriesTable,
  MarkupEntriesTable,
  ProjectEstimateDocuments,
  TimeEntriesTable,
} from './ProjectEstimateTables.js';
import { ProjectEstimateRowInfo, SubTitleTitle } from './components.js';

const SectionWrapperS = styled(HeaderWrapper)`
  border-bottom: ${({ skipBorder }) =>
    skipBorder ? 'none' : '1px solid #ebebeb'};
  padding-top: ${R.propOr(0, 'paddingTop')};
`;

const InfoContainer = styled.div`
  background-color: #e6f7ff;
  display: flex;
  padding: 5px;
  height: 36px;
  border-radius: 4px;
  margin: 15px 0px 5px 0px;
  align-items: center;
`;

const TextS = styled(Text)`
  color: #014398;
  font-family: 'TTNormsRegular';
`;

const ReadOnlyEditorS = styled(ReadOnlyEditor)`
  color: #999999;
`;

function ExpirationInfoNote() {
  return (
    <InfoContainer>
      <Icon name="warning-info" width="16" height="16" color="#014398" />
      <TextS>
        Please note: estimate is valid for thirty (30) days of the date listed
      </TextS>
    </InfoContainer>
  );
}

// getEstimateAmountByPropWithFormat :: (String, ProjectEstimate) -> String
const getEstimateAmountByPropWithFormat = (prop, estimate) =>
  R.compose(centsToDollarsWithFormat, R.propOr(0, prop))(estimate);

function ProjectEstimateBreakdownSections({
  isBreakdown,
  afterHoursCall,
  ...props
}) {
  if (!isBreakdown) {
    return null;
  }

  return (
    <>
      {isNotNillOrEmptyPath(['manualEntries'])(props) && (
        <SectionWrapperS>
          <ManualEntriesTable {...props} />
        </SectionWrapperS>
      )}
      {(isNotNillOrEmptyPath(['timeEntries'])(props) || afterHoursCall) && (
        <SectionWrapperS>
          <TimeEntriesTable {...props} afterHoursCall={afterHoursCall} />
        </SectionWrapperS>
      )}

      {isNotNillOrEmptyPath(['markupEntries'])(props) && (
        <SectionWrapperS>
          <MarkupEntriesTable {...props} afterHoursCall={afterHoursCall} />
        </SectionWrapperS>
      )}
    </>
  );
}

ProjectEstimateBreakdownSections.propTypes = {
  isBreakdown: bool,
  afterHoursCall: number,
};

export function ReviewProjectEstimateInfo({ projectEstimate }) {
  return (
    <>
      <SectionWrapperS>
        <ESFMFullLogo />
        <Title>Project Estimate</Title>
      </SectionWrapperS>
      <SectionWrapperS>
        <ProjectEstimateRowInfo
          label="Project #"
          value={propOrNothingUI('projectNumber', projectEstimate)}
        />
        <ProjectEstimateRowInfo
          label="Location"
          value={propOrNothingUI('location', projectEstimate)}
        />
        <ProjectEstimateRowInfo
          label="Project Manager"
          value={propOrNothingUI('projectManagerName', projectEstimate)}
        />
        <ProjectEstimateRowInfo
          label="Estimate Created"
          value={formatDate(projectEstimate?.estimateDate)}
        />
        <ExpirationInfoNote />
      </SectionWrapperS>
      <SectionWrapperS skipBorder>
        <SubTitleTitle>Scope of work</SubTitleTitle>
        <ReadOnlyEditorS
          value={propOrNothingUI('description', projectEstimate)}
        />
      </SectionWrapperS>
      <ProjectEstimateBreakdownSections {...projectEstimate} />
      {isNotNillOrEmptyPath(['attachments'])(projectEstimate) && (
        <SectionWrapperS>
          <ProjectEstimateDocuments {...projectEstimate} />
        </SectionWrapperS>
      )}
      <SectionWrapperS paddingTop="10px">
        {projectEstimate?.isTransparent && (
          <ProjectEstimateRowInfo
            justifyContent="flex-end"
            label="Markup"
            value={getEstimateAmountByPropWithFormat(
              'markupTotal',
              projectEstimate,
            )}
          />
        )}
        <ProjectEstimateRowInfo
          justifyContent="flex-end"
          label="Subtotal"
          value={getEstimateAmountByPropWithFormat('subTotal', projectEstimate)}
        />
        <ProjectEstimateRowInfo
          justifyContent="flex-end"
          label="Work Order Charge"
          value={getEstimateAmountByPropWithFormat('woCharge', projectEstimate)}
        />
        <ProjectEstimateRowInfo
          justifyContent="flex-end"
          label="Tax"
          value={getEstimateAmountByPropWithFormat('tax', projectEstimate)}
        />
        <ProjectEstimateRowInfo
          justifyContent="flex-end"
          label="Total"
          value={getEstimateAmountByPropWithFormat('total', projectEstimate)}
        />
      </SectionWrapperS>
    </>
  );
}

ReviewProjectEstimateInfo.propTypes = {
  projectEstimate: shape({
    projectNumber: string,
    location: string,
    projectManagerName: string,
    estimateDate: oneOfType([string, instanceOf(Date)]),
  }),
};
