import {
  func,
  bool,
  shape,
  string,
  object,
  arrayOf,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import { v4 as uuidV4 } from 'uuid';
import React, { useState } from 'react';
import { MAX_FILES_LIMIT, MAX_FILE_SIZE_ERROR_MESSAGE } from '@poly/constants';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';

import { attachmentsPropTypes } from './commonPropTypes.js';
import { useNotificationContext } from '../Notificator/NotificatorProvider.js';

// getFileName :: File -> String
const getFileName = R.compose(
  R.converge(R.slice(0), [R.findLastIndex(R.equals('.')), R.identity]),
  R.prop('name'),
);

const MAX_TAKE_FILES = MAX_FILES_LIMIT + 1;

// prepareUploadFiles :: [Files] -> [PreparedFiles]
export const prepareUploadFiles = R.ifElse(
  R.is(Array),
  R.compose(
    R.when(
      R.propSatisfies(R.gt(R.__, MAX_FILES_LIMIT), 'length'),
      R.take(MAX_TAKE_FILES),
    ),
    R.map(
      R.applySpec({
        _id: () => uuidV4(),
        fileName: getFileName,
        upload: R.identity,
      }),
    ),
  ),
  R.always([]),
);

// getRejectedFileErrMsg :: [RejectedError] -> String
// RejectedError = Object
const getRejectedFileErrMsg = R.compose(
  R.join('\n'),
  R.map(
    R.ifElse(
      propEqLegacy('code', 'file-too-large'),
      R.always(MAX_FILE_SIZE_ERROR_MESSAGE),
      R.prop('message'),
    ),
  ),
);

// getUploadFiles :: [PreparedFiles] -> [Files]
const getUploadFiles = R.ifElse(
  Array.isArray,
  R.map(R.prop('upload')),
  R.always([]),
);

// isFilesTypeValid :: ([Files], [FileType]) -> Boolean
// FileType = String
const isFilesTypeValid = R.curry((files, acceptTypes) =>
  R.compose(R.isEmpty, R.difference(R.__, acceptTypes), R.pluck('type'))(files),
);

const normalizeFiles = (files) => {
  if (isNilOrEmpty(files)) {
    return files;
  }

  return files.map((file) => {
    let newType = file.type;

    if (file.type === 'image/heif') {
      newType = 'image/heic';
    }

    if (isNilOrEmpty(file.type)) {
      const ext = file.name.split('.').pop().toLowerCase();
      const mimeTypes = {
        heic: 'image/heic',
        heif: 'image/heic',
      };

      newType = mimeTypes[ext] || '';
    }

    if (newType !== file.type) {
      const copiedFile = new File([file], file.name, { type: newType });
      copiedFile.path = file.path;

      return copiedFile;
    }

    return file;
  });
};

export function UploadFileLogic({
  multiple,
  Component,
  autoFocus,
  attachments,
  acceptTypes,
  onTypeError,
  setNewAttachments,
  ...props
}) {
  const [isDragOver, setIsDragOver] = useState(false);

  const { showWarningNotification } = useNotificationContext();

  const validateFilesByAcceptType = (files) => {
    if (isNilOrEmpty(files)) {
      return getUploadFiles(attachments);
    }

    if (R.isEmpty(acceptTypes)) return files;

    if (isFilesTypeValid(files, acceptTypes)) return files;
    onTypeError();
    return [];
  };

  const handleNewFilesUploading = (files) => {
    const handledFiles = normalizeFiles(files);
    const validateFiles = validateFilesByAcceptType(handledFiles);
    const preparedFiles = prepareUploadFiles(validateFiles);
    setNewAttachments(preparedFiles);
  };

  const onFileNameChange = (fileId) => (e) => {
    const files = attachments.map((file) =>
      file._id === fileId ? { ...file, fileName: e.target.value } : file,
    );
    setNewAttachments(files);
  };

  const onDragEnter = () => setIsDragOver(true);
  const onDragLeave = () => setIsDragOver(false);
  const onFileUpload = (e) => handleNewFilesUploading(e.target.files);

  const onDrop = (files) => {
    handleNewFilesUploading(files);
    setIsDragOver(false);
  };

  const onFileRemove = (fileId) => () => {
    const files = attachments.filter(({ _id }) => _id !== fileId);
    setNewAttachments(files);
  };

  const onDropRejected = (rejectedFiles) => {
    rejectedFiles.map(({ errors }) =>
      showWarningNotification(getRejectedFileErrMsg(errors)),
    );
  };

  return (
    <Component
      {...{
        onFileUpload,
        onFileNameChange,
        onDragEnter,
        onDragLeave,
        onDrop,
        onFileRemove,
        onDropRejected,
        isDragOver,
        attachments,
        multiple,
        autoFocus,
        ...props,
      }}
    />
  );
}

export const fileErrorPropTypes = oneOfType([
  string,
  arrayOf(
    shape({
      editorError: string,
      error: string,
      fileId: string,
      commonError: string,
    }),
  ),
]);

UploadFileLogic.propTypes = {
  multiple: bool,
  autoFocus: bool,
  onTypeError: func,
  acceptTypes: arrayOf(string),
  attachments: attachmentsPropTypes,
  setNewAttachments: func.isRequired,
  Component: oneOfType([func, object]).isRequired,
};

UploadFileLogic.defaultProps = {
  multiple: true,
  acceptTypes: [],
  autoFocus: false,
  onTypeError: () => null,
};
