import React from 'react';
import styled from 'styled-components';
import { TableSearchInput } from '@poly/admin-ui/src/components/TableSearchInput.js';
import { useModalContext } from '@poly/admin-ui/src/components/Modal/ModalProvider.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import {
  MainHeader,
  HeaderLinksWrapper,
  PageHeaderContainer,
} from '@poly/admin-book/src/Header/index.js';

import { AssetTypesForm } from './AssetTypesForm.js';
import { addAssetTypesFormId } from './constants.js';
import { AssetTypesExportButtons } from './AssetTypesExportButtons.js';
import { FlexCenterWrapper } from '../../components/ExportButtons.js';

const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
`;

function AddAssetTypeButton() {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: addAssetTypesFormId,
      title: 'Create New Asset Type',
      formId: addAssetTypesFormId,
      btnCaption: 'Create',
      content: <AssetTypesForm isNewType />,
    });

  return <LinkButton onClick={onClick}>Add New</LinkButton>;
}

export function AssetTypesPageHeader(props) {
  return (
    <PageHeaderContainer height="100px">
      <MainHeader>Asset Types</MainHeader>
      <FlexColumnWrapper>
        <FlexCenterWrapper gap="25px">
          <AssetTypesExportButtons {...props} />
          <TableSearchInput />
        </FlexCenterWrapper>
        <HeaderLinksWrapper>
          <AddAssetTypeButton />
        </HeaderLinksWrapper>
      </FlexColumnWrapper>
    </PageHeaderContainer>
  );
}
