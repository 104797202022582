import React from 'react';
import * as R from 'ramda';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import {
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book/src/Header/index.js';
import { SupplierSelect } from '@poly/admin-ui/src/components/SupplierSelect.js';
import { DateRangePicker } from '@poly/admin-book/src/DateRangePicker/DateRangePicker.js';
import { ToolBarBtnDivider } from '@poly/admin-book/src/Table/styles.js';
import { alwaysNewDate } from '@poly/utils/src/dates.js';
import { ClientSelect } from '@poly/admin-ui/src/modules/forms/fields/ClientSelect/ClientSelect.js';

import {
  FilterContainerS,
  FlexColumnFullWidth,
} from '../SpendReport/SpendDetailsPageHeader/SpendDetailsPageHeaderC.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';
import {
  SubCategoryReportPdfExportButton,
  subCategoryReportFilterPropTypes,
} from './SubCategoryReportPdfExportButton.js';
import { SubCategoryReportXlsExportButton } from './SubCategoryReportXlsExportButton.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';

const SectionContainer = styled.div`
  display: flex;
  justify-content: ${R.propOr('flex-start', 'justifyContent')};
`;

const ExportButtonsWrapper = styled.div`
  display: flex;

  justify-content: flex-end;
  margin-right: 10px;
`;

const searchSubCategoryReportFilters = {
  supplier: 'supplier',
  project: 'project',
  invoiceDate: 'invoiceDate',
  client: 'client',
};

const subCategoryReportFilterConfig = [
  { name: searchSubCategoryReportFilters.supplier },
  { name: searchSubCategoryReportFilters.project },
  { name: searchSubCategoryReportFilters.client },
  {
    name: searchSubCategoryReportFilters.invoiceDate,
    nestedFields: ['startDate', 'endDate'],
    defaultValue: {
      startDate: alwaysNewDate().toDateString(),
      endDate: alwaysNewDate().toDateString(),
    },
  },
];

export function SubCategoryReportHeader({ filter, setFilter, loading }) {
  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    subCategoryReportFilterConfig,
    setFilter,
  );

  const handleResetFilters = () => {
    onReset();
    setFilter(null);
  };

  const isExportBtnDisabled = loading || !filter?.client;

  return (
    <PageHeaderContainer height="160px">
      <FlexColumnFullWidth>
        <MainHeader>Sub-Category Report</MainHeader>
        <FilterContainerS>
          <SectionContainer>
            <SearchHeaderColumn titleWidth="75px" title="Client">
              <ClientSelect
                value={searchFilters.client}
                onChange={handlers.client}
                includeAllOption={false}
                isClearable
                required
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn titleWidth="75px" title="Supplier">
              <SupplierSelect
                value={searchFilters.supplier}
                onChange={handlers.supplier}
                isClearable
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn titleWidth="120px" title="Project Number">
              <ProjectSelect
                value={searchFilters.project}
                onChange={handlers.project}
                withoutError
                width="100%"
              />
            </SearchHeaderColumn>
          </SectionContainer>
        </FilterContainerS>
        <FilterContainerS>
          <SearchHeaderColumn titleWidth="100px" title="Invoice Date">
            <DateRangePicker
              value={searchFilters.invoiceDate}
              onChange={handlers.invoiceDate}
              width="100%"
            />
          </SearchHeaderColumn>
          <SectionContainer justifyContent="flex-end">
            <SearchHeaderColumn position="flex-end">
              <ExportButtonsWrapper>
                <SubCategoryReportPdfExportButton
                  filter={filter}
                  disabled={isExportBtnDisabled}
                />
                <ToolBarBtnDivider />
                <SubCategoryReportXlsExportButton
                  filter={filter}
                  disabled={isExportBtnDisabled}
                />
              </ExportButtonsWrapper>
            </SearchHeaderColumn>
            <SearchHeaderButton
              loader={loading}
              size="small"
              styleType="basicPrimary"
              onClick={handleResetFilters}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              loader={loading}
              onClick={onSearch}
              disabled={loading || !searchFilters.client}
            >
              Search
            </SearchHeaderButton>
          </SectionContainer>
        </FilterContainerS>
      </FlexColumnFullWidth>
    </PageHeaderContainer>
  );
}

SubCategoryReportHeader.propTypes = {
  setFilter: func.isRequired,
  loading: bool,
  filter: subCategoryReportFilterPropTypes,
};
