import React from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import { Icon } from '@poly/admin-book/src/Icon/index.js';
import { Popover } from '@poly/admin-book/src/Popover/index.js';
import styled from 'styled-components';

const PopoverContent = styled.div`
  padding: 12px 20px;
  height: auto;
  background: #12347a;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  max-width: ${R.propOr('550px', 'contentMaxWidth')};
`;

export function PurchaseOrderBalanceWarning({
  warning,
  iconSize = 18,
  contentMaxWidth,
  position = 'left',
  withPortalAnchor = true,
  ...props
}) {
  const iconElement = <Icon name="yellowWarning" size={iconSize} />;

  return (
    <Popover
      {...props}
      position={position}
      bgColor="#12347a"
      title={iconElement}
      content={
        <PopoverContent contentMaxWidth={contentMaxWidth}>
          {warning}
        </PopoverContent>
      }
      withPortalAnchor={withPortalAnchor}
    />
  );
}

PurchaseOrderBalanceWarning.propTypes = {
  iconSize: string,
  position: string,
  withPortalAnchor: bool,
  contentMaxWidth: string,
  warning: string.isRequired,
};
