import { isNilOrEmpty } from '@poly/utils';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { MAX_ITEMS, usePaginationParams } from '@poly/admin-ui';

import { getProjectsByQueryData } from './helpers/query-data.js';

const bulkEditingProjectQuery = gql`
  query bulkEditingProjectQuery($input: CollectionSearchParams) {
    searchProjects(input: $input) {
      total
      hits {
        _id
        type
        status
        projectId
        description
        startDate
        endDate
        property {
          _id
          name
        }
        client {
          _id
          name
        }
        manager {
          fullName
          profile {
            fullName
          }
        }
      }
    }
    searchRecurringProjects(input: $input) {
      total
      hits {
        _id
        type
        status
        projectId
        description
        startDate
        endDate
        property {
          _id
          name
        }
        client {
          _id
          name
        }
        manager {
          fullName
          profile {
            fullName
          }
        }
      }
    }
  }
`;

export const useBulkEditProjectsQuery = ({
  sort,
  input,
  isUserWithUpdateProjectPermission,
}) => {
  const paginationParams = usePaginationParams();

  const { data, loading } = useQuery(bulkEditingProjectQuery, {
    variables: { input: { sort, ...input, ...paginationParams } },
    fetchPolicy: 'network-only',
    skip: isNilOrEmpty(input) || !isUserWithUpdateProjectPermission,
  });

  return { data, loading };
};

export const useBulkEditProjectsLazyQuery = () => {
  const [getProjectsQuery] = useLazyQuery(bulkEditingProjectQuery);

  const getProjects = async (input, sort) => {
    const { data } = await getProjectsQuery({
      variables: { input: { sort, ...input, from: 0, size: MAX_ITEMS } },
      fetchPolicy: 'network-only',
    });

    return getProjectsByQueryData(data);
  };

  return getProjects;
};
