import {
  sub,
  format,
  isPast,
  isToday,
  endOfDay,
  endOfMonth,
  startOfYear,
  endOfQuarter,
  startOfMonth,
  startOfQuarter,
  isValid,
  subDays,
  startOfDay,
} from 'date-fns';
import * as R from 'ramda';

import { isNilByPath } from './ramda.js';

/**
 * alwaysNewDate :: _ -> Date
 */
export const alwaysNewDate = () => new Date();

/**
 * yearPeriodOptions :: Number => Date -> [Number]
 */
export const yearPeriodOptions = (startYear) =>
  R.compose(R.range(startYear), R.inc, R.invoker(0, 'getFullYear'));

// wholeDays :: Number -> Number
export const wholeDays = R.compose(Math.trunc, R.divide(R.__, 24));

// toDate :: String -> Date
export const toDate = (string) => new Date(string);

// ensureIsDate :: Date -> Date
export const ensureIsDate = R.when(R.is(String), toDate);

// convertDaysToSeconds :: Number|String -> Number
export const convertDaysToSeconds = R.multiply(86400);

const formatF = (pattern) => (date) => format(date, pattern);

const dateFormat = 'MM/dd/yyyy';
const dateTimeFormat = `${dateFormat} 'at' h:mm a`;

// formatDateByPattern :: String -> Date -> String
export const formatDateByPattern = (pattern) =>
  R.compose(R.when(R.is(Date), formatF(pattern)), ensureIsDate);

// formatDateTime :: Date -> String
export const formatDateTime = formatDateByPattern(dateTimeFormat);

// formatDate :: Date -> String
export const formatDate = R.compose(
  R.when(R.is(Date), formatF(dateFormat)),
  ensureIsDate,
);

// customFormatDate :: String -> Date -> String
export const customFormatDate = (formatPattern) =>
  R.compose(R.when(R.is(Date), formatF(formatPattern)), ensureIsDate);

// subCurry :: Duration -> Date -> Date
// Duration = { [month|year|day]: Number }
const subFromDate = (duration) => (date) => sub(date, duration);

// startOfCurrentMonth :: _ -> Date
export const startOfCurrentMonth = R.compose(startOfMonth, alwaysNewDate);

// startOfCurrentDay :: _ -> Date
export const endOfCurrentDay = R.compose(endOfDay, alwaysNewDate);

// subFromStartOfCurrentMonth :: Number -> _ -> Date
const subFromStartOfCurrentMonth = (months) =>
  R.compose(subFromDate({ months }), startOfCurrentMonth);

// startOfLastMonth :: _ -> Date
export const startOfLastMonth = subFromStartOfCurrentMonth(1);

// endOfLastMonth :: _ -> Date
export const endOfLastMonth = R.compose(endOfMonth, startOfLastMonth);

// startOfPreviousMonth :: _ -> Date
export const startOfPreviousMonth = subFromStartOfCurrentMonth(2);

// endOfPreviousMonth :: _ -> Date
export const endOfPreviousMonth = R.compose(endOfMonth, startOfPreviousMonth);

// startOfCurrentQuarter :: _ -> Date
export const startOfCurrentQuarter = R.compose(startOfQuarter, alwaysNewDate);

// subFromStartOfCurrentQuarter :: _ -> Date
const subFromStartOfCurrentQuarter = (months) =>
  R.compose(subFromDate({ months }), startOfCurrentQuarter);

// startOfLastQuarter :: _ -> Date
export const startOfLastQuarter = subFromStartOfCurrentQuarter(3);

// startOfLastQuarter :: _ -> Date
export const endOfLastQuarter = R.compose(endOfQuarter, startOfLastQuarter);

// startOfPreviousQuarter :: _ -> Date
export const startOfPreviousQuarter = subFromStartOfCurrentQuarter(6);

// endOfPreviousQuarter :: _ -> Date
export const endOfPreviousQuarter = R.compose(
  endOfQuarter,
  startOfPreviousQuarter,
);

// startOfCurrentYear :: _ -> Date
export const startOfCurrentYear = R.compose(startOfYear, alwaysNewDate);

// startOfLastYear :: _ -> Date
export const startOfLastYear = R.compose(
  subFromDate({ years: 1 }),
  startOfCurrentYear,
);

// endOfDayLastYear :: _ -> Date
export const endOfDayLastYear = R.compose(
  subFromDate({ years: 1 }),
  endOfCurrentDay,
);

// isDateInPast :: DateTime -> Boolean
const isDateInPast = R.both(isPast, R.complement(isToday));

// isExpiredDateByPath :: [String] -> Supplier -> Boolean
export const isExpiredDateByPath = (path) =>
  R.ifElse(
    isNilByPath(path),
    R.F,
    R.compose(isDateInPast, toDate, R.path(path)),
  );

// isValidDate :: String -> Boolean
export const isValidDate = R.compose(
  R.ifElse(
    isValid,
    R.compose(R.complement(R.test(/^[+-]/)), (date) => date.toISOString()),
    R.F,
  ),
  (date) => new Date(date),
);

// subDaysToTodayByProp :: String -> Object -> Date
// eslint-disable-next-line import/no-unused-modules
export const subDaysToTodayByProp = (propName) =>
  R.ifElse(
    R.prop(propName),
    R.compose(
      (days) => startOfDay(subDays(new Date(), days)),
      R.prop(propName),
    ),
    R.always(null),
  );
