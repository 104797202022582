import * as R from 'ramda';
import React from 'react';
import arrayMutators from 'final-form-arrays';
import { func } from 'prop-types';
import { FormCreator } from '@poly/admin-book';
import { BulkEditObjectType } from '@poly/constants';
import { useOutSidebarContext } from '@poly/client-utils';
import { usePristineSubscribe } from '@poly/client-routing';
import { useDispatch, useSelector } from 'react-redux';
import {
  commonSidebarFormLayout,
  commonSidebarFormFieldLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormSectionLayout,
} from '@poly/admin-ui';

import { RuleLine } from './RuleLine.js';
import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';
import { FilterActionButtons } from './components/FilterActionButtons.js';
import { usePrepareQueryInput } from './usePrepareQueryInput.js';
import {
  BulkEditingPageTitleS,
  BulkEditingSidebarHeaderS,
  BulkEditingSidebarWrapperS,
} from './components/styled-components.js';
import {
  BulkEditingFilterFormId,
  BulkEditingFilterSidebarId,
} from './constants.js';
import {
  getBulkEditFiltersConfig,
  setBulkEditFiltersConfig,
} from '../../redux/searchFilters/bulkEditFiltersConfig.js';

const getBulkEditingFilterFormSections = (setObjectType) => [
  {
    order: 1,
    layout: { padding: 0 },
    fields: [
      {
        order: 1,
        layout: { row: 1, width: '100%' },
        field: {
          name: 'rules',
          isArrayField: true,
          withChangeFieldValue: true,
          Component: RuleLine,
          additionalProps: { setObjectType },
        },
      },
      {
        order: 2,
        layout: { row: 2, width: '100%', margin: '0 0 0 24px' },
        field: {
          name: 'actionButtons',
          withChangeFieldValue: true,
          Component: FilterActionButtons,
        },
      },
    ],
  },
];

export function BulkEditingFilterSidebar({ setInput, setObjectType }) {
  const dispatch = useDispatch();
  const { closeOutSidebar } = useOutSidebarContext();
  const prepareQueryInput = usePrepareQueryInput();
  const pristineSubscribeProps = usePristineSubscribe({
    id: BulkEditingFilterSidebarId,
  });

  const previousFilters = useSelector(getBulkEditFiltersConfig);

  const onClose = () => closeOutSidebar(BulkEditingFilterSidebarId);

  const submitHandler = async (formData) => {
    const input = prepareQueryInput(formData);

    if (R.isNil(input)) {
      return null;
    }

    setInput(input);
    dispatch(setBulkEditFiltersConfig(formData));

    return onClose();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    BulkEditingFilterFormId,
    submitHandler,
  );

  const initialValues = previousFilters || {
    rules: [
      {
        objectType: BulkEditObjectType.PROJECTS,
        rulesAndOrCondition: { and: true, or: false },
      },
      {},
    ],
  };

  return (
    <BulkEditingSidebarWrapperS>
      <BulkEditingSidebarHeaderS>
        <BulkEditingPageTitleS>Bulk Editing Filters</BulkEditingPageTitleS>
        <SidebarCloseIcon onClick={onClose} />
      </BulkEditingSidebarHeaderS>
      <FormCreator
        initialValues={initialValues}
        onSubmit={onSubmit}
        id={BulkEditingFilterSidebarId}
        mutators={arrayMutators}
        layout={commonSidebarFormLayout}
        sections={getBulkEditingFilterFormSections(setObjectType)}
        fieldLayout={commonSidebarFormFieldLayout}
        sectionLayout={commonSidebarFormSectionLayout}
        {...pristineSubscribeProps}
      />
    </BulkEditingSidebarWrapperS>
  );
}

BulkEditingFilterSidebar.propTypes = {
  setInput: func,
  setObjectType: func,
};
