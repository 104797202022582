import React from 'react';
import * as R from 'ramda';
import { moneyColumnStyles, Table } from '@poly/admin-book';
import { extractTablePropsFromConfig } from '@poly/admin-ui';
import { formatTotal, formatDate } from '@poly/utils';
import styled from 'styled-components';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { NOTHING_UI_STRING, sqlSortTypes } from '@poly/constants';
import {
  sqlSortQuery,
  useHasUserAccessWithPermission,
} from '@poly/client-utils';

import { READ_TRANSACTION_PERMISSION } from '@poly/security';
import { transactionTypeUILabels } from '../../modules/core/constants/journals.js';
import { TableLinkRow } from '../../components/TableLinkRow.js';
import { useOpenTransactionDetailsSidebar } from '../../sidebars/TransactionDetailsSidebar/useOpenTransactionDetailsSidebar.js';

const SearchTransactionsTableS = styled(Table)`
  tr {
    width: 100%;
    margin: 0;
  }
  ${moneyColumnStyles(6, 200)}
`;

export const transactionsSearchTableConfig = [
  [
    'Transaction Date',
    R.compose(formatDate, R.prop('date')),
    sqlSortQuery('date', sqlSortTypes.DATE),
  ],
  [
    'Created By',
    R.pathOr(NOTHING_UI_STRING, ['createdBy', 'profile', 'fullName']),
    sqlSortQuery('created_by_sort'),
  ],
  [
    'Account Name',
    R.either(
      R.path(['supplier', 'company', 'name']),
      R.path(['client', 'name']),
    ),
    sqlSortQuery('supplier_or_client_name_sort'),
  ],
  [
    'Chart Of Accounts',
    R.path(['account', 'name']),
    sqlSortQuery('account_name_sort'),
  ],
  [
    'Transaction Type',
    R.compose(R.prop(R.__, transactionTypeUILabels), R.prop('type')),
    sqlSortQuery('type_sort'),
  ],
  [
    'Transaction Amount',
    R.compose(formatTotal, R.prop('amount')),
    sqlSortQuery('amount', sqlSortTypes.NUMERIC),
  ],
];

function SearchTransactionsTableRow({ row, ...props }) {
  const openTransactionDetailsSidebar = useOpenTransactionDetailsSidebar();
  const hasPermission = useHasUserAccessWithPermission(
    READ_TRANSACTION_PERMISSION,
  );

  return (
    <TableLinkRow
      {...props}
      row={row}
      hasPermission={hasPermission}
      onClick={() =>
        openTransactionDetailsSidebar(row.transactionNumber, row.type)
      }
    />
  );
}

SearchTransactionsTableRow.propTypes = {
  row: shape({
    transactionNumber: string.isRequired,
  }),
};

export function SearchTransactionsTable({ isPrint, transactions, ...props }) {
  const tableProps = extractTablePropsFromConfig(transactionsSearchTableConfig);

  const allTableRows = {
    ...tableProps,
    ...props,
    ...(isPrint ? {} : { RowComponent: SearchTransactionsTableRow }),
    rows: transactions,
  };

  return <SearchTransactionsTableS {...allTableRows} />;
}

SearchTransactionsTable.propTypes = {
  isPrint: bool,
  transactions: arrayOf(
    shape({
      transactionNumber: string.isRequired,
      date: string.isRequired,
      type: string.isRequired,
      account: shape({
        name: string.isRequired,
      }).isRequired,
      supplier: shape({
        company: shape({
          name: string.isRequired,
        }),
      }),
      amount: number.isRequired,
    }),
  ),
};
