import * as R from 'ramda';
import React from 'react';
import { formatDate } from '@poly/utils';
import { getServiceTypes } from '@poly/admin-ui';
import { LinkButtonWithLoader } from '@poly/admin-book';
import { arrayOf, bool, object, oneOfType, string } from 'prop-types';
import {
  performExcelExport,
  EXPORT_XLS_CAPTION,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';

import { useSuppliersLazyQuery } from './useSuppliersLazyQuery.js';

// getSuppliersRowConfig :: Boolean -> Supplier -> [ExcelExportDataCell]
const getSuppliersRowConfig = (showDateColumn) =>
  R.compose(
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.default, showDateColumn ? 4 : 3),
    ),
    R.unless(R.always(showDateColumn), R.init),
    R.juxt([
      R.pathOr('', ['company', 'name']),
      R.pathOr('', ['company', 'address', 'formatted_address']),
      getServiceTypes,
      R.compose(formatDate, R.prop('createdAt')),
    ]),
  );

export const getSuppliersXlsExportConfig = ({
  title,
  suppliers,
  showDateColumn,
  exportFileName,
}) => ({
  exportFileName,
  columnWidths: showDateColumn ? [20, 40, 40, 10] : [20, 40, 40],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, showDateColumn ? 4 : 3),
      showDateColumn
        ? ['Name', 'Address', 'Service Types', 'Date Created']
        : ['Name', 'Address', 'Service Types'],
    ),
    ...R.map(getSuppliersRowConfig(showDateColumn), suppliers),
  ],
});

// getSuppliersByQueryData :: { searchSuppliers: { hits: [Supplier] } } -> [Supplier]
const getSuppliersByQueryData = R.pathOr([], ['searchSuppliers', 'hits']);

export function SuppliersTabXlsExportButton({
  query,
  title,
  exportSort,
  showDateColumn,
  exportFileName,
}) {
  const queryHandler = useSuppliersLazyQuery();

  const onClick = async () => {
    const { data } = await queryHandler({ query, exportSort });

    const suppliers = getSuppliersByQueryData(data);
    const excelConfig = getSuppliersXlsExportConfig({
      title,
      suppliers,
      showDateColumn,
      exportFileName,
    });

    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}

SuppliersTabXlsExportButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object,
  title: string,
  exportSort: arrayOf(oneOfType([string, object])),
  showDateColumn: bool,
  exportFileName: string,
};
