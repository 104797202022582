/* eslint-disable import/no-unused-modules */
import React from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { func, oneOfType, shape, string } from 'prop-types';
import styled from 'styled-components';
import { UserStatuses } from '@poly/constants/src/users.js';
import { ADMIN_APP_NAME } from '@poly/security/src/app-names.js';
import { CLIENT_APP_AIT } from '@poly/security/src/access-item-types.js';
import {
  EQUALS_AIT_OPERATOR,
  FLIPPED_CONTAINS_AIT_OPERATOR,
} from '@poly/security/src/operators.js';
import { getThemeColor } from '@poly/admin-book/src/utils.js';
import { isNilOrEmpty } from '@poly/utils/src/general.js';

import { DEFAULT_USER_QUERY } from '../../../../../hocs/users/queries.js';
import { AsyncSearchSelect } from '../../multiSearchSelect/createAsyncSearchSelect.js';

const USERS_WITH_CLIENTS_SEARCH = gql`
  query USERS_WITH_CLIENTS_SEARCH($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        fullName
        emails {
          address
        }
        clients {
          _id
          name
        }
        linkedClients {
          _id
          name
        }
      }
    }
  }
`;

const CustomOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 35px;
  border-bottom: 1px solid #f2f2f2;
  padding: 11px 15px;
  :hover {
    background-color: ${getThemeColor(['secondaryLighter2'])};
  }
`;

export const CustomOptionLabelS = styled.div`
  color: #1a1514;
  font-size: 12px;
`;

export const CustomOptionDotS = styled.div`
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background-color: #5e6271;
`;

export const CustomOptionLabelSuffixS = styled.div`
  color: #5e6271;
  font-size: 12px;
`;

const getClientContactsElasticQuery = (clientId) => ({
  bool: {
    must: [
      { term: { status: UserStatuses.ACTIVE } },
      ...(clientId
        ? [
            {
              nested: {
                path: 'links',
                query: {
                  term: {
                    'links.clientId': clientId,
                  },
                },
              },
            },
          ]
        : [
            {
              nested: {
                path: 'userGroups',
                query: { exists: { field: 'userGroups.userGroupId' } },
              },
            },
            {
              nested: {
                path: 'accessItems',
                query: {
                  bool: {
                    should: [
                      {
                        term: {
                          [`accessItems.types.${CLIENT_APP_AIT}.${EQUALS_AIT_OPERATOR}`]:
                            ADMIN_APP_NAME,
                        },
                      },
                      {
                        term: {
                          [`accessItems.types.${CLIENT_APP_AIT}.${FLIPPED_CONTAINS_AIT_OPERATOR}`]:
                            ADMIN_APP_NAME,
                        },
                      },
                      {
                        bool: {
                          must_not: { exists: { field: 'accessItems.types' } },
                        },
                      },
                    ],
                  },
                },
              },
            },
          ]),
    ],
  },
});

function ClientContactCustomOption({ children, innerProps, innerRef, data }) {
  return (
    <CustomOptionContainer {...innerProps} ref={innerRef}>
      <CustomOptionLabelS>{children}</CustomOptionLabelS>
      <CustomOptionDotS />
      <CustomOptionLabelSuffixS>{data?.clientName}</CustomOptionLabelSuffixS>
    </CustomOptionContainer>
  );
}

ClientContactCustomOption.propTypes = {
  children: string,
  innerProps: shape({}),
  innerRef: oneOfType([shape({}), func]),
  data: shape({
    clientName: string,
  }),
};

// prepareClientContactOption :: [User] -> [Option]
const prepareClientContactOption = R.applySpec({
  value: R.prop('_id'),
  label: R.prop('fullName'),
  clientName: R.pathOr('-', ['client', 'name']),
});

// mergeUserWithClients :: (User, [Clients]) -> User
const mergeUserWithClients = (user, clients) =>
  R.ifElse(
    isNilOrEmpty,
    R.always(user),
    R.map(R.compose(R.mergeLeft(user), R.objOf('client'))),
  )(clients);

// prepareClientContactHits :: String -> [User] -> [User]
export const prepareClientContactHits = (clientId) =>
  R.compose(
    R.when(R.always(clientId), R.filter(R.pathEq(clientId, ['client', '_id']))),
    R.unnest,
    R.map(
      R.converge(mergeUserWithClients, [
        R.omit(['linkedClients', 'clients']),
        R.compose(
          R.uniqBy(R.prop('_id')),
          R.reject(isNilOrEmpty),
          R.unnest,
          R.juxt([R.propOr([], 'clients'), R.propOr([], 'linkedClients')]),
        ),
      ]),
    ),
  );

export function ClientContactSelect({
  placeholder,
  getClientId,
  formData,
  ...props
}) {
  const clientId = getClientId(formData);

  const searchSelectProps = {
    isClearable: true,
    valueEndpointName: 'user',
    gqlOptionsQuery: USERS_WITH_CLIENTS_SEARCH,
    optionsEndpointName: 'searchUsers',
    gqlValueQuery: DEFAULT_USER_QUERY,
    searchQuery: getClientContactsElasticQuery(clientId),
    placeholder: placeholder || 'Start typing contacts',
    hitToOption: prepareClientContactOption,
    components: { Option: ClientContactCustomOption },
    prepareHits: prepareClientContactHits(clientId),
    ...props,
  };

  return <AsyncSearchSelect {...searchSelectProps} />;
}

ClientContactSelect.propTypes = {
  placeholder: string,
  getClientId: func.isRequired,
  formData: shape({}),
};
