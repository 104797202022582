import * as R from 'ramda';
import React from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';

import { UnassignedProjectsTableComp } from './UnassignedProjectsComponents.js';
import { unassignedProjectsTableConfig } from './tableConfig.js';

export function UnassignedProjectsTable({ projects, isPrintPDF, ...props }) {
  const tableProps = useMapConfigToTableProps(
    R.map(R.mergeLeft({ isPrintPDF })),
    unassignedProjectsTableConfig,
    projects,
  );

  return <UnassignedProjectsTableComp {...props} {...tableProps} />;
}

UnassignedProjectsTable.displayName = 'UnassignedProjectsTable';
UnassignedProjectsTable.propTypes = {
  isPrintPDF: bool,
  // eslint-disable-next-line react/forbid-prop-types
  projects: arrayOf(object),
};
