import React, { useState } from 'react';
import { TableSearchInput } from '@poly/admin-ui';
import { PageHeaderContainer, MainHeader } from '@poly/admin-book';

import { ReassignUserTable } from './ReassignUserTable.js';
import { ReassignUserExportButtons } from './ReassignUserExportButtons.js';
import { FlexCenterWrapper } from '../../components/ExportButtons.js';

export function ReassignUserPage() {
  const [sort, setSort] = useState(null);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Reassign User</MainHeader>
        <FlexCenterWrapper gap="20px">
          <ReassignUserExportButtons sort={sort} />
          <TableSearchInput />
        </FlexCenterWrapper>
      </PageHeaderContainer>
      <ReassignUserTable setSort={setSort} />
    </>
  );
}
