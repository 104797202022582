import React from 'react';
import { arrayOf, string, object, oneOfType } from 'prop-types';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';

import { PrintExportButtons } from '../../components/ExportButtons.js';
import { getRecurringJournalsExcelConfig } from './getRecurringJournalsExcelConfig.js';
import { useRecurringJournalsLazyQuery } from './useRecurringJournalsQuery.js';
import { RecurringJournalsTable } from './RecurringJournalsTable.js';

export function RecurringJournalsExportButtons({ sort }) {
  const { runRecurringJournalsQuery, loading } =
    useRecurringJournalsLazyQuery(sort);

  const handlePrintPDF = async () => {
    const recurringJournals = await runRecurringJournalsQuery();

    await openPrintWindowWithData({
      recurringJournals,
      Layout: CommonPrintLayout,
      Table: RecurringJournalsTable,
      fileName: 'recurring_journal_entries_export.pdf.',
      metaData: { title: 'Recurring Journal Entries' },
      isPrint: true,
    });
  };

  const handleExportXLS = async () => {
    const recurringJournals = await runRecurringJournalsQuery();

    const config = getRecurringJournalsExcelConfig(recurringJournals);

    return performExcelExport(config);
  };

  return (
    <PrintExportButtons
      loading={loading}
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
    />
  );
}

RecurringJournalsExportButtons.propTypes = {
  sort: arrayOf(oneOfType([string, object])),
};
