import * as R from 'ramda';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { formatDateOrNothingUI, pathOrNothingUI } from '@poly/client-utils';
import { NOTHING_UI_STRING } from '@poly/constants';

import { getTaskPriorityUI, projectDescriptionSort } from './MyTasksTable.js';

// getTaskProject :: Task -> Project
const getTaskProject = R.either(
  R.path(['document', 'project']),
  R.path(['document', 'recurringProject']),
);

// getTaskRowExcelConfig :: Task -> [ExcelExportDataCell]
const getTaskRowExcelConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 7)),
  R.juxt([
    R.compose(
      R.defaultTo(NOTHING_UI_STRING),
      R.prop('projectId'),
      getTaskProject,
    ),
    R.compose(formatDateOrNothingUI, R.prop('dueDate')),
    pathOrNothingUI(['description']),
    getTaskPriorityUI,
    R.compose(R.defaultTo(NOTHING_UI_STRING), projectDescriptionSort),
    R.compose(
      R.defaultTo(NOTHING_UI_STRING),
      R.either(
        R.compose(R.path(['client', 'name']), getTaskProject),
        R.either(
          R.path(['document', 'property', 'client', 'name']),
          R.path(['document', 'client', 'name']),
        ),
      ),
    ),
    R.compose(
      R.defaultTo(NOTHING_UI_STRING),
      R.either(
        R.compose(R.path(['property', 'name']), getTaskProject),
        R.path(['document', 'property', 'name']),
      ),
    ),
  ]),
);

// getTasksExcelConfig :: [Task] -> ExcelConfig
export const getTasksExcelConfig = (tasks) => ({
  exportFileName: 'tasks_export.xls',
  columnWidths: [15, 15, 20, 15, 40, 30, 30],
  rows: [
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 7),
      [
        'Project ID',
        'Due Date',
        'Task',
        'Priority',
        'Project Description',
        'Client',
        'Property',
      ],
    ),
    ...R.map(getTaskRowExcelConfig, tasks),
  ],
});
