import * as R from 'ramda';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { convertCentsToDollars } from '@poly/utils/src/converters.js';

// getAssetTypesRowExcelConfig :: AssetType -> [ExcelExportDataCell]
const getAssetTypesRowExcelConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 2),
    ExcelExportCellType.defaultCurrency,
  ]),
  R.juxt([
    R.propOr('', 'name'),
    R.propOr('', 'lifeExpectancy'),
    R.compose(convertCentsToDollars, R.propOr(0, 'replacementCost')),
  ]),
);

// getAssetTypesExcelConfig :: [AssetType] -> ExcelConfig
export const getAssetTypesExcelConfig = (assetTypes) => ({
  exportFileName: 'asset_types_export.xls',
  columnWidths: [25, 15, 15],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 2),
        ExcelExportCellType.tableCurrencyHeader,
      ],
      ['Name', 'Life Expectancy', 'Asset Replacement Cost'],
    ),
    ...R.map(getAssetTypesRowExcelConfig, assetTypes),
  ],
});
