import * as R from 'ramda';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { usePaginationParams } from '@poly/admin-ui/src/hooks/usePaginationParams.js';
import { useSetItemsCount } from '@poly/admin-ui/src/hooks/useSetItemsCount.js';
import { useMemo, useState } from 'react';
import { MAX_ITEMS } from '@poly/admin-ui';

const recurringJournalQuery = gql`
  query recurringJournalsQuery($input: RecurringJournalsInput) {
    recurringJournals(input: $input) {
      hits {
        _id
        start_date
        end_date
        reference_id
        status
        schedule {
          every
          repeats
          days
          weekDay
        }
        lines {
          cash_amount
          accrual_amount
          account {
            code
            accountType {
              _id
              normal_balance
            }
          }
        }
      }
      total
    }
  }
`;

// getRecurringJournals :: RecurringJournalsResult -> [RecurringJournal]
const getRecurringJournals = R.pathOr([], ['recurringJournals', 'hits']);

export const useRecurringJournalsQuery = (sort) => {
  const paginationParams = usePaginationParams();

  const { data, loading } = useQuery(recurringJournalQuery, {
    variables: { input: { ...paginationParams, sort } },
    fetchPolicy: 'network-only',
  });

  useSetItemsCount(R.pathOr(0, ['recurringJournals', 'total']), data);

  const recurringJournals = getRecurringJournals(data);

  return { recurringJournals, loading };
};

export const useRecurringJournalsLazyQuery = (sort) => {
  const [loading, setLoading] = useState(false);

  const paginationParams = usePaginationParams();

  const input = useMemo(
    () => ({
      ...paginationParams,
      sort,
      size: MAX_ITEMS,
    }),
    [sort, paginationParams],
  );

  const [runQuery] = useLazyQuery(recurringJournalQuery);

  const runRecurringJournalsQuery = async () => {
    setLoading(true);

    const { data } = await runQuery({ variables: { input } });

    setLoading(false);

    return getRecurringJournals(data);
  };

  return {
    loading,
    runRecurringJournalsQuery,
  };
};
