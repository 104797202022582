import React from 'react';
import { ClientStatuses } from '@poly/constants';

import { ClientsExportButtonsBase } from '../Clients/ClientsExportButtons.js';

export function ProspectsExportButtons(props) {
  return (
    <ClientsExportButtonsBase
      status={ClientStatuses.PROSPECT}
      withStatus={false}
      {...props}
    />
  );
}
