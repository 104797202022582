import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { usePaginationParams } from '@poly/admin-ui';

import { STAFF_USERS_BY_SEARCH_TABLE } from './queries.js';

export const useStaffDirectoryLazyQuery = () => {
  const searchTerm = useSelector((state) => state.searchText);
  const paginationParams = usePaginationParams();
  const [queryHandler] = useLazyQuery(STAFF_USERS_BY_SEARCH_TABLE);

  const handler = async ({ query, exportSort }) => {
    const response = await queryHandler({
      fetchPolicy: 'network-only',
      variables: {
        searchInput: {
          query,
          searchTerm,
          sort: exportSort,
          ...paginationParams,
        },
      },
    });

    return response;
  };

  return handler;
};
