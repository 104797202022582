import React from 'react';
import styled, { css } from 'styled-components';
import { func, string, bool } from 'prop-types';
import {
  internationalPhoneUtils,
  PasswordInputSection,
  InputSection,
} from '@poly/client-utils';

import {
  Footer,
  ErrorText,
  LoginButton,
  LoginPageLayout,
} from '../Components.js';
import { ButtonLoader } from '../Loader.js';

const forgotPasswordCustomLinkStyles = css`
  margin-top: 20px;
  text-decoration: none;
  color: #07b5b3;
`;

export const ForgotPasswordLink = styled.span`
  font-family: 'TT Norms', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #bcbcbc;
  ${({ marginBottom }) => !!marginBottom && 'margin-bottom: 15px'};
  ${({ marginTop }) => !!marginTop && forgotPasswordCustomLinkStyles};
`;

const PhoneS = styled.div`
  color: #999;
  font-family: TTNormsMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
`;

function LoginForm({
  // "phone" used at asset-scanner for staff users login
  phone,
  title,
  loading,
  subTitle,
  hideLogo,
  className,
  signInError,
  handleSubmit,
  goToForgotPassword,
  forgotPasswordMessage,
}) {
  const onSubmit = (event) => {
    event.preventDefault();
    const creds = {
      password: event.target.elements.password.value,
      ...(phone
        ? { phone: internationalPhoneUtils.formattingInternationalPhone(phone) }
        : { email: event.target.elements.email.value }),
    };
    handleSubmit(creds);
  };

  return (
    <LoginPageLayout
      title={title}
      subTitle={subTitle}
      onSubmit={onSubmit}
      hideLogo={hideLogo}
      darkLogo={!!subTitle}
      className={className}
    >
      {forgotPasswordMessage && <ErrorText>{forgotPasswordMessage}</ErrorText>}
      {phone ? (
        <PhoneS>{phone}</PhoneS>
      ) : (
        <InputSection
          title="EMAIL"
          inputProps={{
            lower: true,
            type: 'email',
            name: 'email',
            required: true,
            autoFocus: true,
            placeholder: 'Email Address',
          }}
        />
      )}
      <PasswordInputSection
        title="PASSWORD"
        inputProps={{
          required: true,
          type: 'password',
          name: 'password',
          placeholder: 'Password',
        }}
      />
      {signInError && <ErrorText>{signInError}</ErrorText>}
      {goToForgotPassword && !subTitle && (
        <ForgotPasswordLink marginBottom onClick={goToForgotPassword}>
          forgotten your password?
        </ForgotPasswordLink>
      )}
      <Footer>
        <LoginButton type="submit" disabled={loading}>
          {loading && <ButtonLoader />}
          Log In
        </LoginButton>
      </Footer>
      {goToForgotPassword && !!subTitle && (
        <ForgotPasswordLink marginTop onClick={goToForgotPassword}>
          Forgot Password
        </ForgotPasswordLink>
      )}
    </LoginPageLayout>
  );
}

LoginForm.propTypes = {
  phone: string,
  title: string,
  hideLogo: bool,
  subTitle: string,
  className: string,
  signInError: string,
  loading: bool.isRequired,
  goToForgotPassword: func,
  handleSubmit: func.isRequired,
  forgotPasswordMessage: string,
};

LoginForm.defaultProps = {
  signInError: null,
  forgotPasswordMessage: null,
};

export default LoginForm;
