import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '@poly/admin-book';
import {
  ensureIsDate,
  customFormatDate,
  getSupplierArrivalTimeByClientConfigs,
} from '@poly/utils';
import {
  bool,
  func,
  shape,
  object,
  string,
  oneOfType,
  instanceOf,
} from 'prop-types';

const COMMON_DATE_AND_TIME_FORMAT = 'MM/dd/yyyy (h:mm a)';

// supplierArrivalTimePickerFormatter :: Date -> String
const supplierArrivalTimePickerFormatter = R.compose(
  R.when(R.is(Date), customFormatDate(COMMON_DATE_AND_TIME_FORMAT)),
  ensureIsDate,
);

export function SupplierArrivalTimePicker({
  value,
  onChange,
  formData,
  skipFutureTimeForToday,
  ...props
}) {
  const [currentValue, setCurrentValue] = useState(value);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  // this useEffect is used to dynamically change time-select options
  // one of usages is with "skipFutureTime"
  useEffect(() => {
    const updateToNextQuarterMinute = () => {
      const now = new Date();
      const nextMinute = Math.ceil(now.getMinutes() / 15) * 15;
      const nextTarget = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        nextMinute % 60,
        0,
        0,
      );
      if (nextMinute >= 60) {
        nextTarget.setHours(nextTarget.getHours() + 1);
      }
      return nextTarget - now;
    };

    const timeout = setTimeout(() => {
      setCurrentDateTime(new Date());
      const interval = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 15 * 60 * 1000);

      return () => clearInterval(interval);
    }, updateToNextQuarterMinute());

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const newValue = getSupplierArrivalTimeByClientConfigs(formData || {});

    onChange(newValue);
    setCurrentValue(newValue);
  }, [formData?.priorityId]);

  const handleChange = (newValue) => {
    onChange(newValue);
    setCurrentValue(newValue);
  };

  const dateAndTimePickerProps = {
    ...props,
    width: '100%',
    value: currentValue,
    onChange: handleChange,
    formatDate: supplierArrivalTimePickerFormatter,
    withTimePicker: true,
    // next 2 props work together
    currentDateTime,
    skipFutureTimeForToday,
  };

  return <DatePicker {...dateAndTimePickerProps} />;
}

SupplierArrivalTimePicker.propTypes = {
  value: oneOfType([string, instanceOf(Date)]),
  onChange: func,
  skipFutureTimeForToday: bool,
  formData: shape({
    // eslint-disable-next-line react/forbid-prop-types
    client: object,
  }),
};
