import React, { useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@poly/admin-book';
import { object } from 'prop-types';
import { CommonPrintLayout } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { openPrintWindowWithData } from '@poly/client-utils';

import { ExportPDFBtn } from '../WorkInProgressReport/WorkInProgressExportPDFBtn.js';
import { SearchTransactionsTable } from './SearchTransactionsTable.js';
import { useSearchTransactionsLazyQuery } from './useSearchTransactionsQuery.js';

const LoaderWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  min-width: 40px;
`;

export function SearchTransactionsPdfExportButton({ sort, filters, ...props }) {
  const [printing, setPrinting] = useState(false);
  const queryHandler = useSearchTransactionsLazyQuery();

  const onClick = async () => {
    setPrinting(true);

    const { transactions } = await queryHandler({ sort, filters });

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: SearchTransactionsTable,
      fileName: 'transaction_amount_search_export',
      metaData: { title: 'Transaction Amount Search' },
      isPrint: true,
      transactions,
    });

    setPrinting(false);
  };

  if (printing) {
    return (
      <LoaderWrapperS>
        <Loader size={16} />
      </LoaderWrapperS>
    );
  }

  return (
    <ExportPDFBtn {...props} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </ExportPDFBtn>
  );
}

SearchTransactionsPdfExportButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sort: object,
  // eslint-disable-next-line react/forbid-prop-types
  filters: object,
};
