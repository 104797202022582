import * as R from 'ramda';
import React, { useState } from 'react';
import arrayMutators from 'final-form-arrays';
import { gql, useMutation } from '@apollo/client';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { ClientBidRulesTypes } from '@poly/constants';
import { FormCreator, Text } from '@poly/admin-book';
import {
  CommonSidebarFormWrapper,
  useNotificationState,
  SidebarRow,
} from '@poly/admin-ui';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  isNilOrEmpty,
  formatTotal,
  propEqLegacy,
} from '@poly/utils';

import {
  ColumnList,
  TabSectionWrapper,
  EditClientTabButton,
} from '../commonComponents.js';
import {
  editClientBidRulesFormId,
  ClientBidRulePerAssetFeeLabel,
  ClientBidRuleManagementFeeLabel,
} from '../../constants.js';
import { commonModalLayout } from '../../../../modules/forms/common.js';
import { ClientBidRulesLineComponent } from './ClientBidRulesLineComponent.js';
import { BlockWithLabel } from '../../../components/commonSidebarComponents.js';

const defaultRules = [
  {
    isRuleActive: false,
    type: ClientBidRulesTypes.perAsset,
    label: ClientBidRulePerAssetFeeLabel,
  },
  {
    isRuleActive: false,
    label: ClientBidRuleManagementFeeLabel,
    type: ClientBidRulesTypes.jobCostPercentage,
  },
];

const editBidRulesMutation = gql`
  mutation editBidRulesMutation($input: UpdateBidRulesInput!) {
    updateBidRules(input: $input) {
      bidRulesUpdated
    }
  }
`;

// isPercentageTypeValue :: ClientBidRule -> Boolean
const isPercentageTypeValue = propEqLegacy(
  'type',
  ClientBidRulesTypes.jobCostPercentage,
);

// getRulesAmount :: [ClientBidRule] -> [String]
const getRulesAmount = R.compose(
  R.map(
    R.ifElse(
      isPercentageTypeValue,
      R.compose(R.concat(R.__, '%'), R.toString, R.prop('amount')),
      R.compose(formatTotal, convertCentsToDollars, R.prop('amount')),
    ),
  ),
  R.defaultTo([]),
);

// getRulesLabel :: [ClientBidRule] -> [String]
const getRulesLabel = R.map(R.prop('label'));

// getRulesStatus :: [ClientBidRule] -> [String]
const getRulesStatus = R.map(
  R.ifElse(R.prop('isRuleActive'), R.always('On'), R.always('Off')),
);

// prepareClientBidRulesInitialValues :: ([ClientBidRule], Boolean) -> FormValues
const prepareClientBidRulesInitialValues = (
  bidRules,
  showAssetsWarning = false,
) =>
  R.compose(
    R.objOf('bidRules'),
    R.map(
      R.when(
        R.propEq(ClientBidRulesTypes.perAsset, 'type'),
        R.assoc('showAssetsWarning', showAssetsWarning),
      ),
    ),
    R.ifElse(
      R.isEmpty,
      R.always(defaultRules),
      R.map(
        R.converge(R.mergeLeft, [
          R.pick(['type', 'label', 'isRuleActive']),
          R.ifElse(
            isPercentageTypeValue,
            R.pick(['amount']),
            R.compose(
              R.objOf('amount'),
              convertCentsToDollars,
              R.prop('amount'),
            ),
          ),
        ]),
      ),
    ),
  )(bidRules);

// prepareFormDataToMutationInput :: FormValues -> UpdateClientBidRulesInput
const prepareFormDataToMutationInput = R.over(
  R.lensProp('bidRules'),
  R.compose(
    R.map(
      R.compose(
        R.reject(isNilOrEmpty),
        R.converge(R.mergeLeft, [
          R.pick(['type', 'label', 'isRuleActive']),
          R.ifElse(
            isPercentageTypeValue,
            R.compose(R.objOf('amount'), R.propOr(0, 'amount')),
            R.compose(
              R.objOf('amount'),
              convertDollarsToCents,
              R.propOr(0, 'amount'),
            ),
          ),
        ]),
      ),
    ),
    R.reject(R.propSatisfies(isNilOrEmpty, 'label')),
  ),
);

// checkIfRulesAreEmpty :: FormData -> Boolean
const checkIfRulesAreEmpty = R.propEq(defaultRules, 'bidRules');

// prepareBidRulesForPreview :: [ClientBidRule] -> [ClientBidRule]
const prepareBidRulesForPreview = R.defaultTo([]);

function WarningText({ children }) {
  return (
    <Text size="12px" color={['notificator', 'warning', 'text']}>
      *{children}
    </Text>
  );
}

WarningText.propTypes = { children: string.isRequired };

export function BidRulesTab({
  id,
  bidRules,
  hasAccess,
  collection,
  showAssetsWarning,
  rulesInheritedWarning,
}) {
  const [showEditForm, setShowEditForm] = useState(false);
  const [editBidRules] = useMutation(editBidRulesMutation);
  const { showSuccessNotification, showWarningNotification } =
    useNotificationState();

  const onCancel = () => setShowEditForm(false);

  const onSubmitHandler = async (formData) => {
    const input = prepareFormDataToMutationInput(formData);

    const bidRulesAreEmpty = checkIfRulesAreEmpty(input);

    if (bidRulesAreEmpty) {
      return showWarningNotification('Please select at least one rule');
    }

    await editBidRules({ variables: { input: { ...input, id, collection } } });

    showSuccessNotification('Bid Rules successfully updated');

    return onCancel();
  };

  const preparedBidRules = prepareBidRulesForPreview(bidRules);

  return (
    <TabSectionWrapper>
      {!!rulesInheritedWarning && (
        <SidebarRow justify>
          <WarningText>{rulesInheritedWarning}</WarningText>
          {hasAccess && (
            <EditClientTabButton onClick={() => setShowEditForm(true)}>
              edit
            </EditClientTabButton>
          )}
        </SidebarRow>
      )}
      <SidebarRow justify>
        <BlockWithLabel
          width="50%"
          id="rule-label"
          label="Rule Label"
          Component={ColumnList}
          list={getRulesLabel(preparedBidRules)}
        />
        <BlockWithLabel
          width="25%"
          id="rule-amount"
          label="Rule Amount"
          Component={ColumnList}
          list={getRulesAmount(preparedBidRules)}
        />
        <BlockWithLabel
          width="20%"
          id="rule-status"
          label="Rule Status"
          Component={ColumnList}
          list={getRulesStatus(preparedBidRules)}
        />
        {!rulesInheritedWarning && hasAccess && (
          <EditClientTabButton onClick={() => setShowEditForm(true)}>
            edit
          </EditClientTabButton>
        )}
      </SidebarRow>
      {showEditForm && (
        <CommonSidebarFormWrapper
          onCancel={onCancel}
          title="Edit Bid Rules"
          formId={editClientBidRulesFormId}
        >
          <FormCreator
            validateOnBlur
            layout={commonModalLayout}
            onSubmit={onSubmitHandler}
            id={editClientBidRulesFormId}
            mutators={{ ...arrayMutators }}
            initialValues={prepareClientBidRulesInitialValues(
              bidRules,
              showAssetsWarning,
            )}
            sections={[
              {
                order: 1,
                layout: { column: 1 },
                fields: [
                  {
                    order: 1,
                    layout: { row: 1 },
                    field: {
                      name: 'bidRules',
                      isArrayField: true,
                      Component: ClientBidRulesLineComponent,
                    },
                  },
                ],
              },
            ]}
          />
        </CommonSidebarFormWrapper>
      )}
    </TabSectionWrapper>
  );
}

BidRulesTab.propTypes = {
  id: string.isRequired,
  showAssetsWarning: bool,
  hasAccess: bool.isRequired,
  rulesInheritedWarning: string,
  collection: string.isRequired,
  bidRules: arrayOf(
    shape({
      type: string.isRequired,
      label: string.isRequired,
      amount: number.isRequired,
    }),
  ).isRequired,
};
