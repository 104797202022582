import * as R from 'ramda';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { JournalPaymentStatus, NOTHING_UI_STRING } from '@poly/constants';
import { READ_JOURNAL_PAYMENT_BATCH_PERMISSION } from '@poly/security';
import { getThemeColor, Icon, LinkButton } from '@poly/admin-book';
import { useTableSorting } from '@poly/admin-ui';
import {
  formatMoneyTransactionPayeeNameColumn,
  propEqLegacy,
  formatTotal,
  formatDate,
  applyProp,
} from '@poly/utils';
import {
  useHasUserAccessWithPermission,
  useTableInfiniteScrollQuery,
  highlightTextToReactElement,
  commonNestedSortQuery,
  keywordSortQuery,
  commonSortQuery,
} from '@poly/client-utils';

import { useOpenTransactionDetailsSidebar } from '../../sidebars/TransactionDetailsSidebar/useOpenTransactionDetailsSidebar.js';
import {
  bankRegisterAccountDetailsQuery,
  getRegistersPageQueryVariables,
  bankRegisterTransactionsQuery,
} from './bankRegistersQuery.js';
import {
  isMoneyTransactionReconciled,
  getMoneyTransactionAmount,
} from './helpers.js';

// isTransactionVoided :: MoneyTransaction -> Boolean
const isTransactionVoided = propEqLegacy(
  'payment_status',
  JournalPaymentStatus.VOIDED,
);

// getReconciledCellValue :: MoneyTransaction -> String
const getReconciledCellValue = R.ifElse(
  isMoneyTransactionReconciled,
  () => <Icon name="checkMark" size={15} />,
  R.always(null),
);

// formatNetAmountByTransaction :: (String -> ReactNode, Boolean) -> {netAmount: Number} -> ReactNode
const formatNetAmountByTransaction = (highlightMatches, isPayment) =>
  R.compose(
    highlightMatches,
    R.ifElse(R.equals(0), R.always(''), formatTotal),
    getMoneyTransactionAmount(isPayment),
  );

const VoidedColorWrapperS = styled.span`
  color: ${getThemeColor(['secondaryMid'])};
`;

// formatDateByTransaction :: (String -> ReactNode) -> { date: Date } -> ReactNode
const formatDateByTransaction = (highlightMatches) =>
  R.compose(highlightMatches, formatDate, R.prop('date'));

const formatDateColumn = (highlightMatches) =>
  function (transaction) {
    const dateValue = formatDateByTransaction(highlightMatches)(transaction);

    if (isTransactionVoided(transaction)) {
      return <VoidedColorWrapperS>{dateValue}</VoidedColorWrapperS>;
    }

    return dateValue;
  };

const formatAmountColumn = (highlightMatches, isPayment) =>
  function (transaction) {
    const amountValue = formatNetAmountByTransaction(
      highlightMatches,
      isPayment,
    )(transaction);

    if (isTransactionVoided(transaction)) {
      return <VoidedColorWrapperS>{amountValue}</VoidedColorWrapperS>;
    }

    return amountValue;
  };

function TransactionLink({
  name,
  transactionType,
  transactionNumber,
  ...props
}) {
  const openTransactionDetailsSidebar = useOpenTransactionDetailsSidebar();

  const hasPermission = useHasUserAccessWithPermission(
    READ_JOURNAL_PAYMENT_BATCH_PERMISSION,
  );

  if (hasPermission) {
    return (
      <LinkButton
        onClick={() =>
          openTransactionDetailsSidebar(transactionNumber, transactionType)
        }
        {...props}
      >
        {name}
      </LinkButton>
    );
  }
  return name;
}

TransactionLink.propTypes = {
  name: string.isRequired,
  transactionType: string.isRequired,
  transactionNumber: string.isRequired,
};

export const getBankRegistersTableConfig = (isCredit, searchTerm) => {
  const highlightMatches = highlightTextToReactElement(searchTerm);

  return [
    ['Clr', getReconciledCellValue],
    ['Date', formatDateColumn(highlightMatches), commonSortQuery(['date'])],
    [
      'Check Number',
      R.compose(highlightMatches, R.prop('reference_column')),
      keywordSortQuery(['reference_column']),
    ],
    [
      'Payee',
      R.compose(highlightMatches, formatMoneyTransactionPayeeNameColumn),
      keywordSortQuery(['payee_name_column']),
    ],
    [
      isCredit ? 'Charge' : 'Payment',
      formatAmountColumn(highlightMatches, true),
      commonNestedSortQuery(['lines', 'payment_amount']),
    ],
    [
      isCredit ? 'Payment' : 'Deposit',
      formatAmountColumn(highlightMatches, false),
      commonNestedSortQuery(['lines', 'deposit_amount']),
    ],
    [
      'Balance',
      // if some transactions are missing
      // (because of full text search filtering)
      // then current balance is incorrect
      searchTerm
        ? R.always(NOTHING_UI_STRING)
        : applyProp(formatTotal)('currentBalance'),
    ],
    [
      '',
      (transaction) => (
        <TransactionLink
          transactionType={transaction.type}
          transactionNumber={transaction._id}
          target="_blank"
          name="View"
        />
      ),
    ],
  ];
};

export const useAccountRegistersQuery = (query, isCredit) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: getBankRegistersTableConfig(isCredit, query?.searchTerm),
    column: 2,
  });

  const { input, accountId, fromDate } = useMemo(
    () => getRegistersPageQueryVariables({ ...query, sort }),
    [query, sort],
  );

  const { data: accountData, loading: accountLoading } = useQuery(
    bankRegisterAccountDetailsQuery,
    {
      variables: { accountId, fromDate },
      skip: !accountId,
    },
  );

  const {
    tableProps,
    data: transactionsData,
    loading: transactionsLoading,
  } = useTableInfiniteScrollQuery(bankRegisterTransactionsQuery, input, {
    endpointName: 'searchMoneyTransactions',
    pageSize: 1000,
    skip: !query,
  });

  const data = R.mergeRight(accountData, transactionsData);
  const loading = transactionsLoading || accountLoading;

  return { data, loading, tableProps: { ...tableSortingProps, tableProps } };
};
