import React from 'react';
import { bool } from 'prop-types';
import { performExcelExport } from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { CommonOuterButton } from '@poly/admin-ui';

import { paymentsDataPropTypes } from './propTypes.js';
import { getVoidSupplierPaymentsXLSConfig } from './getVoidSupplierPaymentsXLSConfig.js';
import { getBatchPaymentsByData } from './helpers.js';

export function VoidSupplierPaymentsExportXLSBtn({ paymentsData, loading }) {
  const onClick = async () => {
    const batchPayments = getBatchPaymentsByData(paymentsData);

    if (batchPayments.length > 0) {
      const exportConfig = getVoidSupplierPaymentsXLSConfig({
        batchPayments,
        exportFileName: 'void_supplier_payments_export',
      });

      performExcelExport(exportConfig);
    }
  };

  return (
    <CommonOuterButton
      onClick={onClick}
      loading={loading}
      disabled={!paymentsData}
    >
      {EXPORT_XLS_CAPTION}
    </CommonOuterButton>
  );
}

VoidSupplierPaymentsExportXLSBtn.propTypes = {
  loading: bool.isRequired,
  paymentsData: paymentsDataPropTypes,
};
