import * as R from 'ramda';
import Dropzone from 'react-dropzone';
import styled, { css } from 'styled-components';

import { getThemeColor, getThemeFont } from '../utils.js';
import { UploadFilesBtn } from '../UploadFilesBtn/index.js';
import { FlexContainer } from '../lib/index.js';
import { Icon } from '../Icon/index.js';

// fileHasError :: FileItemProps -> Boolean
// FileItemProps = { hasError: Boolean, error: String, isDragOver: Boolean }
const fileHasError = R.anyPass([
  R.prop('hasError'),
  R.prop('error'),
  R.prop('isDragOver'),
]);

// getFileInputBorder :: FileItemProps -> String
const getFileInputBorder = R.compose(
  R.concat('1px solid '),
  R.ifElse(
    fileHasError,
    getThemeColor(['secondaryMid']),
    getThemeColor(['midLight']),
  ),
);

// getFileNameBorder :: FileItemProps -> String
const getFileNameBorder = R.ifElse(
  fileHasError,
  getFileInputBorder,
  R.always('none'),
);

export const DropzoneS = styled(Dropzone)`
  width: 100%;
  height: 100%;
`;

const disabledAreaStyles = css`
  div,
  span,
  label {
    cursor: not-allowed;
    color: #cecfd2;
    background-color: ${getThemeColor(['midLight'])};

    &:hover {
      text-decoration: none;
    }
  }
`;

export const AreaWrapper = styled.div`
  position: relative;
  color: ${getThemeColor(['primary'])};
  background-color: ${getThemeColor(['white'])};
  box-sizing: border-box;
  width: 100%;
  overflow: visible;
  border-radius: 3px;
  font-weight: ${getThemeFont(['semibold'])};

  ${({ disabled }) => disabled && disabledAreaStyles};

  &::placeholder {
    opacity: 0.6;
  }
`;

export const FieldBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: ${getThemeFont(['regular'])};
  height: 38px;
  border-radius: 3px;
  border-width: ${({ isDragOver }) => (isDragOver ? '2px' : '1px')};
  border-style: dashed;
  border-color: ${getThemeColor(['midLight'])};
  background-color: ${getThemeColor(['white'])};
  position: relative;
  margin-top: 10px;
`;

export const DnDWrapper = styled(FlexContainer)`
  align-items: center;
`;

export const DragLineWrapper = styled(FieldBottom)`
  height: ${({ dragAreaHeight }) => dragAreaHeight || '38px'};
  margin: 0;
`;

export const DnDSpan = styled.span`
  color: ${getThemeColor(['midDark'])};
  font-size: 12px;
  line-height: 18px;
`;

export const FileListWrapper = styled.ul`
  list-style-type: none;
  text-align: right;
  margin: ${({ isEmpty }) => (isEmpty ? 0 : '10px 0 0 0')};
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  & > div {
    display: flex;
  }
`;

const fileItemCommonStyles = css`
  border-radius: 3px;
  font-weight: ${getThemeFont(['regular'])};
  width: ${({ readOnly, isFileNameChangeable }) =>
    readOnly || !isFileNameChangeable ? '100%' : 'calc(50% - 8px)'};
`;

export const FileListItem = styled.li`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  > input {
    color: ${getThemeColor(['primary'])};
    background-color: ${getThemeColor(['white'])};
    box-sizing: border-box;
    overflow: hidden;
    border: ${getFileInputBorder};

    ${fileItemCommonStyles};
  }

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    padding: 0 10px;
    background-color: ${getThemeColor(['accent2Lighter4'])};
    color: ${getThemeColor(['midDark'])};
    font-size: 12px;
    line-height: 14px;
    font-weight: ${getThemeFont(['regular'])};
    margin: 0;
    border: ${getFileNameBorder};

    ${fileItemCommonStyles};

    > a,
    span {
      display: inline;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: calc(100% - 18px);
    }
  }
`;

export const FileNameInput = styled.input`
  height: 24px;
  line-height: 18px;
  font-size: 12px;
  padding: 6px 10px;
  border: none;
  width: 100%;

  &::placeholder {
    opacity: 0.6;
  }
`;

export const IconClose = styled(Icon).attrs((props) => ({
  size: 8,
  color: getThemeColor(['midDark'])(props),
}))`
  margin-left: 10px;
  cursor: pointer;
`;

export const UploadFilesBtnS = styled(UploadFilesBtn)`
  color: ${getThemeColor(['primaryRegular'])};
  font-size: 12px;
  font-weight: ${getThemeFont(['medium'])};
  padding-left: 5px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
