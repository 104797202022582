import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { Loader } from '@poly/admin-book';

import { BankRegistersBody } from './BankRegistersBody.js';
import { BankRegistersHeader } from './BankRegistersHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { useAccountRegistersQuery } from './useAccountRegistersQuery.js';

export function AccountRegistersPage({
  title,
  exportTitle,
  hideCheckNumber,
  systemAccountType,
}) {
  const [query, setQuery] = useState(null);

  const { data, loading, tableProps } = useAccountRegistersQuery(
    query,
    hideCheckNumber,
  );

  return (
    <PageWithSearchHeader headerHeight="165px">
      <BankRegistersHeader
        data={data}
        query={query}
        title={title}
        loading={loading}
        setQuery={setQuery}
        exportTitle={exportTitle}
        hideCheckNumber={hideCheckNumber}
        systemAccountType={systemAccountType}
      />
      {loading ? (
        <Loader />
      ) : (
        <BankRegistersBody
          data={data}
          query={query}
          tableProps={tableProps}
          hideCheckNumber={hideCheckNumber}
        />
      )}
    </PageWithSearchHeader>
  );
}

AccountRegistersPage.propTypes = {
  hideCheckNumber: bool,
  title: string.isRequired,
  systemAccountType: string,
  exportTitle: string.isRequired,
};
