import * as R from 'ramda';
import { MasterSupplierStatus } from '@poly/constants';
import { createExcelExportCell, ExcelExportCellType } from '@poly/utils';
import { getServiceTypes } from '@poly/admin-ui';

const masterSuppliersTabMap = {
  [MasterSupplierStatus.ACTIVE]: 'active',
  [MasterSupplierStatus.BLOCKED]: 'blocked',
};

export const getMasterSupplierExportFileName = ({ tab, type, ext }) =>
  `master_suppliers_${R.toLower(type)}_${
    masterSuppliersTabMap[tab]
  }_export.${ext}`;

// getMasterSupplierRowExcelConfig :: MasterSupplier -> [ExcelExportDataCell]
const getMasterSupplierRowExcelConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 3)),
  R.juxt([
    R.path(['company', 'name']),
    R.path(['company', 'address', 'formatted_address']),
    getServiceTypes,
  ]),
);

export const getMasterSuppliersExcelConfig = ({
  tab,
  type,
  masterSuppliers,
}) => ({
  exportFileName: getMasterSupplierExportFileName({ tab, type, ext: 'xls' }),
  columnWidths: [40, 55, 35],
  rows: [
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 3),
      ['Name', 'Address', '	Service Types'],
    ),
    ...R.map(getMasterSupplierRowExcelConfig, masterSuppliers),
  ],
});
