import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { isNilOrEmpty } from '@poly/utils';
import { ClientSelect } from '@poly/admin-ui';
import { bool, func, shape } from 'prop-types';
import { endOfToday, startOfMonth } from 'date-fns';
import { DatePicker, ToolBarBtnDivider } from '@poly/admin-book';

import { Text } from '@poly/admin-book/src/Text/index.js';

import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { ButtonsWrapper } from '../UnassignedProjectsPage/UnassignedProjectsComponents.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { ButtonsContainerS } from '../WorkInProgressReport/WorkInProgressHeader.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { DetailedClientSurveysReportPDFBtn } from './DetailedClientSurveysReportPDFBtn.js';
import { DetailedClientSurveysReportXLSBtn } from './DetailedClientSurveysReportXLSBtn.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import {
  SelectsWrapper,
  SearchHeaderWrapper,
} from '../WorkInProgressQCReport/WorkInProgressQCHeader.js';

const SearchHeaderWrapperS = styled(SearchHeaderWrapper)`
  margin-top: 0;

  @media (max-width: 1470px) {
    flex-direction: row;
  }
`;

const SearchHeaderColumnS = styled(SearchHeaderColumn)`
  @media (max-width: 1430px) {
    margin-right: 5px;
    width: 100px;
  }
`;

const SelectsWrapperS = styled(SelectsWrapper)`
  width: auto;
`;

const detailedClientSurveysReportFiltersConfig = [
  { name: 'clientId' },
  { name: 'projectId' },
  { name: 'dateFrom', defaultValue: startOfMonth(new Date()) },
  { name: 'dateTo', defaultValue: endOfToday() },
];

// checkIsDisabledExport :: { searchProjects: { hits: [Project] } } -> Boolean
const checkIsDisabledExport = R.pathSatisfies(isNilOrEmpty, [
  'detailedClientSurveysReport',
  'hits',
]);

function HeaderTitle() {
  return <Text>Detailed Client Surveys Report</Text>;
}

export function DetailedClientSurveysReportPageHeader({
  data,
  sort,
  loading,
  setQueryFilters,
}) {
  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    detailedClientSurveysReportFiltersConfig,
    setQueryFilters,
  );

  const handleReset = () => {
    setQueryFilters({});
    onReset();
  };

  const isDisabledExport = checkIsDisabledExport(data);

  return (
    <SearchPageHeaderContainer
      clientId={searchFilters.clientId}
      TitleComp={HeaderTitle}
    >
      <SearchHeaderWrapperS>
        <SelectsWrapperS>
          <SearchHeaderColumn
            title="Client"
            titleWidth="120px"
            filterWidth="240px"
          >
            <ClientSelect
              value={searchFilters.clientId}
              onChange={handlers.clientId}
              width="100%"
              includeAllOption={false}
              withoutSkip
              isClearable
            />
          </SearchHeaderColumn>
        </SelectsWrapperS>
        <SelectsWrapperS>
          <SearchHeaderColumn
            title="Date From"
            titleWidth="80px"
            filterWidth="240px"
          >
            <DatePicker
              value={searchFilters.dateFrom}
              onChange={handlers.dateFrom}
              width="100%"
            />
          </SearchHeaderColumn>
        </SelectsWrapperS>
        <SelectsWrapperS>
          <SearchHeaderColumn
            title="Date To"
            titleWidth="80px"
            filterWidth="240px"
          >
            <DatePicker
              value={searchFilters.dateTo}
              onChange={handlers.dateTo}
              width="100%"
            />
          </SearchHeaderColumn>
        </SelectsWrapperS>
      </SearchHeaderWrapperS>
      <SearchHeaderWrapperS>
        <SelectsWrapperS>
          <SearchHeaderColumn
            title="Project Number"
            titleWidth="120px"
            filterWidth="240px"
          >
            <ProjectSelect
              value={searchFilters.projectId}
              onChange={handlers.projectId}
              width="100%"
            />
          </SearchHeaderColumn>
        </SelectsWrapperS>
        <ButtonsWrapper>
          <SearchHeaderColumnS filterWidth="250px">
            <ButtonsContainerS>
              <DetailedClientSurveysReportPDFBtn
                disabled={isDisabledExport}
                loader={loading}
                sort={sort}
                filter={searchFilters}
              />
              <ToolBarBtnDivider />
              <DetailedClientSurveysReportXLSBtn
                disabled={isDisabledExport}
                filter={searchFilters}
                loader={loading}
                sort={sort}
              />
            </ButtonsContainerS>
          </SearchHeaderColumnS>
          <SearchHeaderColumn filterWidth="190px">
            <SearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={handleReset}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              onClick={onSearch}
              loader={loading}
            >
              Search
            </SearchHeaderButton>
          </SearchHeaderColumn>
        </ButtonsWrapper>
      </SearchHeaderWrapperS>
    </SearchPageHeaderContainer>
  );
}

DetailedClientSurveysReportPageHeader.propTypes = {
  data: shape({}),
  sort: shape({}),
  loading: bool,
  setQueryFilters: func.isRequired,
};
