import { MIME_TYPES } from '@poly/constants';
import * as R from 'ramda';

// getFileUploadErrorMessageByResult :: AttachFilesResult -> String
export const getFileUploadErrorMessageByResult = R.compose(
  R.ifElse(
    R.isEmpty,
    R.always(''),
    R.compose(
      R.concat('Failed to upload some files: '),
      R.join('; '),
      R.uniq,
      R.map(R.prop('error')),
    ),
  ),
  R.pathOr([], ['data', 'attachFilesToDocument', 'errors']),
);

// isEmailFile :: File -> Boolean
export const isEmailFile = R.pathSatisfies(R.complement(R.isNil), [
  'emailParams',
  'to',
]);

// isImageFileType ::  File -> Boolean
// eslint-disable-next-line import/no-unused-modules
export const isImageFileType = R.compose(
  R.includes(R.__, [
    MIME_TYPES.IMAGE_JPEG,
    MIME_TYPES.IMAGE_JPG,
    MIME_TYPES.IMAGE_PNG,
  ]),
  R.prop('fileType'),
);
