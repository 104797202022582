import * as R from 'ramda';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { pathOrNothingUI, formatDateOrNothingUI } from '@poly/client-utils';
import { JournalPaymentStatus } from '@poly/constants';

// getVoidSupplierPaymentRowConfig :: SupplierPayment -> [ExcelExportDataCell]
const getVoidSupplierPaymentRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 5),
    ...R.repeat(ExcelExportCellType.dollarCurrency, 1),
  ]),
  R.juxt([
    R.compose(formatDateOrNothingUI, R.prop('date')),
    R.ifElse(
      R.prop('is_split'),
      pathOrNothingUI(['masterSupplier', 'company', 'name']),
      pathOrNothingUI(['supplier', 'company', 'name']),
    ),
    pathOrNothingUI(['supplier', 'tax', 'id']),
    pathOrNothingUI(['account', 'name']),
    R.ifElse(
      R.propEq(JournalPaymentStatus.RECONCILED, 'payment_status'),
      R.always('Reconciled'),
      pathOrNothingUI(['check_number']),
    ),
    R.prop('amount'),
  ]),
);

export const getVoidSupplierPaymentsXLSConfig = ({
  batchPayments,
  exportFileName,
}) => ({
  exportFileName: `${exportFileName}.xlsx`,
  columnWidths: [15, 30, 15, 30, 20, 20],
  rows: [
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 6),
      [
        'Date',
        'Supplier Name',
        'Supplier Tax ID #',
        'Bank',
        'Check Number',
        'Amount',
      ],
    ),
    ...R.map(getVoidSupplierPaymentRowConfig, batchPayments),
  ],
});
