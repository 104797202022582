import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';

import { StaffReportTable } from './StaffReportTable.js';
import { StaffReportHeader } from './StaffReportHeader.js';
import { NoDataToReport } from '../../components/NoDataToReport.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { ReportPageTableWrapper } from '../../components/ReportPageTableWrapper.js';
import { useUserGroupListQuery } from '../UserGroupManagement/useUserGroupListQuery.js';

const StaffReportPageTableWrapper = styled(ReportPageTableWrapper)`
  & > div {
    justify-content: center;
  }
`;

const staffReportQuery = gql`
  query STAFF_REPORT($input: StaffReportInput!) {
    staffReport(input: $input) {
      user {
        _id
        profile {
          fullName
        }
      }
      activeChildrenProjects
      activeProjects
      activeRecurringProjects
      completedReactiveProjects
      completedRecurringProjects
      completedPreventativeProjects
      completedCapExProjects
      closedProjects
      completedProjectTasks
      createdProjects
      createdSuppliers
      postedUpdates
      createdMasterProjects
    }
  }
`;

// getDefaultUserGroupIds :: [UserGroup] -> [ID]
const getDefaultUserGroupIds = R.compose(
  R.map(R.prop('_id')),
  R.filter(
    R.either(
      R.path(['config', 'isDefaultCSR']),
      R.path(['config', 'isDefaultManagement']),
    ),
  ),
);

export function StaffReportPage() {
  const [query, setQuery] = useState(null);
  const { userGroupList } = useUserGroupListQuery();

  const defaultUserGroupIds = useMemo(
    () => getDefaultUserGroupIds(userGroupList),
    [userGroupList],
  );

  const { loading, data } = useQuery(staffReportQuery, {
    variables: { input: query },
    fetchPolicy: 'network-only',
    skip: !query,
  });

  const staffReport = useMemo(() => R.propOr([], 'staffReport', data), [data]);

  return (
    <PageWithSearchHeader headerHeight="170px">
      <StaffReportHeader
        {...{
          query,
          loading,
          setQuery,
          staffReport,
          defaultUserGroupIds,
        }}
      />
      <StaffReportPageTableWrapper loading={loading}>
        {R.isEmpty(staffReport) ? (
          <NoDataToReport />
        ) : (
          <StaffReportTable {...{ query, staffReport }} />
        )}
      </StaffReportPageTableWrapper>
    </PageWithSearchHeader>
  );
}
