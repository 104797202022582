import * as R from 'ramda';

import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { getRecurringJournalsTableConfig } from './RecurringJournalsTable.js';

const tableConfig = getRecurringJournalsTableConfig(true, false);

// getRecurringJournalsRowExcelConfig :: RecurringJournal -> [ExcelExportDataCell]
const getRecurringJournalsRowExcelConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 4),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
  ]),
  R.juxt(R.map(R.nth(1), tableConfig)),
);

export const getRecurringJournalsExcelConfig = (recurringJournals) => ({
  exportFileName: 'recurring_journal_entries_export.xls',
  columnWidths: R.repeat(15, 6),
  rows: [
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 4),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 2),
      ],
      R.map(R.head, tableConfig),
    ),
    ...R.map(getRecurringJournalsRowExcelConfig, recurringJournals),
  ],
});
