import React from 'react';
import { Input } from '@poly/admin-book';
import { bool, func, number } from 'prop-types';

import { FlexColumn } from '../../../../../../components/FlexContainer.js';
import { LimitLabel } from './LimitLabel.js';

const AMOUNT_LIMIT = 500;

export function PriorityAmountInput({ onChange, ...props }) {
  const onChangePriorityAmount = (amount) => {
    onChange(amount * 1);
  };

  const inputProps = {
    ...props,
    type: 'numberMask',
    required: true,
    maxValue: AMOUNT_LIMIT,
    onChange: onChangePriorityAmount,
  };

  return (
    <FlexColumn>
      <Input {...inputProps} />
      <LimitLabel>max {AMOUNT_LIMIT}</LimitLabel>
    </FlexColumn>
  );
}

PriorityAmountInput.propTypes = {
  value: number,
  onChange: func,
  isDefaultPriority: bool,
};
