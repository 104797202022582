import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon, Loader, Text } from '@poly/admin-book';
import { isNilOrEmpty } from '@poly/utils';
import { useModalContext } from '@poly/admin-ui';
import { gql, useMutation } from '@apollo/client';
import { arrayOf, func, object, string } from 'prop-types';

const BULK_EDITING_RESULT_MODAL_ID = 'bulkEditingResultModalId';

const ResultContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > svg {
    position: unset;
    margin-bottom: 10px;
  }
`;

const ResultContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
`;

const ResultContentRowWithBorder = styled(ResultContentRow)`
  border-bottom: 1px solid #bcbcbc;
`;

const bulkEditMutation = gql`
  mutation bulkEditMutation($input: BulkEditInput!) {
    bulkEdit(input: $input) {
      errorUpdateDocumentIds
      successUpdateDocumentIds
    }
  }
`;

// prepareMutationInput :: ([ID], String) -> { fieldsToEdit: [{ field: String, fieldValue: ID|String|Object }] } -> MutationInput
const prepareMutationInput = (targetDocumentIds, objectType) =>
  R.compose(
    R.when(R.propSatisfies(isNilOrEmpty, 'update'), R.always(null)),
    R.mergeLeft({ targetDocumentIds, objectType }),
    R.applySpec({
      updateReason: R.propOr('', 'updateReason'),
      update: {
        [objectType]: R.compose(
          R.mergeAll,
          R.map(({ field, fieldValue }) => ({ [field]: fieldValue })),
          // first line is only action button
          R.tail,
          R.propOr([], 'fieldsToEdit'),
        ),
      },
    }),
  );

// getMutationResult :: { bulkEdit: BulkEditResult } -> [ID]
const getErrorUpdatedIds = R.pathOr([], ['bulkEdit', 'errorUpdateDocumentIds']);

// getSuccessUpdatedIds :: { bulkEdit: BulkEditResult } -> [ID]
const getSuccessUpdatedIds = R.pathOr(
  [],
  ['bulkEdit', 'successUpdateDocumentIds'],
);

function BulkEditingResultContent({
  formData,
  objectType,
  setDocuments,
  setQueryInput,
  setDocumentsCount,
  targetDocumentIds,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [runBulkEditMutation] = useMutation(bulkEditMutation);
  const [errorUpdateCount, setErrorUpdateCount] = useState(0);
  const [successUpdateCount, setSuccessUpdateCount] = useState(0);

  useEffect(() => {
    async function performAction() {
      const input = prepareMutationInput(
        targetDocumentIds,
        objectType,
      )(formData);

      const { data: mutationData } = await runBulkEditMutation({
        variables: { input },
      });

      const errorUpdateDocumentIds = getErrorUpdatedIds(mutationData);
      const successUpdateDocumentIds = getSuccessUpdatedIds(mutationData);

      setErrorUpdateCount(errorUpdateDocumentIds.length);
      setSuccessUpdateCount(successUpdateDocumentIds.length);

      if (errorUpdateDocumentIds.length > 0 && setQueryInput) {
        setQueryInput({ query: { terms: { _id: errorUpdateDocumentIds } } });
      } else {
        setQueryInput({});
        setDocuments([]);
        setDocumentsCount(0);
      }

      setIsLoading(false);
    }

    performAction();
  }, []);

  if (isLoading) {
    return (
      <ResultContentWrapper>
        <Loader />
        <Text>{targetDocumentIds.length} of Projects will be updated</Text>
      </ResultContentWrapper>
    );
  }

  return (
    <ResultContentWrapper>
      <ResultContentRowWithBorder>
        <Icon name="successUpdateCheckMark" size={32} />
        <Text>{successUpdateCount} projects were successfully updated</Text>
      </ResultContentRowWithBorder>
      <ResultContentRow>
        <Icon name="errorUpdateCheckMark" size={32} />
        <Text>{errorUpdateCount} update failed</Text>
      </ResultContentRow>
    </ResultContentWrapper>
  );
}

BulkEditingResultContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formData: object,
  objectType: string,
  setDocuments: func,
  setQueryInput: func,
  setDocumentsCount: func,
  targetDocumentIds: arrayOf(string),
};

export function useBulkEditingResultModal() {
  const { openModal } = useModalContext();

  const showEditingResultModal = (props) => {
    openModal({
      id: BULK_EDITING_RESULT_MODAL_ID,
      minWidth: '350px',
      content: <BulkEditingResultContent {...props} />,
    });
  };

  return showEditingResultModal;
}
