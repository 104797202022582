import React, { useEffect } from 'react';
import { func, shape, oneOfType, string, instanceOf, bool } from 'prop-types';
import { DatePicker, LinkButton, HeaderLinksWrapper } from '@poly/admin-book';
import {
  CommonPrintLayout,
  useNotificationState,
  SupplierSelect,
} from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { openPrintWindowWithData } from '@poly/client-utils';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { accountsPayableEntriesPropTypes } from './prop-types.js';
import { AccountsPayableReportTable } from './AccountsPayableReportTable.js';
import { AccountsPayableReportExcelExportButton } from './AccountsPayableReportExcelExportButton.js';
import { prepareFiltersToQuery } from './accountsReportUtils.js';

const defaultDateValue = new Date().toISOString();

const accountsPayableReportFilterConfig = [
  { name: 'supplierId' },
  { name: 'date', defaultValue: defaultDateValue },
];

export function AccountsPayableReportHeader({
  setFilter,
  reportEntries,
  filter,
  loading,
}) {
  const onSearchHandler = (values) => {
    setFilter(prepareFiltersToQuery(values));
  };

  const { showErrorNotification } = useNotificationState();
  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    accountsPayableReportFilterConfig,
    onSearchHandler,
  );

  const onResetSearch = () => {
    onReset();
    setFilter({});
  };

  const onPrintPDFClick = async () => {
    if (reportEntries.length === 0) {
      showErrorNotification('No records to export');
      return;
    }

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: AccountsPayableReportTable,
      metaData: {
        title: 'Accounts Payable Report',
      },
      fileName: 'accounts_payable_report',
      data: reportEntries,
    });
  };

  useEffect(onSearch, []);

  return (
    <SearchPageHeaderContainer title="Accounts Payable Report">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Supplier"
            titleWidth="100px"
            filterWidth="260px"
          >
            <SupplierSelect
              isClearable
              withoutError
              onChange={handlers.supplierId}
              value={searchFilters.supplierId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date"
            titleWidth="100px"
            filterWidth="260px"
          >
            <DatePicker
              value={searchFilters.date}
              onChange={handlers.date}
              width="100%"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="345px">
            <FlexCenterAlign>
              <HeaderLinksWrapper>
                <LinkButton onClick={onPrintPDFClick}>
                  {PRINT_PDF_CAPTION}
                </LinkButton>
                <AccountsPayableReportExcelExportButton
                  {...{
                    reportEntries,
                    filter,
                    defaultDateValue,
                  }}
                />
              </HeaderLinksWrapper>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

AccountsPayableReportHeader.propTypes = {
  setFilter: func.isRequired,
  reportEntries: accountsPayableEntriesPropTypes.isRequired,
  filter: shape({
    date: oneOfType([string, instanceOf(Date)]),
  }),
  loading: bool,
};
