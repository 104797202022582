import * as R from 'ramda';
import { components } from 'react-select';
import { isNilOrEmpty } from '@poly/utils';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { bool, shape, oneOfType, instanceOf, string, func } from 'prop-types';

import { Select } from '../Select/Select.js';
import {
  addOptionDurationByStartDate,
  generateTimePickerOptions,
  dateValueToTimeWithRound,
  timeToDateValue,
} from './helpers.js';

// we don't want to have invalid date on time picker clear value
// so we set time to 00:00 by default on time picker clear
const defaultTimeOption = '12:00 am';

function Option(props) {
  const ref = useRef();

  const { isSelected, children, selectProps } = props;

  const timeInDate = R.path(['formData', 'timeIn'], selectProps);

  useEffect(() => {
    if (isSelected) {
      ref.current.scrollIntoView({
        block: 'center',
      });
    }
  }, [isSelected]);

  const optionProps = {
    ...props,
    innerRef: ref,
    children: addOptionDurationByStartDate(timeInDate)(children),
  };

  return <components.Option {...optionProps} />;
}

Option.propTypes = {
  isSelected: bool,
  children: string.isRequired,
  selectProps: shape({ formData: shape({ timeIn: instanceOf(Date) }) })
    .isRequired,
};

export function TimePicker({
  value,
  onChange,
  formData,
  timeOutDate,
  ...props
}) {
  const [internalValue, setInternalValue] = useState(
    dateValueToTimeWithRound(value),
  );

  useEffect(() => {
    setInternalValue(dateValueToTimeWithRound(value));
  }, [value]);

  const timeInDate = formData?.timeIn;

  const options = useMemo(
    () => generateTimePickerOptions(timeInDate, timeOutDate),
    [timeInDate, timeOutDate],
  );

  const onChangeInternal = (selectedOption) => {
    const newDate = timeToDateValue(
      isNilOrEmpty(value) ? new Date() : value,
      isNilOrEmpty(selectedOption) ? defaultTimeOption : selectedOption,
    );

    onChange(newDate);
  };

  return (
    <Select
      {...props}
      isSearchable
      options={options}
      allowModalOverflow
      formData={formData}
      value={internalValue}
      components={{ Option }}
      placeholder="Select time"
      onChange={onChangeInternal}
    />
  );
}

const valuePropType = oneOfType([
  instanceOf(Date),
  // not sure where it is coming from, but initially
  // we're getting empty string from final form's array component
  string,
]);

TimePicker.propTypes = {
  value: valuePropType,
  onChange: func.isRequired,
  timeOutDate: valuePropType,
  formData: shape({ timeIn: valuePropType }),
};
