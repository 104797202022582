import React, { useContext } from 'react';
import { bool, object } from 'prop-types';
import { Loader } from '@poly/admin-book';

import {
  WhiteBodyWrapper,
  PayInvoicesTableCard,
} from '../PaySuppliersPage/PaySuppliersComponents.js';
import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { accountsPayableEntriesPropTypes } from './prop-types.js';
import { AccountsPayableReportTable } from './AccountsPayableReportTable.js';

export function AccountsPayableReportBody({
  loading,
  reportEntries,
  tableSortProps,
}) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  return loading ? (
    <Loader />
  ) : (
    <WhiteBodyWrapper minHeight={contentHeight}>
      <PayInvoicesTableCard height="100%">
        <AccountsPayableReportTable {...tableSortProps} data={reportEntries} />
      </PayInvoicesTableCard>
    </WhiteBodyWrapper>
  );
}

AccountsPayableReportBody.propTypes = {
  loading: bool,
  reportEntries: accountsPayableEntriesPropTypes.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableSortProps: object,
};
