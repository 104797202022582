import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { number, string } from 'prop-types';

import { getThemeColor } from '../utils.js';

const CharacterCountComponent = styled.p`
  margin: 3px 0 3px 5px;
  font-size: 10px;
  color: ${getThemeColor(['secondary'])};
  align-self: ${R.propOr('flex-start', 'characterCountPosition')};
`;

export function CharacterCount({ limit, length, characterCountPosition }) {
  return (
    <CharacterCountComponent characterCountPosition={characterCountPosition}>
      {limit - length} chars left
    </CharacterCountComponent>
  );
}

CharacterCount.propTypes = {
  limit: number.isRequired,
  length: number.isRequired,
  characterCountPosition: string,
};
