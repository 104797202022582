import React from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import {
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book/src/Header/index.js';
import { useTableSorting } from '@poly/admin-ui/src/hooks/useTableSorting.js';
import { TableCardWithPaginationContainer } from '@poly/admin-ui/src/components/Table.js';

import { useRecurringJournalsQuery } from './useRecurringJournalsQuery.js';
import {
  RecurringJournalsTable,
  getRecurringJournalsTableConfig,
} from './RecurringJournalsTable.js';
import { RecurringJournalsExportButtons } from './RecurringJournalsExportButtons.js';

export function RecurringJournalsPage() {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: getRecurringJournalsTableConfig(),
    column: 1,
  });

  const { recurringJournals, loading } = useRecurringJournalsQuery(sort);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Recurring Journal Entries</MainHeader>
        <RecurringJournalsExportButtons sort={sort} />
      </PageHeaderContainer>
      <DefaultBodyWrapper height="calc(100% - 90px)">
        <TableCardWithPaginationContainer>
          {loading ? (
            <Loader />
          ) : (
            <RecurringJournalsTable
              recurringJournals={recurringJournals}
              {...tableSortingProps}
            />
          )}
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </>
  );
}
