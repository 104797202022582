import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { InputErrorMsg, Table } from '@poly/admin-book';
import { gql, useQuery } from '@apollo/client';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { MAX_ITEMS, useMapConfigToTablePropsWithSorting } from '@poly/admin-ui';
import {
  PROPERTY_ID_AIT,
  EQUALS_AIT_OPERATOR,
  FLIPPED_CONTAINS_AIT_OPERATOR,
  NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
} from '@poly/security';

import { prepareTableItems } from './helpers.js';
import { DeletePreAssignedUserGroupButton } from './DeletePreAssignedUserGroupButton.js';

const userGroupsTableQuery = gql`
  query listUserGroups(
    $clientId: ID!
    $listUserGroupsInput: ListUserGroupsInput
    $searchPropertiesInput: CollectionSearchParams!
  ) {
    listUserGroups(input: $listUserGroupsInput) {
      _id
      name
    }
    searchProperties(input: $searchPropertiesInput) {
      hits {
        _id
        name
      }
    }
    client(id: $clientId) {
      _id
      name
    }
  }
`;

const UserGroupsTable = styled(Table)`
  height: unset;
  min-height: unset;

  td:nth-child(1),
  th:nth-child(1) {
    width: 95%;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 5%;
  }
`;

const tableConfig = [
  ['User Group', R.prop('userGroupName'), R.prop('userGroupName')],
  ['', DeletePreAssignedUserGroupButton],
];

// getUserGroupIdsByValue :: [UserUserGroupInput] -> [ID]
const getUserGroupIdsByValue = R.compose(
  R.map(R.prop('userGroupId')),
  R.defaultTo([]),
);

// getPropertyIdsByValue :: [UserUserGroupInput] -> [ID]
const getPropertyIdsByValue = R.compose(
  R.uniq,
  R.reject(R.isNil),
  R.flatten,
  R.map(
    R.juxt([
      R.prop(EQUALS_AIT_OPERATOR),
      R.prop(FLIPPED_CONTAINS_AIT_OPERATOR),
      R.prop(NOT_FLIPPED_CONTAINS_AIT_OPERATOR),
    ]),
  ),
  R.map(R.path(['value', PROPERTY_ID_AIT])),
  R.flatten,
  R.map(R.propOr([], 'variables')),
  R.defaultTo([]),
);

// getClientByQueryData :: { client: Client } -> Client
const getClientByQueryData = R.prop('client');

// getPropertiesByQueryData :: { searchProperties: { hits: [Property] } } -> [Property]
const getPropertiesByQueryData = R.pathOr([], ['searchProperties', 'hits']);

// getUserGroupsByQueryData :: [ID] -> { listUserGroups: [UserGroup] } -> [UserGroup]
const getUserGroupsByQueryData = (userGroupIds) =>
  R.compose(
    R.filter(R.propSatisfies(R.includes(R.__, userGroupIds), '_id')),
    R.propOr([], 'listUserGroups'),
  );

export function UserGroupsTableComponent({
  value,
  error,
  hasError,
  formData,
  onChange,
  changeFieldValue,
}) {
  const userGroupIds = getUserGroupIdsByValue(value);
  const propertyIds = getPropertyIdsByValue(value);
  const { clientId } = formData || {};

  const { data } = useQuery(userGroupsTableQuery, {
    variables: {
      clientId,
      searchPropertiesInput: {
        size: MAX_ITEMS,
        query: { terms: { _id: propertyIds } },
      },
    },
    skip: !value,
  });

  const client = getClientByQueryData(data);
  const properties = getPropertiesByQueryData(data);
  const userGroups = getUserGroupsByQueryData(userGroupIds)(data);

  const onDeletePreAssignedUserGroup = (index) => {
    onChange(R.remove(index, 1, value));
  };

  const tableProps = useMapConfigToTablePropsWithSorting({
    tableConfig,
    initialSorting: { columnKey: 0, dir: 1 },
    items: R.map(
      R.mergeLeft({ changeFieldValue, onDeletePreAssignedUserGroup }),
      prepareTableItems(client, properties, userGroups)(value),
    ),
  });

  if (hasError && !!error) {
    return <InputErrorMsg>{error}</InputErrorMsg>;
  }

  if (R.isEmpty(value)) {
    return null;
  }

  return (
    <UserGroupsTable
      isSortable
      rows={tableProps.rows}
      columns={tableProps.columns}
      headers={tableProps.headers}
      sorting={tableProps.sorting}
      sortQueries={tableProps.sortQueries}
      onHeaderCellClick={tableProps.onHeaderCellClick}
    />
  );
}

UserGroupsTableComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: arrayOf(object),
  error: string,
  // eslint-disable-next-line react/forbid-prop-types
  formData: object,
  onChange: func,
  hasError: bool,
  changeFieldValue: func,
};
