import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useReactiveQuery } from '@poly/client-utils/src/hooks/useReactiveQuery.js';
import { usePaginationParams } from '@poly/admin-ui/src/hooks/usePaginationParams.js';
import { useSetItemsCount } from '@poly/admin-ui/src/hooks/useSetItemsCount.js';

export const ASSET_TYPES_QUERY = gql`
  query ASSET_TYPES_QUERY($input: AssetTypesSearchInput!) {
    assetTypes(input: $input) {
      hits {
        _id
        name
        lifeExpectancy
        replacementCost
      }
      total
    }
  }
`;

const ASSET_TYPES_CHANGED_SUBSCRIPTION = gql`
  subscription ASSET_TYPES_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAssetTypeChanged(input: $input) {
      id
      type
    }
  }
`;

export const useAssetTypesQuery = (sort) => {
  const searchText = useSelector((state) => state.searchText);

  const paginationParams = usePaginationParams();

  const { data, loading } = useReactiveQuery(
    ASSET_TYPES_QUERY,
    ASSET_TYPES_CHANGED_SUBSCRIPTION,
    {
      queryOptions: {
        variables: { input: { searchText, sort, ...paginationParams } },
      },
      subscriptionOptions: {},
    },
  );

  useSetItemsCount(R.pathOr(0, ['assetTypes', 'total']), data);

  return {
    data,
    loading,
  };
};
