import { BulkEditObjectType } from '@poly/constants';

export const BulkEditingSidebarId = 'BulkEditingSidebarId';
export const BulkEditingFormId = 'BulkEditingFormId';

export const BulkEditingFilterSidebarId = 'BulkEditingFilterSidebarId';
export const BulkEditingFilterFormId = 'BulkEditingFilterFormId';

export const FieldOperation = {
  EQ: 'eq',
  IN: 'in',
  NIN: 'nin',
  LT: 'lt',
  GT: 'gt',
};

export const BulkEditProjectFieldValues = {
  STATUS: 'status',
  ACCOUNTING_STATUS: 'accountingStatus',
  PROJECT_TYPE: 'projectType',
  COST_TYPE: 'type',
  CLIENT_NAME: 'clientId',
  SCR_MANAGER: 'managerId',
  PROPERTY_NAME: 'propertyId',
  SERVICE_TYPE: 'serviceTypeId',
  KEYWORD: 'keyword',
  STATE: 'property.address.address_parts.administrative_area_level_1',
  CITY: 'property.address.address_parts.locality',
  START: 'startDate',
  END: 'endDate',
};

export const FieldSelectOptionsByObjectTypeMap = {
  [BulkEditObjectType.PROJECTS]: [
    {
      label: 'Status',
      value: BulkEditProjectFieldValues.STATUS,
    },
    {
      label: 'Accounting',
      value: BulkEditProjectFieldValues.ACCOUNTING_STATUS,
    },
    {
      label: 'Project Type',
      value: BulkEditProjectFieldValues.PROJECT_TYPE,
    },
    {
      label: 'Cost Type',
      value: BulkEditProjectFieldValues.COST_TYPE,
    },
    {
      label: 'Client Name',
      value: BulkEditProjectFieldValues.CLIENT_NAME,
    },
    {
      label: 'CSR Manager',
      value: BulkEditProjectFieldValues.SCR_MANAGER,
    },
    {
      label: 'Property Name',
      value: BulkEditProjectFieldValues.PROPERTY_NAME,
    },
    {
      label: 'Service Type',
      value: BulkEditProjectFieldValues.SERVICE_TYPE,
    },
    {
      label: 'Keyword',
      value: BulkEditProjectFieldValues.KEYWORD,
    },
    {
      label: 'State',
      value: BulkEditProjectFieldValues.STATE,
    },
    {
      label: 'City',
      value: BulkEditProjectFieldValues.CITY,
    },
    {
      label: 'Start',
      value: BulkEditProjectFieldValues.START,
    },
    {
      label: 'End',
      value: BulkEditProjectFieldValues.END,
    },
  ],
};

export const BulkEditProjectFieldToEditValues = {
  SERVICE_TYPE: 'serviceTypeId',
  STATUS: 'status',
  PROBLEM_CODE: 'problemCodeId',
  SPEND_TYPE: 'spendType',
  DESCRIPTION: 'description',
  ASSIGNED_CSR: 'managerId',
  CLIENT_NOT_TO_EXCEED: 'nte',
  INVOICE_DESCRIPTION: 'invoiceDescription',
  EXEMPT_FROM_SALES_TAX: 'exemptSalesTax',
  AFTER_HOURS_CALL: 'isAfterHoursCall',
};

export const FieldToEditSelectOptionsByObjectTypeMap = {
  [BulkEditObjectType.PROJECTS]: [
    {
      label: 'Service Type',
      value: BulkEditProjectFieldToEditValues.SERVICE_TYPE,
    },
    {
      label: 'Status',
      value: BulkEditProjectFieldToEditValues.STATUS,
    },
    {
      label: 'Problem Code',
      value: BulkEditProjectFieldToEditValues.PROBLEM_CODE,
    },
    {
      label: 'Spend Type',
      value: BulkEditProjectFieldToEditValues.SPEND_TYPE,
    },
    {
      label: 'Description',
      value: BulkEditProjectFieldToEditValues.DESCRIPTION,
    },
    {
      label: 'Assigned CSR',
      value: BulkEditProjectFieldToEditValues.ASSIGNED_CSR,
    },
    {
      label: 'Client Not to Exceed',
      value: BulkEditProjectFieldToEditValues.CLIENT_NOT_TO_EXCEED,
    },
    {
      label: 'Invoice Description',
      value: BulkEditProjectFieldToEditValues.INVOICE_DESCRIPTION,
    },
    {
      label: 'Exempt from Sales Tax',
      value: BulkEditProjectFieldToEditValues.EXEMPT_FROM_SALES_TAX,
    },
    {
      label: 'After Hours Call',
      value: BulkEditProjectFieldToEditValues.AFTER_HOURS_CALL,
    },
  ],
};

export const ProjectTypeSelectOptionValues = {
  PROJECT: 'projectProjectTypeSelectOptionValue',
  CHILD_RECURRING: 'childRecurringProjectTypeSelectOptionValue',
  MASTER_RECURRING: 'masterRecurringProjectTypeSelectOptionValue',
  CHILD_PM: 'childPMProjectTypeSelectOptionValue',
  PM: 'pmProjectTypeSelectOptionValue',
};
