import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useLocation, useRouterQuery } from '@poly/client-routing';
import { getThemeColor, Icon } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';
import { REVIEW_PROJECT_ESTIMATE_ROUTE } from '@poly/constants';

import { ESFMFullLogo } from '../../components/Logo.js';
import { VENDOR_INFO_TYPES } from '../../constants/vendor.js';
import { VendorInfoSection } from '../../components/VendorInfoSection.js';

const mapVendorTypeToPageInfo = {
  [VENDOR_INFO_TYPES.TOKEN_EXPIRED]: {
    title: 'Oops!',
    icon: 'warning',
    content:
      'It looks like the link you have clicked is expired. Please contact our support center for additional information.',
  },
  [VENDOR_INFO_TYPES.INVOICE_SUBMITTED]: {
    title: 'Hello!',
    icon: 'checkMark',
    content:
      "Thank you for your efforts, but it's already done. We have already received this invoice.",
  },
  [VENDOR_INFO_TYPES.DOCUMENTS_RECEIVED]: {
    title: 'Hello!',
    icon: 'checkMark',
    content:
      "Thank you for your efforts, but it's already done. We have already received your documents.",
  },

  [VENDOR_INFO_TYPES.DOCUMENTS_SUBMITTED]: {
    title: 'Hello!',
    icon: 'checkMark',
    content:
      'Thank you for submitting this information. We will review it and reach out if we have any questions or concerns.',
  },

  [VENDOR_INFO_TYPES.PROJECT_ESTIMATE_SUBMITTED]: {
    title: 'Thank you for reviewing the estimate!',
    icon: 'checkMark',
    content:
      'We’ll reach out if we have any questions or need further clarification.',
    contentColor: '#999999',
  },
};

const VendorInfoPageWrapperS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${getThemeColor(['light'])};
  font-family: Lato, sans-serif;
  line-height: normal;
  letter-spacing: normal;
`;

const VendorInfoCardS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 650px;
  height: auto;
  padding: 32px 25px;
  background-color: ${getThemeColor(['white'])};
`;

const VendorContentWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0 16px 0;
`;

// getIconColor :: { icon: String } -> String
const getIconColor = R.ifElse(
  propEqLegacy('icon', 'warning'),
  getThemeColor(['amber']),
  getThemeColor(['inspiniaBlueLighter']),
);

const VendorIconWrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: ${getIconColor};
`;

const VendorInfoContentS = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px;
`;

const VendorInfoTextS = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${R.propOr('#000', 'contentColor')};
`;

const VendorInfoTitleS = styled(VendorInfoTextS)`
  font-size: 22px;
  padding-bottom: 16px;
`;

// getIconProps :: String -> Object
const getIconProps = (icon) =>
  icon === 'warning'
    ? {
        size: 45,
        style: { marginLeft: '-3px' },
      }
    : { size: 28 };

// checkIsValidType :: String -> Boolean
const checkIsValidType = (type) =>
  R.compose(R.includes(type), R.values)(VENDOR_INFO_TYPES);

// prepareTypeAsAlwaysExists :: String -> String
const prepareTypeAsAlwaysExists = R.unless(
  checkIsValidType,
  R.always(VENDOR_INFO_TYPES.TOKEN_EXPIRED),
);

// getPathnameByRoute :: String -> String
const getPathnameByRoute = R.compose(
  R.replace('/:randomId', ''),
  R.defaultTo(''),
  R.head,
  R.tail,
  R.split('/'),
);

// withInsuranceLink :: (String, {state: {from: String}}) -> Boolean
const withInsuranceLink = (type, location) =>
  R.and(
    checkIsValidType(type),
    R.compose(
      R.complement(
        R.includes(
          getPathnameByRoute(R.pathOr('', ['state', 'from'], location)),
        ),
      ),
      R.map(getPathnameByRoute),
    )([REVIEW_PROJECT_ESTIMATE_ROUTE]),
  );

export function VendorInfoPage() {
  const { type } = useRouterQuery(['type']);

  const location = useLocation();

  const alwaysExistsType = prepareTypeAsAlwaysExists(type);

  const { title, content, icon, contentColor } =
    mapVendorTypeToPageInfo[alwaysExistsType];

  const isWithInsuranceLink = withInsuranceLink(type, location);

  return (
    <VendorInfoPageWrapperS>
      <VendorInfoCardS>
        <ESFMFullLogo />
        <VendorContentWrapperS>
          <VendorIconWrapperS icon={icon}>
            <Icon name={icon} color="white" {...getIconProps(icon)} />
          </VendorIconWrapperS>
          <VendorInfoContentS>
            <VendorInfoTitleS>{title}</VendorInfoTitleS>
            <VendorInfoTextS contentColor={contentColor}>
              {content}
            </VendorInfoTextS>
          </VendorInfoContentS>
        </VendorContentWrapperS>
        <VendorInfoSection withInsuranceLink={isWithInsuranceLink} />
      </VendorInfoCardS>
    </VendorInfoPageWrapperS>
  );
}

VendorInfoPage.displayName = 'VendorInfoPage';
