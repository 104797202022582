import React, { useEffect } from 'react';
import { string, element, shape, func } from 'prop-types';
import { Loader } from '@poly/admin-book';
import { SidebarSubTabs } from '@poly/admin-ui';

import { projectStatusesUI } from '../../../modules/core/constants/projects.js';
import { CommonProjectsTabTable } from '../commonSidebarComponents.js';
import { projectsSubTabsConfig } from '../../../modules/tabs/common.js';
import { useSearchSubTabsProjectsQuery } from './useSearchSubTabsProjectsQuery.js';
import { TabTitleBase } from '../../../modules/tabs/projectsTab/ProjectsTabTitle.js';

function CommonProjectsTabTitle({ status, ...restProps }) {
  const { count } = useSearchSubTabsProjectsQuery({ status, ...restProps });

  return (
    <TabTitleBase
      status={status}
      count={count}
      text={projectStatusesUI[status]}
    />
  );
}

CommonProjectsTabTitle.propTypes = { status: string.isRequired };

function CommonProjectsTab({ setCurrentTab, setFoundProjects, ...props }) {
  const { status } = props;

  const { loading, projects, tableProps } =
    useSearchSubTabsProjectsQuery(props);

  useEffect(() => {
    if (setCurrentTab) {
      setCurrentTab(status);
    }
  }, [status]);

  useEffect(() => {
    if (setFoundProjects) {
      setFoundProjects(projects);
    }
  }, [projects?.length]);

  if (loading) return <Loader />;

  return <CommonProjectsTabTable {...tableProps} />;
}

CommonProjectsTab.propTypes = {
  status: string,
  setCurrentTab: func,
  setFoundProjects: func,
};

export function CommonProjectsSubTabs({
  entity,
  toolBar,
  searchTerm,
  setCurrentTab,
  setFoundProjects,
}) {
  return (
    <SidebarSubTabs
      tabs={projectsSubTabsConfig.map(([status, title]) => [
        <CommonProjectsTabTitle {...{ status, searchTerm, entity }} />,
        title,
        <CommonProjectsTab
          {...{ status, searchTerm, entity, setCurrentTab, setFoundProjects }}
        />,
      ])}
      defaultValue="all"
      toolBar={toolBar}
    />
  );
}

CommonProjectsSubTabs.propTypes = {
  toolBar: element,
  searchTerm: string,
  setCurrentTab: func,
  setFoundProjects: func,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }),
};
