import { string } from 'prop-types';
import React, { useState, useEffect } from 'react';

const VERSION_FILE_URL = '/version.json';
const CHECK_FOR_UPDATE_INTERVAL = 60e3; // check every 60 seconds

const fetchCurrentAppVersion = async () => {
  const resp = await fetch(VERSION_FILE_URL, { cache: 'no-cache' });
  const { version } = await resp.json();
  return version;
};

const reloadApplicationPage = async () => {
  window.location.reload();
};

export function useOldVersionDetector(notifyUserAboutNewVersion) {
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const onCheckForNewVersion = async () => {
      try {
        const latestVersion = await fetchCurrentAppVersion();

        if (latestVersion !== process.env.APP_VERSION) {
          notifyUserAboutNewVersion(latestVersion, reloadApplicationPage);

          // stop checking for new version
          clearInterval(intervalId);
          setIntervalId(null);
          return false;
        }

        // eslint-disable-next-line no-console
        console.log(`Version is up to date: ${latestVersion}`);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to check lasted app version', err);
      }
      return true;
    };

    // check immediately first time
    onCheckForNewVersion().then((needToCheckPeriodically) => {
      if (needToCheckPeriodically) {
        const newIntervalId = setInterval(
          onCheckForNewVersion,
          CHECK_FOR_UPDATE_INTERVAL,
        );

        setIntervalId(newIntervalId);
      }
    });

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return reloadApplicationPage;
}

// eslint-disable-next-line import/no-unused-modules
export function OldVersionDetectorMessage({ version, className }) {
  return (
    <div className={className}>
      A new version (v. {version}) of your app is available. Your current
      version may not work properly. Hit reload page to apply updates.
    </div>
  );
}

OldVersionDetectorMessage.propTypes = {
  className: string,
  version: string.isRequired,
};
