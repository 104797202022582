import React, { useState } from 'react';
import { bool, object, shape, string } from 'prop-types';
import styled from 'styled-components';
import { PatternFormat } from 'react-number-format';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  height: 39px;
  padding: 10px 15px;
  font-size: 14px;
  line-height: normal;
  color: #78828c;
  font-family: 'TTNormsMedium', sans-serif;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #fff;

  ${({ lower }) => lower && 'text-transform: lowercase;'};
  ${({ isPasswordInput }) => isPasswordInput && 'padding-right: 60px;'}

  &::placeholder {
    color: #999999;
    text-transform: none;
  }
`;

export function InputSection({ inputProps }) {
  return (
    <InputWrapper>
      <Input {...inputProps} />
    </InputWrapper>
  );
}

function CodeInput(props) {
  return (
    <InputSection
      title="code"
      inputProps={{
        ...props,
        name: 'code',
        placeholder: 'Two-Factor Authentication Code',
        autoFocus: true,
        required: true,
      }}
    />
  );
}

export function TwoFACodeInput() {
  return <PatternFormat format="######" name="code" customInput={CodeInput} />;
}

/* eslint-disable react/forbid-prop-types */
InputSection.propTypes = {
  inputProps: object.isRequired,
};

const SectionContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TogglePasswordButton = styled.div`
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 10px;
  cursor: pointer;
  font-size: 10px;
  line-height: 12px;
  color: #bcbcbc;
`;

export function PasswordInputSection({ title, inputProps }) {
  const [showPassword, setShowPassword] = useState(false);

  const newInputProps = {
    ...inputProps,
    type: showPassword ? 'text' : 'password',
    isPasswordInput: true,
  };

  const onTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <SectionContainer>
      <InputSection title={title} inputProps={newInputProps} />
      <TogglePasswordButton onClick={onTogglePassword}>
        {showPassword ? 'HIDE' : 'SHOW'}
      </TogglePasswordButton>
    </SectionContainer>
  );
}

PasswordInputSection.propTypes = {
  title: string,
  inputProps: shape({ name: string, placeholder: string, required: bool }),
};
