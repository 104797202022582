import React from 'react';
import * as R from 'ramda';
import { ProjectType } from '@poly/constants';
import { SidebarTabs } from '@poly/admin-ui';

import { ProjectSidebarBidRulesTab } from './tabs/ProjectSidebarBidRulesTab.js';
import { ProjectSidebarFinancialTab } from './tabs/ProjectSidebarFinancialTab.js';

const projectFinancialTabs = {
  projectBidRules: 'project-bid-rules',
  projectFinancial: 'project-financial',
};

// checkIfProjectBidType :: { project: Project } -> Boolean
const checkIfProjectBidType = R.pathEq(ProjectType.BID, ['project', 'type']);

export function ProjectSidebarFinancial(props) {
  const isBidProject = checkIfProjectBidType(props);

  return isBidProject ? (
    <SidebarTabs
      omitSidebarByTabs={['sidebarTab']}
      customTabQueryKey="projectFinancialTab"
      defaultValue={projectFinancialTabs.projectFinancial}
      tabs={[
        [
          'Financial',
          projectFinancialTabs.projectFinancial,
          <ProjectSidebarFinancialTab {...props} withoutLabel />,
        ],
        [
          'Bid Rules',
          projectFinancialTabs.projectBidRules,
          <ProjectSidebarBidRulesTab {...props} />,
        ],
      ]}
    />
  ) : (
    <ProjectSidebarFinancialTab {...props} />
  );
}
