import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Table } from '@poly/admin-book';
import { number, shape } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';

import { bulkEditDocumentsPropType } from './prop-types.js';
import { bulkEditProjectTableConfig } from './table-config/bulkEditProjectTableConfig.js';

const BulkEditingTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 20px;

    > div {
      margin-top: 5px;
    }
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 100px;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 150px;
  }

  th:nth-child(7),
  td:nth-child(7) {
    width: 100px;
  }

  th:nth-child(8),
  td:nth-child(8) {
    width: 100px;
  }

  th:nth-child(9),
  td:nth-child(9) {
    width: 100px;
  }
`;

export function BulkEditingTable({ documents, pagination, ...restTableProps }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    bulkEditProjectTableConfig,
    documents,
  );

  return (
    <BulkEditingTableS
      pagination={pagination}
      {...restTableProps}
      {...tableProps}
    />
  );
}

BulkEditingTable.propTypes = {
  documents: bulkEditDocumentsPropType,
  pagination: shape({
    allItemsCount: number,
    itemsPerPage: number,
    currentPage: number,
  }),
};
