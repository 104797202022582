import {
  HTTP_FORBIDDEN_STATUS,
  HTTP_UNAUTHORIZED_STATUS,
} from './httpStatuses.js';

export const TechnicianSkills = {
  generalMaintenance: 'generalMaintenance',
  carpentry: 'carpentry',
  plumbing: 'plumbing',
  hvac: 'hvac',
  movesAddsChanges: 'movesAddsChanges',
  cosmeticMaintenance: 'cosmeticMaintenance',
  preventiveMaintenance: 'preventiveMaintenance',
  escortVendors: 'escortVendors',
};

export const UserStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const UserEmployeeInfoStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  LEAVE: 'leave',
};

export const StaffReportMetricType = {
  ACTIVE_RECURRING_PROJECTS: 'activeRecurringProjects',
  ACTIVE_CHILDREN_PROJECTS: 'activeChildrenProjects',
  ACTIVE_PROJECTS: 'activeProjects',
  COMPLETED_REACTIVE_PROJECTS: 'completedReactiveProjects',
  COMPLETED_RECURRING_PROJECTS: 'completedRecurringProjects',
  COMPLETED_PREVENTATIVE_PROJECTS: 'completedPreventativeProjects',
  COMPLETED_CAP_EX_PROJECTS: 'completedCapExProjects',
  CLOSED_PROJECTS: 'closedProjects',
  CREATED_PROJECTS: 'createdProjects',
  CREATED_MASTER_PROJECTS: 'createdMasterProjects',
  COMPLETED_PROJECT_TASKS: 'completedProjectTasks',
  POSTED_UPDATES: 'postedUpdates',
  CREATED_SUPPLIERS: 'createdSuppliers',
};

export const TWO_FA_CODE_INCORRECT_ERROR = {
  code: HTTP_FORBIDDEN_STATUS,
  message: 'Incorrect 2FA code',
};

export const USE_PASSWORD_LOGIN_ERROR = {
  code: HTTP_FORBIDDEN_STATUS,
  message: 'Use password login form',
};

export const TOKEN_EXPIRED_ERROR = {
  code: HTTP_UNAUTHORIZED_STATUS,
  message: 'Token expired',
};

export const UserRelatedEntities = {
  tasksCount: 'Tasks',
  projectRequesterCount: 'Requesters',
  mentionGroupsCount: 'Mention Groups',
  clientManagerCount: 'Client Managers',
  projectSiteContactCount: 'Site Contacts',
  supplierManagerCount: 'Supplier Managers',
  propertyBranchManagerCount: 'Branch Managers',
  projectAssignedCSRCount: 'Project Assigned CSR',
  propertyAssignedCSRCount: 'Property Assigned CSR',
  propertyDistrictManagerCount: 'District Facility Managers',
};
