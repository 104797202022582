import React from 'react';
import * as R from 'ramda';
import { boolToYesNo } from '@poly/client-utils';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { EXPORT_XLS_CAPTION } from '@poly/constants/src/print.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { NOTHING_UI_STRING, ProjectSpendTypesUI } from '@poly/constants';
import { arrayOf, object, oneOfType, shape, string } from 'prop-types';

import { getClientRefNumberUI } from '../../components/ProjectPrintToPDF/parts/PrintProjectProjectDetails.js';
import { WorkInProgressExportXLSBtnS } from '../WorkInProgressReport/WorkInProgressExportXLSBtn.js';
import { useMondelezProjectRMStatusReportLazyQuery } from './useMondelezProjectRMStatusReportQuery.js';

// getMondelezProjectRMStatusRowConfig :: [Project] -> [ExcelExportDataCell]
const getMondelezProjectRMStatusRowConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 4)),
  R.juxt([
    R.propOr('', 'projectId'),
    R.compose(R.propOr('', R.__, ProjectSpendTypesUI), R.prop('spendType')),
    R.compose(
      R.when(R.equals(NOTHING_UI_STRING), R.always('')),
      getClientRefNumberUI,
    ),
    R.compose(boolToYesNo, R.propOr(false, 'notRM')),
  ]),
);

export const getMondelezProjectRMStatusXlsExportConfig = (data) => ({
  exportFileName: 'mondelez_project_rm_status.xlsx',
  columnWidths: [15, 20, 35, 10],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: 'Mondelez Project R&M status',
      },
    ],
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 4),
      ['WO #', 'WO Type', 'Client Ref # / PO', 'Not R&M'],
    ),
    ...R.map(
      getMondelezProjectRMStatusRowConfig,
      R.pathOr([], ['searchProjects', 'hits'], data),
    ),
  ],
});

export function MondelezProjectRMStatusXLSBtn({ filter, sort, ...props }) {
  const queryHandler = useMondelezProjectRMStatusReportLazyQuery();

  const onExportClick = async () => {
    const { data } = await queryHandler({ filter, sort });

    const excelConfig = getMondelezProjectRMStatusXlsExportConfig(data);

    performExcelExport(excelConfig);
  };

  return (
    <WorkInProgressExportXLSBtnS {...props} onClick={onExportClick}>
      {EXPORT_XLS_CAPTION}
    </WorkInProgressExportXLSBtnS>
  );
}

MondelezProjectRMStatusXLSBtn.propTypes = {
  filter: shape({}),
  sort: arrayOf(oneOfType([string, object])),
};
