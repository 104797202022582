import React from 'react';
import * as R from 'ramda';
import { useModalContext } from '@poly/admin-ui';
import { IconButton } from '@poly/admin-book';
import { capitalizeFirstLetter, sqlSortQuery } from '@poly/client-utils';
import { shape, string } from 'prop-types';

import { editAccountTypeFormId } from '../constants.js';
import { AccountTypeForm } from '../forms/AccountTypeForm.js';

function EditButton({ accountType }) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: editAccountTypeFormId,
      title: 'Edit Account Type',
      formId: editAccountTypeFormId,
      btnCaption: 'Save',
      content: (
        <AccountTypeForm isNewAccountType={false} document={accountType} />
      ),
    });

  return !accountType.system_type ? (
    <IconButton size={15} name="edit" onClick={onClick} />
  ) : null;
}

EditButton.propTypes = {
  accountType: shape({ system_type: string }),
};

export const getAccountTypesTableConfig = (isPrint = false) => [
  ['Name', R.propOr('', 'name'), sqlSortQuery('name')],
  ['Category', R.prop('categoryUI'), sqlSortQuery('category')],
  ['Normal Balance', R.prop('normalBalanceUI'), sqlSortQuery('normal_balance')],
  [
    'Status',
    R.compose(capitalizeFirstLetter, R.propOr('', 'status')),
    sqlSortQuery('status'),
  ],
  ...(isPrint ? [] : [['', EditButton]]),
];
