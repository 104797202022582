import * as R from 'ramda';
import React from 'react';
import { bool, shape } from 'prop-types';

import { Table } from '@poly/admin-book/src/Table/Table.js';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { getMondelezProjectRMStatusReportTableConfig } from './table-config.js';

export function MondelezProjectRMStatusPageTable({
  data,
  isPrintPDF,
  pagination,
  ...restTableProps
}) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    getMondelezProjectRMStatusReportTableConfig(isPrintPDF),
    R.pathOr([], ['searchProjects', 'hits'])(data),
  );

  return <Table {...restTableProps} {...tableProps} />;
}

MondelezProjectRMStatusPageTable.propTypes = {
  data: shape({}),
  pagination: shape({}),
  isPrintPDF: bool,
};
