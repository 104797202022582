import * as R from 'ramda';

import { wrapAsArrayIfSingleItem } from './useMultipleSubscriptions.js';

// isQueryOrSubscriptionsSkip :: Input -> Boolean
// InputOptions = { skip: Boolean }
// Input = {
//    queryOptions: InputOptions
//    subscriptionOptions: InputOptions | [InputOptions]
// }
export const isQueryOrSubscriptionsSkip = R.either(
  R.pathOr(false, ['queryOptions', 'skip']),
  R.compose(
    R.all(R.equals(true)),
    R.map(R.propOr(false, 'skip')),
    wrapAsArrayIfSingleItem,
    R.prop('subscriptionOptions'),
  ),
);
