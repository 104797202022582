import * as R from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';
import { object } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { assocBy, isNilOrEmpty } from '@poly/utils';
import { FormFieldLabel, getThemeColor } from '@poly/admin-book';
import {
  MAX_ITEMS,
  FlexColumn,
  FlexContainer,
  SearchInputComp,
} from '@poly/admin-ui';
import {
  filterDocumentsMatchedByPaths,
  highlightMatchesInObjectCurried,
} from '@poly/client-utils';

import { FlexRow } from '../../../../components/appBar/HistoryPopUp/historyComponents.js';
import { SelectPropertiesTable } from './SelectPropertiesTable.js';

const FlexRowS = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
`;

const NoteContainer = styled.span`
  font-size: 11px;
  color: ${getThemeColor(['secondary'])};
`;

const PropertiesListContainer = styled(FlexContainer)`
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  height: 200px;
`;

const clientPropertiesQuery = gql`
  query clientPropertiesQuery(
    $clientId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    client(id: $clientId) {
      _id
      searchProperties(input: $searchInput) {
        hits {
          _id
          name
          isMaster
          masterProperty {
            _id
            name
            isMaster
            address {
              formatted_address
            }
          }
          address {
            formatted_address
          }
        }
        total
      }
    }
  }
`;

const searchTermPaths = [['name'], ['address', 'formatted_address']];

// prepareProperties :: String -> { client: Client } -> [Property]
const prepareProperties = (searchTerm) =>
  R.compose(
    R.map(
      R.compose(
        assocBy(
          'highlightedName',
          R.compose(
            R.prop('name'),
            highlightMatchesInObjectCurried(searchTermPaths, searchTerm),
          ),
        ),
        assocBy(
          'highlightedAddress',
          R.compose(
            R.path(['address', 'formatted_address']),
            highlightMatchesInObjectCurried(searchTermPaths, searchTerm),
          ),
        ),
      ),
    ),
    R.uniqBy(R.prop('_id')),
    R.converge(R.concat, [
      R.compose(R.reject(isNilOrEmpty), R.map(R.prop('masterProperty'))),
      R.identity,
    ]),
    filterDocumentsMatchedByPaths(searchTermPaths, searchTerm),
    R.pathOr([], ['client', 'searchProperties', 'hits']),
  );

export function SelectPropertiesComponent({ formData, ...props }) {
  const [searchTerm, setSearchTerm] = useState('');

  const { clientId } = formData || {};

  const { data } = useQuery(clientPropertiesQuery, {
    variables: {
      clientId,
      searchInput: { from: 0, size: MAX_ITEMS },
    },
    skip: !clientId,
  });

  const properties = prepareProperties(searchTerm)(data);

  return (
    <>
      <FlexColumn>
        <FormFieldLabel size="13px" padding="0">
          Properties
        </FormFieldLabel>
        <FlexRowS>
          <NoteContainer>
            Note: if no properties are selected, user will be granted access to
            all properties.
          </NoteContainer>
          <SearchInputComp
            name="search-client-portal-properties"
            placeholder="Search properties"
            onChange={(a) => setSearchTerm(a.target.value)}
            value={searchTerm}
          />
        </FlexRowS>
      </FlexColumn>
      <PropertiesListContainer>
        <SelectPropertiesTable {...props} properties={properties} />
      </PropertiesListContainer>
    </>
  );
}

SelectPropertiesComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formData: object,
};
