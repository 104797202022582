import React from 'react';
import { func } from 'prop-types';
import { Select } from '@poly/admin-book';
import { BulkEditObjectType } from '@poly/constants';

const objectTypeSelectOptions = [
  { value: BulkEditObjectType.PROJECTS, label: 'Projects' },
];

export function ObjectTypeSelect({ onChange, setObjectType, ...props }) {
  const onObjectTypeSelectChange = (objectType) => {
    setObjectType(objectType);
    onChange(objectType);
  };

  const selectProps = {
    options: objectTypeSelectOptions,
    placeholder: 'Select object type',
    width: '160px',
    required: true,
    onChange: onObjectTypeSelectChange,
    ...props,
  };

  return <Select {...selectProps} />;
}

ObjectTypeSelect.propTypes = {
  onChange: func,
  setObjectType: func,
};
