import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { supplierAddressColumn } from '@poly/admin-ui';
import { keywordSortQuery } from '@poly/client-utils';
import { WindowedTable } from '@poly/admin-book';
import {
  createFullTextSearchQuery,
  getNestedQuery,
  removePropDeep,
} from '@poly/utils';
import {
  NOTHING_UI_STRING,
  SupplierStatuses,
  SupplierTypes,
} from '@poly/constants';

import {
  supplierNameColumn,
  supplierPhoneColumn,
  supplierRatingColumn,
  supplierHourlyRateColumn,
} from '../columns/suppliers.js';
import {
  FilterTypePath,
  FilterStatusPath,
} from '../../core/constants/search.js';
import { SupplierFilterTypes } from '../../pagesHeaders/SupplierSearchPageHeader/filter/constants.js';
import { getDistanceSortQuery } from '../../forms/assignSupplierForm/utils.js';
import {
  getQueryFromCheckboxes,
  getFuzzinessQuery,
  getWildCardQuery,
  getEntityQuery,
  ifNotEmpty,
} from '../commonTableUtils.js';

// mapUIProjectTypes :: UIType -> Type || [Type]
const mapUISupplierTypes = R.cond([
  [
    R.equals(SupplierFilterTypes.SUBCONTRACTOR),
    R.always(SupplierTypes.SUBCONTRACTOR),
  ],
  [
    R.equals(SupplierFilterTypes.ADMINISTRATIVE),
    R.always(SupplierTypes.ADMINISTRATIVE),
  ],
  [R.T, R.always([])],
]);

// generateSupplierStatusQuery :: Object -> Object
const generateSupplierStatusQuery = R.compose(
  getQueryFromCheckboxes(FilterStatusPath, R.identity),
  R.pick(R.values(SupplierStatuses)),
);

// generateSupplierTypeQuery :: Object -> Object
const generateSupplierTypeQuery = R.compose(
  getQueryFromCheckboxes(FilterTypePath, mapUISupplierTypes),
  R.pick(R.values(SupplierFilterTypes)),
);

// getSupplierServiceTypeQuery :: Object -> Object
const getSupplierServiceTypeQuery = R.compose(
  getNestedQuery('company.services', 'serviceId'),
  R.prop('serviceTypeId'),
);

// getSupplierWildCardQuery :: Object -> Object
const getSupplierWildCardQuery = R.compose(
  getWildCardQuery('company.address.address_parts.postal_code'),
  R.prop('zipCode'),
);

/**
 * getMustQuery :: DataObject -> QueryObjectMust
 */
// eslint-disable-next-line import/no-unused-modules
export const getMustQuery = R.compose(
  R.objOf('must'),
  R.when(R.isEmpty, R.always(null)),
  R.reject(R.isNil),
  R.converge(Array.of, [
    generateSupplierTypeQuery,
    generateSupplierStatusQuery,
    getSupplierServiceTypeQuery,
    getEntityQuery(
      'state',
      'company.address.address_parts.administrative_area_level_1',
    ),
    getEntityQuery('country', 'company.address.address_parts.country'),
    getFuzzinessQuery('city', 'company.address.address_parts.locality'),
    getSupplierWildCardQuery,
  ]),
);

/**
 * getFilterQuery :: DataObject -> QueryObjectFilter
 */
// eslint-disable-next-line import/no-unused-modules
export const getFilterQuery = R.compose(
  R.objOf('filter'),
  ifNotEmpty(R.objOf('geo_distance')),
  R.ifElse(
    R.propSatisfies(R.isNil, 'geocodeAddress'),
    R.always({}),
    R.converge(R.mergeRight, [
      R.pipe(
        R.prop('radiusDistance'),
        R.concat(R.__, 'miles'),
        R.objOf('distance'),
      ),
      R.pipe(
        R.prop('geocodeAddress'),
        R.defaultTo(''),
        R.objOf('company.address.location'),
      ),
    ]),
  ),
);

/**
 * prepareGeocodeAddress :: ObjectWilCoordinates -> PreparedObjectWithCoordinates
 */
// eslint-disable-next-line import/no-unused-modules
export const prepareGeocodeAddress = R.when(
  R.complement(R.isNil),
  R.pipe(
    removePropDeep('__typename'),
    // we set all coordinates to 0 to display no results if zip code not exists
    R.map(R.when(R.isNil, R.always(0))),
  ),
);

// getMatchItem :: (String, String) -> QueryObjectMatch
const getMatchObject = R.curry((field, value) => ({
  match: { [field]: value },
}));

/**
 * getShouldQuery :: DataObject -> QueryObjectShould
 */
// eslint-disable-next-line import/no-unused-modules
export const getShouldQuery = R.compose(
  R.objOf('should'),
  R.ifElse(
    R.isNil,
    R.always(null),
    R.converge(Array.of, [
      getMatchObject('company.name'),
      getMatchObject('company.dba'),
      getMatchObject('remit.name'),
      getMatchObject('tax.name'),
      createFullTextSearchQuery,
    ]),
  ),
  R.prop('searchName'),
);

// generateSearchQueryByConfig :: DataObject -> QueryObject
export const generateSupplerSearchQueryByConfig = R.compose(
  ifNotEmpty(R.objOf('bool')),
  R.reject(R.isNil),
  R.mergeAll,
  R.converge(Array.of, [getShouldQuery, getMustQuery, getFilterQuery]),
);

export const searchSuppliersTableStyles = css`
  height: calc(100% - 30px);

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 10%;
  }

  th:last-child,
  td:last-child {
    width: 5%;
  }

  padding-bottom: 10px;
`;

export const SupplierSearchTableS = styled(WindowedTable)`
  ${searchSuppliersTableStyles};
`;

// supplierDistanceSortQuery :: Coordinates -> String -> [ElasticSortQUery]
const supplierDistanceSortQuery = (geocodeAddress) => (order) =>
  [
    getDistanceSortQuery(geocodeAddress, order),
    ...keywordSortQuery(['company', 'name'])(order),
  ];

export const getSearchSuppliersTableConfig = (geocodeAddress) => [
  supplierNameColumn,
  supplierAddressColumn,
  supplierPhoneColumn,
  supplierRatingColumn,
  supplierHourlyRateColumn,
  [
    'Distance',
    R.propOr(NOTHING_UI_STRING, 'distance'),
    ...(geocodeAddress ? [supplierDistanceSortQuery(geocodeAddress)] : []),
  ],
];
