import React from 'react';
import styled from 'styled-components';
import { arrayOf, func, shape, string } from 'prop-types';
import { TableSearchInput } from '@poly/admin-ui/src/components/TableSearchInput.js';
import { useModalContext } from '@poly/admin-ui/src/components/Modal/ModalProvider.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import {
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book/src/Header/index.js';

import { FlexRow } from '../../components/appBar/HistoryPopUp/historyComponents.js';
import { AssetManufacturersForm } from './AssetManufacturersForm.js';
import { addAssetManufacturerFormId } from './constants.js';
import { MergeAssetComponentsButton } from './MergeAssetComponentsButton.js';
import { mergeAssetComponentsEntity } from './merge-config.js';
import { FlexCenterWrapper } from '../../components/ExportButtons.js';
import { AssetManufacturersExportButtons } from './AssetManufacturersExportButtons.js';

const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
`;

const HeaderLinksWrapper = styled(FlexRow)`
  justify-content: space-between;
  width: 115px;
`;

function AddAssetManufacturerButton() {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: addAssetManufacturerFormId,
      title: 'Create New Manufacturer',
      formId: addAssetManufacturerFormId,
      btnCaption: 'Create',
      content: <AssetManufacturersForm isNewManufacturer />,
    });

  return (
    <LinkButton
      onClick={onClick}
      data-testid="add-new-asset-manufacturer-button-id"
    >
      Add New
    </LinkButton>
  );
}

export function AssetManufacturersPageHeader({
  selectedRows,
  setSelectedRows,
  assetManufacturers,
  ...props
}) {
  return (
    <PageHeaderContainer height="100px">
      <MainHeader>Asset Manufacturers</MainHeader>
      <FlexColumnWrapper>
        <FlexCenterWrapper gap="25px">
          <AssetManufacturersExportButtons {...props} />
          <TableSearchInput />
        </FlexCenterWrapper>

        <HeaderLinksWrapper>
          <MergeAssetComponentsButton
            selectedRows={selectedRows}
            documents={assetManufacturers}
            setSelectedRows={setSelectedRows}
            mergeEntity={mergeAssetComponentsEntity.MANUFACTURER}
          />
          <AddAssetManufacturerButton />
        </HeaderLinksWrapper>
      </FlexColumnWrapper>
    </PageHeaderContainer>
  );
}

AssetManufacturersPageHeader.propTypes = {
  selectedRows: arrayOf(string),
  setSelectedRows: func,
  assetManufacturers: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ),
};
