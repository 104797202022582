import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { FormField, LinkButton } from '@poly/admin-book';
import { arrayOf, func, number, object, shape, string } from 'prop-types';

import { DeleteLineIcon } from './components/DeleteLineIcon.js';
import { FieldToEditSelect } from './FieldToEditSelect.js';
import { getAlreadySelectedFields } from './helpers/already-selected-fields.js';
import { FieldToEditValueComponent } from './components/FieldToEditValueComponent/FieldToEditValueComponent.js';

const FieldsToEditLineWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${R.propOr('space-between', 'justify')};
  padding: 10px 24px;
  border-bottom: 1px solid #ebebeb;
`;

const FieldsToEditRowGroupS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;

const FormFieldWrapper = styled.div`
  width: ${R.prop('width')};
`;

const DeleteLineIconS = styled(DeleteLineIcon)`
  margin-left: 12px;
`;

const ChangeToLabelS = styled.div`
  line-height: 12px;
  font-size: 10px;
  font-weight: 500;
  color: #999;
  margin: 10px 0 0 0;
`;

// getCurrentSelectedField :: Int -> [{ field: String }] -> String
const getCurrentSelectedField = (index) => R.path([index, 'field']);

export function FieldsToEditLine({
  name,
  index,
  objectType,
  changeFieldValue,
  fields: { push, value, remove },
}) {
  const isFirstLine = index === 0;
  const alreadySelectedFields = getAlreadySelectedFields(value);

  const onAddFieldsToEdit = (e) => {
    e.preventDefault();
    push({});
  };

  if (isFirstLine) {
    return (
      <FieldsToEditLineWrapperS justify="flex-end">
        <LinkButton onClick={onAddFieldsToEdit}>Add New Item</LinkButton>
      </FieldsToEditLineWrapperS>
    );
  }

  const selectedField = getCurrentSelectedField(index)(value);

  return (
    <FieldsToEditLineWrapperS>
      {!isFirstLine && (
        <FieldsToEditRowGroupS>
          <FormFieldWrapper width="30%">
            <FormField
              name={`${name}.field`}
              Component={FieldToEditSelect}
              additionalProps={{
                index,
                objectType,
                changeFieldValue,
                alreadySelectedFields,
              }}
            />
          </FormFieldWrapper>
          <ChangeToLabelS>change to</ChangeToLabelS>
          <FormFieldWrapper width="50%">
            <FormField
              name={`${name}.fieldValue`}
              Component={FieldToEditValueComponent}
              additionalProps={{ selectedField, objectType, rowIndex: index }}
            />
          </FormFieldWrapper>
          <DeleteLineIconS
            name="delete"
            size={18}
            onClick={() => remove(index)}
          />
        </FieldsToEditRowGroupS>
      )}
    </FieldsToEditLineWrapperS>
  );
}

FieldsToEditLine.propTypes = {
  name: string,
  index: number,
  objectType: string,
  changeFieldValue: func,
  fields: shape({
    push: func,
    remove: func,
    // eslint-disable-next-line react/forbid-prop-types
    value: arrayOf(object),
  }),
};
