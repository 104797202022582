import { debounce } from '@poly/utils';
import * as R from 'ramda';
import { useMemo, useCallback, useEffect } from 'react';

import { safeLocalStorage } from '../../safeLocalStorage.js';

export const retainedValueKeyPrefix = 'polyPersistentValue.';

// getRetainedValueKey :: String -> String
// eslint-disable-next-line import/no-unused-modules
export const getRetainedValueKey = R.concat(retainedValueKeyPrefix);

export const usePersistentFormValue = (persistKey = '') => {
  const getCurrentRetainedValue = () => {
    const value = safeLocalStorage.getItem(getRetainedValueKey(persistKey));

    if (value) {
      return JSON.parse(value);
    }
    return value;
  };

  const retainedValue = useMemo(getCurrentRetainedValue, []);

  const onChangePersistentValue = useCallback(
    (value) => {
      if (value) {
        safeLocalStorage.setItem(
          getRetainedValueKey(persistKey),
          JSON.stringify(value),
        );
      } else {
        safeLocalStorage.removeItem(getRetainedValueKey(persistKey));
      }
    },
    [persistKey],
  );

  const onChangePersistentValueDebounced = useCallback(
    debounce(500)((v) => {
      onChangePersistentValue(v);
    }),
    [],
  );

  useEffect(() => () => onChangePersistentValueDebounced?.cancel?.(), []);

  const cleanupRetainedValue = useCallback(() => {
    onChangePersistentValueDebounced?.cancel?.();
    localStorage.removeItem(getRetainedValueKey(persistKey));
  }, [persistKey, onChangePersistentValueDebounced]);

  return {
    onChangePersistentValue: onChangePersistentValueDebounced,
    cleanupRetainedValue,
    retainedValue,
    // use non Memo retained value to prevent clear data on refetch
    getRetainedValue: getCurrentRetainedValue,
  };
};
