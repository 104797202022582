import styled from 'styled-components';
import React from 'react';
import { string, func } from 'prop-types';

import { getThemeColor } from '../utils.js';

const LabelS = styled.label`
  color: ${getThemeColor(['accent2'])};
  font-size: 12px;
  cursor: pointer;
`;
export function UploadFilesBtn({ text, onChange, className, id, ...props }) {
  return (
    <>
      <input {...props} id={id} type="file" onChange={onChange} hidden />
      <LabelS className={className} htmlFor={id}>
        {text}
      </LabelS>
    </>
  );
}

UploadFilesBtn.propTypes = {
  text: string,
  onChange: func,
  className: string,
  id: string.isRequired,
};

UploadFilesBtn.defaultProps = { text: 'Upload Files', className: '' };
