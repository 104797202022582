import * as R from 'ramda';
import { ALL } from '@poly/admin-ui';
import { NOTHING_UI_STRING } from '@poly/constants';
import {
  formatDateProp,
  pathOrNothingUI,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';

import {
  projectStatusColumn,
  projectStartDateColumn,
  projectDescriptionColumn,
} from '../../../../modules/tables/columns/projects.js';

// getClientSidebarProjectsXlsExportRowConfig :: Project -> [ExcelExportDataCell]
const getClientSidebarProjectsXlsExportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
  ]),
  R.juxt([
    pathOrNothingUI(['projectId']),
    projectDescriptionColumn[1],
    pathOrNothingUI(['property', 'name']),
    pathOrNothingUI(['client', 'name']),
    pathOrNothingUI(['manager', 'fullName']),
    projectStatusColumn[1],
    projectStartDateColumn[1],
    R.ifElse(
      R.prop('endDate'),
      formatDateProp('endDate'),
      R.always(NOTHING_UI_STRING),
    ),
  ]),
);

export const getClientSidebarProjectsXlsExportConfig = (
  currentTab,
  foundProjects,
) => ({
  exportFileName: `client_projects_${
    currentTab === ALL ? 'all' : R.toLower(currentTab)
  }_export.xlsx`,
  columnWidths: [15, 40, 20, 20, 20, 20, 10, 10],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      [
        'Project ID',
        'Description',
        'Property',
        'Client',
        'Assigned SCR',
        'Status',
        'Start Date',
        'End Date',
      ],
    ),
    ...R.map(getClientSidebarProjectsXlsExportRowConfig, foundProjects),
  ],
});
