import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, shape, string, func, number, bool } from 'prop-types';
import { getProjectSpendTypeOrNothingUI } from '@poly/client-utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { NOTHING_UI_STRING } from '@poly/constants';
import {
  getProjectSpendCostByConfig,
  convertCentsToDollars,
  formatTotal,
  formatDate,
} from '@poly/utils';
import {
  moneyColumnStyles,
  useMoreScroll,
  getThemeColor,
  Popover,
  Icon,
  Cell,
  Text,
  Row,
} from '@poly/admin-book';

import {
  CellTitleS,
  TableHeaderC,
} from '../../../modules/tables/commonTableComps.js';
import { SupplierLink } from '../../../components/Links.js';
import { FlexColumn } from '../../../components/FlexContainer.js';
import { ProjectLink } from '../../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { spendReportEntryShapePropType } from '../SpendDetailsPageHeader/spendReportsPropTypes.js';

export const BorderedRow = styled(Row)`
  border-bottom: ${({ isLast, ...props }) =>
    isLast ? `1px solid ${getThemeColor(['midLight'])(props)}` : 'none'};

  ${({ hasError, ...props }) =>
    hasError ? `color: ${getThemeColor(['accent'])(props)};` : ''};
`;

export const CellS = styled(Cell)`
  vertical-align: middle;
`;

export const CellTotalS = styled(CellS)`
  margin-right: 14px;
  font-size: 16px;
  width: 120px !important;
  text-align: right;
  line-height: 34px;
`;

export const SpendReportTableS = styled.table`
  background-color: ${getThemeColor(['lightest'])};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-spacing: 0;
  height: 100%;
  overflow-y: ${({ withoutHierarchy }) =>
    withoutHierarchy ? 'auto' : 'hidden'};

  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: 95px;
  }

  ${moneyColumnStyles(8, 110)};
  ${moneyColumnStyles(9, 100)};
  ${moneyColumnStyles(10, 105)};
`;

export const RowTotalS = styled(Row)`
  justify-content: flex-end;
  display: flex;
  align-items: center;
  td:last-child {
    padding-left: 5px;
  }
`;

const columnPropTypes = arrayOf(func);
const headersPropTypes = arrayOf(
  shape({
    title: string.isRequired,
  }),
);

const rowPropTypes = {
  propertyId: string.isRequired,
  propertyName: string.isRequired,
  invoicesTotal: number.isRequired,
  clientInvoicesTotal: number.isRequired,
  propertyInvoicesReport: arrayOf(shape({ _id: string.isRequired })),
};

TableHeaderC.propTypes = {
  headers: headersPropTypes,
};

export const TableFlexRow = FlexColumn.withComponent('tbody');

function PropertyWithInvoicesSection({
  headers,
  columns,
  propertyId,
  propertyName,
  propertyInvoicesReport,
}) {
  const firstColumn = R.head(columns);
  const lastColumn = R.last(columns);
  const filteredColumns = R.without([firstColumn, lastColumn], columns);

  return (
    <>
      <Row key={`row_${propertyId}_title`}>
        <CellTitleS>{propertyName}</CellTitleS>
      </Row>
      {propertyInvoicesReport.map((project) => {
        const { invoices } = project;
        return invoices.map((invoice, index) => (
          <BorderedRow
            key={`row_${invoice._id}`}
            isLast={index === invoices.length - 1}
            enableHoverEffect
          >
            {index === 0 ? (
              <CellS
                key={`cell_${propertyId}_${invoice._id}_0`}
                rowspan={invoices.length}
              >
                {firstColumn(project)}
              </CellS>
            ) : (
              <CellS />
            )}
            {filteredColumns.map((ColumnComp, i) => (
              <CellS
                key={`cell_${propertyId}_${invoice._id}_${headers[i].title}`}
              >
                {ColumnComp({
                  ...project,
                  ...invoice,
                  skipClientInvoice: index > 0,
                })}
              </CellS>
            ))}
            {index === 0 ? (
              <CellS
                key={`cell_${propertyId}_${invoice._id}_${invoices.length}`}
                rowspan={invoices.length}
              >
                {lastColumn(project)}
              </CellS>
            ) : (
              <CellS />
            )}
          </BorderedRow>
        ));
      })}
    </>
  );
}

PropertyWithInvoicesSection.propTypes = {
  headers: arrayOf(shape),
  columns: arrayOf(func),
  propertyId: string,
  propertyName: string,
  propertyInvoicesReport: arrayOf(shape),
};

function TableRowC({ rows, columns, headers }) {
  return (
    <>
      {rows.map(
        ({
          invoicesTotal,
          propertyName,
          propertyId,
          childProperties,
          clientInvoicesTotal,
          propertyInvoicesReport,
        }) => (
          <TableFlexRow key={`row_${propertyId}`}>
            <PropertyWithInvoicesSection
              headers={headers}
              columns={columns}
              propertyId={propertyId}
              propertyName={propertyName}
              propertyInvoicesReport={propertyInvoicesReport}
            />
            {childProperties?.map((childProperty) => (
              <PropertyWithInvoicesSection
                key={`child-property-${childProperty?.propertyId}`}
                headers={headers}
                columns={columns}
                {...childProperty}
              />
            ))}
            <RowTotalS key={`row_${propertyId}_total`}>
              <CellTotalS>
                {formatTotal(convertCentsToDollars(invoicesTotal))}
              </CellTotalS>
              <CellTotalS>
                {formatTotal(convertCentsToDollars(clientInvoicesTotal))}
              </CellTotalS>
            </RowTotalS>
          </TableFlexRow>
        ),
      )}
    </>
  );
}

TableRowC.propTypes = {
  rows: arrayOf(shape(rowPropTypes)).isRequired,
  columns: columnPropTypes,
  headers: arrayOf(shape({ title: string })),
};

function InvoiceNumberLink({ file, invoiceNumber }) {
  return file ? (
    <a href={file} target="_blank" rel="noopener noreferrer">
      {invoiceNumber}
    </a>
  ) : (
    invoiceNumber
  );
}

InvoiceNumberLink.propTypes = {
  file: string,
  invoiceNumber: string.isRequired,
};

// formatSupplierLink :: Supplier -> ReactComponent
export const formatSupplierLink = (supplier) => (
  <SupplierLink
    name={R.path(['company', 'name'], supplier)}
    _id={R.prop('_id', supplier)}
  />
);

const SpendReportProjectLinkWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    flex-shrink: 0;

    > div > div:last-child {
      left: -15px;
    }
  }

  > a {
    ${({ exempted }) => exempted && 'color: #F79009;'};
  }
`;

const PopoverContentWrapperS = styled.div`
  display: flex;
  color: #fff;
  font-size: 12px;
  max-width: 300px;
  line-height: 16px;
  border-radius: 5px;
  padding: 12px 15px;
  background-color: #12347a;
`;

function SpendReportProjectLink({
  projectType,
  projectNumber,
  exemptSalesTax,
}) {
  if (!projectNumber) {
    return null;
  }

  return (
    <SpendReportProjectLinkWrapperS exempted={exemptSalesTax}>
      <ProjectLink type={projectType} projectId={projectNumber}>
        {projectNumber}
      </ProjectLink>
      {exemptSalesTax && (
        <Popover
          position="bottom"
          bgColor="#12347a"
          isClickable={false}
          title={<Icon name="warning-info" size={11} color="#F79009" />}
          content={
            <PopoverContentWrapperS>
              This project is exempt from sales tax
            </PopoverContentWrapperS>
          }
        />
      )}
    </SpendReportProjectLinkWrapperS>
  );
}

SpendReportProjectLink.propTypes = {
  projectType: string,
  exemptSalesTax: bool,
  projectNumber: string,
};

export const commonSpendReportTableConfig = [
  ['Project #', SpendReportProjectLink],
  [
    'Invoice #',
    R.ifElse(
      R.prop('invoiceNumber'),
      InvoiceNumberLink,
      R.always(NOTHING_UI_STRING),
    ),
  ],
];

export const spendReportTableConfig = [
  ...commonSpendReportTableConfig,
  ['Invoice Date', R.compose(formatDate, R.prop('date'))],
  [
    'Supplier',
    R.ifElse(
      R.propSatisfies(R.isNil, 'supplierId'),
      R.always(NOTHING_UI_STRING),
      R.compose(
        formatSupplierLink,
        R.applySpec({
          _id: R.prop('supplierId'),
          company: { name: R.prop('supplier') },
        }),
      ),
    ),
  ],
  ['Service Type', R.propOr(NOTHING_UI_STRING, 'serviceType')],
  ['Description', R.propOr(NOTHING_UI_STRING, 'description')],
  ['Spend Type', getProjectSpendTypeOrNothingUI],
  ['Spend Cost', R.compose(formatTotal, getProjectSpendCostByConfig)],
  ['Cost', R.compose(formatTotal, convertCentsToDollars, R.propOr(0, 'total'))],
  [
    'Client Invoice',
    R.compose(
      formatTotal,
      convertCentsToDollars,
      R.propOr(0, 'clientInvoicesAmount'),
    ),
  ],
];

export function SpendReportsTableC({ reports, withoutHierarchy }) {
  const { headers, columns, rows } = useMapConfigToTableProps(
    R.map(R.over(R.lensProp('propertyInvoicesReport'), R.defaultTo([]))),
    spendReportTableConfig,
    reports,
  );

  const { MoreScrollIcon, onScroll } = useMoreScroll('0px');

  return (
    <>
      <SpendReportTableS
        onScroll={onScroll}
        withoutHierarchy={withoutHierarchy}
      >
        {withoutHierarchy && <TableHeaderC headers={headers} />}
        <TableRowC columns={columns} headers={headers} rows={rows} />
      </SpendReportTableS>
      <MoreScrollIcon />
    </>
  );
}

SpendReportsTableC.propTypes = {
  withoutHierarchy: bool,
  reports: arrayOf(spendReportEntryShapePropType),
};

const TextS = styled(Text)`
  text-align: center;
  margin-top: 20px;
  width: 100%;
`;

export function NoReportText() {
  return <TextS>No data to report</TextS>;
}
